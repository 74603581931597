import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Wrapper from "../components/layout/wrapper";
import { ConfigProvider, DatePicker, notification, Space } from 'antd';
import { connect } from "react-redux";

import { User } from "../models/user";
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/tr';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/tr_TR';
import trLocale from 'antd/lib/locale/tr_TR';

import ProfilePhoto from "../components/user/profilePhoto";
import { Modal, Button } from "react-bootstrap";
import TransactionDetail from "../components/transaction/detail";
import AgentGiroList from "../components/dashboard/agent/list";
import GiroList from "../components/dashboard/giro/list";
import PurchaseSaleAgreementList from "../components/dashboard/contract/purchase-sale-agreement/list";
import LeaseAgreementList from "../components/dashboard/contract/lease-agreement/list";
import CallAreaChart from "../components/dashboard/call/callAreaChart";
import TransactionMap from "../components/dashboard/map/transactionMap";
import RecruitmentMeetCountChart from "../components/dashboard/recruitment/recruitmentMeetCountChart";
import WatchScheduleActionCountChart from "../components/dashboard/asistant/watchScheduleActionCountChart";
import WatchSchedulesCarousel from "../components/dashboard/asistant/watchSchedulesCarousel";
import SatisfactionSurveyCarousel from "../components/dashboard/customer/satisfactionSurveyCarousel";
import WorkSchedulesCarousel from "../components/dashboard/employee/workSchedulesCarousel";
import TransactionTypesCountChart from "../components/dashboard/transaction/transactionTypesCountChart";
import TransactionChart from "../components/dashboard/transaction/transactionChart";
import RecruitmentMeetCountApexDonutChart from "../components/dashboard/recruitment/recruitmentMeetCountApexDonutChart";
import WatchScheduleActionCountApexDonutChart from "../components/dashboard/asistant/watchScheduleActionCountApexDonutChart";
import TransactionTypesCountApexDonutChart from "../components/dashboard/transaction/transactionTypesCountApexDonutChart";
import TransactionApexLineChart from "../components/dashboard/transaction/transactionApexLineChart";
import AgentApexChart from "../components/dashboard/agent/agentApexChart";
import SatisfactionSurveyGaugeChart from "../components/dashboard/customer/satisfactionSurveyGaugeChart";
import LazyLoad from "react-lazyload";
import HeatmapMap from "../components/dashboard/map/heatMap";
import AdminDashboard from "../components/dashboard/screen/adminDashboard";
import EmployeeDashboard from "../components/dashboard/screen/employeeDashboard";
import AgentDashboard from "../components/dashboard/screen/agentDashboard";
import AntDesignRangePicker from "../components/constant/antDesign/rangePicker";

dayjs.locale("tr");
dayjs.extend(localeData);
dayjs.extend(updateLocale);

// Set Monday as the first day of the week
dayjs.updateLocale('tr', {
    weekStart: 1,
    weekdays: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
    weekdaysShort: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
});

const { RangePicker } = DatePicker;
const rangePresets: any = {
    'Son 7 Gün': [dayjs().subtract(7, 'days'), dayjs()],
    'Bu Ay': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Geçen Ay': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Bu Yıl': [dayjs().startOf('year'), dayjs()],
    'Geçen Yıl': [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
    '1. Çeyrek': [dayjs().startOf('year').add(0, 'month').startOf('month'), dayjs().startOf('year').add(4, 'month').endOf('month')],
    '2. Çeyrek': [dayjs().startOf('year').add(3, 'month').startOf('month'), dayjs().startOf('year').add(5, 'month').endOf('month')],
    '3. Çeyrek': [dayjs().startOf('year').add(6, 'month').startOf('month'), dayjs().startOf('year').add(8, 'month').endOf('month')],
    '4. Çeyrek': [dayjs().startOf('year').add(9, 'month').startOf('month'), dayjs().startOf('year').add(11, 'month').endOf('month')]
};

const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
for (let i = 0; i < 12; i++) {
    rangePresets[monthNames[i]] = [dayjs().month(i).startOf('month'), dayjs().month(i).endOf('month')];
}

const Dashboard = (props: { user: User }) => {
    const [verData, setVerData] = useState({ name: 'Ver', visible: true });
    const [selectedDateName, setSelectedDateName] = useState<string>('Bu Ay');
    const [send_filter, setSendFilter] = useState(true);

    useEffect(() => {
        localStorage.removeItem('data6');
        localStorage.removeItem('lastUpdate6');
        localStorage.removeItem('data7');
        localStorage.removeItem('lastUpdate7');

        const storedData = localStorage.getItem('data9');

        if (!storedData) {
            localStorage.setItem('data9', JSON.stringify(verData));
        } else {
            setVerData(JSON.parse(storedData));
        }
    }, []);

    useEffect(() => {
        const lastUpdate = localStorage.getItem('lastUpdate9');

        if (!lastUpdate || new Date().getTime() - new Date(lastUpdate).getTime() > 1000 * 60 * 60 * 24 * 7) {
            const newData = { name: 'İşlem güncelleme alanında sözleşme tarihi değiştirme sorunu çözüldü', visible: true };

            localStorage.removeItem('data9');
            localStorage.setItem('data9', JSON.stringify(newData));
            localStorage.setItem('lastUpdate9', new Date().toString());

            showNotification(newData.name);

            setVerData({ ...verData, visible: false });
        }
    }, [verData]);

    useEffect(() => {
        localStorage.setItem('data9', JSON.stringify(verData));
    }, [verData]);

    const showNotification = (name: string) => {
        const verInfo = localStorage.getItem('ver');
        notification.open({
            type: 'info',
            duration: 0,
            message: `Versiyon ${verInfo} Güncellemesi`,
            description: (
                <div>
                    <strong>Güncelleme kapsımında yapılan yenilikler</strong>
                    <ul className="list-unstyled">
                        <li>✔ Yeni işlem oluşturma sayfasında hesaplama alanında hız çalışmesı ve fiyat hesaplamaları üzerinde güncellemeler yapıldı.</li>
                        <li>✔ Askıda kalan giriş işlemi sorunu çözüldü.</li>
                        <li>✔ Günlük iş raporu yenilenmiş haliyle yeniden aktif edildi.</li>
                    </ul>
                </div>
            ),
        });
    };

    const [date_range, setDateRange] = useState<any | null>([]);
    const [start_date, setStartDate] = useState<any | null>(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [end_date, setEndDate] = useState<any | null>(dayjs().endOf('month').format('YYYY-MM-DD'));

    //Transciton Modal 
    const [show_id, setShowID] = useState<number>(0);
    const [show_full_name, setShowFullName] = useState<any | null>('');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const startOfWeek = moment().startOf('isoWeek').format('YYYY-MM-DD');
    const endOfWeek = moment().endOf('isoWeek').format('YYYY-MM-DD');

    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

    const startOfYear = moment().startOf('year').format('YYYY-MM-DD');
    const endOfYear = moment().endOf('year').format('YYYY-MM-DD');

    const modalLoaded = () => {
    };

    const renderDashboard = () => {
        if (props.user.role_id === 1 || props.user.id === 1) {
            return (
                <AdminDashboard
                    selectedDateName={selectedDateName}
                    start_date={start_date}
                    end_date={end_date}
                    send_filter={send_filter}
                />
            );
        } else if (props.user.role_id === 11) {
            return (
                <AgentDashboard props={props} 
                selectedDateName={selectedDateName}
                start_date={start_date}
                end_date={end_date}
                send_filter={send_filter}
                />
            );
        } else {
            return <EmployeeDashboard props={props} />;
        }
    };


    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Remax AQUA</h4>
                        {props.user.role_id === 1 || props.user.id === 1 || props.user.role_id === 11  ?
                            <Space>
                                <label className="pt-2">Rapor Tarih Aralığı</label>
                                <div className="form-group select-style">
                                    {/* <ConfigProvider locale={{
                                        ...trLocale,
                                        Calendar: {
                                            ...(trLocale.Calendar || {}),
                                            lang: {
                                                ...((trLocale.Calendar && trLocale.Calendar.lang) || {}),
                                                shortWeekDays: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
                                            },
                                        },
                                    }}>
                                        <RangePicker
                                            format={'DD/MM/YYYY'}
                                            ranges={rangePresets}
                                            value={date_range}
                                            style={{ width: '100%' }}
                                            onChange={(value: any, dateString) => {
                                                setDateRange(value);
                                                setStartDate(dayjs(dateString.toString().split(',')[0], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setEndDate(dayjs(dateString.toString().split(',')[1], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setSendFilter(prev => !prev);
                                                const selectedRangeKey = Object.keys(rangePresets).find(key => {
                                                    const range = rangePresets[key];
                                                    return dayjs(value[0]).isSame(range[0]) && dayjs(value[1]).isSame(range[1]);
                                                });
                                                if (selectedRangeKey) {
                                                    setSelectedDateName(selectedRangeKey);
                                                } else {
                                                    setSelectedDateName(dateString.toString().split(',')[0] + '-' + dateString.toString().split(',')[1]);
                                                }
                                            }
                                            }
                                        />
                                    </ConfigProvider> */}
                                    <AntDesignRangePicker
                                        dateRange={date_range}
                                        setDateRange={setDateRange}
                                        setStartDate={setStartDate}
                                        setEndDate={setEndDate}
                                        setSendFilter={setSendFilter}
                                        setSelectedDateName={setSelectedDateName}
                                    />
                                </div>

                            </Space> : null}
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="javascript: void(0);">Gösterge Paneli</a></li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            {renderDashboard()}
        </Wrapper>

    )
}

export default connect(
    (state: { user: User }) => {
        return {
            user: state.user
        };
    }
)(Dashboard);