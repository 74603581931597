import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink, Navigate } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import Paginator from "../../../components/paginator";
import Mailto from "../../../components/link/mailto";
import Tel from "../../../components/link/tel";
import { Customer } from "../../../models/accounting/customer/customer";
import { Modal, ModalBody, ModalFooter, ModalTitle, Button } from "react-bootstrap";
import { NumericFormat, PatternFormat } from 'react-number-format';
import { FiArrowRight, FiEdit2, FiPlus } from "react-icons/fi";


import Swal from 'sweetalert2';
import { DatePicker, Tag, Select, Table } from 'antd';

//Table
import type { ColumnsType } from 'antd/es/table';

import "react-datepicker/dist/react-datepicker.css";
import { PaymentMethod } from "../../../models/attribute/paymentMethod/paymentMethod";
import TransactionDetail from "../../../components/transaction/detail";
import ProfilePhoto from "../../../components/user/profilePhoto";
import { useGiroTypeContext } from "../../../providers/attribute/giroType/giroTypeProvider";
import { GiroType } from "../../../models/attribute/giroType/giroType";

import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';

import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { PropertyGenus } from "../../../models/attribute/propertyGenus/propertyGenus";
import { useUserContext } from "../../../providers/user/userProvider";
import { useCountryContext } from "../../../providers/general/location/country/countryProvider";
import { useResourceOfSellContext } from "../../../providers/attribute/sourceOfSell/sourceOfSellProvider";
import { useResourcesOfFindContext } from "../../../providers/attribute/sourceOfFind/sourceOfFindProvider";
import { useLandOfficeContext } from "../../../providers/general/location/landOffice/landOfficeProvider";
import { useProvinceContext } from "../../../providers/general/location/province/provinceProvider";
import { usePropertyGenusContext } from "../../../providers/attribute/propertyGenus/propertyGenusProvider";
import { PhoneCode } from "../../../models/general/country/phoneCode";
import { PropertyType } from "../../../models/attribute/propertyType/propertyType";
import { User } from "../../../models/user";
import { SourceOfFind } from "../../../models/attribute/sourceOfFind/sourceOfFind";
import { Province } from "../../../models/general/location/province/province";
import { District } from "../../../models/general/location/district/district";
import { Neighbourhood } from "../../../models/general/location/neighbourhood/neighbourhood";
import { Site } from "../../../models/general/site/site";
import { Status } from "../../../models/attribute/status/status";
import LeaseAgreementDetail from "../../../components/contract/lease-agreement/detail";
import { LeaseAgreementReport } from "../../../models/contract/lease-agreements/leaseAgreementReport";
import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import { MeetMeta } from "../../../models/recruitment/meet/meetMeta";
import { Meet } from "../../../models/recruitment/meet/meet";
import { Attribute } from "../../../models/attribute/attribute";
import { Metadata } from "react-phone-number-input";
import MeetDetail from "../../../components/recruitment/meet/detail";
import AntDesignRangePicker from "../../../components/constant/antDesign/rangePicker";


dayjs.locale("tr");
const { Option } = Select;

const { RangePicker } = DatePicker;
const currentYear = moment().year();
const rangePresets: any = {
    'Son 7 Gün': [dayjs().subtract(7, 'days'), dayjs()],
    'Bu Ay': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Geçen Ay': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Bu Yıl': [dayjs().startOf('year'), dayjs()],
    'Geçen Yıl': [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
    '1. Çeyrek': [dayjs().startOf('year').add(0, 'month').startOf('month'), dayjs().startOf('year').add(4, 'month').endOf('month')],
    '2. Çeyrek': [dayjs().startOf('year').add(3, 'month').startOf('month'), dayjs().startOf('year').add(5, 'month').endOf('month')],
    '3. Çeyrek': [dayjs().startOf('year').add(6, 'month').startOf('month'), dayjs().startOf('year').add(8, 'month').endOf('month')],
    '4. Çeyrek': [dayjs().startOf('year').add(9, 'month').startOf('month'), dayjs().startOf('year').add(11, 'month').endOf('month')]
};

const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
for (let i = 0; i < 12; i++) {
    rangePresets[monthNames[i]] = [dayjs().month(i).startOf('month'), dayjs().month(i).endOf('month')];
}


//Table
interface DataType {
    id(id: any): unknown;
    candidate_name: any;
    advertisementType: Attribute;
    communication: Attribute;
    result: Attribute;
    user: User;
    creadet_at: any;
    meta: MeetMeta;
    key: React.Key;
}

const Meets = () => {

    const [selected_agents, setSelectedAgents] = useState([]);

    const calculateDateDifference = (end_date: any) => {
        const today = moment();
        const endDate = moment(end_date, "DD/MM/YYYY");
        const differenceInDays = endDate.diff(today, "days");
        return differenceInDays;
    };

    const [reports, setReports] = useState<LeaseAgreementReport | any | null>(null);
    const [users, setUsers] = useUserContext();
    const [giro_types, setGiroTypes] = useGiroTypeContext();
    const [phone_codes, setPhoneCodes] = useCountryContext();
    const [resources_of_find, setResourcesOfFind] = useResourcesOfFindContext();
    const [land_offices, setLandOffices] = useLandOfficeContext();
    const [provinces, setProvinces] = useProvinceContext();
    const [property_genuses, setPropertyGenuses] = usePropertyGenusContext();

    const [data, setData] = useState<any | null>([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [show_id, setShowID] = useState<number>(0);
    const [show_full_name, setShowFullName] = useState<any | null>('');
    const [redirect_id, setRedirectId] = useState(0);
    const [property_types, setPropertyTypes] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [districts, setDistricts] = useState([]);
    const [neighbourhoods, setNeighbourhoods] = useState([]);

    const [meets, setMeets] = useState([]);
    const [meet, setMeet] = useState<Meet | any>([]);
    const [meetMeta, setMeetMeta] = useState([]);
    const [meetID, setMeetID] = useState('');

    //Detail Modal
    const [loading, setLoading] = useState(true);
    const [full_name, setFullName] = useState('');


    //Modal    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (id: number) => {
        (
            async () => {
                setShow(true);
                const meet = await axios.get(`recruitment/meets/${id}`);
                setMeet(meet.data);
                setMeetID(meet.data.id);
                setFullName(meet.data.first_name + " " + meet.data.last_name);
                setMeetMeta(meet.data.meta);
            }
        )()
    };

    const modalLoaded = () => {
    };

    const del = async (id: number) => {

        Swal.fire({
            title: 'Emin misiniz?',
            text: "Kalıcı olarak silinecektir",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c84ee',
            cancelButtonColor: '#fd625e',
            confirmButtonText: 'Evet, sil!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.delete(`recruitment/meets/${id}`);

                setMeets(meets.filter((u: Meet) => u.id !== id));
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Veri silindi',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }


    //Modal add meet
    const [name, setName] = useState('');
    const [definition, setDefinition] = useState('');
    const [meet_date_time, setMeetDateTime] = useState(new Date());

    //For Filter
    const [selectedDateName, setSelectedDateName] = useState<string>('Bu Ay');
    const [user_categories, setUserCategories] = useState([]);
    const [realtors, setRealtors] = useState([]);
    const [sites, setSites] = useState([]);

    const [property_genus_ids, setPropertyGenusIds] = useState<any | null>();
    const [property_type_ids, setPropertyTypeIds] = useState<any | null>([]);
    const [giro_type_id, setGiroTypeId] = useState<any | null>(null);
    const [category_id, setCategoryId] = useState<any | null>(null);
    const [extra_filter_see, setExtraFilterSee] = useState(false);
    const [realtor_id, setRealtorId] = useState<any | null>(null);
    const [realtor_search, setRealtorSearch] = useState('');
    const [site_id, setSiteId] = useState<any | null>(null);
    const [site_search, setSiteSearch] = useState('');
    const [source_of_sell_id, setSourceOfSellId] = useState<any | null>(null);
    const [source_of_find_id, setSourceOfFindId] = useState<any | null>(null);
    const [status_id, setStatusId] = useState<any | null>(null);
    const [province_id, setProvinceId] = useState<any | null>(null);
    const [province_name, setProvinceName] = useState('');
    const [district_id, setDistrictId] = useState<any | null>(null);
    const [district_name, setDistrictName] = useState('');
    const [neighbourhood_id, setNeighbourhoodId] = useState<any | null>(null);
    const [neighbourhood_name, setNeighbourhoodName] = useState('');
    const [land_office_id, setLandOfficeId] = useState<any | null>(null);
    const [date_range, setDateRange] = useState<any | null>([]);
    const [start_date, setStartDate] = useState<any | null>(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [end_date, setEndDate] = useState<any | null>(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [out_office_see, setOutOfficeSee] = useState(false);
    const [document_no, setDocumentNo] = useState<any | null>(null);
    const [document_no_val, setDocumentNoVal] = useState<any | null>(null);
    const [customer_name, setCustomerName] = useState<any | null>(null);
    const [customer_name_val, setCustomerNameVal] = useState<any | null>(null);
    const [customer_id_no, setCustomerIdNo] = useState<any | null>(null);
    const [customer_id_no_val, setCustomerIdNoVal] = useState<any | null>(null);
    const [bob, setBob] = useState<any | null>(null);
    const [parcel, setParcel] = useState<any | null>(null);
    const [filter, setFilter] = useState(false);
    const [send_filter, setSendFilter] = useState(true);
    const [filter_url, setFilterUrl] = useState<any | null>('');
    const [sort, setSort] = useState(false);
    const [sort_url, setSortUrl] = useState<any | null>('&sort[order]=desc');
    const [sort_req, setSortReq] = useState<any | null>([]);

    const [customer_phone_code_id, setCustomerPhoneCodeId] = useState<any | null>(34);
    const [customer_phone, setCustomerPhone] = useState<any | null>(null);
    const [clear_filter_inputs, setClearFilterInputs] = useState(false);

    const [metas, setCustomerCount] = useState([0]);
    const handleRemoveItem = (item: any) => {
        setCustomerCount(metas.filter(item => item !== item));
    };

    //Table

    const [pagination, setPagination] = useState<any | null>({
        current: 1,
        pageSize: 100,
        currentPageSize: 100,
        total: reports?.total,
        pageSizeOptions: [10, 20, 50, 100, 500, 100],
        showQuickJumper: true,
        showSizeChanger: true,
        showLessItems: true,
        responsive: true
    });

    const columns: ColumnsType<DataType> = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: true,
            responsive: ["md", "lg", "xl", "xxl"],
            render: (id, record) => {
                return (
                    <>
                        {id}
                    </>
                );
            },
        },
        {
            title: 'Ay',
            key: 'created_at',
            dataIndex: 'created_at',
            responsive: ["md", "lg", "xl", "xxl"],
            render: (created_at) => (
                <>
                    <Tag className={`label label-${moment(created_at, "DD/MM/YYYY").format('MM')}`}>{moment(created_at, "DD/MM/YYYY").format('MMMM')}</Tag>
                </>
            ),

        },
        {
            title: 'Görüşme Tarihi',
            dataIndex: 'meta',
            sorter: true,
            render: (meta) => {
                return (
                    <>
                        {meta[0]?.meet_date_time}
                    </>
                );
            },
        },
        {
            title: 'Ad Soyad',
            key: 'candidate_name',
            dataIndex: 'candidate_name',
            render: (id, record: any) => {

                return (
                    <>
                        <a onClick={() => { handleShow(record?.id); setShow(true); setShowID(record?.id); setShowFullName(`${record?.first_name} ${record?.last_name}`); }}>
                            {`${record?.first_name} ${record?.last_name}`}
                        </a>
                    </>
                );
            },
        },
        {
            title: 'İlan Türü',
            dataIndex: 'advertisementType',
            responsive: ["md", "lg", "xl", "xxl"],
            sorter: true,
            render: (advertisementType) => {
                return (
                    <>
                        {advertisementType?.name}
                    </>
                );
            },
        },
        {
            title: 'İletişim Kanalı',
            dataIndex: 'communication',
            sorter: false,
            render: (communication) => {

                return (
                    <>
                        <Tag color={communication?.color}>{communication?.name}</Tag>
                    </>
                );
            },
        },
        {
            title: 'Görüşme Sonucu',
            responsive: ["md", "lg", "xl", "xxl"],
            dataIndex: 'result',
            sorter: true,
            render: (result) => {
                return (
                    <>
                        <Tag color={result?.color}>{result?.name}</Tag>
                    </>
                );
            },
        },
        {
            title: 'Ekleyen Personel',
            responsive: ["md", "lg", "xl", "xxl"],
            dataIndex: 'user',
            sorter: true,
            render: (user) => {
                return (
                    <>
                        {user?.first_name} {user?.last_name}
                    </>
                );
            },
        },
        
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'created_at',
            sorter: true
        },
        {
            title: '',
            dataIndex: 'command',
            sorter: false,
            render: (id, record: any) => {
                return (
                    <>
                        <div className="dropdown">
                            <button className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="bx bx-dots-horizontal-rounded"></i>
                            </button>

                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <Link to={`/reacruitment/meets/${record.id}/edit`}
                                        className="dropdown-item">Güncelle</Link>
                                </li>
                                <li><a className="dropdown-item" href="#" onClick={() => del(record.id)}>Sil</a></li>
                            </ul>
                        </div>
                    </>
                );
            },
        },
    ];

    //Filter
    useEffect(() => {
        if (clear_filter_inputs) {
            setPropertyGenusIds([]);
            setPropertyTypeIds([]);
            setStatusId(null);
            setGiroTypeId(null);
            setCategoryId(null);
            setSiteId(null);
            setSiteSearch('');
            setSourceOfSellId(null);
            setSourceOfFindId(null);
            setProvinceId(null);
            setProvinceName('');
            setDistrictId(null);
            setDistrictName('');
            setNeighbourhoodId(null);
            setNeighbourhoodName('');
            setLandOfficeId(null);
            setSelectedAgents([]);
            setDateRange([]);
            setDocumentNo('');
            setDocumentNoVal('');
            setCustomerName('');
            setCustomerNameVal('');
            setCustomerIdNo('');
            setCustomerIdNoVal('');
            setCustomerPhone('');
            setCustomerPhoneCodeId(34);
            setBob('');
            setParcel('');
            setClearFilterInputs(false);
            setFilter(true);
        }
    }, [clear_filter_inputs]);

    useEffect(() => {

        let fUrl = '';
        if (giro_type_id > 0) {
            fUrl = `&filter_giro_type_id=${giro_type_id}`;
        }

        if (status_id > 0) {
            fUrl = `&filter_status_id=${status_id}`;
        }

        if (parseInt(document_no) > 0) {
            fUrl = `${fUrl}&filter_document_no=${document_no}`;
        }

        if (property_genus_ids?.length > 0) {
            property_genus_ids.forEach((property_genus_id: number) => {
                fUrl = `${fUrl}&filter_property_genus_id[]=${property_genus_id}`;
            });
        }

        if (property_type_ids?.length > 0) {
            property_type_ids.forEach((property_type_id: number) => {
                fUrl = `${fUrl}&filter_property_type_id[]=${property_type_id}`;
            });
        }

        if (parseInt(category_id) > 0) {
            fUrl = `${fUrl}&filter_category_id=${category_id}`;
        }

        if (selected_agents?.length > 0) {
            selected_agents.forEach((agent_id: number) => {
                fUrl = `${fUrl}&filter_agent_id[]=${agent_id}`;
            });
        }

        if (customer_name !== '' && customer_name !== ' ' && customer_name !== null && customer_name !== undefined) {
            fUrl = `${fUrl}&filter_customer_name=${customer_name}`;
        }

        if (customer_id_no !== '' && customer_id_no !== ' ' && customer_id_no !== null && customer_id_no !== undefined) {
            fUrl = `${fUrl}&filter_customer_id_no=${customer_id_no}`;
        }

        if (customer_phone !== '' && customer_phone !== ' ' && customer_phone !== null && customer_phone !== undefined) {
            fUrl = `${fUrl}&filter_customer_phone_code_id=${customer_phone_code_id}&filter_customer_phone=${customer_phone}`;
        }

        if (parseInt(realtor_id) > 0) {
            fUrl = `${fUrl}&filter_other_realtor_id=${realtor_id}`;
        }

        if (parseInt(source_of_find_id) > 0) {
            fUrl = `${fUrl}&filter_source_of_find_id=${source_of_find_id}`;
        }

        if (parseInt(source_of_sell_id) > 0) {
            fUrl = `${fUrl}&filter_source_of_sell_id=${source_of_sell_id}`;
        }

        if (parseInt(province_id) > 0) {
            fUrl = `${fUrl}&filter_province_id=${province_id}`;
        }

        if (parseInt(district_id) > 0) {
            fUrl = `${fUrl}&filter_district_id=${district_id}`;
        }

        if (parseInt(neighbourhood_id) > 0) {
            fUrl = `${fUrl}&filter_neighbourhood_id=${neighbourhood_id}`;
        }

        if (parseInt(bob) > 0) {
            fUrl = `${fUrl}&filter_bob=${bob}`;
        }


        if (parseInt(parcel) > 0) {
            fUrl = `${fUrl}&filter_parcel=${parcel}`;
        }

        if (parseInt(site_id) > 0) {
            fUrl = `${fUrl}&filter_site_id=${site_id}`;
        }

        if (parseInt(land_office_id) > 0) {
            fUrl = `${fUrl}&filter_land_office_id=${land_office_id}`;
        }

        setFilterUrl(fUrl);
        setFilter(false);
        setSendFilter(true);
    }, [filter]);

    useEffect(() => {
        setTimeout(() => {
            let sUrl = '';
            let sType = '';
            if (sort_req.order === undefined) {
                sUrl = '&sort[order]=desc';
            } else {
                (sort_req.order == 'ascend') ? sType = '&sort[order]=asc' : sType = '&sort[order]=desc';
                sUrl = `&sort[by]=${sort_req.field}`;
            }
            setSortUrl(sUrl + sType);
            setSort(false);
            setSendFilter(true);
        }, 500);
    }, [sort]);

    useEffect(() => {
        (
            async () => {
                if (send_filter) {
                    setLoading(true);

                    axios.get(`recruitment/meets?limit=${pagination.pageSize}&page=${pagination.current}${sort_url}&filter_between[]=${start_date}&filter_between[]=${end_date}${filter_url}`)
                        .then(({ data }) => {
                            setData(data.meet.data);
                            setReports(data.meet.report);
                            setPagination({
                                current: pagination.current,
                                pageSize: pagination.pageSize,
                                currentPageSize: pagination.currentPageSize,
                                total: data.meet.report?.total
                            });
                            setLoading(false);
                        });
                    setSendFilter(false);
                }
            }
        )()
    }, [send_filter]);


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/34');

                setStatuses(data.data);
            }
        )()
    }, []);


    const edit = (id: number) => {
        return <Navigate to={`/recruitment/meets/${id}/edit`} />;
    }


    const handleRowDoubleClick = (id: number) => {
        setRedirectId(id);
    }


    if (redirect_id > 0) {
        return <Navigate to={`/recruitment/meets/${redirect_id}/edit`} />;
    }

    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Görüşmeler</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><NavLink to={'/'}>Gösterge Paneli</NavLink></li>
                                <li className="breadcrumb-item active">Görüşmeler</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-sm-6 mb-3">
                                    <div className="form-group select-style">
                                    <label className="d-block">Tarih Aralığı ( {selectedDateName} )</label>

                                        {/* <RangePicker
                                            format={'DD/MM/YYYY'}
                                            locale={locale}
                                            ranges={rangePresets}
                                            value={date_range}
                                            style={{ width: '100%' }}
                                            onChange={(value, dateString) => {
                                                setDateRange(value);
                                                setStartDate(dayjs(dateString.toString().split(',')[0], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setEndDate(dayjs(dateString.toString().split(',')[1], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setSendFilter(true);
                                            }
                                            }
                                        /> */}
                                         <AntDesignRangePicker
                                        dateRange={date_range}
                                        setDateRange={setDateRange}
                                        setStartDate={setStartDate}
                                        setEndDate={setEndDate}
                                        setSendFilter={setSendFilter}
                                        setSelectedDateName={setSelectedDateName}
                                    />
                                    </div>
                                </div>
                                {/* <div className="col-lg-3 col-sm-6">
                                    <div className="form-group select-style">
                                        <label>Nitelik</label>
                                        <Select className="form-control" defaultValue={giro_type_id} showSearch
                                            value={giro_type_id}
                                            placeholder="Nitelik Seçiniz"
                                            allowClear={true}
                                            onChange={e => { setGiroTypeId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >

                                            {giro_types.map((giro_types: GiroType) => {
                                                return (
                                                    <Option value={giro_types.id} title={giro_types.name}
                                                        key={giro_types.name}
                                                        id={giro_types.name}>{giro_types.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="form-group select-style">
                                        <label>Gayrimenkul Cinsi</label>
                                        <Select className="form-control" defaultValue={property_genus_ids} value={property_genus_ids} showSearch
                                            optionFilterProp="children"
                                            mode="multiple"
                                            allowClear={true}
                                            placeholder="Gayrimenkul Cinsi Seçiniz"
                                            onChange={e => { setPropertyGenusIds(e); setPropertyTypeIds([]); setFilter(true); }}
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {property_genuses.map((property_genuses: PropertyGenus) => {
                                                return (
                                                    <Option value={property_genuses.id} title={property_genuses.name}
                                                        key={property_genuses.name}
                                                        id={property_genuses.name}>{property_genuses.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6" style={property_genus_ids == 10 ? { display: 'none' } : {}} >
                                    <div className="form-group select-style">
                                        <label>Gayrimenkul Türü</label>
                                        <Select className="form-control" defaultValue={property_type_ids} showSearch
                                            value={property_type_ids}
                                            mode="multiple"
                                            allowClear={true}
                                            placeholder="Gayrimenkul Türü Seçiniz"
                                            onChange={e => { setPropertyTypeIds(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >

                                            {property_types.map((property_types: PropertyType) => {
                                                return (
                                                    <Option value={property_types.id} title={property_types.name}
                                                        key={property_types.name}
                                                        id={property_types.name}>{property_types.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-3">
                                    <div className="form-group mb-3">
                                        <label>Yetki Belge No</label>
                                        <input type="number"
                                            className="form-control"
                                            defaultValue={document_no_val}
                                            value={document_no_val}
                                            onChange={e => { setDocumentNoVal(e.target.value); setTimeout(() => { setDocumentNo(e.target.value); setFilter(true); }, 500); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-3">
                                    <div className="form-group mb-3 select-style">
                                        <label>İşlem Durumu</label>
                                        <Select className="form-control" defaultValue={status_id} showSearch
                                            value={status_id}
                                            allowClear={true}
                                            placeholder="İşlem Durumunu Seçiniz"
                                            onChange={e => { setStatusId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );
                                            }}
                                        >
                                            {statuses.map((statuses: Status) => {
                                                return (
                                                    <Option value={statuses.id} title={statuses.name}
                                                        key={statuses.name}
                                                        id={statuses.name}>{statuses.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="form-group mb-3 select-style">
                                        <label>Danışman (lar)</label>
                                        <Select className="form-control" showSearch
                                            allowClear={true}
                                            onChange={e => { setSelectedAgents(e); setFilter(true); }}
                                            value={selected_agents}
                                            defaultValue={selected_agents}
                                            mode="multiple"
                                            placeholder="Danışman Seçiniz"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {users.map((users: User) => {
                                                return (
                                                    <Option value={users.id} title={users.first_name + ' ' + users.last_name}
                                                        key={users.first_name + ' ' + users.last_name}
                                                        id={users.first_name + ' ' + users.last_name}>{users.first_name + ' ' + users.last_name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-md-3" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3">
                                        <label>Müşteri / Firma Adı</label>
                                        <input type="text"
                                            className="form-control"
                                            defaultValue={customer_name_val}
                                            value={customer_name_val}
                                            onChange={e => { setCustomerNameVal(e.target.value); setTimeout(() => { setCustomerName(e.target.value); setFilter(true); }, 500); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-md-3" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3">
                                        <label>Kimlik / Pasaport Numarası</label>
                                        <input type="text"
                                            className="form-control"
                                            defaultValue={customer_id_no_val}
                                            value={customer_id_no_val}
                                            onChange={e => { setCustomerIdNoVal(e.target.value); setTimeout(() => { setCustomerIdNo(e.target.value); setFilter(true); }, 500); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-md-3" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3">
                                        <label>Telefon</label>
                                        <div className="input-group">
                                            <div className="input-group-text p-0">
                                                <Select value={customer_phone_code_id} style={{ width: 100 }} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => { setCustomerPhoneCodeId(e); setFilter(true); }}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {phone_codes.map((phone_codes: PhoneCode) => {
                                                        let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                        return (
                                                            <Option value={phone_codes.id} title={phone_codes.name}
                                                                key={phone_codes.name}
                                                                id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <PatternFormat format="(###) ### ## ##"
                                                onChange={e => { setTimeout(() => { setCustomerPhone(e.target.value); setFilter(true); }, 500); }}
                                                value={customer_phone} className="form-control" allowEmptyFormatting mask=" " />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-2 col-sm-6" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3 select-style">
                                        <label>İl</label>
                                        <Select className="form-control" defaultValue={province_id} value={province_id} showSearch
                                            optionFilterProp="children"
                                            allowClear={true}
                                            placeholder="İl Seçiniz"
                                            onChange={e => { setProvinceId(e); setDistrictId(null); setNeighbourhoodId(null); setFilter(true); }}
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {provinces.map((provinces: Province) => {
                                                return (
                                                    <Option value={provinces.id} title={provinces.name}
                                                        key={provinces.name}
                                                        id={provinces.name}>{provinces.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-6" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3 select-style">
                                        <label>İlçe</label>
                                        <Select className="form-control" defaultValue={district_id} showSearch
                                            optionFilterProp="children"
                                            allowClear={true}
                                            onChange={e => { setDistrictId(e); setNeighbourhoodId(null); setFilter(true); }}
                                            value={district_id}
                                            placeholder="İlçe Seçiniz"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {districts.map((districts: District) => {
                                                return (
                                                    <Option value={districts.id} title={districts.name}
                                                        key={districts.name}
                                                        id={districts.name}>{districts.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-6" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3 select-style">
                                        <label>Mahalle</label>
                                        <Select className="form-control" defaultValue={neighbourhood_id} showSearch
                                            value={neighbourhood_id}
                                            allowClear={true}
                                            onChange={e => { setNeighbourhoodId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            placeholder="Mahalle Seçiniz"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {neighbourhoods.map((neighbourhoods: Neighbourhood) => {
                                                return (
                                                    <Option value={neighbourhoods.id} title={neighbourhoods.name}
                                                        key={neighbourhoods.name}
                                                        id={neighbourhoods.name}>{neighbourhoods.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-xl-1 col-md-1" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3">
                                        <label>Ada</label>
                                        <input type="text"
                                            className="form-control"
                                            value={bob}
                                            onChange={e => { setBob(e.target.value); setFilter(true); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-1 col-md-1" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3">
                                        <label>Parsel</label>
                                        <input type="text"
                                            className="form-control"
                                            value={parcel}
                                            onChange={e => { setParcel(e.target.value); setFilter(true); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-6" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group select-style">
                                        <label>Site</label>
                                        <Select className="form-control" defaultValue={site_id} showSearch
                                            value={site_id}
                                            allowClear={true}
                                            placeholder="Site Seçiniz"

                                            onChange={e => { setSiteId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            onSearch={e => setSiteSearch(e)}
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {sites.map((sites: Site) => {
                                                return (
                                                    <Option value={sites.id} title={sites.name}
                                                        key={sites.name}
                                                        id={sites.name}>{sites.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-6" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3 select-style">
                                        <label>Portföy Bulma Kaynağı</label>
                                        <Select className="form-control" defaultValue={source_of_find_id} showSearch
                                            value={source_of_find_id}
                                            allowClear={true}
                                            placeholder="Portföy Bulma Kaynağını Seçiniz"
                                            onChange={e => { setSourceOfFindId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {resources_of_find.map((resources_of_find: SourceOfFind) => {
                                                return (
                                                    <Option value={resources_of_find.id} title={resources_of_find.name}
                                                        key={resources_of_find.name}
                                                        id={resources_of_find.name}>{resources_of_find.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div> */}
                            </div>
                            <div className="mt-3">
                                <div className="float-start">
                                    <button type="button" onClick={e => setClearFilterInputs(true)} className="btn btn-soft-light btn-sm" >Temizle</button>
                                </div>
                                <div className="float-end">
                                    <button type="button" onClick={e => { extra_filter_see ? setExtraFilterSee(false) : setExtraFilterSee(true); }} className="btn btn-soft-secondary btn-sm" >{extra_filter_see ? 'Daha az seçenek' : 'Daha fazla seçenek'}</button>
                                    <Link to="/recruitment/meets/create" className="ms-2 btn btn-light btn-sm"><i className="bx bx-plus me-1"></i> Yeni Ekle</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table mb-0 table-bordered table-hover">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Toplam</th>
                                            <th>Fiyat <strong className="text-danger d-inline-flex"></strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th><strong>{reports?.total}</strong></th>
                                            <td scope="row">
                                                <NumericFormat
                                                    value={reports?.total.toFixed(2).toString().replace('.', ',')}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> */}
                    <div className="card">
                        <div className="card-body">
                            <Table
                                className="table table-striped"
                                size="small"
                                columns={columns}
                                dataSource={data}
                                loading={loading}
                                scroll={{ y: '100%', x: true }}
                                rowKey={(record) => `${record.id}`}
                                onRow={(record: any) => {
                                    return {
                                        onDoubleClick: (event) => handleRowDoubleClick(record.id)
                                    };
                                }}
                                expandable={{
                                    expandedRowRender: (record) => {
                                        return (
                                            <>
                                                <div className="table-reponsive">
                                                    <table className={`table`}>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Başlık</th>
                                                                <th>Açıklama</th>
                                                                <th>Tarih</th>
                                                                <th>Saat</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {record?.meta?.map((meta: any,index:number) => {
                                                                return (
                                                                    <tr key={meta.id}>
                                                                        <td>{index+1}</td>
                                                                        <td>{`${meta?.name}`}</td>
                                                                        <td>{`${meta?.definition=='0'?'':meta?.definition}`}</td>
                                                                        <td>{`${meta?.meet_date}`}</td>
                                                                        <td>{`${meta?.meet_time}`}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        )
                                    },
                                    rowExpandable: (record:any) => record?.meta !== 'Not Expandable',
                                }}
                                pagination={pagination}
                                onChange={(val: any, filter: any, sorter: any, extra: any) => {
                                    setPagination({
                                        current: val.current,
                                        pageSize: val.pageSize,
                                        currentPageSize: val.currentPageSize
                                    });
                                    setSortReq(sorter);
                                    setSort(true);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} onEntered={modalLoaded} size="lg" animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Görüşme Detayları - {full_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <MeetDetail id={show_id} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Kapat
                    </Button>
                    <Link className="btn btn-primary" to={`/recruitment/meets/${meetID}/edit`}>
                        Güncelle
                    </Link>
                </Modal.Footer>
            </Modal>

        </Wrapper>
    )
}

export default Meets;