import React from 'react';
import { Card } from 'react-bootstrap';

interface CustomCardProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  title?: string;
  subtitle?: string;
  imgSrc?: string;
  imgAlt?: string;
  text?: string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const CustomCard: React.FC<CustomCardProps> = ({
  header,
  footer,
  title,
  subtitle,
  imgSrc,
  imgAlt,
  text,
  className,
  style,
  children,
  ...rest
}) => {
  return (
    <Card className={className} style={style} {...rest}>
      {imgSrc && <Card.Img variant="top" src={imgSrc} alt={imgAlt} />}
      {header && <Card.Header>{header}</Card.Header>}
      <Card.Body>
        {title && <Card.Title>{title}</Card.Title>}
        {subtitle && <Card.Subtitle className="mb-2 text-muted">{subtitle}</Card.Subtitle>}
        {text && <Card.Text>{text}</Card.Text>}
        {children}
      </Card.Body>
      {footer && <Card.Footer>{footer}</Card.Footer>}
    </Card>
  );
};

export default CustomCard;
