import axios from "axios";
import React, { Dispatch, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Wrapper from "../../components/layout/wrapper";
import { Role } from "../../models/role";
import Swal from 'sweetalert2'
import { delRole } from "../../axios";
import { connect, useDispatch, useSelector } from "react-redux";
import { setRole } from "../../redux/actions/roleAction";

const Roles = (props: any) => {

    useEffect(() => {
        (
            async () => {
                try {
                    const {data} = await axios.get('user');

                    props.setRole(new Role(
                        data.id,
                        data.name
                    ));
                    console.log('erryesr')
                } catch (e:any) {
                    console.log(e.message)
                }
            }
        )();
       
    }, []);

    const [roles, setRoles] = useState([]);

    useEffect(() => {
        (
            async () => {
    const {data} = await axios.get(`roles`);

                setRoles(data);
            }
        )()
    }, []); 

    const del = async (id: number) => {
        Swal.fire({
        title: 'Emin misiniz?',
        text: "Kalıcı olarak silinecektir!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Evet, Sil!',
        cancelButtonText: 'İptal'
        }).then(async (result) => {
            if (result.isConfirmed) {
               // delRole(id)
             //   setRoles(roles.filter((d: Role) => d.id !== id));
                Swal.fire(
                'Silindi!',
                'Başarıyla silindi.',
                'success'
                )
            }
        })
     
    }

    return(
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Roller </h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><NavLink to={'/'}>Gösterge Paneli</NavLink></li>
                                <li className="breadcrumb-item active">Roller</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>        
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">                                             
                        <div className="card-body">
                        <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <h5 className="card-title">Rol Listesi {props.role?.name}</h5>
                                    </div>
                                </div>
    
                                <div className="col-md-6">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">                                      
                                        <div>
                                        <Link to="/roles/create" className="btn btn-light"><i className="bx bx-plus me-1"></i> Yeni Ekle</Link>
                                        </div>
                                    </div>
    
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Rol</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {roles.map((role: Role) => {
                                            return (
                                                <tr key={role.id}>
                                                    <td>{role.id}</td>
                                                    <td>{role.name}</td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="bx bx-dots-horizontal-rounded"></i>
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-end">
                                                                <li>
                                                                    <Link to={`/roles/${role.id}/edit`}
                                                                    className="dropdown-item">Güncelle</Link>
                                                                </li>
                                                                <li><a className="dropdown-item" href="#" onClick={() => del(role.id)}>Sil</a></li>
                                                            </ul>
                                                        </div>                                                       
                                                    </td>
                                                </tr>
                                            )
                                        })}                                      
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}


const mapStateToProps = (state: { role: Role }) => {
    return {
        role: state.role
    };
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        setUser: (role: Role) => dispatch(setRole(role))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Roles);
