import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink, Navigate } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import Paginator from "../../../components/paginator";
import Swal from 'sweetalert2';

import Breadcrumb from "../../../components/constant/breadcrumb";
import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import { ContractTemplate } from "../../../models/contract/contractTemplate/contractTemplate";

const ContractTemplates = () => {

    const [contract_templates, setContractTemplates] = useState([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [redirect_id, setRedirectId] = useState(0);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`contract/contract-templates?page=${page}`);

                setContractTemplates(data);
                setLastPage(data.meta.last_page);
            }
        )()
    }, [page]);

    const del = async (id: number) => {

        Swal.fire({
            title: 'Emin misiniz?',
            text: "Kalıcı olarak silinecektir",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c84ee',
            cancelButtonColor: '#fd625e',
            confirmButtonText: 'Evet, sil!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.delete(`contract/contract-templates/${id}`);

                setContractTemplates(contract_templates.filter((u: ContractTemplate) => u.id !== id));
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Veri silindi',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }

    const items: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Sözleşme Şablonları', active: true }
    ];

    const handleRowDoubleClick = (id: number) => {
        setRedirectId(id);
    }

    if (redirect_id > 0) {
        return <Navigate to={`/contract/contract-templates/${redirect_id}/edit`} />;
    }
    
    return (
        <Wrapper>          
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Sözleşme Şablonları</h4>
                        <div className="page-title-right">
                            <Breadcrumb items={items} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <h5 className="card-title">Sözleşme Şablonları</h5>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                            <Link to="/contract/contract-templates/create" className="btn btn-light"><i className="bx bx-plus me-1"></i> Yeni Ekle</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Sözleşme Adı</th>                                            
                                            <th>Ekleyen Personel</th>
                                            <th>Güncelleme Tarihi</th>
                                            <th>Oluşturma Tarihi</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contract_templates.map((contract_template: ContractTemplate) => {
                                            return (
                                                <tr key={contract_template.id} onDoubleClick={() => handleRowDoubleClick(contract_template.id)}>
                                                    <td>{contract_template.id}</td>
                                                    <td>{contract_template.name}</td>
                                                    <td>{`${contract_template.user.first_name} ${contract_template.user.last_name} `}</td>
                                                    <td>{contract_template.updated_at}</td>
                                                    <td>{contract_template.created_at}</td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="bx bx-dots-horizontal-rounded"></i>
                                                            </button>

                                                            <ul className="dropdown-menu dropdown-menu-end">
                                                                <li>
                                                                    <Link to={`/contract/contract-templates/${contract_template.id}/edit`}
                                                                        className="dropdown-item">Güncelle</Link>
                                                                </li>
                                                                <li><a className="dropdown-item" href="#" onClick={() => del(contract_template.id)}>Sil</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <Paginator page={page} lastPage={lastPage} pageChanged={setPage} />

                        </div>
                    </div>
                </div>
            </div>

        </Wrapper>
    )
}

export default ContractTemplates;