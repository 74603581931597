import { WorkScheduleResponse } from "../../../models/employee/workSchedule/WorkScheduleResponse";
import { WorkSchedules } from "../../../models/employee/workSchedule/workSchedule";
import { apiClient } from "../../config";
import { WORK_SCHEDULE_ENDPOINT } from "../../endpoints/workSchedules/workScheduleEndpoints";
import { handleApiError } from "../../utils/errorHandler";


export const fetchWorkSchedules = async (params?: Record<string, any>): Promise<WorkScheduleResponse> => {
  try {
    const { data } = await apiClient.get(WORK_SCHEDULE_ENDPOINT, { params });
    return data;
  } catch (error) {
    handleApiError(error);
    return new WorkScheduleResponse();
  }
};

export const createWorkSchedule = async (workScheduleData: WorkSchedules) => {
  try {
    return await apiClient.post(WORK_SCHEDULE_ENDPOINT, workScheduleData);
  } catch (error) {
    handleApiError(error);
  }
};

export const updateWorkSchedule = async (id: number, workScheduleData: WorkSchedules) => {
  try {
    return await apiClient.put(`${WORK_SCHEDULE_ENDPOINT}/${id}`, workScheduleData);
  } catch (error) {
    handleApiError(error);
  }
};

export const deleteWorkSchedule = async (id: number) => {
  try {
    return await apiClient.delete(`${WORK_SCHEDULE_ENDPOINT}/${id}`);
  } catch (error) {
    handleApiError(error);
  }
};
