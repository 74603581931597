
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { CommentStatusContext } from '../../../contexts/attribute/commentStatus/commentStatusContext';
import { Attribute } from '../../../models/attribute/attribute';

export const CommentStatusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [comment_statuses, setCommentStatuses] = useState<Attribute[]>([]);

  useEffect(() => {
      let isMounted = true;

      async function getCommentStatuses() {
        try {
          const { data } = await axios.get('attributes/14');
          if (isMounted) {
            setCommentStatuses(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
  
      const timer = setTimeout(() => {
        getCommentStatuses();
      }, 5000);

      return () => {
        isMounted = false;
        clearTimeout(timer);
      };
  }, []);


  return (
      <CommentStatusContext.Provider value={[comment_statuses, setCommentStatuses]}>
          {children}
      </CommentStatusContext.Provider>
  );
}

export const useCommentStatusContext = () => useContext(CommentStatusContext);
