
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { PropertyGenusContext } from '../../../contexts/attribute/propertyGenus/propertyGenusContext';
import { Attribute } from '../../../models/attribute/attribute';

export const PropertyGenusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [property_genuses, setPropertyGenuses] = useState<Attribute[]>([]);

  useEffect(() => {
      let isMounted = true;

      async function getDatas() {
        try {
          const { data } = await axios.get('attributes/7');
          if (isMounted) {
            setPropertyGenuses(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
  
      const timer = setTimeout(() => {
        getDatas();
      }, 5000);
  
      return () => {
        isMounted = false;
        clearTimeout(timer);
      };
  }, []);


  return (
      <PropertyGenusContext.Provider value={[property_genuses, setPropertyGenuses]}>
          {children}
      </PropertyGenusContext.Provider>
  );
}

export const usePropertyGenusContext = () => useContext(PropertyGenusContext);
