import React from "react";
import SunEditor from 'suneditor-react';

const CustomSunEditor = ({ placeholder = null, height='100vh', value =null, defaultValue=null, onChange = null,
}: any) => {
    return (
        <>
            <SunEditor
                height={height}
                onChange={onChange}
                setContents={value}
                defaultValue={defaultValue}
                placeholder={placeholder}
                setOptions={{                    
                    buttonList: [
                        ['undo', 'redo'],
                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                        ['fontSize', 'font'],
                        ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'table'],
                        ['link', 'image', 'video'],
                        ['removeFormat'],
                        ['lineHeight'],
                        ['fullScreen', 'showBlocks', 'codeView', 'preview', 'print']
                    ],
                    font: ['Arial', 'Times New Roman', 'Courier New', 'Verdana', 'Georgia']
                }}
            />
        </>
    )
}
export default CustomSunEditor;
