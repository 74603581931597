import { Attribute } from "./attribute";

export class AttributeMeta {
    constructor(
        public id = 0,
        public name = '',
        public type = '',
        public ordernum = '',
        public color = '',
        public attribute = new Attribute(),
        public child = new Attribute()
    ) {
    }
}