import { Role } from "./role";
import { Category } from "./user/category";
import { TeamLeader } from "./user/teamLeader";

export class User {
    constructor(
        public id = 0,
        public first_name:string = '',
        public last_name:string = '',
        public email = '',
        public whatsapp = '',
        public role_id = 0,
        public phone = '',
        public percent = 0,
        public price = 0,
        public team_leader_id = 0,
        public team_leader_percent = 0,
        public team_leader_price = 0,
        public office_service_fee = 0,
        public total_service_fee = 0,
        public status_id = 0,
        public role = new Role(),
        public category = new Category(),
        public team_leader = new TeamLeader()
    ) {
    }

    get name() {
        return this.first_name + ' ' + this.last_name;
    }
}