import { User } from '../../models/user';
import { UserResponse } from '../../models/user/userResponse';
import { apiClient } from '../config';
import { USER_ENDPOINT } from '../endpoints/userEndpoints';
import { handleApiError } from '../utils/errorHandler';

export const fetchUsers = async (params?: Record<string, any>): Promise<UserResponse> => {
  try {
    const { data } = await apiClient.get('users-filter', { params });
    return data;
  } catch (error) {
    handleApiError(error);
    return new UserResponse();
  }
};

export const createUser = async (userData: User) => {
  try {
    return await apiClient.post(USER_ENDPOINT, userData);
  } catch (error) {
    handleApiError(error);
  }
};

export const updateUser = async (id: number, userData: User) => {
  try {
    return await apiClient.put(`${USER_ENDPOINT}/${id}`, userData);
  } catch (error) {
    handleApiError(error);
  }
};

export const deleteUser = async (id: number) => {
  try {
    return await apiClient.delete(`${USER_ENDPOINT}/${id}`);
  } catch (error) {
    handleApiError(error);
  }
};
