import React, { useEffect, useState } from "react";
import { Link, Navigate } from 'react-router-dom';
import axios from "axios";
import Header from "./header";
import Menu from "./menu";
import { connect } from "react-redux";
import { User } from "../../models/user";
import { setUser } from "../../redux/actions/setUserAction";
import { setMenu } from "../../redux/actions/setMenuAction";
import Footer from "./footer";
import { Module } from "../../models/module";
import OneSignal from 'react-onesignal';
import { Role } from "../../models/role";
import Cookies from "js-cookie";
import FirebaseNotificationComponent from "../notification/firebase/NotificationComponent";
import { FloatButton } from "antd";
import { BiSolidGrid } from "react-icons/bi";
import { CgViewGrid } from "react-icons/cg";
import { BsGridFill } from "react-icons/bs";
import { FiGrid } from "react-icons/fi";
import { AiOutlineAudio } from "react-icons/ai";

interface WrapperProps {
  children: React.ReactNode;
  fullLayout?: boolean;
  setUser: (user: User) => void;
  setMenu: (menu: any) => void;
  user: User;
}


const Wrapper = (props: WrapperProps) => {
  const { fullLayout = true, children, setUser } = props;
  const [redirect, setRedirect] = useState(false);
  const [notificationSendStatus, setNotificationSendStatus] = useState(false);


  const checkSession = async () => {
      try {
          const token = Cookies.get('jwt');
          if (!token) {
              setRedirect(true);
              return;
          }
  
          const { data } = await axios.get('user');
          if (data.id === 0 || data.status_id !== 255) {
              Cookies.remove('jwt', { path: '/' });
              setRedirect(true);
          } else {
              
          const user = new User(
            data.id,
            data.first_name,
            data.last_name,
            data.email,
            data.role_id,
            data.role.id,
            data.role.name
          );
          user.role = data.role;

          props.setUser(user);

          }
      } catch (e) {
          Cookies.remove('jwt', { path: '/' });
          setRedirect(true);
      }
  };
  
  useEffect(() => {
      checkSession();
  }, []);

  if (redirect) {
    return <Navigate to={'/login'} />;
  }

  return (
    <>
      {fullLayout ? (
        <div id="layout-wrapper">
          <Header />
          <Menu />
          <FirebaseNotificationComponent />

          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                {props.children}
              </div>
            </div>
            {props?.user?.role_id === 1 || props?.user.id === 1 ? ( // Kullanıcı rolüne göre görünürlüğü kontrol et
              <FloatButton.Group>
                <FloatButton icon={<AiOutlineAudio />} />
                <Link to="/launch">
                  <FloatButton icon={<FiGrid />} />
                </Link>
              </FloatButton.Group>
            ) : null}
            <Footer />
          </div>
        </div>
      ) : (
        <div>{props.children}</div> // Render just the children
      )}
    </>
  )
}

const mapStateToProps = (state: { user: User, menu: Module }) => {
  return {
    user: state.user,
    menu: state.menu
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUser: (user: User) => dispatch(setUser(user)),
    setMenu: (menu: Module) => dispatch(setMenu(menu))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
