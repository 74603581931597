import { Builder } from "../constant/builder/builder";
import { UserData } from "./userData";


export class UserResponse {
  constructor(
    public builder: Builder = new Builder('created_at'),
    public user: UserData = new UserData()
  ) {}
}
