import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'antd';
import AntDesignModal from '../../constant/antDesign/modal';
import AntDesignUserSelect from '../../selectors/dataSelectors/antDesign/AntDesignUsersSelect';
import useWatchSchedules from '../../../api/hooks/useWatchSchedules';
import Swal from 'sweetalert2';
import WatchScheduleActionList from '../../tables/watchSchedules/watchScheduleActionList';
import { WatchScheduleActions } from '../../../models/asistant/watchSchedule/watchScheduleAction';

interface WatchScheduleDetailModalProps {
    showWSD: boolean;
    show: boolean;
    setWSDShow: (showWSD: boolean) => void;
    setShow: (show: boolean) => void;
    data?: WatchScheduleActions
    onSuccess?: () => void;
}

const WatchScheduleDetailModal: React.FC<WatchScheduleDetailModalProps> = ({ showWSD, setWSDShow, show, setShow, data, onSuccess }) => {
    console.log(data);
    const [loading, setLoading] = useState(false);
    const { removeWatchSchedule } = useWatchSchedules();

    useEffect(() => {
        console.log('Data received in modal:', data);
      }, [data]);

      
    useEffect(() => {
        if (showWSD) {
            setLoading(false);
        }
    }, [showWSD]);

    const handleOk = () => {
        console.log('OK clicked');
    };

    const handleSubmit = (values: any) => {
        console.log('Form values:', values);
    };

    const handleLoadingStatus = (loading: boolean) => {
        setLoading(loading);
        console.log(loading);
    };


    const handleDelete = async () => {

        if (data?.id) {
            try {
                setLoading(true);
                setShow(false);
                Swal.fire({
                    title: 'Emin misiniz?',
                    text: "Seçilen gün için kullanıcı ve kullanıcının tüm yaptığı işlemler kalıcı olarak silinecektir!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#1c84ee',
                    cancelButtonColor: '#fd625e',
                    confirmButtonText: 'Evet, sil!',
                    cancelButtonText: 'İptal'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await removeWatchSchedule(data.id);
                        if (onSuccess) onSuccess();
                        setWSDShow(false);
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Veri silindi',
                            showConfirmButton: false,
                            timer: 1500
                        });

                    }
                })
            } catch (error) {
                console.error('Failed to delete watch schedule', error);
            } finally {
                setLoading(false);
            }
        } else {
            console.error('No user ID provided for deletion');
        }
    };

    const [form] = Form.useForm();

    const formItems = (
        <>
            <Form.Item
                name="user"
                rules={[{ required: true, message: 'Lütfen danışman seçiniz' }]}
            >
                <Button type="default" onClick={() => { setWSDShow(false); setShow(false); }}>
                    Yeni Ekle
                </Button>
            </Form.Item>
            <Form.Item>
            <WatchScheduleActionList agent_id={data?.user?.id??0} meet_date={data?.meet_date??''} />
            </Form.Item>
            <Form.Item>
                <Row justify="end" gutter={16} className="mt-3">
                    <Col>
                        <Button type="default" onClick={() => { setWSDShow(false); setShow(false); }}>
                            İptal
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" onClick={handleDelete} danger>
                            Danışmanı Sil
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </>
    );

    return (
        <AntDesignModal
            title={`${data?.agent.first_name || '...'} ${data?.agent.last_name || ''}  - ${data?.meet_date || ''}`}
            visible={showWSD}
            centered
            onOk={handleOk}
            onCancel={() => setWSDShow(false)}
            width={1200}
            zIndex={1001}
            footer={null}
        >
            {data ? (
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{ user: data.user.id }}
                    onFinish={handleSubmit}
                >
                    {formItems}
                </Form>
            ) : (
                <div>Yükleniyor...</div>
            )}
        </AntDesignModal>
    );
};

export default WatchScheduleDetailModal;
