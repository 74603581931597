import { Category } from "./category";
import { Role } from "./../role";

export class TeamLeader {
    map(arg0: (task: any, index: any) => JSX.Element) {
        throw new Error("Method not implemented.");
    }
    TeamLeader: any;
    constructor(
        public id = 0,
        public first_name = '',
        public last_name = '',
        public email = '',
        public whatsapp = '',
        public phone = '',
        public role = new Role(),
        public category = new Category(),
    ) {
    }

    get name() {
        return this.first_name + ' ' + this.last_name;
    }
}