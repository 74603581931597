import axios from "axios";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Spin, Statistic, Tag } from 'antd';

import Mailto from "../../link/mailto";
import Tel from "../../link/tel";

import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
const { Countdown } = Statistic;

const PurchaseSaleAgreementDetail = ({ id }: any) => {
  const [purchase_sale_agreement, setPurchaseSaleAgreement] = useState<any | null>(null);

  const calculateDateDifference = (end_date:any) => {
    const today = moment();
    const endDate = moment(end_date, "DD.MM.YYYY");
    const differenceInDays = endDate.diff(today, "days");
    return differenceInDays;
  };
  useEffect(() => {
    (
      async () => {
        if (id > 0) {
          const { data } = await axios.get(`contract/purchase-sale-agreements/${id}`);
          setPurchaseSaleAgreement(data);
        }
      }
    )()
  }, [id]);

  const dateDifference = calculateDateDifference(purchase_sale_agreement?.end_date);

  let color = 'green';
  if (dateDifference < 0) {
    color = 'red';
  }
  if (dateDifference < 11 && dateDifference > 0) {
    color = 'lime';
  }

  if (!purchase_sale_agreement) {
    return (
      <>
        <div className="d-flex flex-column justify-content-center align-items-center mb-3 mt-5 mb-5">
          <div className="d-flex justify-content-between bd-highlight mb-3">
            <Spin></Spin>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="row">

      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>SATICI BİLGİLERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>MÜŞTERİ TÜRÜ</th>
              <th>{purchase_sale_agreement?.seller?.customerType?.name}</th>
            </tr>
            {purchase_sale_agreement?.seller?.customer_type_id === 2 ?
              <>
                <tr>
                  <th>FİRMA ADI</th>
                  <td>{purchase_sale_agreement?.seller?.company_name}</td>
                </tr>
                <tr>
                  <th>VERGİ DAİRESİ / VERGİ NUMARASI</th>
                  <td>{purchase_sale_agreement?.seller?.tax_admin} / {purchase_sale_agreement?.seller?.tax_number}</td>
                </tr>
              </>
              : null}
            <tr>
              <th>AD SOYAD</th>
              <td>{purchase_sale_agreement?.seller?.first_name} {purchase_sale_agreement?.seller?.last_name}</td>
            </tr>
            <tr>
              <th>{purchase_sale_agreement?.seller?.idType?.name}</th>
              <td>{purchase_sale_agreement?.seller?.id_no}</td>
            </tr>
            <tr>
              <th scope="row">TELEFON</th>
              <td><Tel phone={`${purchase_sale_agreement?.seller?.phoneCode?.phone_code} ${purchase_sale_agreement?.seller?.phone}`}>+{purchase_sale_agreement?.seller?.phoneCode?.phone_code} {purchase_sale_agreement?.seller?.phone}</Tel></td>
            </tr>
            {purchase_sale_agreement?.seller?.email != '0' ?
              <tr>
                <th scope="row">EMAİL</th>
                <td><Mailto email={purchase_sale_agreement?.seller?.email} body="Merhaba">{purchase_sale_agreement?.seller?.email}</Mailto></td>
              </tr>
              : null}
            <tr>
              <th>DOĞUM TARİHİ</th>
              <td>{purchase_sale_agreement?.seller?.birthday}</td>
            </tr>
            <tr>
              <th scope="row">ADRES</th>
              <td>{purchase_sale_agreement?.seller?.address}</td>
            </tr>
            {purchase_sale_agreement.seller?.definition != '0' ?

              <tr>
                <th scope="row">NOT</th>
                <td>{purchase_sale_agreement?.seller?.definition}</td>
              </tr>
              : null}

          </tbody>
        </table>
      </div>
      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>ALICI BİLGİLERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>MÜŞTERİ TÜRÜ</th>
              <th>{purchase_sale_agreement?.buyer?.customerType?.name}</th>
            </tr>
            {purchase_sale_agreement?.buyer?.customer_type_id === 2 ?
              <>
                <tr>
                  <th>FİRMA ADI</th>
                  <td>{purchase_sale_agreement?.buyer?.company_name}</td>
                </tr>
                <tr>
                  <th>VERGİ DAİRESİ / VERGİ NUMARASI</th>
                  <td>{purchase_sale_agreement?.buyer?.tax_admin} / {purchase_sale_agreement?.buyer?.tax_number}</td>
                </tr>
              </>
              : null}
            <tr>
              <th>AD SOYAD</th>
              <td>{purchase_sale_agreement?.buyer?.first_name} {purchase_sale_agreement?.buyer?.last_name}</td>
            </tr>
            <tr>
              <th>{purchase_sale_agreement?.buyer?.idType?.name}</th>
              <td>{purchase_sale_agreement?.buyer?.id_no}</td>
            </tr>
            <tr>
              <th scope="row">TELEFON</th>
              <td><Tel phone={`${purchase_sale_agreement?.buyer?.phoneCode?.phone_code} ${purchase_sale_agreement?.buyer?.phone}`}>+{purchase_sale_agreement?.buyer?.phoneCode?.phone_code} {purchase_sale_agreement?.buyer?.phone}</Tel></td>
            </tr>
            {purchase_sale_agreement?.buyer?.email != '0' ?
              <tr>
                <th scope="row">EMAİL</th>
                <td><Mailto email={purchase_sale_agreement?.buyer?.email} body="Merhaba">{purchase_sale_agreement?.buyer?.email}</Mailto></td>
              </tr>
              : null}
            <tr>
              <th>DOĞUM TARİHİ</th>
              <td>{purchase_sale_agreement?.buyer?.birthday}</td>
            </tr>
            <tr>
              <th scope="row">ADRES</th>
              <td>{purchase_sale_agreement?.buyer?.address}</td>
            </tr>
            {purchase_sale_agreement.buyer?.definition != '0' ?

              <tr>
                <th scope="row">NOT</th>
                <td>{purchase_sale_agreement?.buyer?.definition}</td>
              </tr>
              : null}

          </tbody>
        </table>
      </div>
      {purchase_sale_agreement.guarantor?.id >0 ?
              <>
                <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>KEFİL BİLGİLERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>MÜŞTERİ TÜRÜ</th>
              <th>{purchase_sale_agreement?.guarantor?.customer_type?.name}</th>
            </tr>
            {purchase_sale_agreement?.guarantor?.customer_type_id === 2 ?
              <>
                <tr>
                  <th>FİRMA ADI</th>
                  <td>{purchase_sale_agreement?.guarantor?.company_name}</td>
                </tr>
                <tr>
                  <th>VERGİ DAİRESİ / VERGİ NUMARASI</th>
                  <td>{purchase_sale_agreement?.guarantor?.tax_admin} / {purchase_sale_agreement?.guarantor?.tax_number}</td>
                </tr>
              </>
              : null}
            <tr>
              <th>AD SOYAD</th>
              <td>{purchase_sale_agreement?.guarantor?.first_name} {purchase_sale_agreement?.guarantor?.last_name}</td>
            </tr>
            <tr>
              <th>{purchase_sale_agreement?.guarantor?.id_type?.name}</th>
              <td>{purchase_sale_agreement?.guarantor?.id_no}</td>
            </tr>
            <tr>
              <th scope="row">TELEFON</th>
              <td><Tel phone={`${purchase_sale_agreement?.guarantor?.phoneCode?.phone_code} ${purchase_sale_agreement?.guarantor?.phone}`}>+{purchase_sale_agreement?.guarantor?.phoneCode?.phone_code} {purchase_sale_agreement?.guarantor?.phone}</Tel></td>
            </tr>
            {purchase_sale_agreement?.guarantor?.email != '0' ?
              <tr>
                <th scope="row">EMAİL</th>
                <td><Mailto email={purchase_sale_agreement?.guarantor?.email} body="Merhaba">{purchase_sale_agreement?.guarantor?.email}</Mailto></td>
              </tr>
              : null}
            <tr>
              <th>DOĞUM TARİHİ</th>
              <td>{purchase_sale_agreement?.guarantor?.birthday}</td>
            </tr>
            <tr>
              <th scope="row">ADRES</th>
              <td>{purchase_sale_agreement?.guarantor?.address}</td>
            </tr>
            {purchase_sale_agreement.guarantor?.definition != '0' ?

              <tr>
                <th scope="row">NOT</th>
                <td>{purchase_sale_agreement?.guarantor?.definition}</td>
              </tr>
              : null}

          </tbody>
        </table>
      </div>
              </>
              : null}
      <div className="clearfix border-bottom border-light mb-3"></div>
      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>MÜLK BİLGİLERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>GAYRİMENKUL CİNSİ</th>
              <th>{purchase_sale_agreement.property?.propertyGenus?.name}</th>
            </tr>
            {purchase_sale_agreement.property?.propertyGenus?.id !== 10 ?
              <>
                <tr>
                  <th>GAYRİMENKUL TÜRÜ</th>
                  <td>{purchase_sale_agreement.property?.propertyType && purchase_sale_agreement.property?.propertyType.name}</td>
                </tr>
              </>
              : null}
            <tr>
              <th>ADA</th>
              <td>{purchase_sale_agreement.property?.bob}</td>
            </tr>
            <tr>
              <th>PARSEL</th>
              <td>{purchase_sale_agreement.property?.parcel}</td>
            </tr>
            <tr>
              <th>İL</th>
              <td>{purchase_sale_agreement.property?.province.name}</td>
            </tr>
            <tr>
              <th>İLÇE</th>
              <td>{purchase_sale_agreement.property?.district?.name}</td>
            </tr>
            <tr>
              <th>MAHALLE</th>
              <td>{purchase_sale_agreement.property?.neighbourhood?.name}</td>
            </tr>
            <tr>
              <th>TAM ADRES</th>
              <td>{purchase_sale_agreement.property?.address}</td>
            </tr>
            {purchase_sale_agreement.property_genus_id === 8 || purchase_sale_agreement.property_genus_id === 10 ? null :
              <>
                <tr>
                  <th>BULUNDUĞU KAT</th>
                  <td>{purchase_sale_agreement.property?.floor_location?.name}</td>
                </tr>
              </>
            }
            <tr>
              <th>BİNA YAŞI</th>
              <td>{purchase_sale_agreement.property?.build_date}</td>
            </tr>
            <tr>
              <th>BRÜT m<sup>2</sup></th>
              <td>{purchase_sale_agreement.property?.brut}</td>
            </tr>
            {purchase_sale_agreement.property_genus_id === 8 || purchase_sale_agreement.property_genus_id === 10 ? null :
              <>
                <tr>
                  <th>NET m<sup>2</sup></th>
                  <td>{purchase_sale_agreement.property?.net}</td>
                </tr>
                <tr>
                  <th>YAPI TÜRÜ</th>
                  <td>{purchase_sale_agreement.property?.build_type?.name}</td>
                </tr>
                {purchase_sale_agreement.property?.build_type?.id === 115 ?
                  <>
                    <tr>
                      <th>SİTE</th>
                      <td>{purchase_sale_agreement.property?.site?.name}</td>
                    </tr>
                  </>
                  : null}
              </>
            }
            {purchase_sale_agreement.property?.definition != '0' && purchase_sale_agreement.property?.definition != null ?
              <tr>
                <th scope="row">NOT</th>
                <td>{purchase_sale_agreement.property?.definition}</td>
              </tr>
              : null}

          </tbody>
        </table>
      </div>
      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>ÖDEME BİLGİLERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>SATIŞ FİYATI</th>
              <th>
              <NumericFormat
                  value={purchase_sale_agreement.sale_price.toString().replace('.', ',')}
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                /> {purchase_sale_agreement.sale_currency?.name}
              </th>
            </tr>
            <tr>
              <th style={{ width: 170 }}>KAPORA TUTARI</th>
              <th>
                <strong>
              <NumericFormat
                  value={purchase_sale_agreement.deposit_price.toString().replace('.', ',')}
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                /> {purchase_sale_agreement.deposit_currency?.name}
                </strong>
              </th>
            </tr>
            <tr>
              <th style={{ width: 170 }}>CEZAİ ŞART BEDELİ</th>
              <th>
              <NumericFormat
                  value={purchase_sale_agreement.penalty_clause_amount.toString().replace('.', ',')}
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                /> {purchase_sale_agreement.penalty_clause_amount_currency?.name}
              </th>
            </tr>
            {purchase_sale_agreement?.payment_type?.map((payment: any, index: number) => (
                        <tr key={index}>
                          <th>{payment?.meta?.name}</th>
                          <th>
                            <strong>
                              <NumericFormat
                                value={payment?.price.toString().replace('.', ',')}
                                thousandSeparator="."
                                decimalSeparator=","
                                displayType="text"
                              /> {purchase_sale_agreement?.penalty_clause_amount_currency?.name}
                            </strong>
                          </th>
                        </tr>
                      ))}
          </tbody>
        </table>
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>SÖZLEŞME BİLGİLERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>KALAN GÜN SAYISI</th>
              <th>
              {purchase_sale_agreement.status?.id === 296 && moment(purchase_sale_agreement.last_date_time, 'DD/MM/YYYY HH:mm:ss') > moment() && (
                        <Tag color="red">
                            <Countdown
                                className="custom-antd-countdown"
                                value={moment(purchase_sale_agreement.last_date_time, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm')}
                                format="D Gün HH:mm:ss"
                                onFinish={() => {}}
                            />
                        </Tag>
                    )}
                    {purchase_sale_agreement.status?.id === 296 && moment(purchase_sale_agreement.last_date_time, 'DD/MM/YYYY HH:mm:ss') < moment() && (
                        <strong>
                            <Tag color="red">Süresi Bitti</Tag>
                        </strong>
                    )}  
              </th>
            </tr>
            <tr>
              <th>BİTİŞ TARİHİ</th>
              <td>{moment(purchase_sale_agreement.end_date).format('DD/MM/YYYY')}</td>
            </tr>
            <tr>
              <th>KALAN GÜN SAYISI</th>
              <td><Tag color={color}>{moment(purchase_sale_agreement.end_date).diff(moment(), 'days')}</Tag></td>
            </tr>
            <tr>
              <th scope="row">DURUM</th>
              <td><Tag color={purchase_sale_agreement.status?.color}>{purchase_sale_agreement.status?.name}</Tag></td>
            </tr>

          </tbody>
        </table>
      </div>
      <div className="clearfix border-bottom border-light mb-3"></div>
      <div className="col-12">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>SÖZLEŞME MADDELERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th colSpan={2} dangerouslySetInnerHTML={{ __html: purchase_sale_agreement.content }} />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PurchaseSaleAgreementDetail;

