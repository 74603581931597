import { NavLink } from 'react-router-dom';
import { BreadcrumbItem } from '../../models/constant/breadcrumb/breadcrumb';


interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb = ({ items }: BreadcrumbProps) => {
  return (
    <ol className="breadcrumb m-0">
      {items.map((item, index) => (
        <li key={index} className={`breadcrumb-item ${item.active ? 'active' : ''}`}>
          {item.active ? item.label : item.path ? <NavLink to={item.path}>{item.label}</NavLink> : item.label}
        </li>
      ))}
    </ol>
  );
};

export default Breadcrumb;
