
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { Attribute } from '../../../models/attribute/attribute';
import { StatusContext } from '../../../contexts/attribute/status/statusContext';

export const StatusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [statuses, setStatuses] = useState<Attribute[]>([]);

  useEffect(() => {
    let isMounted = true;

    async function getStatus() {
      try {
        const { data } = await axios.get('attributes/22');
        if (isMounted) {
          setStatuses(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    getStatus();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <StatusContext.Provider value={[statuses, setStatuses]}>
      {children}
    </StatusContext.Provider>
  );
}

export const useStatusContext = () => useContext(StatusContext);
