import axios from "axios";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Link, NavLink } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import { User } from "../../../models/user";
import Moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";


import { Input, Form, DatePicker, Select } from "antd";

import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import isMobile from "../../../components/constant/isMobile";
import PageLayout from "../../../components/constant/page/layout";
import WorkScheduleIndex from "../../../components/pages/workSchedule";
import WorkSchedulesIndex from "../../../components/pages/workSchedules";

const { Option } = Select;

dayjs.locale("tr");
const { RangePicker } = DatePicker;
const rangePresets: any = {
    'Son 7 Gün': [dayjs().subtract(7, 'days'), dayjs()],
    'Bu Ay': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Geçen Ay': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Bu Yıl': [dayjs().startOf('year'), dayjs()],
    'Geçen Yıl': [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
    '1. Çeyrek': [dayjs().startOf('year').add(0, 'month').startOf('month'), dayjs().startOf('year').add(4, 'month').endOf('month')],
    '2. Çeyrek': [dayjs().startOf('year').add(3, 'month').startOf('month'), dayjs().startOf('year').add(5, 'month').endOf('month')],
    '3. Çeyrek': [dayjs().startOf('year').add(6, 'month').startOf('month'), dayjs().startOf('year').add(8, 'month').endOf('month')],
    '4. Çeyrek': [dayjs().startOf('year').add(9, 'month').startOf('month'), dayjs().startOf('year').add(11, 'month').endOf('month')]
};

const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
for (let i = 0; i < 12; i++) {
    rangePresets[monthNames[i]] = [dayjs().month(i).startOf('month'), dayjs().month(i).endOf('month')];
}

interface Item {
    key: string;
    id: number;
    name: string;
    phone: string;
    email: string;
    customer_type: string;
    property_type: string;
    property_genus: string;
    contact_id: string;
    meet_time: string;
}

const WorkSchedules = (props: { user: User }) => {
    

    return (
        <Wrapper>

        <PageLayout title={'Günlük İş Raporu'}
            breadcrumbItems={[
                { label: 'Gösterge Paneli', path: '/' },
                { label: 'Günlük İş Raporu', active: true },
            ]}>
            <WorkSchedulesIndex />
        </PageLayout>

    </Wrapper>
    )
}

export default connect(
    (state: { user: User }) => {
        return {
            user: state.user
        };
    }
)(WorkSchedules);