import React from 'react';
import { NumericFormat } from "react-number-format";

const GiroLossFormat = ({ value }: any) => {
  return (
    <NumericFormat
    value={
      value && typeof value === 'number'
        ? value < 0
          ? (-1 * value).toFixed(2).toString().replace('.', ',')
          : `-${value.toFixed(2).toString().replace('.', ',')}`
        : '0,00'
    }
    thousandSeparator="."
    decimalSeparator=","
    displayType="text"
  />
  );
}

export default GiroLossFormat;


