import React, { SyntheticEvent, useEffect, useState, useRef, useMemo } from "react";
import { Link, Navigate, NavLink } from "react-router-dom";
import axios from "axios";

//Boostrap
import { Modal, Button } from "react-bootstrap";

import { Radio, Select, DatePicker, Checkbox, ConfigProvider, message } from 'antd';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { animateScroll as scroll } from 'react-scroll'

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import {
    setKey,
    setDefaults,
    setLanguage,
    setRegion,
    fromAddress,
    fromLatLng,
    fromPlaceId,
    setLocationType,
    geocode,
    RequestType,
} from "react-geocode";

import Wrapper from "../../../components/layout/wrapper";
import { User } from "../../../models/user";



import { PlusOutlined, InboxOutlined, DownloadOutlined, ConsoleSqlOutlined } from "@ant-design/icons";

import { CustomerType } from "../../../models/attribute/customerType/customerType";
import { PhoneCode } from "../../../models/general/country/phoneCode";
import { IdType } from "../../../models/attribute/idType/idType";
import { LandOffice } from "../../../models/general/location/landOffice/landOffice";
import { Province } from "../../../models/general/location/province/province";
import { District } from "../../../models/general/location/district/district";
import { Neighbourhood } from "../../../models/general/location/neighbourhood/neighbourhood";
import { PropertyGenus } from "../../../models/attribute/propertyGenus/propertyGenus";
import { PropertyType } from "../../../models/attribute/propertyType/propertyType";
import { FloorLocation } from "../../../models/attribute/floorLocation/floorLocation";
import { BuildType } from "../../../models/attribute/buildType/buildType";
import { Site } from "../../../models/general/site/site";
import { GiroType } from "../../../models/attribute/giroType/giroType";
import { Realtor } from "../../../models/general/realtor/realtor";
import { PaymentMethod } from "../../../models/attribute/paymentMethod/paymentMethod";

import { SourceOfFind } from "../../../models/attribute/sourceOfFind/sourceOfFind";
import { SourceOfSell } from "../../../models/attribute/sourceOfSell/sourceOfSell";
import { Country } from "../../../models/general/country/country";
import { useUserContext } from "../../../providers/user/userProvider";
import { useIdTypeContext } from "../../../providers/attribute/idType/idTypeProvider";
import { useGiroTypeContext } from "../../../providers/attribute/giroType/giroTypeProvider";
import { useCustomerTypeContext } from "../../../providers/attribute/customerType/customerTypeProvider";
import { useCountryContext } from "../../../providers/general/location/country/countryProvider";
import { useLandOfficeContext } from "../../../providers/general/location/landOffice/landOfficeProvider";
import { usePaymentMethodContext } from "../../../providers/attribute/paymentMethod/paymentMethodProvider";
import { useCommentStatusContext } from "../../../providers/attribute/commentStatus/commentStatusProvider";
import { useResourceOfSellContext } from "../../../providers/attribute/sourceOfSell/sourceOfSellProvider";
import { useResourcesOfFindContext } from "../../../providers/attribute/sourceOfFind/sourceOfFindProvider";
// import { Attribute } from "../../../models/attribute/attribute";
import { useProvinceContext } from "../../../providers/general/location/province/provinceProvider";
import { useBuildTypeContext } from "../../../providers/attribute/buildType/buildTypeProvider";
import { usePropertyGenusContext } from "../../../providers/attribute/propertyGenus/propertyGenusProvider";
import TransactionInfoCard from "../../../components/transaction/infoCard";
import useAttributes from "../../../api/hooks/attributes/useAttributes";
import { AttributeFilter } from "../../../models/attribute/attributeFilter";
import { AttributeData } from "../../../models/attribute/attributeData";
import { AttributeNew } from "../../../models/attribute/attributeNewData";
import { TransactionInfo } from "../../../models/transaction/transactionInfo";

const { Option } = Select;

const Create = () => {
    const params = useMemo(() => ({
        filter_types: [5, 6, 7, 9, 10, 11, 13, 14, 15, 16],
        limit:1000
      }), []);
    
      const { attributes, isLoading } = useAttributes(params);
    
      const customer_types = attributes.filter((attr: AttributeNew) => attr.type === 5);
      const id_types = attributes.filter((attr: AttributeNew) => attr.type === 6);
    
      const transformedAttributes = useMemo(() => {
        return attributes.map((attr: AttributeNew) => ({
          ...attr,
          type: attr.type,
          ordernum: attr.ordernum,
        }));
      }, [attributes]);
      

    setKey("AIzaSyA4ZrsurXwR2QD4Xx5-CpHPD4N3qvurBMo");

    // General
    const [disable_button, setDisableButton] = useState(false);
    const [save_button_text, setSaveButtonText] = useState('Kaydet');
    const [ret_agents, setRetAgents] = useState<any | null>([]);

    // Seller
    const [seller_customer_type_id, setSellerCustomerTypeId] = useState(1);
    const [seller_company_name, setSellerCompanyName] = useState('');
    const [seller_tax_admin, setSellerTaxAdmin] = useState('');
    const [seller_tax_number, setSellerTaxNumber] = useState('');
    const [seller_phone_code_id, setSellerPhoneCodeId] = useState(34);
    const [seller_first_name, setSellerFirstName] = useState<any | null>('');
    const [seller_last_name, setSellerLastName] = useState<any | null>('');
    const [seller_id_type_id, setSellerIdTypeId] = useState(3);
    const [seller_nationality_id, setSellerNationalityId] = useState(34);
    const [seller_id_no, setSellerIdNo] = useState('');
    const [seller_birthday, setSellerBirthday] = useState('');
    const [seller_email, setSellerEmail] = useState('');
    const [seller_address, setSellerAddress] = useState('');
    const [seller_phone, setSellerPhone] = useState('');
    const [seller_note, setSellerNote] = useState('');

    // Buyer
    const [buyer_customer_type_id, setBuyerCustomerTypeId] = useState(1);
    const [buyer_company_name, setBuyerCompanyName] = useState('');
    const [buyer_tax_admin, setBuyerTaxAdmin] = useState('');
    const [buyer_tax_number, setBuyerTaxNumber] = useState('');
    const [buyer_phone_code_id, setBuyerPhoneCodeId] = useState(34);
    const [buyer_first_name, setBuyerFirstName] = useState('');
    const [buyer_last_name, setBuyerLastName] = useState('');
    const [buyer_nationality_id, setBuyerNationalityId] = useState(34);
    const [buyer_id_type_id, setBuyerIdTypeId] = useState(3);
    const [buyer_id_no, setBuyerIdNo] = useState('');
    const [buyer_birthday, setBuyerBirthday] = useState('');
    const [buyer_email, setBuyerEmail] = useState('');
    const [buyer_address, setBuyerAddress] = useState('');
    const [buyer_phone, setBuyerPhone] = useState('');
    const [buyer_note, setBuyerNote] = useState('');

    //Other
    const [seller_note_see, setSellerNoteSee] = useState(false);
    const [buyer_note_see, setBuyerNoteSee] = useState(false);
    const [property_note_see, setPropertyNoteSee] = useState(false);
    const [out_office_see, setOutOfficeSee] = useState(false);
    const [calc_status, setCalcStatus] = useState(false);
    const [ref_calc_status, setRefCalcStatus] = useState(false);
    const [calc_status_for_agent, setCalcStatusForAgent] = useState(false);
    const [selected_agent_reload, setSelectedAgentReload] = useState(false);
    const [fix_total_service_fee, setFixTotalServiceFee] = useState(false);
    const [fix_total_service_fee_for_agent, setFixTotalServiceFeeForAgent] = useState(false);
    const [agents_total_service_fee, setAgentsTotalServiceFee] = useState(0);

    const [agent_price_status, setAgentPriceStatus] = useState<any | null>([]);
    const MAX_LIMIT = 100;

    const [other_realtor_show, setOtherRealtorShow] = useState(false);

    //Property
    const [province_id, setProvinceId] = useState<any | null>(7);
    const [province_name, setProvinceName] = useState<any | null>('');
    const [district_id, setDistrictId] = useState<any | null>(2038);
    const [district_name, setDistrictName] = useState<any | null>('');
    const [neighbourhood_id, setNeighbourhoodId] = useState<any | null>(2338);
    const [neighbourhood_name, setNeighbourhoodName] = useState<any | null>('');

    const [bob, setBob] = useState<any | null>(null);
    const [parcel, setParcel] = useState<any | null>(null);
    const [gross, setGross] = useState<any | null>(null);
    const [net, setNet] = useState<any | null>(null);
    const [land_office_id, setLandOfficeId] = useState<any | null>(1);
    const [deed_date, setDeedDate] = useState<any | null>('');
    const [property_address, setPropertyAddress] = useState<any | null>('');
    const [build_date, setBuildDate] = useState<any | null>(null);
    const [property_note, setPropertyNote] = useState<any | null>('');
    const [site_search, setSiteSearch] = useState<any | null>('');
    const [site_id, setSiteId] = useState<any | null>(null);
    const [property_genus_id, setPropertyGenusId] = useState<any | null>(7);
    const [property_type_id, setPropertyTypeId] = useState<any | null>(14);
    const [floor_location_id, setFloorLocationId] = useState<any | null>();
    const [build_type_id, setBuildTypeId] = useState<any | null>(114);

    //Transaction
    const [contract_date, setContractDate] = useState<any | null>('');
    const [sale_price, setSalePrice] = useState<any | null>(null);
    const [fair_price, setFairPrice] = useState<any | null>(null);
    const [title_deed_cost, setTitleDeedCost] = useState<any | null>(0);
    const [seller_service_fee, setSellerServiceFee] = useState<any | null>(0);
    const [buyer_service_fee, setBuyerServiceFee] = useState<any | null>(0.0);
    const [out_office_referral_fee, setOutOfficeReferralFee] = useState<any | null>(0);
    const [office_referral_fee, setOfficeReferralFee] = useState<any | null>(0);
    const [total_service_fee, setTotalServiceFee] = useState<any | null>(0);
    const [giro_type_id, setGiroTypeId] = useState(116);
    const [realtor_id, setRealtorId] = useState<any | null>(null);
    const [realtor_search, setRealtorSearch] = useState<any | null>('');
    const [total_service_fee_for_agent, setTotalServiceFeeForAgent] = useState<any | null>(null);
    const [source_of_sell_id, setSourceOfSellId] = useState<any | null>(null);
    const [source_of_find_id, setSourceOfFindId] = useState<any | null>(null);
    const [payment_method, setPaymentMethod] = useState<any | null>([120]);
    const [comment_status, setCommentStatus] = useState<any | null>(null);
    const [selected_agent, setSelectedAgent] = useState<any | null>(null);
    const [total_service_fee_agent, setTotalServiceFeeAgent] = useState<any | null>(null);
    const [agent_percent, setAgentPercent] = useState<any | null>(null);
    const [agent_service_fee, setAgentServiceFee] = useState<any | null>([]);
    const [team_leader_percent, setTeamLeaderPercent] = useState<any | null>(null);
    const [team_leader_service_fee, setTeamLeaderServiceFee] = useState<any | null>(null);
    const [office_service_fee, setOfficeServiceFee] = useState<any | null>(null);
    const [download_word, setDownloadWord] = useState<any | null>(true);
    const [partner_count, setPartnerCount] = useState<any | null>(0);

    // Realtor
    const [realtor_first_name, setRealtorFirstName] = useState('');
    const [realtor_last_name, setRealtorLastName] = useState('');
    const [realtor_company_name, setRealtorCompanyName] = useState('');
    const [realtor_email, setRealtorEmail] = useState('');
    const [realtor_phone_code_id, setRealtorPhoneCodeId] = useState<any | null>(34);
    const [realtor_phone, setRealtorPhone] = useState('');
    const [realtor_note, setRealtorNote] = useState('');

    //Site
    const [site_name, setSiteName] = useState('');
    const [site_address, setSiteAddress] = useState('');
    const [total_house, setTotalHouse] = useState(0);
    const [manager_name, setManagerName] = useState('');
    const [mobile_phone, setMobilePhone] = useState('');
    const [office_phone, setOfficePhone] = useState('');
    const [site_note, setSiteNote] = useState('');

    //Calculate
    useEffect(() => {
        if (calc_status) {
            let totalServiceFee = Math.round((parseFloat(seller_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) + parseFloat(buyer_service_fee)) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee))).toFixed(2).toString().replace('.', ',');
            if (giro_type_id === 117) {
                totalServiceFee = Math.round((parseFloat(seller_service_fee.toString().replace(/[.]/g, '').replace(',', '.'))) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee))).toFixed(2).toString().replace('.', ',');
            }
            if (agent_price_status.length > 0) {
                setTotalServiceFeeForAgent((parseFloat(totalServiceFee.replace(',', '.')) / parseInt(agent_price_status.length.toString())).toFixed(2));
            } else {
                setTotalServiceFeeForAgent(totalServiceFee);
            }
            setTotalServiceFee(totalServiceFee);
            setFixTotalServiceFee(true);
            if (giro_type_id === 116) {
                setSellerServiceFee((parseFloat(sale_price) * 0.02 * 1.20).toFixed(2).toString().replace('.', ','));
                setBuyerServiceFee((parseFloat(sale_price) * 0.02 * 1.20).toFixed(2).toString().replace('.', ','));
                setTitleDeedCost((parseFloat(fair_price) * 0.04 + 2466).toFixed(2).toString().replace('.', ','));
            }
            if (giro_type_id === 117) {
                setSellerServiceFee((parseFloat(sale_price) * 1.20).toFixed(2).toString().replace('.', ','));
            }
            setCalcStatus(false);
            setCalcStatusForAgent(true);
        }
    }, [calc_status]);

    useEffect(() => {
        if (fix_total_service_fee) {
            let totalServiceFee = Math.round((parseFloat(seller_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) + parseFloat(buyer_service_fee)) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee))).toFixed(2).toString().replace('.', ',');
            if (giro_type_id === 117) {
                totalServiceFee = Math.round((parseFloat(seller_service_fee.toString().replace(/[.]/g, '').replace(',', '.'))) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee))).toFixed(2).toString().replace('.', ',');
            }
            setTotalServiceFee(totalServiceFee);
            setFixTotalServiceFee(false);
        }
    }, [fix_total_service_fee]);

    useEffect(() => {
        if (ref_calc_status) {
            let totalServiceFee = Math.round((parseFloat(seller_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) + parseFloat(buyer_service_fee.toString().replace(/[.]/g, '').replace(',', '.'))) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee))).toFixed(2).toString().replace('.', ',');
            if (giro_type_id === 117) {
                totalServiceFee = Math.round((parseFloat(seller_service_fee.toString().replace(/[.]/g, '').replace(',', '.'))) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee))).toFixed(2).toString().replace('.', ',');
            }
            if (agent_price_status.length > 0) {
                setTotalServiceFeeForAgent((parseFloat(totalServiceFee.toString().replace(/[.]/g, '').replace(',', '.')) / parseInt(agent_price_status.length.toString())).toFixed(2));
            } else {
                setTotalServiceFeeForAgent(totalServiceFee);
            }
            setTotalServiceFee(totalServiceFee);
            setCalcStatusForAgent(true);
            setRefCalcStatus(false);
        }
    }, [ref_calc_status]);

    useEffect(() => {
        if (calc_status_for_agent) {
            let totalServiceFee = (parseFloat(seller_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) + parseFloat(buyer_service_fee) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee))).toFixed(2).toString().replace('.', ',');
            if (giro_type_id === 117) {
                totalServiceFee = (parseFloat(seller_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee))).toFixed(2).toString().replace('.', ',');
            }
            if (partner_count > 0) {
                setTotalServiceFeeForAgent((parseFloat(totalServiceFee) / parseInt(partner_count)));
            } else {
                setTotalServiceFeeForAgent(totalServiceFee);
            }
            setFixTotalServiceFeeForAgent(true);
            setCalcStatusForAgent(false);
        }
    }, [calc_status_for_agent]);

    useEffect(() => {
        if (fix_total_service_fee_for_agent) {
            let totalServiceFee = (parseFloat(seller_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) + parseFloat(buyer_service_fee) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee))).toFixed(2).toString().replace('.', ',');
            if (giro_type_id === 117) {
                totalServiceFee = (parseFloat(seller_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee))).toFixed(2).toString().replace('.', ',');
            }
            if (partner_count > 0) {
                setTotalServiceFeeForAgent((parseFloat(total_service_fee) / parseInt(partner_count)).toFixed(2));
            } else {
                setTotalServiceFeeForAgent(total_service_fee);
            }
            setFixTotalServiceFeeForAgent(false);
        }
    }, [fix_total_service_fee_for_agent]);

    const [selected_agents, setSelectedAgents] = useState([]);

    const updateState = (i: number, e: any, type: any) => {
        const myNextList = [...selected_agents];
        type SelectedAgent = {
            id: number;
        };

        const find_selected_agents: any = myNextList.find(
            (a: SelectedAgent) => a.id === i
        );

        let totalServiceFee = Math.round((parseFloat(seller_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) + parseFloat(buyer_service_fee)) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee))).toFixed(2).toString().replace('.', ',');
        if (giro_type_id === 117) {
            totalServiceFee = Math.round(parseFloat(seller_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee))).toFixed(2).toString().replace('.', ',');
        }
        if (agent_price_status.length > 0) {
            totalServiceFee = (parseFloat(totalServiceFee) / parseInt(agent_price_status.length.toString())).toFixed(2).toString().replace('.', ',');
        }
        switch (type) {
            case 'percent':
                find_selected_agents.percent = parseFloat(e.target.value.toString().replace(/[.]/g, '').replace(',', '.'));
                find_selected_agents.price = (parseFloat(totalServiceFee) * (e.target.value / 100)).toFixed(2).toString().replace('.', ',');
                find_selected_agents.office_service_fee = (parseFloat(totalServiceFee) - (parseFloat(totalServiceFee) * (e.target.value / 100) + (parseFloat(find_selected_agents.team_leader_price.toString().replace(',', '.'))))).toFixed(2).toString().replace('.', ',');
                break;
            case 'price':
                find_selected_agents.price = parseFloat(e.target.value);
                break;
            case 'team_leader_percent':
                find_selected_agents.team_leader_percent = e.target.value;
                find_selected_agents.team_leader_price = (parseFloat(totalServiceFee) * (e.target.value / 100)).toFixed(2).toString().replace('.', ',');
                find_selected_agents.office_service_fee = (parseFloat(totalServiceFee) - (parseFloat(find_selected_agents.price.toString().replace(',', '.')) + (parseFloat(totalServiceFee) * (e.target.value / 100)))).toFixed(2).toString().replace('.', ',');
                break;
            case 'team_leader_price':
                find_selected_agents.team_leader_price = e.target.value.toString().replace(/[.]/g, '').replace(',', '.');
                break;
            case 'total_service_fee':
                find_selected_agents.total_service_fee = e.target.value;
                find_selected_agents.price = (parseFloat(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')) * (find_selected_agents.percent / 100)).toFixed(2).toString().replace('.', ',');
                find_selected_agents.team_leader_price = (parseFloat(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')) * (find_selected_agents.team_leader_percent / 100)).toFixed(2).toString().replace('.', ',');
                find_selected_agents.office_service_fee = ((parseFloat(e.target.value.toString().replace(/[.]/g, '').replace(',', '.'))) - (parseFloat(find_selected_agents.price.toString().replace(',', '.')) + parseFloat(find_selected_agents.team_leader_price.toString().replace(',', '.')))).toString().replace('.', ',');
                break;
        }
        interface Agent {
            total_service_fee: number;
        }
        //şüpheli hesap hatası çıkabilir
        const agentsTotalPrice = myNextList.reduce((acc, cur: Agent) => acc + parseFloat(cur?.total_service_fee?.toString().replace(/[.]/g, '').replace(',', '.')), 0);
        setAgentsTotalServiceFee(agentsTotalPrice);
        setTimeout(() => {
            setSelectedAgents(myNextList);
        }, 1000);

    };

    const updateForTotalServiceState = (i: number, e: any, type: any) => {
        setTimeout(() => {
            const myNextList = [...selected_agents];
            type SelectedAgent = {
                id: number;
            };

            const find_selected_agents: any = myNextList.find(
                (a: SelectedAgent) => a.id === i
            );
            switch (type) {
                case 'total_service_fee':
                    find_selected_agents.total_service_fee = e.target.value;
                    find_selected_agents.price = (parseFloat(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')) * (find_selected_agents.percent / 100)).toFixed(2).toString().replace('.', ',');
                    find_selected_agents.team_leader_price = (parseFloat(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')) * (find_selected_agents.team_leader_percent / 100)).toFixed(2).toString().replace('.', ',');
                    find_selected_agents.office_service_fee = (((parseFloat(e.target.value.toString().replace(/[.]/g, '').replace(',', '.'))) - (parseFloat(find_selected_agents.price.toString().replace(',', '.')) + parseFloat(find_selected_agents.team_leader_price.toString().replace(',', '.')))).toFixed(2)).toString().replace('.', ',');
                    break;
                case 'percent':
                    find_selected_agents.percent = parseFloat(e.target.value.toString().replace(/[.]/g, '').replace(',', '.'));
                    find_selected_agents.price = (parseFloat(find_selected_agents.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) * (e.target.value / 100)).toFixed(2).toString().replace('.', ',');
                    find_selected_agents.office_service_fee = (parseFloat(find_selected_agents.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) - (parseFloat(find_selected_agents.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) * (e.target.value / 100) + (parseFloat(find_selected_agents.team_leader_price.toString().replace(',', '.'))))).toFixed(2).toString().replace('.', ',');
                    break;
            }
            setSelectedAgents(myNextList);
        }, 500);

    };

    //Real Estate Agents Prices
    useEffect(() => {
        if (agent_price_status.length > 0) {
            (
                async () => {
                    const { data } = await axios.get('users/filter-in/' + agent_price_status);
                    const newArray = data.data.map((item: any, i: any) => {
                        let price = 0;
                        if (parseInt(item.category.percent) > 0) {
                            price = parseFloat(((parseFloat(total_service_fee_for_agent) / parseInt(agent_price_status.length.toString())) * (parseFloat(item.category.percent) / 100)).toFixed(2));
                        }
                        let tLiderPrice = 0;
                        let tLiderPercent = 0;
                        let office_service_price = '0';
                        let agent_price = '0';
                        let officeServiceFee = ((parseFloat(total_service_fee_for_agent.toString().replace(/[.]/g, '').replace(',', '.')) / parseInt(agent_price_status.length.toString())) - price).toFixed(2);
                        if (item.team_leader?.id > 0) {
                            tLiderPercent = parseFloat(item.team_leader_percent);
                            let tLiderPrice = parseFloat(total_service_fee_for_agent) * (tLiderPercent / 100);
                            officeServiceFee = (((parseFloat(total_service_fee_for_agent)) - (price + parseFloat(tLiderPrice.toString().replace(',', '.')))).toFixed(2)).toString().replace('.', ',');
                        }
                        office_service_price = officeServiceFee.replace('.', ',');
                        agent_price = price.toString().replace('.', ',');
                        return { ...item, percent: item.category.percent, price: agent_price, team_leader_price: tLiderPrice, team_leader_percent: tLiderPercent, office_service_fee: office_service_price, total_service_fee: total_service_fee_for_agent.toString().replace('.', ',') };
                    });

                    setSelectedAgents(newArray);
                    const agentsTotalPrice = newArray.reduce((acc: number, cur: { total_service_fee: string }) => { return acc + parseFloat(cur.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')); }, 0);

                    setAgentsTotalServiceFee(agentsTotalPrice);

                    setSelectedAgentReload(true);
                }
            )();
        }
    }, [agent_price_status]);

    useEffect(() => {
        if (selected_agent_reload) {
            if (Array.isArray(selected_agents)) {
                if (agent_price_status.length > 0) {
                    setTimeout(() => {
                        const newArray: any = selected_agents.map((item: any, i: any) => {
                            let price = 0;
                            if (parseInt(item.category.percent) > 0) {
                                price = parseFloat(((parseFloat(total_service_fee_for_agent)) * (parseFloat(item.category.percent) / 100)).toFixed(2));
                            }
                            let tLiderPrice = '0';
                            let tLiderPercent = 0;
                            let office_service_price = '0';
                            let agent_price = '0';
                            let officeServiceFee = ((parseFloat(total_service_fee_for_agent)) - parseFloat(price.toString())).toFixed(2);
                            if (item.team_leader?.id > 0) {
                                tLiderPercent = parseFloat(item.team_leader_percent);
                                tLiderPrice = (parseFloat(((parseFloat(total_service_fee_for_agent)) * (tLiderPercent / 100)).toFixed(2))).toString().replace('.', ',');
                                officeServiceFee = (((parseFloat(total_service_fee_for_agent)) - (price + parseFloat(tLiderPrice)))).toString().replace('.', ',');

                            }
                            office_service_price = officeServiceFee.replace('.', ',');
                            agent_price = price.toString().replace('.', ',');


                            return { ...item, percent: item.category.percent, price: agent_price, team_leader_price: tLiderPrice, team_leader_percent: tLiderPercent, office_service_fee: office_service_price, total_service_fee: total_service_fee_for_agent.toString().replace('.', ',') };
                        });
                        setSelectedAgents(newArray);
                        const agentsTotalPrice = newArray.reduce((acc: number, cur: { total_service_fee: string }) => { return acc + parseFloat(cur.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')); }, 0);
                        setAgentsTotalServiceFee(agentsTotalPrice);
                    }, 500);
                    setSelectedAgentReload(false);
                    setCalcStatusForAgent(true);

                }
            }
        }
    }, [selected_agent_reload]);

    const [communication_id, setCommunicationId] = useState('');
    const [customers, setCustomers] = useState([]);
    const [redirect, setRedirect] = useState(false);

    // const [customer_types, setCustomerTypes] = useState([]);
    // const [phone_codes, setPhoneCodes] = useState([]);
    // const [nationalities, setNationalities] = useState([]);
    // const [id_types, setIdTypes] = useState([]);
    //const [land_offices, setLandOffices] = useState([]);
    //const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [neighbourhoods, setNeighbourhoods] = useState([]);
    //const [property_genuses, setPropertyGenuses] = useState([]);
    const [property_types, setPropertyTypes] = useState([]);
    const [floor_locations, setFloorLocations] = useState([]);
    //const [build_types, setBuildTypes] = useState([]);
    const [sites, setSites] = useState([]);
    const [realtors, setRealtors] = useState([]);
    // const [payment_methods, setPaymentMethods] = useState([]);
    // const [comment_statuses, setCommentStatuses] = useState([]);
    // const [resources_of_sell, setResourcesOfSell] = useState([]);
    // const [resources_of_find, setResourcesOfFind] = useState([]);

    // const [giro_types, setGiroTypes] = useState([]);

    const [users, setUsers] = useUserContext();
    const [giro_types, setGiroTypes] = useGiroTypeContext();
    // const [id_types, setIdTypes] = useIdTypeContext();
    // const [customer_types, setCustomerTypes] = useCustomerTypeContext();
    const [phone_codes, setPhoneCodes] = useCountryContext();
    const [nationalities, setNationalities] = useCountryContext();
    const [payment_methods, setPaymentMethods] = usePaymentMethodContext();
    const [comment_statuses, setCommentStatuses] = useCommentStatusContext();
    const [resources_of_sell, setResourcesOfSell] = useResourceOfSellContext();
    const [resources_of_find, setResourcesOfFind] = useResourcesOfFindContext();
    const [land_offices, setLandOffices] = useLandOfficeContext();
    const [provinces, setProvinces] = useProvinceContext();
    const [build_types, setBuildTypes] = useBuildTypeContext();
    // const [property_genuses, setPropertyGenuses] = usePropertyGenusContext();

    // useEffect(() => {
    //             const {data}:any = useCountryContext();
    //             if(data){
    //                 setPhoneCodes(data);
    //                 setNationalities(data);
    //             }
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('users');

    //             setUsers(data.data);
    //         }

    //     )()
    // }, []);


    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/11');

    //             setGiroTypes(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/5');

    //             setCustomerTypes(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('general/countries');

    //             setPhoneCodes(data);
    //             setNationalities(data);
    //         }
    //     )()
    // }, []);


    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/6');

    //             setIdTypes(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('location/land-offices');

    //             setLandOffices(data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('location/provinces');

    //             setProvinces(data);
    //         }
    //     )()
    // }, []);

    useEffect(() => {
        (
            async () => {

                const { data } = await axios.get('location/district/' + province_id);

                setDistricts(data);
            }
        )()
    }, [province_id]);

    useEffect(() => {

        (
            async () => {
                if (typeof province_name === 'string' && province_name.trim().length > 0) {
                    const { data } = await axios.get('location/province/name-like/' + province_name);
                    if (data.id > 0) { setDistrictId(null); setNeighbourhoodId(null); }
                    setProvinceId(data.id);
                }
            }
        )()

    }, [province_name])


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('location/neighbourhood/' + district_id);

                setNeighbourhoods(data);
            }
        )()
    }, [district_id]);

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0) {

                        const { data } = await axios.get(`location/district/name-like/${district_name}/${province_id}`);
                        setDistrictId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [district_name, province_id])


    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/11');

    //             setGiroTypes(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/5');

    //             setCustomerTypes(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('general/countries');

    //             setPhoneCodes(data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/6');

    //             setIdTypes(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('location/land-offices');

    //             setLandOffices(data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('location/provinces');

    //             setProvinces(data);
    //         }
    //     )()
    // }, []);

    useEffect(() => {
        (
            async () => {

                const { data } = await axios.get('location/district/' + province_id);

                setDistricts(data);
            }
        )()
    }, [province_id]);

    useEffect(() => {

        (
            async () => {
                if (typeof province_name === 'string' && province_name.trim().length > 0) {
                    const { data } = await axios.get('location/province/name-like/' + province_name);
                    if (data.id > 0) { setDistrictId(null); setNeighbourhoodId(null); }
                    setProvinceId(data.id);
                }
            }
        )()

    }, [province_name])


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('location/neighbourhood/' + district_id);

                setNeighbourhoods(data);
            }
        )()
    }, [district_id]);

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0) {

                        const { data } = await axios.get(`location/district/name-like/${district_name}/${province_id}`);
                        setDistrictId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [district_name, province_id])

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0) {

                        const { data } = await axios.get(`location/neighbourhood/name-like/${neighbourhood_name}/${district_id}`);
                        setNeighbourhoodId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [neighbourhood_name, district_id])


    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/7');

    //             setPropertyGenuses(data.data);
    //         }
    //     )()
    // }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/8/' + property_genus_id);

                setPropertyTypes(data.data);
            }
        )()
    }, [property_genus_id]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/9');

                setFloorLocations(data.data);
            }
        )()
    }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/10');

    //             setBuildTypes(data.data);
    //         }
    //     )()
    // }, []);

    useEffect(() => {
        let isMounted = true;
        const source = axios.CancelToken.source();

        const fetchSites = async () => {
            try {
                let response;

                if (site_search.length > 0) {
                    response = await axios.get('sites/search/' + site_search, { cancelToken: source.token });
                } else {
                    response = await axios.get('sites');
                }

                if (isMounted) {
                    setSites(response.data.data || []);
                }
            } catch (error) {
                console.log(error);
            }
        };

        try {
            fetchSites();
        } catch (error) {
            console.log(error);
        }

        const intervalId = setInterval(() => {
            try {
                fetchSites();
            } catch (error) {
                console.log(error);
            }
        }, 5000); // Her 5 saniyede bir sorgu yap

        return () => {
            isMounted = false;
            clearInterval(intervalId);
            source.cancel('Request canceled');
        };
    }, [site_search]);


    useEffect(() => {
        try {
            (async () => {
                if (realtor_search.length > 0) {
                    const { data } = await axios.get('realtors/search/' + realtor_search);
                    setRealtors(data.data);
                } else {
                    const { data } = await axios.get('realtors');
                    setRealtors(data);
                }

            })();
        } catch (error) {
            console.log(' Hata = ' + error);
        }
    }, [realtor_search]);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/13');

    //             setPaymentMethods(data.data);
    //         }
    //     )()
    // }, []);


    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/14');

    //             setCommentStatuses(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/15');

    //             setResourcesOfFind(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/16');

    //             setResourcesOfSell(data.data);
    //         }
    //     )()
    // }, []);


    const customizeRenderEmpty = () => (
        <div style={{ textAlign: 'center' }}>
            <InboxOutlined style={{ fontSize: 40 }} />
            <p>Emlakçı Bulunamadı!</p>
            <Button variant="primary" onClick={handleShow}>Yeni Ekle</Button>

        </div>
    );

    const customizeRenderEmptyForSite = () => (
        <div style={{ textAlign: 'center' }}>
            <InboxOutlined style={{ fontSize: 40 }} />
            <p>Site Bulunamadı!</p>
            <Button variant="primary" onClick={handleSiteShow}>Yeni Ekle</Button>

        </div>
    );


    //Modals
    //Add Realtor Modal
    const [show_realtor, setShowRealtor] = useState(false);
    const handleClose = () => setShowRealtor(false);

    const handleShow = (e: any) => {
        (
            () => {
                setShowRealtor(true);
            }
        )()
    };

    //Add Site Modal
    const [show_site_modal, setShowSiteModal] = useState(false);
    const handleSiteClose = () => setShowSiteModal(false);

    const handleSiteShow = (e: any) => {
        (
            () => {
                setShowSiteModal(true);
            }
        )()
    };

    //Downlaod Modal
    const [show_download, setShowDownload] = useState(false);
    const handleDownloadClose = () => { setShowDownload(false); setRedirect(true); }

    const handleDownloadShow = (e: any) => {
        (
            () => {
                setShowDownload(true);
            }
        )()
    };

    //Google Maps Geo For Site
    const [site_coordinates, setSiteCoordinates] = useState('');

    useEffect(() => {


        fromAddress(site_address).then(
            (response: any) => {
                const { lat, lng } = response.results[0].geometry.location;
                setSiteCoordinates(`${lat},${lng}`)
            },
            (error: any) => {
                //console.error(error);
            }
        );
    }, [site_address]);

    // Google maps  geoFor Property
    const [coordinates, setCoordinates] = useState({ lat: 36.87524849186124, lng: 30.64364152042535 });
    const [property_coordinate, setPropertyCoordinate] = useState<any | null>('36.87524849186124,30.64364152042535');

    useEffect(() => {
        fromAddress(property_address).then(
            (response: any) => {
                const { lat, lng } = response.results[0].geometry.location;
                setCoordinates({ lat: lat, lng: lng })
                setPropertyCoordinate(`${lat},${lng}`)

                let administrativeAreaLevel1;

                for (const result of response.results) {
                    for (const component of result.address_components) {
                        if (component.types.includes('administrative_area_level_1')) {
                            administrativeAreaLevel1 = component.long_name;
                            break;
                        }
                    }
                }
                let administrativeAreaLevel2;

                for (const result of response.results) {
                    for (const component of result.address_components) {
                        if (component.types.includes('administrative_area_level_2')) {
                            administrativeAreaLevel2 = component.long_name;
                            break;
                        }
                    }
                }
                let administrativeAreaLevel3;

                for (const result of response.results) {
                    for (const component of result.address_components) {
                        if (component.types.includes('administrative_area_level_3')) {
                            administrativeAreaLevel3 = component.long_name;
                            break;
                        }
                    }
                }

                let administrativeAreaLevel4;
                for (const result of response.results) {
                    for (const component of result.address_components) {
                        if (component.types.includes('administrative_area_level_4')) {
                            administrativeAreaLevel4 = component.long_name;
                            break;
                        }
                    }
                }

                let administrativeAreaLevelNeighbourhood;
                for (const result of response.results) {
                    for (const component of result.address_components) {
                        if (component.types.includes('neighborhood')) {
                            administrativeAreaLevelNeighbourhood = component.long_name;
                            break;
                        }
                    }
                }

                setProvinceName(administrativeAreaLevel1);
                setDistrictName(administrativeAreaLevel2);
                if (administrativeAreaLevelNeighbourhood != null && administrativeAreaLevelNeighbourhood != '' && administrativeAreaLevelNeighbourhood.trim().length > 0) {
                    setNeighbourhoodName(administrativeAreaLevelNeighbourhood);
                } else if (administrativeAreaLevel3 != null && administrativeAreaLevel3 != '' && administrativeAreaLevel3.trim().length > 0) {
                    setNeighbourhoodName(administrativeAreaLevel3);
                } else if (administrativeAreaLevel4 != null && administrativeAreaLevel4 != '' && administrativeAreaLevel4.trim().length > 0) {
                    setNeighbourhoodName(administrativeAreaLevel4);
                }

            },
            (error: any) => {
                //console.error(error);
            }
        );
    }, [property_address]);

    const containerStyle = {
        width: '100%',
        height: '400px'
    };
    const center = {
        lat: 36.87524849186124,
        lng: 30.64364152042535
    };
    const position = {
        lat: 36.87524849186124,
        lng: 30.64364152042535
    }

    function withEvent(func: Function): React.ChangeEventHandler<any> {
        return (event: React.ChangeEvent<any>) => {
            const { target } = event;
            func(target.value);
        };
    }

    //Save operations 
    //Realtor
    const realtorSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const new_realtor_id = await axios.post('realtors', {
            first_name: realtor_first_name,
            last_name: realtor_last_name,
            company_name: realtor_company_name,
            email: realtor_email,
            phone_code_id: realtor_phone_code_id,
            phone: realtor_phone,
            definition: realtor_note,

        });
        setRealtorSearch(`${realtor_first_name} ${realtor_last_name}`);
        setRealtorId(new_realtor_id);
        setShowRealtor(false);
    }

    //Site
    const siteSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const new_site_id = await axios.post('sites', {
            name: site_name,
            address: site_address,
            manager_name: manager_name,
            office_phone: office_phone,
            mobile_phone: mobile_phone,
            coordinate: site_coordinates,
            definition: site_note,
            house_count: total_house,

        });
        setSiteSearch(`${site_name}`);
        setSiteId(new_site_id);
        setShowSiteModal(false);
    }

    //General
    interface FormState {
        error: { [key: string]: any | null };
        inputRefs: { [key: string]: any | React.RefObject<HTMLInputElement> };
    }

    const [formState, setFormState] = useState<any | null>({ error: {}, inputRefs: {} });
    const [messageApi, contextHolder] = message.useMessage();

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        let errorFields: { [key: string | number]: { name: string, key: any, error: string | null, label: string } } = {};

        let hasError = false;

        const requiredInputs: any = [
            { key: 'seller_first_name', name: 'Satıcı adı ' },
            { key: 'seller_last_name', name: 'Satıcı soyadı' },
            { key: 'seller_phone', name: 'Satıcı telefon numarası' },
            { key: 'seller_id_no', name: 'Satıcı kimlik numarası' },
            { key: 'seller_address', name: 'Satıcı adresi' },

            { key: 'buyer_first_name', name: 'Alıcı adı ' },
            { key: 'buyer_last_name', name: 'Alıcı soyadı' },
            { key: 'buyer_phone', name: 'Alıcı telefon numarası' },
            { key: 'buyer_id_no', name: 'Alıcı kimlik numarası' },
            { key: 'buyer_address', name: 'Alıcı adresi' },

            { key: 'property_address', name: 'Portföy adresi ' },
            { key: 'province_id', name: 'Portföy ili' },
            { key: 'district_id', name: 'Portföy ilçesi' },
            { key: 'neighbourhood_id', name: 'Portföy mahallesi' },

            { key: 'contract_date', name: 'Sözleşme tarihi' },
            { key: 'source_of_find_id', name: 'Portföy bulma kaynağı' },
            { key: 'source_of_sell_id', name: 'Portföy satma kaynağı' },
            { key: 'sale_price', name: 'Satış bedeli' },
            { key: 'seller_service_fee', name: 'Satıcı hizmet bedeli' },
        ];

        requiredInputs.forEach((input: { key: any; name: any; }) => {
            const el = (e.currentTarget as HTMLFormElement).elements[input.key] as HTMLInputElement;


            if (el && !el.value) {
                errorFields[input.key] = { key: input.key, label: input.name, name: input.name, error: "Bu alan zorunlu!" };
                messageApi.open({
                    type: 'error',
                    content: input.name + ' zorunludur. Lütfen doldurunuz.',
                });
                hasError = true;
            }

        });

        if (hasError) {
            if (source_of_find_id === 0 || source_of_find_id === null) {
                errorFields['source_of_find_id'] = { key: 'source_of_find_id', name: 'source_of_find_id', label: 'Portföy bulma kaynağı zorunludur.', error: "Bu alan zorunlu!" };
                messageApi.open({
                    type: 'error',
                    content: 'Portföy bulma kaynağı zorunludur.. Lütfen doldurunuz.',
                });
            }
            if (source_of_sell_id === 0 || source_of_sell_id === null) {
                errorFields['source_of_sell_id'] = { key: 'source_of_sell_id', name: 'source_of_sell_id', label: 'Portföy bulma kaynağı zorunludur.', error: "Bu alan zorunlu!" };
                messageApi.open({
                    type: 'error',
                    content: 'Portföy bulma kaynağı zorunludur.. Lütfen doldurunuz.',
                });
            }

            if (district_id === 0 || district_id === null) {
                errorFields['district_id'] = { key: 'district_id', name: 'district_id', label: 'İlçe seçimi zorunludur.', error: "Bu alan zorunlu!" };
                messageApi.open({
                    type: 'error',
                    content: 'İlçe seçimi zorunludur. Lütfen doldurunuz.',
                });
            }

            if (neighbourhood_id === 0 || neighbourhood_id === null) {
                errorFields['neighbourhood_id'] = { key: 'neighbourhood_id', name: 'neighbourhood_id', label: 'Mahalle seçimi zorunludur.', error: "Bu alan zorunlu!" };
                messageApi.open({
                    type: 'error',
                    content: 'Mahalle seçimi zorunludur.. Lütfen doldurunuz.',
                });
            }

            setFormState({ error: errorFields, inputRefs: formState.inputRefs });

            return;
        }
        if (transaction_data.selected_agents.length === 0) {
            messageApi.open({
                type: 'error',
                content: 'En az 1 danışman seçimi zorunludur.',
            });
        }

        if (hasError) {


            messageApi.open({
                type: 'error',
                content: 'Lütfen zorunlu alanları doldurun!',
            });

            const firstErrorInput = Object.keys(errorFields).find((key) => errorFields[key] !== null);
            if (firstErrorInput && formState.inputRefs[firstErrorInput] && formState.inputRefs[firstErrorInput].current) {
                formState.inputRefs[firstErrorInput.toString()].current.focus();
                scroll.scrollTo(formState.inputRefs[firstErrorInput].current.offsetTop, {
                    duration: 500,
                    smooth: "easeInOutQuint"
                });
            }

        } else {
            setFormState({ error: {}, inputRefs: formState.inputRefs });


            setDisableButton(true);
            setSaveButtonText('Bekleyiniz...');
            const res = await axios.post('transactions', {
                giro_type_id,
                seller_customer_type_id,
                seller_company_name,
                seller_tax_admin,
                seller_tax_number,
                seller_first_name,
                seller_last_name,
                seller_email,
                seller_phone_code_id,
                seller_phone,
                seller_nationality_id,
                seller_id_type_id,
                seller_id_no,
                seller_birthday,
                seller_address,
                seller_note,

                buyer_customer_type_id,
                buyer_company_name,
                buyer_tax_admin,
                buyer_tax_number,
                buyer_first_name,
                buyer_last_name,
                buyer_email,
                buyer_phone_code_id,
                buyer_phone,
                buyer_nationality_id,
                buyer_id_type_id,
                buyer_id_no,
                buyer_birthday,
                buyer_address,
                buyer_note,

                bob,
                parcel,
                land_office_id,
                deed_date,
                property_address,
                province_id,
                district_id,
                neighbourhood_id,
                property_genus_id,
                property_type_id,
                floor_location_id,
                build_date,
                gross,
                net,
                build_type_id,
                site_id,
                property_note,
                property_coordinate,

                transaction_data,
                // source_of_find_id,
                // source_of_sell_id,
                // sale_price,
                // fair_price,
                // title_deed_cost,
                // seller_service_fee,
                // buyer_service_fee,
                // office_referral_fee,
                // realtor_id,
                // out_office_referral_fee,
                // total_service_fee,
                agent_percent,
                agent_service_fee,
                team_leader_percent,
                team_leader_service_fee,
                office_service_fee,
                payment_method,
                comment_status,
                contract_date,
                selected_agents,
                partner_count,
                download_word
            }).then(response => {
                if (response?.data.length > 0) {
                    setRetAgents(response.data);
                    (download_word) ? setShowDownload(true) : setRedirect(true)
                }
            })
                .catch(error => {
                    messageApi.open({
                        type: 'error',
                        content: 'Lütfen zorunlu alanları doldurun!',
                    });

                    if (transaction_data.source_of_find_id === 0 || transaction_data.source_of_find_id === null) {
                        errorFields['source_of_find_id'] = { key: 'source_of_find_id', name: 'source_of_find_id', label: 'Portföy bulma kaynağı zorunludur.', error: "Bu alan zorunlu!" };
                        messageApi.open({
                            type: 'error',
                            content: 'Portföy bulma kaynağı zorunludur.. Lütfen doldurunuz.',
                        });
                    }
                    if (transaction_data.source_of_sell_id === 0 || transaction_data.source_of_sell_id === null) {
                        errorFields['source_of_sell_id'] = { key: 'source_of_sell_id', name: 'source_of_sell_id', label: 'Portföy bulma kaynağı zorunludur.', error: "Bu alan zorunlu!" };
                        messageApi.open({
                            type: 'error',
                            content: 'Portföy bulma kaynağı zorunludur.. Lütfen doldurunuz.',
                        });
                    }

                    if (district_id === 0 || district_id === null) {
                        errorFields['district_id'] = { key: 'district_id', label: 'İlçe seçimi zorunludur.', name: 'district_id', error: "Bu alan zorunlu!" };
                        messageApi.open({
                            type: 'error',
                            content: 'İlçe seçimi zorunludur. Lütfen doldurunuz.',
                        });
                    }

                    if (neighbourhood_id === 0 || neighbourhood_id === null) {
                        errorFields['neighbourhood_id'] = { key: 'neighbourhood_id', label: 'Mahalle seçimi zorunludur.', name: 'neighbourhood_id', error: "Bu alan zorunlu!" };
                        messageApi.open({
                            type: 'error',
                            content: 'Mahalle seçimi zorunludur.. Lütfen doldurunuz.',
                        });
                    }

                    setFormState({ error: errorFields, inputRefs: formState.inputRefs });

                    const firstErrorInput = Object.keys(errorFields).find((key) => errorFields[key] !== null);
                    if (firstErrorInput && formState.inputRefs[firstErrorInput] && formState.inputRefs[firstErrorInput].current) {
                        formState.inputRefs[firstErrorInput.toString()].current.focus();
                        scroll.scrollTo(formState.inputRefs[firstErrorInput].current.offsetTop, {
                            duration: 500,
                            smooth: "easeInOutQuint"
                        });
                    }
                });
        }
        setSaveButtonText('Kaydet');
        setDisableButton(false);
    }

    const [transaction_data, setTransactionData] = useState<any>({});

    const handleDataChange = (data:TransactionInfo) => {
        setTransactionData(data);
    };

    if (redirect) {
        return <Navigate to={'/accounting/transactions'} />;
    }
    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">İşlem Ekle</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><NavLink to={'/'}>Gösterge Paneli</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to={'/accounting/transactions'}>İşlemler</NavLink></li>
                                <li className="breadcrumb-item active">Oluştur</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <form onSubmit={submit}>
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>İşlem Türü</label>
                                                <div>
                                                    <Radio.Group defaultValue={giro_type_id} onChange={e => setGiroTypeId(e.target.value)}>
                                                {attributes
    .filter(attr => Number(attr.type) === 11)
    .map(giroType => (
        <Radio key={giroType.id} value={giroType.id}>{giroType.name}</Radio>
    ))}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Satıcı/Kiralayan Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>{giro_type_id === 116 ? 'Satıcı Bilgileri' : 'Kiralayan Bilgileri'} </label>
                                                <div>
                                                    <Radio.Group defaultValue={seller_customer_type_id} onChange={e => setSellerCustomerTypeId(e.target.value)}>
                                                        {customer_types.map((customer_types: AttributeNew) => {
                                                            return (
                                                                <Radio value={customer_types.id} >{customer_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Firma Adı</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir firma adı giriniz."
                                                    className="form-control"
                                                    onChange={e => setSellerCompanyName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Dairesi</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi dairesi giriniz."
                                                    className="form-control"
                                                    onChange={e => setSellerTaxAdmin(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi numarası giriniz."
                                                    className="form-control"
                                                    onChange={e => setSellerTaxNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                                    name="seller_first_name"
                                                    className="form-control"
                                                    required
                                                    onChange={e => setSellerFirstName(e.target.value)}
                                                />
                                                {formState.error.seller_first_name && <p className="text-danger">{formState.error.seller_first_name.error}</p>}

                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" name="seller_last_name" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                    required
                                                    onChange={e => setSellerLastName(e.target.value)}
                                                />
                                                {formState.error.seller_last_name && <p className="text-danger">{formState.error.seller_last_name.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kimlik Tipi</label>
                                                <Select className="form-control" defaultValue={3} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setSellerIdTypeId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {id_types.map((id_types: AttributeNew) => {
                                                        return (
                                                            <Option value={id_types.id} title={id_types.name}
                                                                key={id_types.name}
                                                                id={id_types.name}>{id_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Numara</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir kimlik numarası giriniz."
                                                    className="form-control"
                                                    name="seller_id_no"
                                                    required
                                                    onChange={e => setSellerIdNo(e.target.value)}
                                                />
                                                {formState.error.seller_id_no && <p className="text-danger">{formState.error.seller_id_no.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select defaultValue={seller_phone_code_id} style={{ width: 100 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setSellerPhoneCodeId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >

                                                            {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                return (
                                                                    <Option value={phone_codes.id} title={phone_codes.name}
                                                                        key={phone_codes.name}
                                                                        id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <PatternFormat format="(###) ### ## ##" name="seller_phone" required className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setSellerPhone(e.target.value)} />
                                                    {formState.error.seller_phone && <p className="text-danger">{formState.error.seller_phone.error}</p>}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    onChange={e => setSellerEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Uyruk</label>
                                                <Select className="form-control" defaultValue={seller_nationality_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setSellerNationalityId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {nationalities?.map((nationalities: Country) => {
                                                        return (
                                                            <Option value={nationalities.id} title={nationalities.name}
                                                                key={nationalities.name}
                                                                id={nationalities.name}>{nationalities.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Doğum Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen doğum tarihi seçiniz."
                                                    className="form-control"
                                                    onChange={e => setSellerBirthday(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    name="seller_address"
                                                    required
                                                    onChange={e => setSellerAddress(e.target.value)}
                                                />
                                                {formState.error.seller_address && <p className="text-danger">{formState.error.seller_address.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { seller_note_see ? setSellerNoteSee(false) : setSellerNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={seller_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    onChange={e => setSellerNote(e.target.value)}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Alıcı/Kiracı Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>{giro_type_id === 116 ? 'Alıcı Bilgileri' : 'Kiracı Bilgileri'}</label>
                                                <div>
                                                    <Radio.Group defaultValue={buyer_customer_type_id} onChange={e => setBuyerCustomerTypeId(e.target.value)}>
                                                        {customer_types.map((customer_types: AttributeNew) => {
                                                            return (
                                                                <Radio value={customer_types.id} >{customer_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Firma Adı</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir firma adı giriniz."
                                                    className="form-control"
                                                    onChange={e => setBuyerCompanyName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Dairesi</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi dairesi giriniz."
                                                    className="form-control"
                                                    onChange={e => setBuyerTaxAdmin(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi numarası giriniz."
                                                    className="form-control"
                                                    onChange={e => setBuyerTaxNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                                    className="form-control"
                                                    name="buyer_first_name"
                                                    required
                                                    onChange={e => setBuyerFirstName(e.target.value)}
                                                />
                                                {formState.error.buyer_first_name && <p className="text-danger">{formState.error.buyer_first_name.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                    name="buyer_last_name"
                                                    required
                                                    onChange={e => setBuyerLastName(e.target.value)}
                                                />
                                                {formState.error.buyer_last_name && <p className="text-danger">{formState.error.buyer_last_name.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kimlik Tipi</label>
                                                <Select className="form-control" defaultValue={buyer_id_type_id} showSearch
                                                    onChange={e => setBuyerIdTypeId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {id_types.map((id_types: AttributeNew) => {
                                                        return (
                                                            <Option value={id_types.id} title={id_types.name}
                                                                key={id_types.name}
                                                                id={id_types.name}>{id_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Kimlik Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir kimlik numarası giriniz."
                                                    className="form-control"
                                                    name="buyer_id_no"
                                                    required
                                                    onChange={e => setBuyerIdNo(e.target.value)}
                                                />
                                                {formState.error.buyer_id_no && <p className="text-danger">{formState.error.buyer_id_no.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select defaultValue={buyer_phone_code_id} style={{ width: 100 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setBuyerPhoneCodeId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >

                                                            {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                return (
                                                                    <Option value={phone_codes.id} title={phone_codes.name}
                                                                        key={phone_codes.name}
                                                                        id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <PatternFormat format="(###) ### ## ##" name="buyer_phone" required onChange={e => setBuyerPhone(e.target.value)} className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " />
                                                    {formState.error.buyer_phone && <p className="text-danger">{formState.error.buyer_phone.error}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    onChange={e => setBuyerEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Uyruk</label>
                                                <Select className="form-control" defaultValue={buyer_nationality_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setBuyerNationalityId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {nationalities?.map((nationalities: Country) => {
                                                        return (
                                                            <Option value={nationalities.id} title={nationalities.name}
                                                                key={nationalities.name}
                                                                id={nationalities.name}>{nationalities.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>


                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Doğum Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen doğum tarihi seçiniz."
                                                    className="form-control"
                                                    onChange={e => setBuyerBirthday(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    name="buyer_address"
                                                    required
                                                    onChange={e => setBuyerAddress(e.target.value)}
                                                />
                                                {formState.error.buyer_address && <p className="text-danger">{formState.error.buyer_address.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { buyer_note_see ? setBuyerNoteSee(false) : setBuyerNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={buyer_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    onChange={e => setBuyerNote(e.target.value)}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Mülk Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Mülk Bilgileri</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ada</label>
                                                <input type="number" data-pristine-required-message="Lütfen ada giriniz."
                                                    className="form-control"
                                                    onChange={e => setBob(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Parsel</label>
                                                <input type="number" data-pristine-required-message="Lütfen parsel giriniz."
                                                    className="form-control"
                                                    onChange={e => setParcel(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={giro_type_id == 116 ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3 select-style">
                                                <label>Tapu Dairesi</label>
                                                <Select className="form-control" defaultValue={land_office_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setLandOfficeId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {land_offices?.map((land_offices: LandOffice) => {
                                                        return (
                                                            <Option value={land_offices.id} title={land_offices.name}
                                                                key={land_offices.name}
                                                                id={land_offices.name}>{land_offices.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={giro_type_id == 116 ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Tapu Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen tapu tarihini giriniz."
                                                    className="form-control"
                                                    onChange={e => setDeedDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" name="property_address" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    required
                                                    onBlur={e => setPropertyAddress(e.target.value)}
                                                />
                                                {formState.error.property_address && <p className="text-danger">{formState.error.property_address.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Harita Konumu</label>
                                                <LoadScript
                                                    googleMapsApiKey="AIzaSyA4ZrsurXwR2QD4Xx5-CpHPD4N3qvurBMo"
                                                >
                                                    <GoogleMap
                                                        mapContainerStyle={containerStyle}
                                                        center={coordinates}
                                                        zoom={15}
                                                    >
                                                        <Marker
                                                            position={coordinates}
                                                            draggable={true}
                                                            onDragEnd={e => {
                                                                if (e.latLng && e.latLng) {
                                                                    setCoordinates({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                                                                    setPropertyCoordinate(`${e.latLng.lat()},${e.latLng.lng()}`);
                                                                }
                                                            }}
                                                        />
                                                    </GoogleMap>
                                                </LoadScript>
                                            </div>
                                        </div>

                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>İl</label>
                                                <Select className="form-control" defaultValue={province_id} value={province_id} showSearch
                                                    optionFilterProp="children"
                                                    placeholder="İl Seçiniz"
                                                    onChange={e => { setProvinceId(e); setDistrictId(null); setNeighbourhoodId(null); }}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {provinces?.map((provinces: Province) => {
                                                        return (
                                                            <Option value={provinces.id} title={provinces.name}
                                                                key={provinces.name}
                                                                id={provinces.name}>{provinces.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {formState.error.province_id && <p className="text-danger">{formState.error.province_id.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>İlçe</label>
                                                <Select className="form-control" defaultValue={district_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => { setDistrictId(e); setNeighbourhoodId(null); }}
                                                    value={district_id}
                                                    placeholder="İlçe Seçiniz"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {districts.map((districts: District) => {
                                                        return (
                                                            <Option value={districts.id} title={districts.name}
                                                                key={districts.name}
                                                                id={districts.name}>{districts.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {formState.error.district_id && <p className="text-danger">{formState.error.district_id.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>Mahalle</label>
                                                <Select className="form-control" defaultValue={neighbourhood_id} showSearch
                                                    value={neighbourhood_id}
                                                    onChange={e => setNeighbourhoodId(e)}
                                                    optionFilterProp="children"
                                                    placeholder="Mahalle Seçiniz"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {neighbourhoods.map((neighbourhoods: Neighbourhood) => {
                                                        return (
                                                            <Option value={neighbourhoods.id} title={neighbourhoods.name}
                                                                key={neighbourhoods.name}
                                                                id={neighbourhoods.name}>{neighbourhoods.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {formState.error.neighbourhood_id && <p className="text-danger">{formState.error.neighbourhood_id.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>Gayrimenkul Cinsi</label>
                                                <Select className="form-control" defaultValue={property_genus_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => { setPropertyGenusId(e); setPropertyTypeId(null); }}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                  {attributes
                                                    .filter(attr => Number(attr.type) === 7) 
                                                    .map(propertyGenus => (
                                                        <Option 
                                                            key={propertyGenus.id} 
                                                            value={propertyGenus.id} 
                                                            title={propertyGenus.name}
                                                            id={propertyGenus.name}
                                                        >
                                                            {propertyGenus.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 10 ? { display: 'none' } : {}} >
                                            <div className="form-group mb-3 select-style">
                                                <label>Gayrimenkul Türü</label>
                                                <Select className="form-control" defaultValue={property_type_id} showSearch
                                                    value={property_type_id}
                                                    placeholder="Gayrimenkul Türü Seçiniz"
                                                    onChange={e => setPropertyTypeId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {property_types.map((property_types: PropertyType) => {
                                                        return (
                                                            <Option value={property_types.id} title={property_types.name}
                                                                key={property_types.name}
                                                                id={property_types.name}>{property_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3 select-style">
                                                <label>Bulunduğu Kat</label>
                                                <Select className="form-control" defaultValue={floor_location_id} showSearch
                                                    value={floor_location_id}
                                                    placeholder="Bulunduğu Katı Seçiniz"
                                                    onChange={e => setFloorLocationId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                {attributes
                                                    .filter(attr => Number(attr.type) === 9) 
                                                    .map(floorLocation => (
                                                        <Option 
                                                            key={floorLocation.id} 
                                                            value={floorLocation.id} 
                                                            title={floorLocation.name}
                                                            id={floorLocation.name}
                                                        >
                                                            {floorLocation.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div>

                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3 date-style">
                                                <label>Bina Yaşı</label>
                                                <DatePicker className="form-control" placeholder="Bina Yaşını Seçiniz" picker="year" format={'YYYY'} onChange={(e, dateStr) => setBuildDate(dateStr)} />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Brüt m<sup>2</sup></label>
                                                <input type="number" data-pristine-required-message="Lütfen brüt alanı giriniz."
                                                    className="form-control"
                                                    onChange={e => setGross(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3">
                                                <label>Net m<sup>2</sup></label>
                                                <input type="number" data-pristine-required-message="Lütfen net alanı giriniz."
                                                    className="form-control"
                                                    onChange={e => setNet(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3">
                                                <label>Yapı Türü</label>
                                                <div>
                                                    <Radio.Group defaultValue={build_type_id} onChange={e => setBuildTypeId(e.target.value)}>
                                                    {attributes
                                                        .filter(attr => Number(attr.type) === 10)
                                                        .map(buildType => (
                                                            <Radio key={buildType.id} value={buildType.id}>
                                                                {buildType.name}
                                                            </Radio>
                                                        ))}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={property_genus_id == 8 || property_genus_id == 10 || build_type_id != 115 ? { display: 'none' } : {}} >
                                            <div className="form-group mb-3 select-style">
                                                <label>Site</label>
                                                <ConfigProvider renderEmpty={customizeRenderEmptyForSite}>
                                                    <Select className="form-control" defaultValue={site_id} showSearch
                                                        value={site_id}
                                                        allowClear={true}
                                                        placeholder="Site Seçiniz"
                                                        onChange={e => setSiteId(e)}
                                                        onSearch={e => setSiteSearch(e)}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => {
                                                            return (
                                                                option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            );

                                                        }}
                                                    >

                                                        {(Array.isArray(sites) && sites.length > 0) ? sites.map((sites: Site) => {
                                                            return (
                                                                <Option value={sites.id} title={sites.name} key={sites.name} id={sites.name}>
                                                                    {sites.name}
                                                                </Option>
                                                            )
                                                        }) : null}
                                                    </Select>
                                                </ConfigProvider>
                                            </div>
                                        </div>

                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { property_note_see ? setPropertyNoteSee(false) : setPropertyNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={property_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    onChange={e => setPropertyNote(e.target.value)}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Mülk Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <TransactionInfoCard agents={users} realtors={realtors} phone_codes={phone_codes} giro_type_id={giro_type_id} attributes={transformedAttributes} onDataChange={handleDataChange}  />

                                </div>
                            </div>
                        </div>



                        {/* İşlem Bedel Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        {/* <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>İşlem Bilgileri</label>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Sözleşme Tarihi</label>
                                                <input type="date" name="contract_date" required data-pristine-required-message="Lütfen sözleşme tarihini giriniz."
                                                    className="form-control"
                                                    onChange={e => setContractDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Portföy Bulma Kaynağı</label>
                                                <Select className="form-control" defaultValue={source_of_find_id} showSearch
                                                    value={source_of_find_id}
                                                    placeholder="Portföy Bulma Kaynağını Seçiniz"
                                                    onChange={e => setSourceOfFindId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {resources_of_find?.map((resources_of_find: SourceOfFind) => {
                                                        return (
                                                            <Option value={resources_of_find.id} title={resources_of_find.name}
                                                                key={resources_of_find.name}
                                                                id={resources_of_find.name}>{resources_of_find.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {formState.error.source_of_find_id && <p className="text-danger">{formState.error.source_of_find_id.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Portföy Satma Kaynağı</label>
                                                <Select className="form-control" defaultValue={source_of_sell_id} showSearch
                                                    value={source_of_sell_id}
                                                    placeholder="Portföy Satma Kaynağını Seçiniz"
                                                    onChange={e => setSourceOfSellId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {resources_of_sell?.map((resources_of_sell: SourceOfSell) => {
                                                        return (
                                                            <Option value={resources_of_sell.id} title={resources_of_sell.name}
                                                                key={resources_of_sell.name}
                                                                id={resources_of_sell.name}>{resources_of_sell.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {formState.error.source_of_sell_id && <p className="text-danger">{formState.error.source_of_sell_id.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Yorum Durumu </label>
                                                <Checkbox.Group className="d-block pt-1" onChange={e => setCommentStatus(e)}>
                                                    {comment_statuses?.map((comment_statuses: Attribute) => {
                                                        return (
                                                            <Checkbox value={comment_statuses.id}>{comment_statuses.name}</Checkbox>
                                                        )
                                                    })}
                                                </Checkbox.Group>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>{giro_type_id === 116 ? 'Satış Bedeli' : 'Kiralama Bedeli'}</label>
                                                <NumericFormat
                                                    required
                                                    className="form-control"
                                                    name="sale_price"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    data-pristine-required-message="Lütfen satış bedelini giriniz."
                                                    onChange={e => { setSalePrice(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')); setCalcStatus(true); setCalcStatusForAgent(true); }}
                                                />
                                                {formState.error.sale_price && <p className="text-danger">{formState.error.sale_price.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={giro_type_id == 116 ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Rayiç Bedel</label>
                                                <NumericFormat
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    data-pristine-required-message="Lütfen rayiç bedeli giriniz."
                                                    onChange={e => { setFairPrice(e.target.value.replace(/[.]/g, '').replace(',', '.')); setCalcStatus(true); }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={giro_type_id == 116 ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Tapu Masrafı</label>
                                                <NumericFormat
                                                    value={title_deed_cost.toString().replace(/[.]/g, '').replace(',', '.')}
                                                    name="title_deed_cost"
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    data-pristine-required-message="Lütfen tapu masrafını giriniz."
                                                    onChange={e => { setTitleDeedCost(e.target.value); }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>{giro_type_id === 116 ? 'Satıcı Hizmet Bedeli' : 'Kiralama Hizmet Bedeli'}</label>
                                                <NumericFormat
                                                    value={seller_service_fee.toString().replace(/[.]/g, '').replace(',', '.')}
                                                    required
                                                    name="seller_service_fee"
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    data-pristine-required-message="Lütfen satıcı hizmet bedelini giriniz."
                                                    onChange={e => { setSellerServiceFee(e.target.value); setRefCalcStatus(true); }}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={giro_type_id == 116 ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Alıcı Hizmet Bedeli</label>
                                                <NumericFormat
                                                    value={buyer_service_fee.toString().replace(/[.]/g, '').replace(',', '.')}
                                                    className="form-control"
                                                    name="buyer_service_fee"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    data-pristine-required-message="Lütfen alıcı hizmet bedelini giriniz."
                                                    onChange={e => {
                                                        setBuyerServiceFee(e.target.value); setRefCalcStatus(true);
                                                        (selected_agents && selected_agents.length > 0) ?? selected_agents.map((agents: User, i) => { updateState(agents.id, e.target.value, 'petotal_service_feercent'); updateForTotalServiceState(agents.id, e.target.value, 'total_service_fee'); })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Ofis İle Paylaşım Bedeli</label>
                                                <NumericFormat
                                                    value={office_referral_fee}
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    data-pristine-required-message="Lütfen ofis ile paylaşım bedelini giriniz."
                                                    onChange={e => { setOfficeReferralFee((e.target.value).replace(/[.]/g, '').replace(',', '.')); setRefCalcStatus(true); }}
                                                />
                                            </div>
                                        </div>
                                        <div className={out_office_see ? 'col-xl-10 col-md-10' : 'col-xl-12 col-md-12'}>
                                            <div className="form-group mb-3 select-style">
                                                <label>Diğer Emlakçı</label>
                                                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                                                    <Select className="form-control" defaultValue={realtor_id} showSearch
                                                        value={realtor_id}
                                                        allowClear={true}
                                                        placeholder="Diğer Emlakçı Seçiniz"
                                                        onChange={e => { setRealtorId(e); e === undefined ? setOutOfficeSee(false) : setOutOfficeSee(true) }}
                                                        optionFilterProp="children"
                                                        onSearch={e => setRealtorSearch(e)}
                                                        filterOption={(input, option) => {
                                                            return (
                                                                option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            );

                                                        }}
                                                    >
                                                        {realtors.map((realtors: Realtor) => {
                                                            let company_name = (realtors.company_name != '0') ? realtors.company_name : '';
                                                            return (
                                                                <Option value={realtors.id} title={realtors.first_name + ' ' + realtors.last_name + ' ' + realtors.company_name}
                                                                    key={realtors.first_name + ' ' + realtors.last_name + ' ' + realtors.company_name}
                                                                    id={realtors.first_name + ' ' + realtors.last_name + ' ' + realtors.company_name}>{realtors.first_name + ' ' + realtors.last_name + ' ' + company_name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </ConfigProvider>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={out_office_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Ofis Dışı Paylaşım Bedeli</label>
                                                <NumericFormat
                                                    className="form-control"
                                                    name="office_referral_fee"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    data-pristine-required-message="Lütfen ofis dışı paylaşım bedelini giriniz."
                                                    onChange={e => { setOutOfficeReferralFee((e.target.value).replace(/[.]/g, '').replace(',', '.')); setRefCalcStatus(true); }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3 d-lg-flex">
                                                <label>Satış Bedeli Ödeme Şekli: </label>
                                                <Checkbox.Group defaultValue={payment_method} className="ms-lg-2 d-block d-lg-inline-flex" onChange={e => setPaymentMethod(e)}>
                                                    {payment_methods?.map((payment_methods: Attribute) => {
                                                        return (
                                                            <Checkbox value={payment_methods.id}>{payment_methods.name}</Checkbox>
                                                        )
                                                    })}
                                                </Checkbox.Group>
                                            </div>
                                        </div>

                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Toplam Hizmet Bedeli : <strong>
                                                    <NumericFormat
                                                        value={total_service_fee.toString().replace('.', ',')}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        displayType="text"
                                                    /> TL </strong></label>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3 select-style">
                                                <label>Danışman (lar)</label>
                                                <Select className="form-control" showSearch
                                                    allowClear={true}
                                                    mode="multiple"
                                                    placeholder="Danışman Seçiniz"
                                                    onChange={e => { setPartnerCount(e.length); setAgentPriceStatus(e); setCalcStatusForAgent(true); if (e.length === 0) { selected_agents.splice(0); setSelectedAgents([]); } }}

                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {users.map((users: User) => {
                                                        return (
                                                            <Option value={users.id} title={users.first_name + ' ' + users.last_name}
                                                                key={users.first_name + ' ' + users.last_name + ' ' + users.id}
                                                                id={users.first_name + ' ' + users.last_name}>{users.first_name + ' ' + users.last_name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div> */}
                                        {contextHolder}
                                        {
                                            selected_agents
                                                ? selected_agents.length > 0 ? selected_agents.map((agents: User, i) => {
                                                    return (
                                                        <>
                                                            <div className="col-xl-12 col-md-12 d-flex align-self-center">
                                                                <h4 className="text-secondary"> {agents.first_name} {agents.last_name}</h4>
                                                            </div>
                                                            <div className="col-xl-2 col-md-2">
                                                                <div className="form-group mb-3">
                                                                    <label>Toplam Hizmet Bedeli </label>
                                                                    <NumericFormat
                                                                        data-pristine-required-message="Toplam hizmet bedelini giriniz."
                                                                        className="form-control"
                                                                        name="total_service_fee"
                                                                        defaultValue={agents.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')}
                                                                        value={agents.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')}
                                                                        decimalSeparator=","
                                                                        thousandSeparator="."
                                                                        required
                                                                        onChange={e => { updateState(agents.id, e, 'total_service_fee'); updateForTotalServiceState(agents.id, e, 'total_service_fee'); }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-2 col-md-2">
                                                                <div className="form-group mb-3">
                                                                    <label>Paylaşım Yüzdesi </label>
                                                                    <div className="input-group">
                                                                        <div className="input-group-text"><strong>%</strong></div>
                                                                        <NumericFormat
                                                                            key={agents.id}
                                                                            id={agents.id.toString()}
                                                                            data-pristine-required-message="Toplam hizmet bedelini giriniz."
                                                                            className="form-control"
                                                                            defaultValue={agents.percent}
                                                                            value={agents.percent}
                                                                            decimalSeparator=","
                                                                            required
                                                                            name="percent"
                                                                            onChange={e => { updateState(agents.id, e, 'percent'); setCalcStatusForAgent(true); updateForTotalServiceState(agents.id, e, 'price'); }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-2 col-md-2">
                                                                <div className="form-group mb-3">
                                                                    <label>G.D. Hizmet Bedeli</label>
                                                                    <NumericFormat
                                                                        data-pristine-required-message="Toplam hizmet bedelini giriniz."
                                                                        className="form-control"
                                                                        // value={(total_service_fee_for_agent * (parseInt(agents.category.percent) / 100)).toFixed(2)}
                                                                        defaultValue={(parseFloat(agents.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) * (agents.percent / 100)).toFixed(2).toString().replace('.', ',')}
                                                                        value={(parseFloat(agents.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) * (agents.percent / 100)).toFixed(2).toString().replace('.', ',')}
                                                                        decimalSeparator=","
                                                                        thousandSeparator="."
                                                                        required
                                                                        onChange={e => {
                                                                            updateState(agents.id, e, 'price'); setCalcStatusForAgent(true);
                                                                        }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-2 col-md-2 " style={agents.team_leader?.id > 0 ? {} : { display: 'none' }} >
                                                                <div className="form-group mb-3">
                                                                    <label title="Takım Lideri ile Paylaşım Yüzdesi">T. Lideri Paylaşım Yüzdesi </label>
                                                                    <div className="input-group">
                                                                        <div className="input-group-text"><strong>%</strong></div>
                                                                        <NumericFormat
                                                                            defaultValue={agents.team_leader_percent}
                                                                            value={agents.team_leader_percent}
                                                                            data-pristine-required-message="Toplam hizmet bedelini giriniz."
                                                                            className="form-control"
                                                                            decimalSeparator=","
                                                                            required
                                                                            onChange={e => { updateState(agents.id, e, 'team_leader_percent'); setCalcStatusForAgent(true); }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-2 col-md-2" style={agents.team_leader?.id > 0 ? {} : { display: 'none' }} >
                                                                <div className="form-group mb-3">
                                                                    <label title="Takım Lideri Hizmet Bedeli">T. Lideri H. Bedeli </label>
                                                                    <NumericFormat
                                                                        data-pristine-required-message="Toplam hizmet bedelini giriniz."
                                                                        defaultValue={(parseFloat(agents.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) * (agents.team_leader_percent / 100)).toFixed(2).toString().replace('.', ',')}
                                                                        value={(parseFloat(agents.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) * (agents.team_leader_percent / 100)).toFixed(2).toString().replace('.', ',')}
                                                                        className="form-control"
                                                                        decimalSeparator=","
                                                                        thousandSeparator="."
                                                                        required
                                                                        onChange={e => { updateState(agents.id, e, 'team_leader_price'); setCalcStatusForAgent(true); }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-2 col-md-2">
                                                                <div className="form-group mb-3">
                                                                    <label>Ofis Hizmet Bedeli</label>
                                                                    <NumericFormat
                                                                        defaultValue={(parseFloat(agents.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) - ((parseFloat(agents.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) * (agents.percent / 100)) + (parseFloat(agents.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) * (agents.team_leader_percent / 100)))).toFixed(2).toString().replace('.', ',')}
                                                                        value={(parseFloat(agents.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) - ((parseFloat(agents.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) * (agents.percent / 100)) + (parseFloat(agents.total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) * (agents.team_leader_percent / 100)))).toFixed(2).toString().replace('.', ',')}
                                                                        data-pristine-required-message="Toplam hizmet bedelini giriniz."
                                                                        className="form-control"
                                                                        decimalSeparator=","
                                                                        thousandSeparator="."
                                                                        required
                                                                        onChange={e => { updateState(agents.id, e, 'office_service_fee'); setCalcStatusForAgent(true); }} />
                                                                </div>
                                                            </div>
                                                            <input type="hidden" name="agent_id" value={agents.id} />
                                                            <input type="hidden" name="team_leader_id" value={agents.team_leader?.id} />
                                                        </>
                                                    )
                                                }) : null : null}

                                        <div className="col-xl-12 col-md-12" style={selected_agents
                                            ? selected_agents?.length > 0 ? (parseFloat(total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) - parseFloat(agents_total_service_fee.toString().replace(/[.]/g, '').replace(',', '.'))) != 0 ? (parseFloat(total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) - parseFloat(agents_total_service_fee.toString().replace(/[.]/g, '').replace(',', '.'))) != 0.01 ? selected_agent_reload ? {} : {} : {} : { display: 'none' } : { display: 'none' } : {}}>
                                            <div className="form-group mb-3">
                                                <div className="alert alert-danger alert-border-left alert-dismissible fade show" role="alert">
                                                    <i className="mdi mdi-block-helper me-3 align-middle"></i><strong>Önemli Hata</strong> Toplam hizmet bedeli ile danışmanlar arası paylaşım bedeli uyuşmuyor. Devam etmek için lütfen düzenleyiniz. <strong>
                                                        Toplam Fark:
                                                        <NumericFormat
                                                            value={(parseFloat(total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) - agents_total_service_fee).toFixed(2).toString().replace('.', ',')}
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            displayType="text"
                                                            className="ms-1"
                                                        /> TL
                                                    </strong>
                                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <Checkbox defaultChecked={download_word} onChange={e => setDownloadWord(e.target.checked)}>Kaydettikten sonra indir</Checkbox>
                                            </div>
                                        </div>
                                        <div className="form-group">

                                            <button type="submit" className="btn btn-primary" disabled={disable_button}>{save_button_text}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {/* //Ohter Realtor Modal */}
            <Modal show={show_realtor} onHide={handleClose} animation={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Emlakçı Ekleme Formu</Modal.Title>
                </Modal.Header>
                <form onSubmit={realtorSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Ad</label>
                                    <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                        className="form-control"
                                        onChange={e => setRealtorFirstName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Soyad</label>
                                    <input type="text" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                        className="form-control"
                                        onChange={e => setRealtorLastName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Firma Adı</label>
                                    <input type="text" data-pristine-required-message="Lütfen bir firma adı giriniz."
                                        className="form-control"
                                        onChange={e => setRealtorCompanyName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Email</label>
                                    <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                        className="form-control"
                                        onChange={e => setRealtorEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Telefon</label>
                                    <div className="input-group">
                                        <div className="input-group-text p-0">
                                            <Select defaultValue={realtor_phone_code_id} style={{ width: 100 }} showSearch
                                                onChange={e => setRealtorPhoneCodeId(e.target.value)}
                                                optionFilterProp="children"
                                                filterOption={(input, option) => {
                                                    return (
                                                        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                        option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    );

                                                }}
                                            >

                                                {phone_codes?.map((phone_codes: PhoneCode) => {
                                                    let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                    return (
                                                        <Option value={phone_codes.id} title={phone_codes.name}
                                                            key={phone_codes.name}
                                                            id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                    )
                                                })}
                                            </Select>

                                        </div>
                                        <PatternFormat format="(###) ### ## ##" className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setRealtorPhone(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Not</label>
                                    <textarea
                                        className="form-control"
                                        onChange={e => setRealtorNote(e.target.value)}>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            İptal
                        </Button>
                        <button type="submit" className="btn btn-primary">Kaydet</button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* //Site Modal */}
            <Modal show={show_site_modal} onHide={handleSiteClose} animation={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Site Ekleme Formu</Modal.Title>
                </Modal.Header>
                <form onSubmit={siteSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Site Adı</label>
                                    <input type="text" data-pristine-required-message="Lütfen bir site adı giriniz."
                                        className="form-control"
                                        onChange={e => setSiteName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Adres</label>
                                    <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                        className="form-control"
                                        onChange={e => setSiteAddress(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Toplam Daire</label>
                                    <input type="number" data-pristine-required-message="Lütfen daire adetini giriniz."
                                        className="form-control"
                                        onChange={e => setTotalHouse(parseInt(e.target.value))}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Yönetici Adı</label>
                                    <input type="text" data-pristine-required-message="Lütfen yönetici adıı giriniz."
                                        className="form-control"
                                        onChange={e => setManagerName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Ofis Telefon</label>
                                    <PatternFormat format="0(###) ### ## ##" className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setOfficePhone(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Cep Telefon</label>
                                    <PatternFormat format="0(###) ### ## ##" className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setMobilePhone(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Not</label>
                                    <textarea
                                        className="form-control"
                                        onChange={e => setSiteNote(e.target.value)}>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            İptal
                        </Button>
                        <button type="submit" className="btn btn-primary">Kaydet</button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* //Download Modal */}
            <Modal show={show_download} onHide={handleDownloadClose} animation={false} centered>

                <Modal.Body>
                    <table className="table table-hover mb-0">
                        <tbody>
                            {Array.isArray(ret_agents) ? ret_agents.map((item: any, i: any) => {
                                let download_url = `https://lluparapp.remaxaqua.com/transactions/documents/${item.id}.docx`;
                                return (
                                    <tr>
                                        <th scope="row">{item.name}</th>
                                        <td><a href={download_url} className="btn btn-sm btn-soft-primary waves-effect waves-light"> <DownloadOutlined style={{ fontSize: '16px' }} /> </a></td>
                                    </tr>
                                )
                            }) : null}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDownloadClose}>
                        Kapat
                    </Button>
                </Modal.Footer>
            </Modal>
        </Wrapper >
    )
}

export default Create;