import React, { useState, useMemo, useEffect } from 'react';
import AntDesignCalendar from '../../constant/antDesign/calendar';
import dayjs, { Dayjs } from 'dayjs';
import isMobile from '../../constant/isMobile';
import useWorkSchedules from '../../../api/hooks/workSchedules/useWorkSchedules';
import { Button, Modal, Tag } from 'antd';
import { BsCheckLg, BsCalendar2X } from 'react-icons/bs';

const WorkSchedulesIndex: React.FC = () => {
  const startDate = useMemo(() => dayjs().subtract(1, 'month').startOf('month'), []);
  const endDate = useMemo(() => dayjs().endOf('month'), []);
  const [limit] = useState<number>(1131);

  const [selectedUser, setSelectedUser] = useState<{ first_name: string; last_name: string } | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedDefinition, setSelectedDefinition] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const params = useMemo(() => ({
    'filter_between[]': [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')],
    limit,
  }), [startDate, endDate, limit]);

  const { workSchedules, loading } = useWorkSchedules(params);

  const renderDateCell = (date: Dayjs) => {
    const schedulesForDate = workSchedules.filter(schedule =>
      dayjs(schedule.working_date).isSame(date, 'day')
    );

    return schedulesForDate.map(schedule => {
      const fullName = isMobile
        ? `${schedule.user?.first_name.charAt(0)}${schedule.user?.last_name.charAt(0)}`
        : `${schedule.user?.first_name} ${schedule.user?.last_name}`;
      const buttonColor = schedule.status?.color || 'geekblue';
      const icon = schedule.status?.id === 328 ? <BsCalendar2X /> : <BsCheckLg />;

      return (
        <Tag
          color={buttonColor}
          onClick={() => handleScheduleClick(schedule.definition, schedule.user, schedule.working_date)}
          className="mb-1"
          key={schedule.id}
        >
          {icon} {fullName}
        </Tag>
      );
    });
  };

  const handleScheduleClick = (definition: string, user: { first_name: string; last_name: string }, working_date: string) => {
    setSelectedDefinition(definition);
    setSelectedUser(user);
    setSelectedDate(working_date);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedDefinition(null);
    setSelectedUser(null);
    setSelectedDate(null);
  };

  return (
    <>
      <AntDesignCalendar dateCellRender={renderDateCell} />

      <Modal
        title={
          selectedUser && selectedDate
            ? `${selectedUser.first_name} ${selectedUser.last_name} - ${dayjs(selectedDate).format('DD/MM/YYYY')} (${dayjs(selectedDate).format('dddd')}) - Çalışma Bilgileri`
            : "Çalışma Bilgileri"
        }
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={<Button onClick={handleModalClose}>Kapat</Button>}
      >
        <div dangerouslySetInnerHTML={{ __html: selectedDefinition || '' }} />
      </Modal>
    </>
  );
};

export default WorkSchedulesIndex;
