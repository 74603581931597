import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Space, Spin } from 'antd';

import { Link } from "react-router-dom";
import { PaymentMethod } from "../../models/attribute/paymentMethod/paymentMethod";
import Mailto from "../link/mailto";
import Tel from "../link/tel";

const TransactionDetail = ({ id }: any) => {
  const [transaction, setTransaction] = useState<any | null>(null);
  const [comment_statuses, setCommentStatuses] = useState<any | null>([]);

  useEffect(() => {
    (
      async () => {
        if (id > 0) {
          const { data } = await axios.get(`transactions/${id}`);
          setTransaction(data);
        }
      }
    )()
  }, [id]);

  useEffect(() => {
    (
      async () => {
        const { data } = await axios.get('attributes/14');
        setCommentStatuses(data.data);
      }
    )()
  }, []);

  if (!transaction) {
    return (
      <>
        <div className="d-flex flex-column justify-content-center align-items-center mb-3 mt-5 mb-5">
          <div className="d-flex justify-content-between bd-highlight mb-3">
            <Spin></Spin>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="row">

      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>  {transaction.giro_type?.id === 116 ? 'SATICI BİLGİLERİ' : 'KİRALAYAN BİLGİLERİ'}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>MÜŞTERİ TÜRÜ</th>
              <th>{transaction?.seller?.customerType.name}</th>
            </tr>
            {transaction?.seller?.customer_type_id === 2 ?
              <>
                <tr>
                  <th>FİRMA ADI</th>
                  <td>{transaction?.seller?.company_name}</td>
                </tr>
                <tr>
                  <th>VERGİ DAİRESİ / VERGİ NUMARASI</th>
                  <td>{transaction?.seller?.tax_admin} / {transaction?.seller?.tax_number}</td>
                </tr>
              </>
              : null}
            <tr>
              <th>AD SOYAD</th>
              <td>{transaction?.seller?.first_name} {transaction?.seller?.last_name}</td>
            </tr>
            <tr>
              <th>{transaction?.seller?.idType.name}</th>
              <td>{transaction?.seller?.id_no}</td>
            </tr>
            <tr>
              <th scope="row">TELEFON</th>
              <td><Tel phone={`${transaction?.seller?.phoneCode.phone_code} ${transaction?.seller?.phone}`}>+{transaction?.seller.phoneCode?.phone_code} {transaction?.seller.phone}</Tel></td>
            </tr>
            {transaction?.seller.email != '0' ?
              <tr>
                <th scope="row">EMAİL</th>
                <td><Mailto email={transaction?.seller.email} body="Merhaba">{transaction?.seller.email}</Mailto></td>
              </tr>
              : null}
            <tr>
              <th>DOĞUM TARİHİ</th>
              <td>{transaction?.seller.birthday}</td>
            </tr>
            <tr>
              <th scope="row">ADRES</th>
              <td>{transaction?.seller.address}</td>
            </tr>
            {transaction.seller.definition != '0' ?

              <tr>
                <th scope="row">NOT</th>
                <td>{transaction?.seller.definition}</td>
              </tr>
              : null}

          </tbody>
        </table>
      </div>

      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>{transaction.giro_type.id === 116 ? 'ALICI BİLGİLERİ' : 'KİRACI BİLGİLERİ'}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>MÜŞTERİ TÜRÜ</th>
              <th>{transaction.buyer?.customerType.name}</th>
            </tr>
            {transaction.buyer?.customer_type_id === 2 ?
              <>
                <tr>
                  <th>FİRMA ADI</th>
                  <td>{transaction.buyer?.company_name}</td>
                </tr>
                <tr>
                  <th>VERGİ DAİRESİ / VERGİ NUMARASI</th>
                  <td>{transaction.buyer?.tax_admin} / {transaction.buyer?.tax_number}</td>
                </tr>
              </>
              : null}
            <tr>
              <th>AD SOYAD</th>
              <td>{transaction.buyer?.first_name} {transaction.buyer?.last_name}</td>
            </tr>
            <tr>
              <th>{transaction.buyer?.idType.name}</th>
              <td>{transaction.buyer?.id_no}</td>
            </tr>
            <tr>
              <th scope="row">TELEFON</th>
              <td><Tel phone={`${transaction?.buyer?.phoneCode.phone_code} ${transaction?.buyer?.phone}`}>+{transaction?.buyer.phoneCode?.phone_code} {transaction?.buyer.phone}</Tel></td>
            </tr>
            {transaction?.buyer.email != '0' ?
              <tr>
                <th scope="row">EMAİL</th>
                <td><Mailto email={transaction.buyer?.email} body="Merhaba">{transaction.buyer?.email}</Mailto></td>
              </tr>
              : null}
            <tr>
              <th>DOĞUM TARİHİ</th>
              <td>{transaction.buyer?.birthday}</td>
            </tr>
            <tr>
              <th scope="row">ADRES</th>
              <td>{transaction.buyer?.address}</td>
            </tr>
            {transaction.buyer.definition != '0' ?
              <tr>
                <th scope="row">NOT</th>
                <td>{transaction.buyer?.definition}</td>
              </tr>
              : null}

          </tbody>
        </table>
      </div>
      <div className="clearfix border-bottom border-light mb-3"></div>
      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>MÜLK BİLGİLERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>GAYRİMENKUL CİNSİ</th>
              <th>{transaction.property.propertyGenus.name}</th>
            </tr>
            {transaction.property.propertyGenus.id !== 10 ?
              <>
                <tr>
                  <th>GAYRİMENKUL TÜRÜ</th>
                  <td>{transaction.property.propertyType && transaction.property.propertyType.name}</td>
                </tr>
              </>
              : null}
            <tr>
              <th>ADA</th>
              <td>{transaction.property.bob}</td>
            </tr>
            <tr>
              <th>PARSEL</th>
              <td>{transaction.property.parcel}</td>
            </tr>
            {transaction.giro_type.id === 116 ?
              <>
                <tr>
                  <th>TAPU DAİRESİ</th>
                  <td>{transaction.property.landOffice.name}</td>
                </tr>
                <tr>
                  <th>TAPU TARİHİ</th>
                  <td>{transaction.property.deed_date}</td>
                </tr>
              </> : null
            }
            <tr>
              <th>İL</th>
              <td>{transaction.property.province.name}</td>
            </tr>
            <tr>
              <th>İLÇE</th>
              <td>{transaction.property.district?.name}</td>
            </tr>
            <tr>
              <th>MAHALLE</th>
              <td>{transaction.property.neighbourhood?.name}</td>
            </tr>
            <tr>
              <th>TAM ADRES</th>
              <td>{transaction.property.address}</td>
            </tr>
            {transaction.property_genus_id === 8 || transaction.property_genus_id === 10 ? null :
              <>
                <tr>
                  <th>BULUNDUĞU KAT</th>
                  <td>{transaction.property.floorLocation?.name}</td>
                </tr>
              </>
            }
            <tr>
              <th>BİNA YAŞI</th>
              <td>{transaction.property.build_date}</td>
            </tr>
            <tr>
              <th>BRÜT m<sup>2</sup></th>
              <td>{transaction.property.brut}</td>
            </tr>
            {transaction.property_genus_id === 8 || transaction.property_genus_id === 10 ? null :
              <>
                <tr>
                  <th>NET m<sup>2</sup></th>
                  <td>{transaction.property.net}</td>
                </tr>
                <tr>
                  <th>YAPI TÜRÜ</th>
                  <td>{transaction.property.buildType?.name}</td>
                </tr>
                {transaction.property?.buildType?.id === 115 ?
                  <>
                    <tr>
                      <th>SİTE</th>
                      <td>{transaction.property.site?.name}</td>
                    </tr>
                  </>
                  : null}
              </>
            }
            {transaction.property.definition != '0' && transaction.property.definition != null ?
              <tr>
                <th scope="row">NOT</th>
                <td>{transaction.property.definition}</td>
              </tr>
              : null}

          </tbody>
        </table>
      </div>
      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>İŞLEM BİLGİLERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>SÖZLEŞME TARİHİ</th>
              <td>{transaction.contract_date}</td>
            </tr>
            <tr>
              <th>PORTFÖY BULMA KAYNAĞI</th>
              <td>{transaction.sourceOfFind?.name}</td>
            </tr>
            <tr>
              <th>{transaction.giro_type.id === 116 ? 'PORTFÖY SATMA KAYNAĞI' : 'PORTFÖY KİRALAMA KAYNAĞI'}</th>
              <td>{transaction.sourceOfSell?.name}</td>
            </tr>
            <tr>
              <th>{transaction.giro_type.id === 116 ? 'SATIŞ BEDELİ ÖDEME ŞEKLİ' : 'KİRALAMA BEDELİ ÖDEME ŞEKLİ'}</th>
              <td>
                {transaction?.payment_methods.map((payment_methods: PaymentMethod, i: any) => {
                  {
                    return (
                      <span className="payment_types">{payment_methods.attribute.name}{i !== transaction?.payment_methods.length - 1 && <span>+</span>}</span>
                    )
                  }
                })}
              </td>
            </tr>
            <tr>
              <th>YORUM DURUMU</th>
              <td>

                {comment_statuses.map((comment_status: PaymentMethod) => {
                  const match = transaction.comment_statuses?.attributes?.find((attributes: { id: any; }) => comment_statuses.id === attributes.id);

                  return (
                    <div className="d-flex">
                      {match ? <CheckOutlined className="pt-1" style={{ fontSize: '14px' }} /> : <CloseOutlined className="pt-1" style={{ fontSize: '14px' }} />}
                      <div className="ms-1">{comment_status.name}</div>
                    </div>
                  )
                })}
              </td>
            </tr>
            <tr>
              <th>{transaction.giro_type.id === 116 ? 'SATIŞ BEDELİ' : 'KİRALAMA BEDELİ'}</th>
              <td>
                <strong>
                  <NumericFormat
                    value={transaction.sale_price.toString().replace('.', ',')}
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                  /> TL
                </strong>
              </td>
            </tr>
            {transaction.giro_type.id === 116 ?
              <>
                <tr>
                  <th>RAYİÇ BEDEL</th>
                  <td>
                    <strong>
                      <NumericFormat
                        value={transaction.fair_price.toString().replace('.', ',')}
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                      /> TL
                    </strong>
                  </td>
                </tr>
                <tr>
                  <th>TAPU MASRAFI</th>
                  <td>
                    <strong>
                      <NumericFormat
                        value={transaction.title_deed_cost.toString().replace('.', ',')}
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                      /> TL
                    </strong>
                  </td>
                </tr>
              </>
              : null}
            <tr>
              <th>{transaction.giro_type.id === 116 ? 'SATICI HİZMET BEDELİ' : 'KİRALAMA HİZMET BEDELİ'}</th>
              <td>
                <strong>
                  <NumericFormat
                    value={transaction?.seller_service_fee.toString().replace('.', ',')}
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                  /> TL
                </strong>
              </td>
            </tr>
            {transaction.giro_type.id === 116 ?
              <tr>
                <th>ALICI HİZMET BEDELİ</th>
                <td>
                  <strong>
                    <NumericFormat
                      value={transaction.buyer_service_fee.toString().replace('.', ',')}
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                    /> TL
                  </strong>
                </td>
              </tr> : null}
            {transaction.office_referral_fee > 0 ?
              <tr>
                <th>OFİS İLE PAYLAŞIM BEDELİ</th>
                <td>
                  <strong>
                    <NumericFormat
                      value={transaction.office_referral_fee.toString().replace('.', ',')}
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                    /> TL
                  </strong>
                </td>
              </tr> : null}
            {transaction.other_realtor_id > 0 ?
              <>
                <tr>
                  <th>DİĞER EMLAKÇI</th>
                  <td>
                    {transaction.realtor?.first_name}
                  </td>
                </tr>
                <tr>
                  <th>OFİS DIŞI PAYLAŞIM BEDELİ</th>
                  <td>
                    <strong>
                      <NumericFormat
                        value={transaction.out_office_referral_fee.toString().replace('.', ',')}
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                      /> TL
                    </strong>
                  </td>
                </tr>
              </>
              : null}
            <tr>
              <th>TOPLAM HİZMET BEDELİ</th>
              <td>
                <strong>
                  <NumericFormat
                    value={transaction.total_service_fee.toString().replace('.', ',')}
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                  /> TL
                </strong>
              </td>
            </tr>
            <tr>
              <th>G.D. HİZMET BEDELİ (%{transaction.agent_percent})</th>
              <td>
                <strong>
                  <NumericFormat
                    value={transaction.agent_service_fee.toString().replace('.', ',')}
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                  /> TL
                </strong>
              </td>
            </tr>
            {transaction.agent.team_leader_id > 0 ?
              <tr>
                <th>T. LİDERİ HİZMET BEDELİ (%{transaction.team_leader_percent})</th>
                <td>
                  <strong>
                    <NumericFormat
                      value={transaction.team_leader_service_fee.toString().replace('.', ',')}
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                    /> TL
                  </strong>
                </td>
              </tr>
              : null}
            <tr>
              <th>OFİS HİZMET BEDELİ</th>
              <td>
                <strong>
                  <NumericFormat
                    value={transaction.office_service_fee.toString().replace('.', ',')}
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                  /> TL
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  );
}

export default TransactionDetail;

