import axios from "axios";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link, Navigate, NavLink, useParams } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import { Checkbox, Select, Switch } from "antd";
import { useUserContext } from "../../../providers/user/userProvider";
import { User } from "../../../models/user";
import { NumericFormat } from "react-number-format";
import CustomInput from "../../../components/form/customInput";
import { useFrequencyContext } from "../../../providers/attribute/frequency/frequencyProvider";
import { Attribute } from "../../../models/attribute/attribute";
import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import Breadcrumb from "../../../components/constant/breadcrumb";
import moment from "moment";
const { Option } = Select;

const Edit = () => {

    // General
    const { id } = useParams();
    const [disable_button, setDisableButton] = useState(false);
    const [save_button_text, setSaveButtonText] = useState('Güncelle');

    const [data, setData] = useState([]);
    const [price, setPrice] = useState<any | null>(null);
    const [frequency_id, setFrequencyId] = useState<number | null>(null);
    const [status_id, setStatusId] = useState<number>(0);
    const [category_id, setCategoryId] = useState<number | null>(null);
    const [due_day, setDueDay] = useState<number | null>(null);
    const [payment_date, setPaymentDate] = useState<any | null>(null);
    const [directive, setDirective] = useState<boolean>(false);
    const [definition, setDefinition] = useState<any | null>('');
    const [type, setType] = useState<number>(149);
    const [directive_type, setDirectiveType] = useState<number>(149);

    const [redirect, setRedirect] = useState(false);

    const [users, setUsers] = useUserContext();
    const [frequencies, setFrequencies] = useFrequencyContext();

    useEffect(() => {
        (
            async () => {
                const { data }= await axios.get(`cashbooks/${id}`);
                setCategoryId(data?.category_id);
                setPrice(data?.price);
                setPaymentDate(data?.payment_date);
                setDefinition(data?.definition);
                setStatusId(data?.status_id);
                setType(data?.type);
                //Directive
                if (data && data.directive) {
                    setDirective(true);
                    setDueDay(data?.directive.due_day);
                    setFrequencyId(data?.directive.frequency_id);
                    setDirectiveType(data?.directive.type);
                }
            }
        )()
    }, [id]);

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        setDisableButton(true);
        setSaveButtonText('Bekleyiniz...');

        await axios.put(`cashbooks/${id}`, {
            category_id,
            price,
            frequency_id,
            payment_date,
            due_day,
            definition,
            status_id,
            type,
            directive_type,
            directive
        });
        setRedirect(true);
    }

    if (redirect) {
        return <Navigate to={'/accounting/table-revenues'} />;
    }
    const items: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Masa Gelirleri', path: '/accounting/table-revenues' },
        { label: 'Güncelle', active: true },
    ];

    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Masa Geliri Güncelle</h4>
                        <div className="page-title-right">
                            <Breadcrumb items={items} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-4">
                            <div>
                                <form onSubmit={submit}>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3 select-style">
                                                <label>Danışman</label>
                                                <Select className="form-control" showSearch
                                                    placeholder="Danışman Seçiniz"
                                                    onChange={e => { setCategoryId(e); }}
                                                    optionFilterProp="children"
                                                    value={category_id}
                                                    defaultValue={category_id}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {users.map((users: User) => {
                                                        return (
                                                            <Option value={users.id} title={users.first_name + ' ' + users.last_name}
                                                                key={users.first_name + ' ' + users.last_name}
                                                                id={users.first_name + ' ' + users.last_name}>{users.first_name + ' ' + users.last_name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Tutar</label>
                                                <NumericFormat
                                                    value={price?.toString().replace(/[.]/g, '').replace(',', '.')}
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    onChange={e => { setPrice(e.target.value); }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Tarih</label>
                                                <CustomInput
                                                    type={'date'}
                                                    required={true}
                                                    value={payment_date}
                                                    onChange={(e: any) => setPaymentDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 mt-4 pt-2">
                                                <Checkbox defaultChecked={directive} checked={directive} onChange={e => setDirective(e.target.checked)}>İşlem emri ekle {directive}</Checkbox>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={directive ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3 select-style">
                                                <label>İşlem Sıklığı</label>
                                                <Select className="form-control" showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setFrequencyId(e)}
                                                    defaultValue={frequency_id}
                                                    value={frequency_id}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {frequencies.map((frequencies: Attribute) => {
                                                        return (
                                                            <Option value={frequencies.id} title={frequencies.name}
                                                                key={frequencies.name}
                                                                id={frequencies.name}>{frequencies.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={directive ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>İşlem Günü</label>
                                                <CustomInput
                                                    type={'number'}
                                                    value={due_day}
                                                    onChange={(e: any) => setDueDay(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Açıklama</label>
                                                <CustomInput
                                                    type={'textarea'}
                                                    value={definition}
                                                    onChange={(e: any) => setDefinition(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Ödeme Durumu</label>
                                                <Switch checked={status_id === 151} checkedChildren="Ödendi" className="ms-2" unCheckedChildren="Ödenmedi" onChange={(e: any) => e ? setStatusId(151) : setStatusId(152)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={disable_button}>{save_button_text}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Edit;