import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'antd';
import AntDesignModal from '../../constant/antDesign/modal';
import AntDesignUserSelect from '../../selectors/dataSelectors/antDesign/AntDesignUsersSelect';
import AntDesignForm from '../../constant/antDesign/form';
import useWatchSchedules from '../../../api/hooks/useWatchSchedules';
import dayjs from 'dayjs';
import { setUser } from '../../../redux/actions/setUserAction';

interface AddWatchScheduleModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
    selectedValue?: string;
    onSuccess?: () => void;  // Yeni onSuccess callback ekleniyor
}

const AddWatchScheduleModal: React.FC<AddWatchScheduleModalProps> = ({ show, setShow, selectedValue, onSuccess }) => {
    const [loading, setLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);  
    const [user_id, setUserId] = useState<number | null>(null);
    const [form] = Form.useForm(); 

    useEffect(() => {
        if (show) {
            setIsProcessing(false);
            form.resetFields(); 
        }
    }, [show, form]);


    const { error, addWatchSchedule } = useWatchSchedules();

    const handleSubmit = async (values: any) => {
        try {
            setIsProcessing(true);
            await addWatchSchedule({
                user_id: values.user_id,
                schedule_date: dayjs(selectedValue, 'DD.MM.YYYY').format('YYYY-MM-DD') || '',
            });
            setShow(false);
            form.resetFields(); 
            if (onSuccess) onSuccess();
            setIsProcessing(false);
            setUserId(null);
        } catch (err) {
            setIsProcessing(false);
            console.error('Failed to add watch schedule:', err);
        }
    };

    const handleLoadingStatus = (loading: boolean) => {
        setLoading(true);
    };

    const formItems = (
        <>
            <Form.Item
                name="user_id"
                rules={[{ required: true, message: 'Lütfen danışman seçiniz' }]}
            >
                <AntDesignUserSelect
                    placeholder="Nöbetçi Danışman Seçiniz"
                    style={{ width: '100%' }}
                    className="mt-3"
                    defaultValue={user_id}
                    value={user_id}
                    loadingSelect={loading}
                    loadingStatus={handleLoadingStatus}
                    params={{ filter_status_id: 255, limit: 62 }}
                    autoFocus
                />
            </Form.Item>
            <Form.Item>
                <Row justify="end" gutter={16} className="mt-3">
                    <Col>
                        <Button type="default" onClick={() => setShow(false)}>
                            İptal
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" htmlType="submit" disabled={isProcessing}>
                        {isProcessing ? 'Kaydediliyor...' : 'Kaydet'}
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </>
    );

    return (
        <AntDesignModal
            title={selectedValue}
            visible={show}
            centered
            width={300}
            footer={null}
            onCancel={() => setShow(false)}
        >
            <AntDesignForm form={form} isLoading={false} onSubmit={handleSubmit}>
                {formItems}
            </AntDesignForm>
        </AntDesignModal>
    );
};

export default AddWatchScheduleModal;
