import { useEffect, useState } from 'react';
import axios from 'axios';
import { Carousel, Rate, Tag } from 'antd';
import ProfilePhoto from '../../user/profilePhoto';
import moment from 'moment';
import { Attribute } from '../../../models/attribute/attribute';
import { Customer } from '../../../models/accounting/customer/customer';
import { User } from '../../../models/user';
import { NumericFormat } from 'react-number-format';
import { TransactionType } from '../../../models/customer/satisfactionSurvey/transactionType';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Modal, Button } from "react-bootstrap";
import SatisfactionSurveyDetail from '../../customer/satisfaction-survey/detail';


//Table
interface DataType {
    last_name: any;
    first_name: any;
    key: React.Key;
    id(id: any): unknown;
    month: any;
    created_at: string;
    customer: Customer;
    category: string;
    avg_rate: any;
    agent: User;
    comment: any;
    transactionType: any;
}

const SatisfactionSurveyCarousel = () => {

    const contentStyle: React.CSSProperties = {
        minHeight: '180px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

    const [score_names, setScoreNames] = useState<any>();
    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`attributes/46`);
                const updatedDesc = data?.data?.map((item: Attribute) => item.name);
                setScoreNames(updatedDesc);
            }
        )()
    }, []);

    const [satisfaction_survey, setSatisfactionSurvey] = useState<DataType[]>([]);
    const [satisfaction_survey_reports, setSatisfactionSurveyReports] = useState<any | null>(null);
    useEffect(() => {
        (
            async () => {

                axios.get(`customer/satisfaction-survey?limit=10&page=1&filter_between[]=${startOfMonth}&filter_between[]=${endOfMonth}&filter_type=149&sort[by]=created_at&sort[order]=desc`)
                    .then(({ data }) => {
                        setSatisfactionSurvey(data?.satisfaction_survey?.data);
                        setSatisfactionSurveyReports(data.satisfaction_survey.report);
                    });
            }
        )()
    }, []);

    const [showFullComment, setShowFullComment] = useState(false);
    const [showFullCommentId, setShowFullCommentId] = useState(null);

    const toggleComment = (id: any) => {
        setShowFullCommentId(id);
        setShowFullComment(!showFullComment);
    };

    const [satisfaction_survey_show_id, setSatisfactionSurveyShowID] = useState<any | null>(0);
    const [show_satisfaction_survey_full_name, setShowSatisfactionSurveyFullName] = useState<any | null>('');
    const [satisfaction_survey_show, setSatisfactionSurveyShow] = useState(false);

    const handleSatisfactionSurveyClose = () => setSatisfactionSurveyShow(false);
    const shortenText = (text: any, maxLength: number,) => {
        if (!showFullComment || showFullCommentId !== text.id) {
            if (text.length <= maxLength) {
                return text;
            }
            return `${text.substring(0, maxLength)}...`;

        }
        return text;
    };

    return (
        <>
            <Carousel autoplay autoplaySpeed={8000} dots={false}>
                {score_names && satisfaction_survey.map((satisfaction_survey: DataType) => {
                    return (
                        <div key={`${satisfaction_survey.id}`} style={contentStyle}>
                            <div className="d-flex align-self-start">
                                <div>
                                    <ProfilePhoto id={satisfaction_survey.agent?.id} imgClass="avatar-lg rounded-circle img-thumbnail" />
                                </div>
                                <div className="flex-1 ms-3">
                                    <h5 className="font-size-15 mb-1">
                                        {satisfaction_survey.agent && (
                                            <a onClick={() => {
                                                setSatisfactionSurveyShow(true);
                                                setSatisfactionSurveyShowID(satisfaction_survey?.id);
                                                setShowSatisfactionSurveyFullName(`${satisfaction_survey.agent?.first_name} ${satisfaction_survey.agent?.last_name}  -  ${satisfaction_survey.customer?.first_name} ${satisfaction_survey.customer?.last_name.toLocaleUpperCase('tr-TR')}`);
                                            }} className="text-dark">{satisfaction_survey.agent.first_name} {satisfaction_survey.agent.last_name}</a>
                                        )}
                                    </h5>
                                    <div className="text-muted mb-0">
                                        <Rate allowHalf disabled tooltips={score_names} value={Math.round(satisfaction_survey.avg_rate)} />
                                        <strong className="ms-2">
                                            <NumericFormat
                                                value={satisfaction_survey.avg_rate?.toString().replace('.', ',')}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                displayType="text"
                                            />
                                        </strong>
                                        <span className="ms-2">
                                            {score_names[Math.round(satisfaction_survey.avg_rate) - 1]}
                                        </span>
                                        <p className="mt-1">
                                            {satisfaction_survey?.transactionType?.map((transaction_type: TransactionType) => {
                                                return (
                                                    <Tag color={'volcano'}>{transaction_type?.transaction_type?.name}</Tag>
                                                )
                                            })}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card bg-light mt-2">
                                <div className="card-body pb-0 align-item-center">
                                    <div style={{ height: showFullComment ? 'auto' : '140px', minHeight: '140px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-line' }}>
                                        <p className="text-muted mt-2">
                                            {showFullComment ? satisfaction_survey.comment : shortenText(satisfaction_survey.comment, 180)}

                                        </p>
                                        {satisfaction_survey.comment.length > 180 && (
                                            <a className="btn btn-link p-0" onClick={() => toggleComment(satisfaction_survey.id)}>
                                                {showFullComment ? <>Daha az  <FiChevronUp /></> : <>Daha fazla <FiChevronDown /></>}
                                            </a>
                                        )}

                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <h5 className="font-size-15 mb-1">{satisfaction_survey.customer.first_name} {satisfaction_survey.customer.last_name.toLocaleUpperCase('tr-TR')}</h5>
                                        <p className="text-muted">{satisfaction_survey.created_at}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Carousel>

            <Modal show={satisfaction_survey_show} onHide={handleSatisfactionSurveyClose} size="xl" animation={false}>
                {satisfaction_survey_show_id && satisfaction_survey_show_id !== null && satisfaction_survey_show_id !== undefined &&
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title><ProfilePhoto id={satisfaction_survey_show_id} imgHeight={35} /> <span className="ms-2">{show_satisfaction_survey_full_name}</span> - <span className="text-danger">{satisfaction_survey_show_id}</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <SatisfactionSurveyDetail id={satisfaction_survey_show_id} />
                        </Modal.Body><Modal.Footer>
                            <Button variant="secondary" onClick={handleSatisfactionSurveyClose}>
                                Kapat
                            </Button>
                        </Modal.Footer>
                    </>
                }
            </Modal>
        </>
    );
};

export default SatisfactionSurveyCarousel;
