import axios from "axios";
import { useEffect, useState } from "react";
import { Spin, Tag } from 'antd';

import Mailto from "../link/mailto";
import Tel from "../link/tel";

const WatchScheduleDetail = ({ id }: any) => {
  const [watch_schedule_action, setWatchScheduleAction] = useState<any | null>(null);

  useEffect(() => {
    (
      async () => {
        if (id > 0) {
          const { data } = await axios.get(`asistant/watch-schedule-actions/${id}`);
          setWatchScheduleAction(data);
        }
      }
    )()
  }, [id]);


  if (!watch_schedule_action) {
    return (
      <>
        <div className="d-flex flex-column justify-content-center align-items-center mb-3 mt-5 mb-5">
          <div className="d-flex justify-content-between bd-highlight mb-3">
            <Spin></Spin>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="row">

      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>  Müşteri Bilgileri</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>MÜŞTERİ TÜRÜ</th>
              <th>{watch_schedule_action?.customer?.customerType.name}</th>
            </tr>
            {watch_schedule_action?.customer?.customer_type_id === 2 ?
              <>
                <tr>
                  <th>FİRMA ADI</th>
                  <td>{watch_schedule_action?.customer?.company_name}</td>
                </tr>
                <tr>
                  <th>VERGİ DAİRESİ / VERGİ NUMARASI</th>
                  <td>{watch_schedule_action?.customer?.tax_admin} / {watch_schedule_action?.customer?.tax_number}</td>
                </tr>
              </>
              : null}
            <tr>
              <th>AD SOYAD</th>
              <td>{watch_schedule_action?.customer?.first_name} {watch_schedule_action?.customer?.last_name}</td>
            </tr>
            {watch_schedule_action?.customer.id_no != '0' ?
            <tr>
              <th>{watch_schedule_action?.customer?.idType?.name}</th>
              <td>{watch_schedule_action?.customer?.id_no}</td>
            </tr>
            :null }
            <tr>
              <th scope="row">TELEFON</th>
              <td><Tel phone={`${watch_schedule_action?.customer?.phoneCode.phone_code} ${watch_schedule_action?.customer?.phone}`}>+{watch_schedule_action?.customer.phoneCode?.phone_code} {watch_schedule_action?.customer.phone}</Tel></td>
            </tr>
            {watch_schedule_action?.customer.email != '0' ?
              <tr>
                <th scope="row">EMAIL</th>
                <td><Mailto email={watch_schedule_action?.customer.email} body="Merhaba">{watch_schedule_action?.customer.email}</Mailto></td>
              </tr>
              : null}
            <tr>
              <th>DOĞUM TARİHİ</th>
              <td>{watch_schedule_action?.customer.birthday}</td>
            </tr>
            {watch_schedule_action?.customer.email != '0' ?
            <tr>
              <th scope="row">ADRES</th>
              <td>{watch_schedule_action?.customer.address}</td>
            </tr>
  : null}
            {watch_schedule_action.customer.definition != '0' ?

              <tr>
                <th scope="row">NOT</th>
                <td>{watch_schedule_action?.customer.definition}</td>
              </tr>
              : null}

          </tbody>
        </table>
      </div>

      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>Diğer Bilgiler</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <th style={{ width: 170 }}>DURUM</th>
              <th><Tag color={watch_schedule_action.status?.color}>{watch_schedule_action.status?.name}</Tag></th>
            </tr>
            <tr>
              <th style={{ width: 170 }}>MÜŞTERİ TÜRÜ</th>
              <th>{watch_schedule_action?.customerApplicationType?.name}</th>
            </tr>
            <tr>
              <th style={{ width: 170 }}>GAYRİMENKUL TÜRÜ</th>
              <td>{`${watch_schedule_action?.propertyGenus?.name} ${watch_schedule_action?.propertyType?.name}`}</td>
            </tr>
            <tr>
              <th style={{ width: 170 }}>İLETİŞİM TİPİ</th>
              <td>{watch_schedule_action?.contactType?.name}</td>
            </tr>
            <tr>
              <th style={{ width: 170 }}>İLETİŞİM KAYNAĞI</th>
              <td>{watch_schedule_action?.contact?.name}</td>
            </tr>
            <tr>
              <th style={{ width: 170 }}>GÖRÜŞME TARİH / SAAT</th>
              <td>{`${watch_schedule_action?.meet_date} / ${watch_schedule_action?.meet_time}`}</td>
            </tr>
            <tr>
              <th style={{ width: 170 }}>AÇIKLAMA</th>
              <td>{watch_schedule_action?.definition}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WatchScheduleDetail;

