
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { IdTypeContext } from '../../../contexts/attribute/idType/idTypeContext';
import { Attribute } from '../../../models/attribute/attribute';

export const IdTypeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [id_types, setIdTypes] = useState<Attribute[]>([]);

  useEffect(() => {
      let isMounted = true;

      async function getIdTypes() {
        try {
          const { data } = await axios.get('attributes/6');
          if (isMounted) {
            setIdTypes(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }

      const timer = setTimeout(() => {
        getIdTypes();
      }, 5000);
  
      return () => {
        isMounted = false;
        clearTimeout(timer);
      };
  }, []);


  return (
      <IdTypeContext.Provider value={[id_types, setIdTypes]}>
          {children}
      </IdTypeContext.Provider>
  );
}

export const useIdTypeContext = () => useContext(IdTypeContext);
