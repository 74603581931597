
import axios from "axios";
import { useEffect, useState,useContext,useRef } from "react";
import { DatePicker, Spin, Tag, Button, Form, Input, Popconfirm, Table, FormInstance, InputRef } from 'antd';

import Mailto from "../../link/mailto";
import Tel from "../../link/tel";

import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { FiEdit2, FiPlus } from "react-icons/fi";
import { AiOutlineCheck, AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import CustomInput from "../../form/customInput";
import React from "react";
import ReminderManager from "../../notification/reminderManager/reminderManager";
import { CallMeta } from "../../../models/asistant/call/callMeta";
const EditableContext = React.createContext<FormInstance<any> | null>(null);

const getPopupContainer = (trigger: any) => {
  // Bu mantığı, spesifik gereksinimlerinize uygun olarak düzenleyebilirsiniz
  return trigger.parentElement || document.body;
};

const EditableRow: React.FC<{ index: number }> = ({ index, ...props }) => {

  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};


const EditableCell: React.FC<{
  title: string;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: Record<string, any>;
  handleSave: (record: Record<string, any>) => void;
}> = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {

  const [editing, setEditing] = useState(false);
    
  const inputRef = useRef<HTMLInputElement | any>(null);
  const form = useContext(EditableContext);
  
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus(); // Optional chaining ekledik
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form?.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form?.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Kayıt hatası:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} alanı zorunludur.`,
          },
        ]}
      >
        {dataIndex === 'call_date_time' ? (
          
          <input
          className="form-control"
          type="datetime-local"
          ref={inputRef}
          onChange={(e) => form?.setFieldsValue({ [dataIndex]: moment(e.target.value).format('DD.MM.YYYY HH:mm') })}
          onBlur={() => save()}
          defaultValue={moment(record[dataIndex]).format("DD.MM.YYYY HH:mm")}
          value={moment(record[dataIndex]).format("DD.MM.YYYY HH:mm")}
        />
        ) : (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const CallMetaManager = ({ id }: any) => {

  const [update_call_meta, setUpdateCallMeta] = useState(true);
  const [call, setCall] = useState<any | null>(null);
  const [callMeta, setCallMeta] = useState<CallMeta[]>([]);
  const [newCallDetails, setNewCallDetails] = useState<CallMeta[] | any>([]);

  const handleAddCall = () => {
    setNewCallDetails([...newCallDetails, { id: newCallDetails.length + 1, date: new Date(),time: new Date().toLocaleTimeString(), title: '', definition: '' }]);
  };

  const handleRemoveCall = (index: number) => {
    setNewCallDetails(newCallDetails.filter((_: any, i: number) => i !== index));
  };

  const getPopupContainer = (trigger: any) => {
    // Adjust this logic based on your specific requirements
    return trigger.parentElement || document.body;
  };

  //Modal add call
  const [name, setName] = useState('');
  const [definition, setDefinition] = useState('');
  //const [call_date_time, setCallDateTime] = useState(new Date());

  const [metas, setCallCount] = useState([0]);
  const handleRemoveItem = (item: any) => {
    setCallCount(metas.filter(item => item !== item));
  };

  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<any>([]);
  
  useEffect(() => {
    (
      async () => {
        if (id > 0 && refreshData) {
          const { data } = await axios.get(`asistant/calls/${id}`);
          setCall(data);
          setCallMeta(data.meta);
          setDataSource(data.meta);
          setRefreshData(false);
        }
      }
    )()
  }, [id,refreshData]);

  //editable
  
  const [count, setCount] = useState(2);
  const handleDelete =  async (id:any) => {
    const newData = dataSource.filter((item:any) => item.id !== id);
    setDataSource(newData);
    await axios.delete(`call-meta/${id}`);
  };
  const defaultColumns = [
    {
      dataIndex: 'no',
      width:'10%',
      render: (text:any,record:any,index:any) => {
        return (
          <>
            {index + 1}.
          </>
        );
      },
    },
    
    {
      title: 'Tarih / Saat',
      dataIndex: 'call_date_time',
      editable: true
    },    
    {
      title: 'Başlık',
      dataIndex: 'title',
      editable: true,
    },
    {
      title: 'Açıklama',
      dataIndex: 'definition',
      editable: true,
    },
    {
      dataIndex: 'operation',
      width:'10%',
      render: (_:any, record:any) =>
        dataSource.length >= 1 ? (
          <Popconfirm
  cancelText="İptal"
  okText="Tamam"
  title="Kalıcı olarak silinecektir. Emin misiniz?"
  style={{ zIndex: 9999999999 }}
  getPopupContainer={getPopupContainer}  // Set the getPopupContainer function if needed
  onConfirm={() => handleDelete(record.id)}
>
  <a className="text-danger"><AiOutlineDelete /></a>
</Popconfirm>
        ) : null,
    },
  ];
  const handleAdd = async () => {
    const defaultDateTime = dayjs(); // Varsayılan değeri burada alın

    const newData = {
      key: dataSource.length + 1,
      no: dataSource.length + 1,
      name: '-',
      call_date_time: defaultDateTime.format("YYYY-MM-DD HH:mm"),
      date: defaultDateTime.format('YYYY-MM-DD'),
      time: defaultDateTime.format('HH:mm'),
      definition: '-',
      call_id: id,
    };
    await axios.post(`call-meta`, newData);
    setCount(count + 1);
    setRefreshData(true);
  };
 
  const handleSave = async (row: any) => {
    try {
      const callMetaId = row.id;
      const updatedRow = {
        ...row,
        call_date_time: moment(row.call_date_time).format("YYYY-MM-DD HH:mm"),
        date: moment(row.call_date_time).format("YYYY-MM-DD"),
        time: moment(row.call_date_time).format("HH:mm"),
      };
  
      await axios.put(`call-meta/${callMetaId}`, updatedRow);
  
      const newData = dataSource.map((item: any) =>
        item.id === callMetaId ? updatedRow : item
      );
  
      setDataSource(newData);
      setUpdateCallMeta(!update_call_meta);
    } catch (error) {
      console.error('Error updating call meta:', error);
    }
  };
  
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record:any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
      render: (text: any, record: any) => {
        if (col.dataIndex === 'call_date_time') {
          return `${record.call_date_time}`;
        }
        if (col.dataIndex === 'date') {
          return `${record.date}`;
        }
        if (col.dataIndex === 'time') {
          return `${record.time}`;
        }
        return text;
      },
    };
  });

  if (!call) {
    return (
      <>
        <div className="d-flex flex-column justify-content-center align-items-center mb-3 mt-5 mb-5">
          <div className="d-flex justify-content-between bd-highlight mb-3">
            <Spin></Spin>
          </div>
        </div>
      </>
    );
  }

  return (
    <>    
      <Button
        onClick={handleAdd}
        type="primary"
        className="btn btn-soft-primary waves-effect waves-light btn-sm float-end mb-2" 
      >
        <FiPlus /> Görüşme Ekle
      </Button>
      <Table
            title={() => (
              <div className="row">
              <div className="text-center" style={{padding: '0.75rem 0.75rem', background: '#e3e4e8', fontWeight: '500'}}>
                GÖRÜŞMELER
              </div>
              </div>
            )}
      
        style={{ overflowX: 'auto', maxWidth: '100%'}}
        scroll={{y: 1400}}
        components={components}
        rowClassName={() => 'editable-row'}
        dataSource={dataSource}
        columns={columns}
      />

    </>
  );
}

export default CallMetaManager;

