import { Avatar } from "antd";
import { AiOutlineUser } from "react-icons/ai";

const ProfilePhoto = ({ id, height,width, imgClass, imgAlt,name }: any) => {
  return (
    <>
      {/* //<img src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745" height={height} width={width} className={imgClass} alt={imgAlt} /> */}
      <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>    {name ? name : <AiOutlineUser />}</Avatar>
      
    </>
  );
}

export default ProfilePhoto;

