import axios from "axios";
import React, { SyntheticEvent, useEffect, useState, useRef } from "react";
import { Link, Navigate, NavLink, useParams } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";

import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import Breadcrumb from "../../../components/constant/breadcrumb";
import CustomInput from "../../../components/form/customInput";
import CustomSunEditor from "../../../components/form/sun-editor/customSunEditor";
import { useUserContext } from "../../../providers/user/userProvider";
import { Attribute } from "../../../models/attribute/attribute";
import { User } from "../../../models/user";
import { CustomerType } from "../../../models/attribute/customerType/customerType";
import { useCustomerTypeContext } from "../../../providers/attribute/customerType/customerTypeProvider";
import { IdType } from "../../../models/attribute/idType/idType";
import { useIdTypeContext } from "../../../providers/attribute/idType/idTypeProvider";
import { useCountryContext } from "../../../providers/general/location/country/countryProvider";
import { PhoneCode } from "../../../models/general/country/phoneCode";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Country } from "../../../models/general/country/country";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { Province } from "../../../models/general/location/province/province";
import { District } from "../../../models/general/location/district/district";
import { Neighbourhood } from "../../../models/general/location/neighbourhood/neighbourhood";
import { PropertyType } from "../../../models/attribute/propertyType/propertyType";
import { FloorLocation } from "../../../models/attribute/floorLocation/floorLocation";
import { BuildType } from "../../../models/attribute/buildType/buildType";

import { Radio, Select, DatePicker, Checkbox, ConfigProvider, message } from 'antd';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { setKey, fromAddress,fromLatLng } from "react-geocode";
import { useProvinceContext } from "../../../providers/general/location/province/provinceProvider";
import { useBuildTypeContext } from "../../../providers/attribute/buildType/buildTypeProvider";
import { usePropertyGenusContext } from "../../../providers/attribute/propertyGenus/propertyGenusProvider";
import moment from 'moment';

//Boostrap
import { Modal, Button } from "react-bootstrap";
import { Asset } from "../../../models/contract/asset/asset";
import { Item } from "../../../models/contract/item/item";
import Property from "../../../components/property/property";
import Swal from "sweetalert2";

const { Option } = Select;

const Create = () => {
    //General
    const [name, setName] = useState('');
    const [content, setContent] = useState('');
    const [redirect, setRedirect] = useState(false);

    const [users, setUsers] = useUserContext();
    const [customer_types, setCustomerTypes] = useCustomerTypeContext();
    const [id_types, setIdTypes] = useIdTypeContext();
    const [phone_codes, setPhoneCodes] = useCountryContext();
    const [nationalities, setNationalities] = useCountryContext();

    const [selected_agent, setSelectedAgent] = useState<any | null>(null);
    const [partner_count, setPartnerCount] = useState<any | null>(0);
    const [selected_agents, setSelectedAgents] = useState([]);
    const [contract_types, setContractTypes] = useState([]);

    const [assets, setAssets] = useState([]);
    const [selected_assets, setSelectedAssets] = useState([]);
    const [asset_name, setAssetName] = useState<any | null>('');

    const [items, setItems] = useState([]);
    const [selected_items, setSelectedItems] = useState([]);
    const [item_name, setItemName] = useState<any | null>('');

    //Document
    const [status_id, setStatusId] = useState<any | null>(326);
    const [purpose_of_use_id, setPurposeOfUseId] = useState<any | null>(187);
    const [statuses, setStatuses] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [purpose_of_use, setPurposeOfUse] = useState([]);
    const [property_status, setPropertyStatus] = useState<any | null>('');

    // Seller
    const [seller_customer_type_id, setSellerCustomerTypeId] = useState(1);
    const [seller_company_name, setSellerCompanyName] = useState('');
    const [seller_tax_admin, setSellerTaxAdmin] = useState('');
    const [seller_tax_number, setSellerTaxNumber] = useState('');
    const [seller_phone_code_id, setSellerPhoneCodeId] = useState(34);
    const [seller_first_name, setSellerFirstName] = useState<any | null>('');
    const [seller_last_name, setSellerLastName] = useState<any | null>('');
    const [seller_id_type_id, setSellerIdTypeId] = useState(3);
    const [seller_nationality_id, setSellerNationalityId] = useState(34);
    const [seller_id_no, setSellerIdNo] = useState<string | any | null >('');
    const [seller_birthday, setSellerBirthday] = useState('');
    const [seller_email, setSellerEmail] = useState('');
    const [seller_address, setSellerAddress] = useState('');
    const [seller_phone, setSellerPhone] = useState('');
    const [seller_note, setSellerNote] = useState('');
    const [seller_bank_name, setSellerBankName] = useState('');
    const [seller_iban, setSellerIban] = useState('');

    // Buyer
    const [buyer_customer_type_id, setBuyerCustomerTypeId] = useState(1);
    const [buyer_company_name, setBuyerCompanyName] = useState('');
    const [buyer_tax_admin, setBuyerTaxAdmin] = useState('');
    const [buyer_tax_number, setBuyerTaxNumber] = useState('');
    const [buyer_phone_code_id, setBuyerPhoneCodeId] = useState(34);
    const [buyer_first_name, setBuyerFirstName] = useState('');
    const [buyer_last_name, setBuyerLastName] = useState('');
    const [buyer_nationality_id, setBuyerNationalityId] = useState(34);
    const [buyer_id_type_id, setBuyerIdTypeId] = useState(3);
    const [buyer_id_no, setBuyerIdNo] = useState('');
    const [buyer_birthday, setBuyerBirthday] = useState('');
    const [buyer_email, setBuyerEmail] = useState('');
    const [buyer_address, setBuyerAddress] = useState('');
    const [buyer_phone, setBuyerPhone] = useState('');
    const [buyer_note, setBuyerNote] = useState('');

     // Guarantor 
     const [guarantor_customer_type_id, setGuarantorCustomerTypeId] = useState(1);
     const [guarantor_company_name, setGuarantorCompanyName] = useState('');
     const [guarantor_tax_admin, setGuarantorTaxAdmin] = useState('');
     const [guarantor_tax_number, setGuarantorTaxNumber] = useState('');
     const [guarantor_phone_code_id, setGuarantorPhoneCodeId] = useState(34);
     const [guarantor_first_name, setGuarantorFirstName] = useState('');
     const [guarantor_last_name, setGuarantorLastName] = useState('');
     const [guarantor_nationality_id, setGuarantorNationalityId] = useState(34);
     const [guarantor_id_type_id, setGuarantorIdTypeId] = useState(3);
     const [guarantor_id_no, setGuarantorIdNo] = useState('');
     const [guarantor_birthday, setGuarantorBirthday] = useState('');
     const [guarantor_email, setGuarantorEmail] = useState('');
     const [guarantor_address, setGuarantorAddress] = useState('');
     const [guarantor_phone, setGuarantorPhone] = useState('');
     const [guarantor_note, setGuarantorNote] = useState('');


    //Property
    const [province_id, setProvinceId] = useState<any | null>(7);
    const [province_name, setProvinceName] = useState<any | null>('');
    const [district_id, setDistrictId] = useState<any | null>(2038);
    const [district_name, setDistrictName] = useState<any | null>('');
    const [neighbourhood_id, setNeighbourhoodId] = useState<any | null>(2338);
    const [neighbourhood_hsb_id, setNeighbourhoodHsbId] = useState<any | null>(null);
    const [neighbourhood_name, setNeighbourhoodName] = useState<any | null>('');
    const [csb_floor_id, setCsbFloorId] = useState<any | null>(0);
    const [csb_parcel_id, setCsbParcelId] = useState<any | null>(0);
    const [rectangle_coordinates, setRectangleCoordinates] = useState<any | null>('');
    const [property_id, setPropertyId] = useState<any | null>(0);
    const [propertyData, setPropertyData] = useState<any | null>(null);


    const [payment_type, setPaymentType] = useState<any | null>(null);
    const [flat_number, setFlatNumber] = useState<any | null>(null);
    const [build_name, setBuildName] = useState<any | null>(null);
    const [street, setStreet] = useState<any | null>(null);
    const [bob, setBob] = useState<any | null>(null);
    const [parcel, setParcel] = useState<any | null>(null);
    const [gross, setGross] = useState<any | null>(null);
    const [net, setNet] = useState<any | null>(null);
    const [land_office_id, setLandOfficeId] = useState<any | null>(1);
    const [deed_date, setDeedDate] = useState<any | null>('');
    const [property_address, setPropertyAddress] = useState<any | null>('');
    const [build_date, setBuildDate] = useState<any | null>(null);
    const [property_note, setPropertyNote] = useState<any | null>('');
    const [site_search, setSiteSearch] = useState<any | null>('');
    const [site_id, setSiteId] = useState<any | null>(null);
    const [property_genus_id, setPropertyGenusId] = useState<any | null>(7);
    const [property_type_id, setPropertyTypeId] = useState<any | null>(14);
    const [floor_location_id, setFloorLocationId] = useState<any | null>();
    const [build_type_id, setBuildTypeId] = useState<any | null>(114);
    const [districts, setDistricts] = useState([]);
    const [neighbourhoods, setNeighbourhoods] = useState([]);
    const [property_types, setPropertyTypes] = useState([]);
    const [floor_locations, setFloorLocations] = useState([]);
    const [sites, setSites] = useState([]);

    //Site
    const [site_name, setSiteName] = useState('');
    const [site_address, setSiteAddress] = useState('');
    const [total_house, setTotalHouse] = useState(0);
    const [manager_name, setManagerName] = useState('');
    const [mobile_phone, setMobilePhone] = useState('');
    const [office_phone, setOfficePhone] = useState('');
    const [site_note, setSiteNote] = useState('');

    //Contract
    const [contract_type_id, setContractTypeId] = useState(182);
    const [start_date, setStartDate] = useState<any | null>(null);
    const [end_date, setEndDate] = useState<any | null>(null);
    const [lease_time, setLeaseTime] = useState<any | null>(null);
    const [per_month_price, setPerMonthPrice] = useState<any | null>(null);
    const [per_year_price, setPerYearPrice] = useState<any | null>(null);
    const [deposit_price, setDepositPrice] = useState<any | null>(null);
    const [currency_id, setCurrencyId] = useState(259);
    const [deposit_currency_id, setDepositCurrencyId] = useState(259);


    //Other
    const [get_coordinate, setGetCoordinate] = useState(false);
    const [coordinate_info, setCoordinateInfo] = useState([]);
    const [note_see, setCustomerNoteSee] = useState(false);
    const [seller_note_see, setSellerNoteSee] = useState(false);
    const [buyer_note_see, setBuyerNoteSee] = useState(false);
    const [guarantor_note_see, setGuarantorNoteSee] = useState(false);
    const [guarantor_see, setGuarantorSee] = useState(false);
    const [property_note_see, setPropertyNoteSee] = useState(false);

    const [disable_button, setDisableButton] = useState(false);
    const [save_button_text, setSaveButtonText] = useState('Kaydet');

    const [provinces, setProvinces] = useProvinceContext();
    const [build_types, setBuildTypes] = useBuildTypeContext();
    const [property_genuses, setPropertyGenuses] = usePropertyGenusContext();

    const [download_word, setDownloadWord] = useState<any | null>(true);
    
    const { id } = useParams();
    
    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`contract/lease-agreements/${id}`);
                //setSelectedAgents(data?.agent_id);
                //Seller
                setSellerCustomerTypeId(data?.seller.customer_type_id);

                setSellerCompanyName(data?.seller.company_name !== '0' ? data?.seller.company_name : '');
                setSellerTaxAdmin(data?.seller.tax_admin !== '0' ? data?.seller.tax_admin : '');
                setSellerTaxNumber(data?.seller.tax_number !== '0' ? data?.seller.tax_number : '');
                setSellerFirstName(data?.seller.first_name);
                setSellerLastName(data?.seller.last_name);

                setSellerNationalityId(data?.seller.nationality_id);

                setSellerIdTypeId(data?.seller.id_type.id);

                setSellerIdNo(data?.seller.id_no.toString());
                setSellerPhoneCodeId(data?.seller.phone_code_id);
                setSellerPhone(data?.seller.phone);
                setSellerEmail(data?.seller.email !== '0' ? data?.seller.email : '');
                setSellerBirthday(data?.seller.birthday);
                setSellerAddress(data?.seller.address);
                (data?.seller.definition !== null && data?.seller.definition !== undefined && data?.seller.definition !== '0') ? setSellerNoteSee(true) : setSellerNoteSee(false);
                setSellerNote(data?.seller.definition);
                setSellerBankName(data?.seller.bank_name);
                setSellerIban(data?.seller.iban)
                //Buyer
                setBuyerCustomerTypeId(data?.buyer.customer_type_id);
                setBuyerCompanyName(data?.buyer.company_name !== '0' ? data?.buyer.company_name : '');
                setBuyerTaxAdmin(data?.buyer.tax_admin !== '0' ? data?.buyer.tax_admin : '');
                setBuyerTaxNumber(data?.buyer.tax_number !== '0' ? data?.buyer.tax_number : '');
                setBuyerFirstName(data?.buyer.first_name);
                setBuyerLastName(data?.buyer.last_name);
                setBuyerNationalityId(data?.buyer.nationality_id);
                setBuyerIdTypeId(data?.buyer.id_type.id);
                setBuyerIdNo(data?.buyer.id_no);
                setBuyerPhoneCodeId(data?.buyer.phone_code_id);
                setBuyerPhone(data?.buyer.phone);
                setBuyerEmail(data?.buyer.email !== '0' ? data?.buyer.email : '');
                setBuyerBirthday(data?.buyer.birthday);
                setBuyerAddress(data?.buyer.address);
                (data?.buyer.definition !== null && data?.buyer.definition !== undefined && data?.buyer.definition !== '0') ? setBuyerNoteSee(true) : setBuyerNoteSee(false);
                setBuyerNote(data?.buyer.definition);

                //Guarantor
                setGuarantorCustomerTypeId(data?.guarantor?.customer_type_id);
                setGuarantorCompanyName(data?.guarantor?.company_name);
                setGuarantorTaxAdmin(data?.guarantor?.tax_admin);
                setGuarantorTaxNumber(data?.guarantor?.tax_number);
                setGuarantorPhoneCodeId(data?.guarantor?.phone_code_id);
                setGuarantorFirstName(data?.guarantor?.first_name);
                setGuarantorLastName(data?.guarantor?.last_name);
                setGuarantorNationalityId(data?.guarantor?.nationality_id);
                setGuarantorIdTypeId(data?.guarantor?.id_type?.id);
                setGuarantorIdNo(data?.guarantor?.id_no);
                setGuarantorBirthday(data?.guarantor?.birthday);
                setGuarantorEmail(data?.guarantor?.email);
                setGuarantorAddress(data?.guarantor?.address);
                setGuarantorPhone(data?.guarantor?.phone);
                setGuarantorNote(data?.guarantor?.note);

                //Property
                setPropertyData(data.property);

                //Contract
                setContent(data?.content);
                setContractTypeId(data?.contract_type_id);
                setStartDate(data?.start_date);
                setEndDate(data?.end_date);
                setLeaseTime(data?.lease_time);
                setPerMonthPrice(data?.per_month_price);
                setPerYearPrice(data?.per_year_price);
                setDepositPrice(data?.deposit);
                setCurrencyId(data?.deposit_currency_id);
                setDepositCurrencyId(data?.deposit_currency_id);
                setPropertyStatus(data?.property_status);
            }
        )()
    }, [id]);


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/40');

                setContractTypes(data.data);
            }
        )()
    }, []);

    // useEffect(() => {
    //     (
    //         async () => {

    //             const { data } = await axios.get('location/district/' + province_id);

    //             setDistricts(data);
    //         }
    //     )()
    // }, [province_id]);

    // useEffect(() => {

    //     (
    //         async () => {
    //             if (typeof province_name === 'string' && province_name.trim().length > 0 && province_name != null) {
    //                 const { data } = await axios.get('location/province/name-like/' + province_name);
    //                 if (data.id > 0) { setDistrictId(null); setNeighbourhoodId(null); }
    //                 setProvinceId(data.id);
    //             }
    //         }
    //     )()

    // }, [province_name])


    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('location/neighbourhood/' + district_id);

    //             setNeighbourhoods(data);
    //         }
    //     )()
    // }, [district_id]);

    // useEffect(() => {
    //     setTimeout(() => {

    //         (
    //             async () => {
    //                 if (typeof district_name === 'string' && district_name.trim().length > 0 && district_name != null) {

    //                     const { data } = await axios.get(`location/district/name-like/${district_name}/${province_id}`);
    //                     setDistrictId(data.id);
    //                 }
    //             }
    //         )()
    //     }, 1000);

    // }, [district_name, province_id])


    // useEffect(() => {
    //     (
    //         async () => {

    //             const { data } = await axios.get('location/district/' + province_id);

    //             setDistricts(data);
    //         }
    //     )()
    // }, [province_id]);

    // useEffect(() => {

    //     (
    //         async () => {
    //             if (typeof province_name === 'string' && province_name.trim().length > 0 && province_name != null) {
    //                 const { data } = await axios.get('location/province/name-like/' + province_name);
    //                 if (data.id > 0) { setDistrictId(null); setNeighbourhoodId(null); }
    //                 setProvinceId(data.id);
    //             }
    //         }
    //     )()

    // }, [province_name])


    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('location/neighbourhood/' + district_id);

    //             setNeighbourhoods(data);
    //         }
    //     )()
    // }, [district_id]);

    // useEffect(() => {
    //     setTimeout(() => {

    //         (
    //             async () => {
    //                 if (typeof district_name === 'string' && district_name.trim().length > 0 && district_name != null) {

    //                     const { data } = await axios.get(`location/district/name-like/${district_name}/${province_id}`);
    //                     setDistrictId(data.id);
    //                 }
    //             }
    //         )()
    //     }, 1000);

    // }, [district_name, province_id])

    // useEffect(() => {
    //     setTimeout(() => {

    //         (
    //             async () => {
    //                 if (typeof province_name === 'string' && province_name.trim().length > 0) {

    //                     const { data } = await axios.get(`location/neighbourhood/name-like/${neighbourhood_name}/${district_id}`);
    //                     setNeighbourhoodId(data.id);
    //                 }
    //             }
    //         )()
    //     }, 1000);

    // }, [neighbourhood_name, district_id])

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/8/' + property_genus_id);

                setPropertyTypes(data.data);
            }
        )()
    }, [property_genus_id]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/41');

                setStatuses(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/42');

                setPurposeOfUse(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/49');

                setCurrencies(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof asset_name === 'string' && asset_name.trim().length > 0) {
                        const { data } = await axios.get(`contract/assets/name-like/${asset_name}`);
                        setAssets(data.data);
                    } else {
                        const { data } = await axios.get(`contract/assets`);
                        setAssets(data.data);
                    }
                }
            )()
        }, 100);

    }, [asset_name])

    useEffect(() => {
        setTimeout(() => {
            (
                async () => {
                    if (typeof item_name === 'string' && item_name.trim().length > 0) {
                        const { data } = await axios.get(`contract/items/name-like/${item_name}`);
                        setItems(data.data);
                    } else {
                        const { data } = await axios.get(`contract/items`);
                        setItems(data.data);
                    }
                }
            )()
        }, 100);
    }, [item_name])

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0) {

                        const { data } = await axios.get(`location/neighbourhood/name-like/${neighbourhood_name}/${district_id}`);
                        setNeighbourhoodId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [neighbourhood_name, district_id])


    //Google Maps Geo For Site
    const [site_coordinates, setSiteCoordinates] = useState('');

    useEffect(() => {


        fromAddress(site_address).then(
            (response: any) => {
                const { lat, lng } = response.results[0].geometry.location;
                setSiteCoordinates(`${lat},${lng}`)
            },
            (error: any) => {
                //console.error(error);
            }
        );
    }, [site_address]);

    // Google maps  geoFor Property
    // const [coordinates, setCoordinates] = useState({ lat: 36.87524849186124, lng: 30.64364152042535 });
    // const [property_coordinate, setPropertyCoordinate] = useState<any | null>('36.87524849186124,30.64364152042535');

    // useEffect(() => {
    //     fromAddress(`${property_address}, ${district_name}, ${province_name}`)
    //         .then(
    //             (response: any) => {
    //                 const { lat, lng } = response.results[0].geometry.location;
    //                 setCoordinates({ lat: lat, lng: lng })
    //                 setPropertyCoordinate(`${lat},${lng}`)

    //                 let administrativeAreaLevel1;

    //                 for (const result of response.results) {
    //                     for (const component of result.address_components) {
    //                         if (component.types.includes('administrative_area_level_1')) {
    //                             administrativeAreaLevel1 = component.long_name;
    //                             break;
    //                         }
    //                     }
    //                 }
    //                 let administrativeAreaLevel2;

    //                 for (const result of response.results) {
    //                     for (const component of result.address_components) {
    //                         if (component.types.includes('administrative_area_level_2')) {
    //                             administrativeAreaLevel2 = component.long_name;
    //                             break;
    //                         }
    //                     }
    //                 }
    //                 let administrativeAreaLevel3;

    //                 for (const result of response.results) {
    //                     for (const component of result.address_components) {
    //                         if (component.types.includes('administrative_area_level_3')) {
    //                             administrativeAreaLevel3 = component.long_name;
    //                             break;
    //                         }
    //                     }
    //                 }

    //                 let administrativeAreaLevel4;
    //                 for (const result of response.results) {
    //                     for (const component of result.address_components) {
    //                         if (component.types.includes('administrative_area_level_4')) {
    //                             administrativeAreaLevel4 = component.long_name;
    //                             break;
    //                         }
    //                     }
    //                 }

    //                 let administrativeAreaLevelNeighbourhood;
    //                 for (const result of response.results) {
    //                     for (const component of result.address_components) {
    //                         if (component.types.includes('neighborhood')) {
    //                             administrativeAreaLevelNeighbourhood = component.long_name;
    //                             break;
    //                         }
    //                     }
    //                 }

    //                 setProvinceName(administrativeAreaLevel1);
    //                 setDistrictName(administrativeAreaLevel2);
    //                 if (administrativeAreaLevelNeighbourhood != null && administrativeAreaLevelNeighbourhood != '' && administrativeAreaLevelNeighbourhood.trim().length > 0) {
    //                     setNeighbourhoodName(administrativeAreaLevelNeighbourhood);
    //                 } else if (administrativeAreaLevel3 != null && administrativeAreaLevel3 != '' && administrativeAreaLevel3.trim().length > 0) {
    //                     setNeighbourhoodName(administrativeAreaLevel3);
    //                 } else if (administrativeAreaLevel4 != null && administrativeAreaLevel4 != '' && administrativeAreaLevel4.trim().length > 0) {
    //                     setNeighbourhoodName(administrativeAreaLevel4);
    //                 }

    //             },
    //             (error: any) => {
    //                 //console.error(error);
    //             }
    //         );
    // }, [property_address]);

    // useEffect(() => {
    //     if (coordinates.lat && coordinates.lng) {
    //         fromLatLng(coordinates?.lat.toString(), coordinates.lng.toString()).then(
    //             (response) => {
    //                 const address = response.results[0].formatted_address;
    //                 setPropertyAddress(address);
    //                 // Diğer işlemleri yapabilirsiniz
    //             },
    //             (error) => {
    //                 console.error('Adres çözümleme hatası:', error);
    //             }
    //         );
    //     }

    // }, [coordinates]);

    //Get Coordinate
    // useEffect(() => {
    //     if (get_coordinate) {
    //         if (province_id > 0 && district_id > 0 && neighbourhood_id > 0 && bob > 0 && parcel > 0) {
    //             (
    //                 async () => {
    //                     try {
    //                         const { data } = await axios.get(`properties-hsb-info/${neighbourhood_id}/${bob}/${parcel}`);
    //                         setCoordinates({ lat: Number(data.coordinate.latitude), lng: Number(data.coordinate.longitude) });
    //                         setGross(data?.m2);
    //                         setPropertyCoordinate(`${data.coordinate.latitude},${data.coordinate.longitude}`);
    //                         setCsbParcelId(data?.csb_parcel_id);
    //                         setCsbFloorId(data?.csb_floor_id);
    //                         setRectangleCoordinates(data.coordinates);
    //                     } catch (error) {x
    //                         console.error('Veri çekme hatası:', error);
    //                     }
    //                 }
    //             )();
    //         }
    //         setGetCoordinate(false);

    //     }
    // }, [get_coordinate]);

    useEffect(() => {
        let isMounted = true;
        const source = axios.CancelToken.source();

        const fetchSites = async () => {
            try {
                let response;

                if (site_search.length > 0) {
                    response = await axios.get('sites/search/' + site_search, { cancelToken: source.token });
                } else {
                    response = await axios.get('sites');
                }

                if (isMounted) {
                    setSites(response.data.data || []);
                }
            } catch (error) {
                console.log(error);
            }
        };

        try {
            fetchSites();
        } catch (error) {
            console.log(error);
        }

     
    }, [site_search]);

    useEffect(() => {
        if (start_date != null && end_date != null) {
            const diff = moment(end_date).diff(start_date, 'months');
            let lTimeDiff = diff >= 12 && diff > 0
                ? `${Math.floor(diff / 12)} Yıl ${(diff % 12 == 0) ? '' : diff % 12 + ' Ay'}`
                : `${diff} Ay`;

            setLeaseTime(lTimeDiff);
        }
    }, [start_date, end_date]);

    useEffect(() => {

        setPerYearPrice(per_month_price * 12);
    }, [per_month_price]);

    const customizeRenderEmptyForSite = () => (
        <div style={{ textAlign: 'center' }}>
            <InboxOutlined style={{ fontSize: 40 }} />
            <p>Site Bulunamadı!</p>
            <Button variant="primary" onClick={handleSiteShow}>Yeni Ekle</Button>

        </div>
    );


    //Modals
    //Add Realtor Modal
    const [show_realtor, setShowRealtor] = useState(false);
    const handleClose = () => setShowRealtor(false);

    const handleShow = (e: any) => {
        (
            () => {
                setShowRealtor(true);
            }
        )()
    };

    //Add Site Modal
    const [show_site_modal, setShowSiteModal] = useState(false);
    const handleSiteClose = () => setShowSiteModal(false);

    const handleSiteShow = (e: any) => {
        (
            () => {
                setShowSiteModal(true);
            }
        )()
    };


    const containerStyle = {
        width: '100%',
        height: '400px'
    };




    useEffect(() => {
        (
            async () => {
                let contrat_type = null;
                (contract_type_id === 183) ? contrat_type = 4 : contrat_type = 3;
                const { data } = await axios.get('contract/contract-templates/' + contrat_type);

                setContent(data.content);
            }
        )()
    }, [contract_type_id]);


    //Site
    const siteSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const new_site_id = await axios.post('sites', {
            name: site_name,
            address: site_address,
            manager_name: manager_name,
            office_phone: office_phone,
            mobile_phone: mobile_phone,
            coordinate: site_coordinates,
            definition: site_note,
            house_count: total_house,

        });
        setSiteSearch(`${site_name}`);
        setSiteId(new_site_id);
        setShowSiteModal(false);
    }
    const [messageApi, contextHolder] = message.useMessage();

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        setDisableButton(true);
        if(partner_count==0){
            messageApi.open({
                type: 'error',
                content: 'Lütfen danışman seçiniz',
            });
        setDisableButton(false);
        }else if(propertyData?.province_id==0 || propertyData?.province_id==null){
            messageApi.open({
                type: 'error',
                content: 'Lütfen il seçiniz',
            });
        setDisableButton(false);
        }else if(propertyData?.district_id==0 || propertyData?.district_id==null){
            messageApi.open({
                type: 'error',
                content: 'Lütfen ilçe seçiniz',
            });
        setDisableButton(false);
        }else if(propertyData?.neighbourhood_id==0 || propertyData?.neighbourhood_id==null){
            messageApi.open({
                type: 'error',
                content: 'Lütfen mahalle seçiniz',
            });
        setDisableButton(false);
        }else if((propertyData?.neighbourhood_id === 7 || propertyData?.neighbourhood_id === 9) && (propertyData?.floor_location_id === 0 || propertyData?.floor_location_id === null)){
            messageApi.open({
                type: 'error',
                content: 'Lütfen dairenin buluduğu katı seçiniz',
            });
        setDisableButton(false);
        }else{
        setSaveButtonText('Bekleyiniz...');

        let save_id = await axios.post('contract/lease-agreements', {
            contract_type_id,
            seller_customer_type_id,
            seller_company_name,
            seller_tax_admin,
            seller_tax_number,
            seller_first_name,
            seller_last_name,
            seller_email,
            seller_phone_code_id,
            seller_phone,
            seller_nationality_id,
            seller_id_type_id,
            seller_id_no,
            seller_birthday,
            seller_address,
            seller_bank_name,
            seller_iban,
            seller_note,

            buyer_customer_type_id,
            buyer_company_name,
            buyer_tax_admin,
            buyer_tax_number,
            buyer_first_name,
            buyer_last_name,
            buyer_email,
            buyer_phone_code_id,
            buyer_phone,
            buyer_nationality_id,
            buyer_id_type_id,
            buyer_id_no,
            buyer_birthday,
            buyer_address,
            buyer_note,

            guarantor_see,
            guarantor_customer_type_id,
            guarantor_company_name,
            guarantor_tax_admin,
            guarantor_tax_number,
            guarantor_first_name,
            guarantor_last_name,
            guarantor_email,
            guarantor_phone_code_id,
            guarantor_phone,
            guarantor_nationality_id,
            guarantor_id_type_id,
            guarantor_id_no,
            guarantor_birthday,
            guarantor_address,
            guarantor_note,

            propertyData,

            start_date,
            end_date,
            lease_time,
            currency_id,
            per_month_price,
            per_year_price,
            deposit_currency_id,
            deposit_price,
            payment_type,
            purpose_of_use_id,
            property_status,
            selected_assets,
            selected_items,
            selected_agents,
            partner_count,
            status_id,
            content
        });
        if (download_word) {
            await axios.get(`contract/lease-agreements/${save_id.data}/download`)
                .then((res: any) => {

                    if (res.data > 0) {
                        window.location.href = `https://lluparapp.remaxaqua.com/contracts/lease-agreements/documents/${res.data}.docx`;
                        setRedirect(true);
                    } else {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Hata1',
                            text: 'İşlem sırasında bir hata gerçekleşti.',
                            showConfirmButton: false,
                            timer: 2500
                        })
                    }
                })
                .catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Hata2',
                        text: 'İşlem sırasında bir hata gerçekleşti.',
                        showConfirmButton: false,
                        timer: 2500
                    })
                });
        } else {
            setRedirect(true);
        }
    }
    }


    if (redirect) {
        return <Navigate to={'/contract/lease-agreements'} />;
    }

    const brItems: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Kira Kontratları', path: '/contract/lease-agreements' },
        { label: 'Oluştur', active: true },
    ];
    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Kira Kontratı Oluştur</h4>
                        <div className="page-title-right">
                            <Breadcrumb items={brItems} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <form onSubmit={submit}>
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Kontrat Türü</label>
                                                <div>
                                                    <Radio.Group defaultValue={contract_type_id} onChange={e => setContractTypeId(e.target.value)}>
                                                        {contract_types?.map((contract_types: Attribute) => {
                                                            return (
                                                                <Radio value={contract_types.id} >{contract_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3 select-style">
                                                <label>Yetkili Danışman(lar)</label>
                                                <Select className="form-control" showSearch
                                                    allowClear={true}
                                                    mode="multiple"
                                                    placeholder="Danışman Seçiniz"
                                                    onChange={e => { setPartnerCount(e.length); setSelectedAgents(e); if (e.length === 0) { selected_agents.splice(0); setSelectedAgents([]); } }}

                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {users.map((users: User) => {
                                                        return (
                                                            <Option value={users.id} title={users.first_name + ' ' + users.last_name}
                                                                key={users.first_name + ' ' + users.last_name}
                                                                id={users.first_name + ' ' + users.last_name}>{users.first_name + ' ' + users.last_name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Kiralayan Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Kiralayan Bilgileri </label>
                                                <div>
                                                    <Radio.Group defaultValue={seller_customer_type_id} value={seller_customer_type_id} onChange={e => setSellerCustomerTypeId(e.target.value)}>
                                                        {customer_types.map((customer_types: CustomerType) => {
                                                            return (
                                                                <Radio value={customer_types.id} >{customer_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Firma Adı</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir firma adı giriniz."
                                                    className="form-control"
                                                    onChange={e => setSellerCompanyName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Dairesi</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi dairesi giriniz."
                                                    className="form-control"
                                                    onChange={e => setSellerTaxAdmin(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi numarası giriniz."
                                                    className="form-control"
                                                    onChange={e => setSellerTaxNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                                    value={seller_first_name}   
                                                    name="seller_first_name"
                                                    className="form-control"
                                                    required
                                                    onChange={e => setSellerFirstName(e.target.value)}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" name="seller_last_name" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                value={seller_last_name}   
                                                    className="form-control"
                                                    required
                                                    onChange={e => setSellerLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kimlik Tipi</label>
                                                <Select className="form-control" defaultValue={seller_id_type_id} value={seller_id_type_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setSellerIdTypeId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {id_types.map((id_types: IdType) => {
                                                        return (
                                                            <Option value={id_types.id} title={id_types.name}
                                                                key={id_types.name}
                                                                id={id_types.name}>{id_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Numara {seller_id_no.toString()}</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir kimlik numarası giriniz."
                                                    className="form-control"
                                                    name="seller_id_no"
                                                    value={seller_id_no}
                                                    required
                                                    onChange={e => setSellerIdNo(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select defaultValue={seller_phone_code_id} value={seller_phone_code_id} style={{ width: 100 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setSellerPhoneCodeId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >

                                                            {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                return (
                                                                    <Option value={phone_codes.id} title={phone_codes.name}
                                                                        key={phone_codes.name}
                                                                        id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <PatternFormat format="(###) ### ## ##" value={seller_phone} name="seller_phone" required className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setSellerPhone(e.target.value)} />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    value={seller_email}
                                                    onChange={e => setSellerEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Uyruk</label>
                                                <Select className="form-control" defaultValue={seller_nationality_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setSellerNationalityId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {nationalities?.map((nationalities: Country) => {
                                                        return (
                                                            <Option value={nationalities.id} title={nationalities.name}
                                                                key={nationalities.name}
                                                                id={nationalities.name}>{nationalities.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Doğum Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen doğum tarihi seçiniz."
                                                    className="form-control"
                                                    value={moment(seller_birthday, "DD/MM/YYYY").format("YYYY-MM-DD")}
                                                    onChange={e => setSellerBirthday(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    name="seller_address"
                                                    value={seller_address}
                                                    required
                                                    onChange={e => setSellerAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { seller_note_see ? setSellerNoteSee(false) : setSellerNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={seller_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    onChange={e => setSellerNote(e.target.value)}>
                                                        {seller_note}
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Kiracı Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Kiracı Bilgileri</label>
                                                <div>
                                                    <Radio.Group defaultValue={buyer_customer_type_id} value={buyer_customer_type_id} onChange={e => setBuyerCustomerTypeId(e.target.value)}>
                                                        {customer_types.map((customer_types: CustomerType) => {
                                                            return (
                                                                <Radio value={customer_types.id} >{customer_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Firma Adı</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir firma adı giriniz."
                                                    className="form-control"
                                                    value={buyer_company_name}
                                                    onChange={e => setBuyerCompanyName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Dairesi</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi dairesi giriniz."
                                                    className="form-control"
                                                    value={buyer_tax_admin}
                                                    onChange={e => setBuyerTaxAdmin(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi numarası giriniz."
                                                    className="form-control"
                                                    value={buyer_tax_number}
                                                    onChange={e => setBuyerTaxNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ad {buyer_first_name}</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                                    className="form-control"
                                                    name="buyer_first_name"
                                                    value={buyer_first_name}
                                                    required
                                                    onChange={e => setBuyerFirstName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                    value={buyer_last_name}
                                                    name="buyer_last_name"
                                                    required
                                                    onChange={e => setBuyerLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kimlik Tipi</label>
                                                <Select className="form-control" defaultValue={buyer_id_type_id} value={buyer_id_type_id} showSearch
                                                    onChange={e => setBuyerIdTypeId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {id_types.map((id_types: IdType) => {
                                                        return (
                                                            <Option value={id_types.id} title={id_types.name}
                                                                key={id_types.name}
                                                                id={id_types.name}>{id_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Kimlik Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir kimlik numarası giriniz."
                                                    className="form-control"
                                                    name="buyer_id_no"
                                                    value={buyer_id_no}
                                                    required
                                                    onChange={e => setBuyerIdNo(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select defaultValue={buyer_phone_code_id} value={buyer_phone_code_id} style={{ width: 100 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setBuyerPhoneCodeId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >

                                                            {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                return (
                                                                    <Option value={phone_codes.id} title={phone_codes.name}
                                                                        key={phone_codes.name}
                                                                        id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <PatternFormat format="(###) ### ## ##" name="buyer_phone" value={buyer_phone} required onChange={e => setBuyerPhone(e.target.value)} className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    value={buyer_email}
                                                    onChange={e => setBuyerEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Uyruk</label>
                                                <Select className="form-control" defaultValue={buyer_nationality_id} value={buyer_nationality_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setBuyerNationalityId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {nationalities?.map((nationalities: Country) => {
                                                        return (
                                                            <Option value={nationalities.id} title={nationalities.name}
                                                                key={nationalities.name}
                                                                id={nationalities.name}>{nationalities.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>


                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Doğum Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen doğum tarihi seçiniz."
                                                    className="form-control"
                                                    value={moment(buyer_birthday, "DD/MM/YYYY").format("YYYY-MM-DD")}
                                                    onChange={e => setBuyerBirthday(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    name="buyer_address"
                                                    value={buyer_address}
                                                    required
                                                    onChange={e => setBuyerAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { buyer_note_see ? setBuyerNoteSee(false) : setBuyerNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={buyer_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    onChange={e => setBuyerNote(e.target.value)}>
                                                        {buyer_note}
                                                </textarea>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <Checkbox onClick={e => { guarantor_see ? setGuarantorSee(false) : setGuarantorSee(true); }}>Kefil var</Checkbox>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Kefil Bilgileri */}
                        <div className="card" style={guarantor_see == true ? {} : { display: 'none' }}>
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Kefil Bilgileri</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                                    className="form-control"
                                                    name="guarantor_first_name"
                                                    onChange={e => setGuarantorFirstName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                    name="guarantor_last_name"
                                                    onChange={e => setGuarantorLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kimlik Tipi</label>
                                                <Select className="form-control" defaultValue={buyer_id_type_id} showSearch
                                                    onChange={e => setGuarantorIdTypeId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {id_types.map((id_types: IdType) => {
                                                        return (
                                                            <Option value={id_types.id} title={id_types.name}
                                                                key={id_types.name}
                                                                id={id_types.name}>{id_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Kimlik Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir kimlik numarası giriniz."
                                                    className="form-control"
                                                    name="guarantor_id_no"
                                                    onChange={e => setGuarantorIdNo(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select defaultValue={guarantor_phone_code_id} style={{ width: 100 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setGuarantorPhoneCodeId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >

                                                            {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                return (
                                                                    <Option value={phone_codes.id} title={phone_codes.name}
                                                                        key={phone_codes.name}
                                                                        id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <PatternFormat format="(###) ### ## ##" name="buyer_phone" required onChange={e => setGuarantorPhone(e.target.value)} className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    onChange={e => setGuarantorEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Uyruk</label>
                                                <Select className="form-control" defaultValue={guarantor_nationality_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setGuarantorNationalityId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {nationalities?.map((nationalities: Country) => {
                                                        return (
                                                            <Option value={nationalities.id} title={nationalities.name}
                                                                key={nationalities.name}
                                                                id={nationalities.name}>{nationalities.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>


                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Doğum Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen doğum tarihi seçiniz."
                                                    className="form-control"
                                                    onChange={e => setGuarantorBirthday(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    name="guarantor_address"
                                                    onChange={e => setGuarantorAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { guarantor_note_see ? setGuarantorNoteSee(false) : setGuarantorNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={guarantor_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    onChange={e => setGuarantorNote(e.target.value)}>
                                                </textarea>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Mülk Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <Property
                                    initialData={''}
                                    pageInfo={{
                                        title: 'Mülk Bilgileri'
                                    }}
                                    exportData={(propertyData: any) => {
                                        setPropertyData(propertyData);
                                        console.log(propertyData);
                                    }}
                                />
                                <div>
                                </div>
                            </div>
                        </div>
                        {/* Sözleşme Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Sözleşme Bilgileri</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Başlangıç Tarihi</label>
                                                <input type="date"
                                                    className="form-control"
                                                    value={start_date}
                                                    required
                                                    onChange={e => setStartDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Bitiş Tarihi</label>
                                                <input type="date"
                                                    className="form-control"
                                                    value={end_date}
                                                    required
                                                    onChange={e => setEndDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Sözleşme Süresi</label>
                                                <input type="text"
                                                    value={lease_time}
                                                    defaultValue={lease_time}
                                                    className="form-control"
                                                    required
                                                    onChange={e => setLeaseTime(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Aylık Kira Bedeli</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select defaultValue={currency_id} value={currency_id} style={{ width: 70 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setCurrencyId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >

                                                            {currencies?.map((currencies: Attribute) => {
                                                                return (
                                                                    <Option value={currencies.id} title={currencies.name}
                                                                        key={currencies.name}
                                                                        id={currencies.id}>{currencies.name}</Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <NumericFormat
                                                        className="form-control"
                                                        name="per_month_price"
                                                        defaultValue={per_month_price?.toString().replace(/[.]/g, '').replace(',', '.')}
                                                        value={per_month_price?.toString().replace(/[.]/g, '').replace(',', '.')}
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        required
                                                        onChange={e => { setPerMonthPrice(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Yıllık Kira Bedeli</label>
                                                <NumericFormat
                                                    className="form-control"
                                                    name="per_year_price"
                                                    value={per_year_price?.toString().replace(/[.]/g, '').replace('.', ',')}
                                                    defaultValue={per_year_price?.toString().replace(/[.]/g, '').replace(',', '.')}
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    required
                                                    onChange={e => { setPerYearPrice(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')); }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Depozito Tutarı</label>
                                                <div className="input-group">
                                                <div className="input-group-text p-0">
                                                        <Select defaultValue={deposit_currency_id} value={deposit_currency_id} style={{ width: 70 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setDepositCurrencyId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >
                                                            {currencies?.map((currencies: Attribute) => {
                                                                return (
                                                                    <Option value={currencies.id} title={currencies.name}
                                                                        key={currencies.name}
                                                                        id={currencies.id}>{currencies.name}</Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                <NumericFormat
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    required
                                                    defaultValue={deposit_price?.toString().replace(/[.]/g, '').replace(',', '.')}
                                                    value={deposit_price?.toString().replace(/[.]/g, '').replace(',', '.')}
                                                    onChange={e => { setDepositPrice(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')); }}
                                                />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">

                                            <div className="form-group mb-3 select-style">
                                                <label>Durum</label>
                                                <Select className="form-control" defaultValue={status_id} showSearch
                                                    value={status_id}
                                                    placeholder="Sözleşme Durumunu Seçiniz"
                                                    onChange={e => setStatusId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {statuses?.map((statuses: Attribute) => {
                                                        return (
                                                            <Option value={statuses.id} title={statuses.name}
                                                                key={statuses.name}
                                                                id={statuses.name}>{statuses.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kullanım Amacı</label>
                                                <Select className="form-control" defaultValue={purpose_of_use_id} showSearch
                                                    value={purpose_of_use_id}
                                                    placeholder="Kullanım Amacını Seçiniz"
                                                    onChange={e => setPurposeOfUseId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {purpose_of_use?.map((purpose_of_use: Attribute) => {
                                                        return (
                                                            <Option value={purpose_of_use.id} title={purpose_of_use.name}
                                                                key={purpose_of_use.name}
                                                                id={purpose_of_use.name}>{purpose_of_use.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-8">
                                            <div className="form-group mb-3">
                                                <label>Kiralananın Durumu</label>
                                                <input type="text"
                                                    className="form-control"
                                                    defaultValue={property_status}
                                                    value={property_status}
                                                    onChange={e => setPropertyStatus(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-8">
                                            <div className="form-group mb-3">
                                                <label>Banka Adı</label>
                                                <input type="text"
                                                    className="form-control"
                                                    defaultValue={seller_bank_name}
                                                    value={seller_bank_name}
                                                    onChange={e => setSellerBankName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-8">
                                            <div className="form-group mb-3">
                                                <label>IBAN Numarası</label>
                                                <input type="text"
                                                    className="form-control"
                                                    defaultValue={seller_iban}
                                                    value={seller_iban}
                                                    onChange={e => setSellerIban(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3 select-style">
                                                <label>Demirbaş(lar)</label>
                                                <Select className="form-control" showSearch
                                                    placeholder="Demirbaş Seçiniz"
                                                    mode="tags"
                                                    allowClear={true}
                                                    onChange={e => setSelectedAssets(e)}
                                                    onSearch={e => setAssetName(e)}
                                                    optionFilterProp="children"
                                                >
                                                    {assets?.map((assets: Asset) => {
                                                        return (
                                                            <Option value={assets.id} title={assets.name}
                                                                key={assets.name}
                                                                id={assets.name}>{assets.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3 select-style">
                                                <label>Eşya(lar)</label>
                                                <Select className="form-control" showSearch
                                                    placeholder="Eşya Seçiniz"
                                                    allowClear={true}
                                                    mode="tags"
                                                    onChange={e => setSelectedItems(e)}
                                                    onSearch={e => setItemName(e)}
                                                    optionFilterProp="children"
                                                >
                                                    {items?.map((items: Item) => {
                                                        return (
                                                            <Option value={items.id} title={items.name}
                                                                key={items.name}
                                                                id={items.name}>{items.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12"> 
                                            <div className="form-group mb-3">
                                                <label>Ödeme Şekli</label>
                                                <input type="text"
                                                    className="form-control"
                                                    value={payment_type}
                                                    defaultValue={payment_type}
                                                    onChange={e => setPaymentType(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Sözleşme Maddeleri</label>
                                                <CustomSunEditor
                                                    defaultValue={content}
                                                    value={content}
                                                    onChange={(e: any) => setContent(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-md-12">
                                        <div className="form-group mb-3">
                                            <Checkbox defaultChecked={download_word} onChange={e => setDownloadWord(e.target.checked)}>Kaydettikten sonra indir</Checkbox>
                                        </div>
                                    </div>
                                    {contextHolder}
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={disable_button}>{save_button_text}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div >
        </Wrapper >
    )
}

export default Create;