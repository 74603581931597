import axios from "axios";
import React, { SyntheticEvent, useEffect, useState, useRef } from "react";
import { Link, Navigate, NavLink } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";

import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import Breadcrumb from "../../../components/constant/breadcrumb";
import CustomInput from "../../../components/form/customInput";
import CustomSunEditor from "../../../components/form/sun-editor/customSunEditor";
import { useUserContext } from "../../../providers/user/userProvider";
import { Attribute } from "../../../models/attribute/attribute";
import { User } from "../../../models/user";
import { CustomerType } from "../../../models/attribute/customerType/customerType";
import { useCustomerTypeContext } from "../../../providers/attribute/customerType/customerTypeProvider";
import { IdType } from "../../../models/attribute/idType/idType";
import { useIdTypeContext } from "../../../providers/attribute/idType/idTypeProvider";
import { useCountryContext } from "../../../providers/general/location/country/countryProvider";
import { PhoneCode } from "../../../models/general/country/phoneCode";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Country } from "../../../models/general/country/country";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { Province } from "../../../models/general/location/province/province";
import { District } from "../../../models/general/location/district/district";
import { Neighbourhood } from "../../../models/general/location/neighbourhood/neighbourhood";
import { PropertyType } from "../../../models/attribute/propertyType/propertyType";
import { FloorLocation } from "../../../models/attribute/floorLocation/floorLocation";
import { BuildType } from "../../../models/attribute/buildType/buildType";

import { Radio, Select, DatePicker, Checkbox, ConfigProvider, message, TreeSelect } from 'antd';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { setKey, fromAddress } from "react-geocode";
import { useProvinceContext } from "../../../providers/general/location/province/provinceProvider";
import { useBuildTypeContext } from "../../../providers/attribute/buildType/buildTypeProvider";
import { usePropertyGenusContext } from "../../../providers/attribute/propertyGenus/propertyGenusProvider";
import moment from 'moment';

import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
//Boostrap
import { Modal, Button } from "react-bootstrap";
import { Asset } from "../../../models/contract/asset/asset";
import { Item } from "../../../models/contract/item/item";
import Property from "../../../components/property/property";
import Swal from "sweetalert2";

const { Option } = Select;

interface TreeNode {
    value: string;
    title: string;
    key: string;
    children?: TreeNode[];
}

const Create = () => {
    //General
    const [name, setName] = useState('');
    const [content, setContent] = useState('');
    const [redirect, setRedirect] = useState(false);

    const [users, setUsers] = useUserContext();
    const [customer_types, setCustomerTypes] = useCustomerTypeContext();
    const [id_types, setIdTypes] = useIdTypeContext();
    const [phone_codes, setPhoneCodes] = useCountryContext();
    const [nationalities, setNationalities] = useCountryContext();

    const [selected_agent, setSelectedAgent] = useState<any | null>(null);
    const [partner_count, setPartnerCount] = useState<any | null>(0);
    const [selected_agents, setSelectedAgents] = useState([]);
    const [transaction_types, setTransactionTypes] = useState([]);

    const [assets, setAssets] = useState([]);
    const [selected_assets, setSelectedAssets] = useState([]);
    const [asset_name, setAssetName] = useState<any | null>('');

    const [items, setItems] = useState([]);
    const [selected_items, setSelectedItems] = useState([]);
    const [item_name, setItemName] = useState<any | null>('');

    //Document
    const [status_id, setStatusId] = useState<any | null>(302);
    const [purpose_of_use_id, setPurposeOfUseId] = useState<any | null>(187);
    const [statuses, setStatuses] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [payment_types, setPaymentTypes] = useState([]);
    const [selected_payment_types, setSelectedPaymentTypes] = useState<{ value: string, label: React.ReactNode, price?: number, currency_id?: number }[]>([]);
    const [purpose_of_use, setPurposeOfUse] = useState([]);
    const [property_status, setPropertyStatus] = useState<any | null>('');
    const [commercial_auth_number, setCommercialAuthNumber] = useState<any | null>('');

    // Seller
    const [seller_customer_type_id, setSellerCustomerTypeId] = useState(1);
    const [seller_company_name, setSellerCompanyName] = useState('');
    const [seller_tax_admin, setSellerTaxAdmin] = useState('');
    const [seller_tax_number, setSellerTaxNumber] = useState('');
    const [seller_phone_code_id, setSellerPhoneCodeId] = useState(34);
    const [seller_first_name, setSellerFirstName] = useState<any | null>('');
    const [seller_last_name, setSellerLastName] = useState<any | null>('');
    const [seller_id_type_id, setSellerIdTypeId] = useState(3);
    const [seller_nationality_id, setSellerNationalityId] = useState(34);
    const [seller_id_no, setSellerIdNo] = useState('');
    const [seller_birthday, setSellerBirthday] = useState('');
    const [seller_email, setSellerEmail] = useState('');
    const [seller_address, setSellerAddress] = useState('');
    const [seller_phone, setSellerPhone] = useState('');
    const [seller_note, setSellerNote] = useState('');
    const [seller_bank_name, setSellerBankName] = useState('');
    const [seller_iban, setSellerIban] = useState('');

    // Buyer
    const [buyer_customer_type_id, setBuyerCustomerTypeId] = useState(1);
    const [buyer_company_name, setBuyerCompanyName] = useState('');
    const [buyer_tax_admin, setBuyerTaxAdmin] = useState('');
    const [buyer_tax_number, setBuyerTaxNumber] = useState('');
    const [buyer_phone_code_id, setBuyerPhoneCodeId] = useState(34);
    const [buyer_first_name, setBuyerFirstName] = useState('');
    const [buyer_last_name, setBuyerLastName] = useState('');
    const [buyer_nationality_id, setBuyerNationalityId] = useState(34);
    const [buyer_id_type_id, setBuyerIdTypeId] = useState(3);
    const [buyer_id_no, setBuyerIdNo] = useState('');
    const [buyer_birthday, setBuyerBirthday] = useState('');
    const [buyer_email, setBuyerEmail] = useState('');
    const [buyer_address, setBuyerAddress] = useState('');
    const [buyer_phone, setBuyerPhone] = useState('');
    const [buyer_note, setBuyerNote] = useState('');

    //Property
    const [province_id, setProvinceId] = useState<any | null>(7);
    const [province_name, setProvinceName] = useState<any | null>('');
    const [district_id, setDistrictId] = useState<any | null>(2038);
    const [district_name, setDistrictName] = useState<any | null>('');
    const [neighbourhood_id, setNeighbourhoodId] = useState<any | null>(2338);
    const [neighbourhood_hsb_id, setNeighbourhoodHsbId] = useState<any | null>(null);
    const [neighbourhood_name, setNeighbourhoodName] = useState<any | null>('');
    const [csb_floor_id, setCsbFloorId] = useState<any | null>(0);
    const [csb_parcel_id, setCsbParcelId] = useState<any | null>(0);
    const [rectangle_coordinates, setRectangleCoordinates] = useState<any | null>('');
    const [property_id, setPropertyId] = useState<any | null>(0);
    const [propertyData, setPropertyData] = useState<any | null>(null);

    const [payment_type, setPaymentType] = useState<any | null>(null);
    const [flat_number, setFlatNumber] = useState<any | null>(null);
    const [build_name, setBuildName] = useState<any | null>(null);
    const [street, setStreet] = useState<any | null>(null);
    const [bob, setBob] = useState<any | null>(null);
    const [parcel, setParcel] = useState<any | null>(null);
    const [gross, setGross] = useState<any | null>(null);
    const [net, setNet] = useState<any | null>(null);
    const [land_office_id, setLandOfficeId] = useState<any | null>(1);
    const [deed_date, setDeedDate] = useState<any | null>('');
    const [property_address, setPropertyAddress] = useState<any | null>('');
    const [build_date, setBuildDate] = useState<any | null>(null);
    const [property_note, setPropertyNote] = useState<any | null>('');
    const [site_search, setSiteSearch] = useState<any | null>('');
    const [site_id, setSiteId] = useState<any | null>(null);
    const [property_genus_id, setPropertyGenusId] = useState<any | null>(7);
    const [property_type_id, setPropertyTypeId] = useState<any | null>(14);
    const [floor_location_id, setFloorLocationId] = useState<any | null>();
    const [build_type_id, setBuildTypeId] = useState<any | null>(114);
    const [districts, setDistricts] = useState([]);
    const [neighbourhoods, setNeighbourhoods] = useState([]);
    const [property_types, setPropertyTypes] = useState([]);
    const [floor_locations, setFloorLocations] = useState([]);
    const [sites, setSites] = useState([]);

    //Site
    const [site_name, setSiteName] = useState('');
    const [site_address, setSiteAddress] = useState('');
    const [total_house, setTotalHouse] = useState(0);
    const [manager_name, setManagerName] = useState('');
    const [mobile_phone, setMobilePhone] = useState('');
    const [office_phone, setOfficePhone] = useState('');
    const [site_note, setSiteNote] = useState('');

    //Contract
    const [contract_id, setContractId] = useState(9);
    const [last_date_time, setLastDateTime] = useState<any | null>(null);
    const [contract_date, setContractDate] = useState<any | null>(moment().format("YYYY-MM-DD"));
    const [start_date, setStartDate] = useState<any | null>(null);

    const [end_date, setEndDate] = useState<any | null>(null);
    const [lease_time, setLeaseTime] = useState<any | null>(null);
    const [per_month_price, setPerMonthPrice] = useState<any | null>(null);
    const [per_year_price, setPerYearPrice] = useState<any | null>(null);
    const [deposit_price, setDepositPrice] = useState<any | null>(null);
    const [deposit_currency_id, setDepositCurrencyId] = useState(259);
    const [penalty_clause_amount, setPenaltyClauseAmount] = useState<any | null>(null);
    const [penalty_clause_amount_currency_id, setPenaltyClauseAmountCurrencyId] = useState(259);

    const [sale_price, setSalePrice] = useState<any | null>(null);
    const [sale_currency_id, setSaleCurrencyId] = useState(259);

    //Other
    const [get_coordinate, setGetCoordinate] = useState(false);
    const [coordinate_info, setCoordinateInfo] = useState([]);
    const [note_see, setCustomerNoteSee] = useState(false);
    const [seller_note_see, setSellerNoteSee] = useState(false);
    const [buyer_note_see, setBuyerNoteSee] = useState(false);
    const [guarantor_note_see, setGuarantorNoteSee] = useState(false);
    const [guarantor_see, setGuarantorSee] = useState(false);
    const [property_note_see, setPropertyNoteSee] = useState(false);

    const [disable_button, setDisableButton] = useState(false);
    const [save_button_text, setSaveButtonText] = useState('Kaydet');

    const [provinces, setProvinces] = useProvinceContext();
    const [build_types, setBuildTypes] = useBuildTypeContext();
    const [property_genuses, setPropertyGenuses] = usePropertyGenusContext();

    const [download_word, setDownloadWord] = useState<any | null>(true);

    const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/11');
                setTransactionTypes(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/8/' + property_genus_id);
                setPropertyTypes(data.data);
            }
        )()
    }, [property_genus_id]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/59');
                setStatuses(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/42');
                setPurposeOfUse(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/49');
                setCurrencies(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof asset_name === 'string' && asset_name.trim().length > 0) {
                        const { data } = await axios.get(`contract/assets/name-like/${asset_name}`);
                        setAssets(data.data);
                    } else {
                        const { data } = await axios.get(`contract/assets`);
                        setAssets(data.data);
                    }
                }
            )()
        }, 100);

    }, [asset_name])


    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0) {

                        const { data } = await axios.get(`location/neighbourhood/name-like/${neighbourhood_name}/${district_id}`);
                        setNeighbourhoodId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [neighbourhood_name, district_id])


    //Google Maps Geo For Site
    const [site_coordinates, setSiteCoordinates] = useState('');

    useEffect(() => {


        fromAddress(site_address).then(
            (response: any) => {
                const { lat, lng } = response.results[0].geometry.location;
                setSiteCoordinates(`${lat},${lng}`)
            },
            (error: any) => {
                //console.error(error);
            }
        );
    }, [site_address]);

    useEffect(() => {
        let isMounted = true;
        const source = axios.CancelToken.source();

        const fetchSites = async () => {
            try {
                let response;

                if (site_search.length > 0) {
                    response = await axios.get('sites/search/' + site_search, { cancelToken: source.token });
                } else {
                    response = await axios.get('sites');
                }

                if (isMounted) {
                    setSites(response.data.data || []);
                }
            } catch (error) {
                console.log(error);
            }
        };

        try {
            fetchSites();
        } catch (error) {
            console.log(error);
        }

        return () => {
            isMounted = false;
            source.cancel('Request canceled');
        };
    }, [site_search]);

    useEffect(() => {
        if (start_date != null && end_date != null) {
            const diff = moment(end_date).diff(start_date, 'months');
            let lTimeDiff = diff >= 12 && diff > 0
                ? `${Math.floor(diff / 12)} Yıl ${(diff % 12 == 0) ? '' : diff % 12 + ' Ay'}`
                : `${diff} Ay`;

            setLeaseTime(lTimeDiff);
        }
    }, [start_date, end_date]);

    useEffect(() => {

        setPerYearPrice(per_month_price * 12);
    }, [per_month_price]);

    const customizeRenderEmptyForSite = () => (
        <div style={{ textAlign: 'center' }}>
            <InboxOutlined style={{ fontSize: 40 }} />
            <p>Site Bulunamadı!</p>
            <Button variant="primary" onClick={handleSiteShow}>Yeni Ekle</Button>

        </div>
    );


    //Modals
    //Add Realtor Modal
    const [show_realtor, setShowRealtor] = useState(false);
    const handleClose = () => setShowRealtor(false);

    const handleShow = (e: any) => {
        (
            () => {
                setShowRealtor(true);
            }
        )()
    };

    //Add Site Modal
    const [show_site_modal, setShowSiteModal] = useState(false);
    const handleSiteClose = () => setShowSiteModal(false);

    const handleSiteShow = (e: any) => {
        (
            () => {
                setShowSiteModal(true);
            }
        )()
    };


    const containerStyle = {
        width: '100%',
        height: '400px'
    };




    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('contract/contract-templates/9');

                setContent(data.content);
            }
        )()
    }, []);

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        setDisableButton(true);
        setSaveButtonText('Bekleyiniz...');

        let save_id = await axios.post('contract/purchase-sale-agreements', {
            contract_id,
            selected_agents,
            partner_count,
            seller_customer_type_id,
            seller_company_name,
            seller_tax_admin,
            seller_tax_number,
            seller_first_name,
            seller_last_name,
            seller_email,
            seller_phone_code_id,
            seller_phone,
            seller_nationality_id,
            seller_id_type_id,
            seller_id_no,
            seller_birthday,
            seller_address,
            seller_bank_name,
            seller_iban,
            seller_note,

            buyer_customer_type_id,
            buyer_company_name,
            buyer_tax_admin,
            buyer_tax_number,
            buyer_first_name,
            buyer_last_name,
            buyer_email,
            buyer_phone_code_id,
            buyer_phone,
            buyer_nationality_id,
            buyer_id_type_id,
            buyer_id_no,
            buyer_birthday,
            buyer_address,
            buyer_note,

            propertyData,

            sale_price,
            sale_currency_id,
            deposit_price,
            deposit_currency_id,
            penalty_clause_amount,
            penalty_clause_amount_currency_id,
            last_date_time,
            commercial_auth_number,
            contract_date,
            selected_payment_types,
            status_id,
            content
        });
        if (download_word) {
            await axios.get(`contract/purchase-sale-agreements/${save_id.data}/download`)
                .then((res: any) => {

                    if (res.data > 0) {
                        window.location.href = `https://lluparapp.remaxaqua.com/contracts/purchase-sale-agreements/documents/${res.data}.docx`;
                        setRedirect(true);
                    } else {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Hata1',
                            text: 'İşlem sırasında bir hata gerçekleşti.',
                            showConfirmButton: false,
                            timer: 2500
                        })
                    }
                })
                .catch(error => {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Hata2',
                        text: 'İşlem sırasında bir hata gerçekleşti.',
                        showConfirmButton: false,
                        timer: 2500
                    })
                });
        } else {
            setRedirect(true);
        }
    }

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/56/0/attributeMeta');
                const processedData: any = processData(data);
                setPaymentTypes(processedData);
            }
        )()
    }, []);

    const processData = (data: Attribute[]): TreeNode[] => {
        const treeData: TreeNode[] = [];

        data.forEach((item: any) => {
            const parentItem: any = {
                value: item.id.toString(),
                title: item.name,
                key: `${item.id}`,
            };

            if (item.attribute_meta.length > 0) {
                parentItem.children = [];

                item.attribute_meta.forEach((child: any, index: any) => {
                    const childItem: any = {
                        value: `${item.id}_${child.child.id.toString()}`,
                        title: `${child.child.name}`,
                        key: `${item.id}_${item.child_id}`,
                    };
                    parentItem.children.push(childItem);
                });
            }

            treeData.push(parentItem);
        });
        return treeData;
    };

    const handleTagRender = (props: any) => {
        const { label, value, closable, onClose } = props;

        const getTitle = (nodeValue: any): any => {
            const node: any = payment_types.find((item: any) => item.value === nodeValue);

            if (node) {
                const titles: string[] = [];
                let currentNode: any | undefined = node;
                while (currentNode) {
                    titles.unshift(currentNode.title);
                    currentNode = payment_types.find((item: any) => item.value === currentNode?.key.split('_')[1]);
                }
                return titles.join(' -> ');
            }
            return label || '';
        };

        return (
            <a className="btn btn-light ms-2">
                <span title={getTitle(value)}>{getTitle(value)}</span>
                {closable && (
                    <span onClick={(e) => { e.stopPropagation(); onClose(); }}> ×</span>
                )}
            </a>
        );
    };

    if (redirect) {
        return <Navigate to={'/contract/purchase-sale-agreements'} />;
    }

    const brItems: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Alım Satım Protokolü Sözleşmeleri', path: '/contract/purchase-sale-agreements' },
        { label: 'Oluştur', active: true },
    ];
    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Alım Satım Protokolü Sözleşmesi Oluştur</h4>
                        <div className="page-title-right">
                            <Breadcrumb items={brItems} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <form onSubmit={submit}>
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3 select-style">
                                                <label>Yetkili Danışman(lar)</label>
                                                <Select className="form-control" showSearch
                                                    allowClear={true}
                                                    mode="multiple"
                                                    placeholder="Danışman Seçiniz"
                                                    onChange={e => { setPartnerCount(e.length); setSelectedAgents(e); if (e.length === 0) { selected_agents.splice(0); setSelectedAgents([]); } }}

                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {users.map((users: User) => {
                                                        return (
                                                            <Option value={users.id} title={users.first_name + ' ' + users.last_name}
                                                                key={users.first_name + ' ' + users.last_name}
                                                                id={users.first_name + ' ' + users.last_name}>{users.first_name + ' ' + users.last_name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Satıcı/Kiracı Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Satıcı Bilgileri</label>
                                                <div>
                                                    <Radio.Group defaultValue={seller_customer_type_id} onChange={e => setSellerCustomerTypeId(e.target.value)}>
                                                        {customer_types.map((customer_types: CustomerType) => {
                                                            return (
                                                                <Radio value={customer_types.id} >{customer_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Firma Adı</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir firma adı giriniz."
                                                    className="form-control"
                                                    onChange={e => setSellerCompanyName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Dairesi</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi dairesi giriniz."
                                                    className="form-control"
                                                    onChange={e => setSellerTaxAdmin(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi numarası giriniz."
                                                    className="form-control"
                                                    onChange={e => setSellerTaxNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                                    name="seller_first_name"
                                                    className="form-control"
                                                    required
                                                    onChange={e => setSellerFirstName(e.target.value)}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" name="seller_last_name" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                    required
                                                    onChange={e => setSellerLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kimlik Tipi</label>
                                                <Select className="form-control" defaultValue={3} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setSellerIdTypeId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {id_types.map((id_types: IdType) => {
                                                        return (
                                                            <Option value={id_types.id} title={id_types.name}
                                                                key={id_types.name}
                                                                id={id_types.name}>{id_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Numara</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir kimlik numarası giriniz."
                                                    className="form-control"
                                                    name="seller_id_no"
                                                    required
                                                    onChange={e => setSellerIdNo(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select defaultValue={seller_phone_code_id} style={{ width: 100 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setSellerPhoneCodeId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >

                                                            {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                return (
                                                                    <Option value={phone_codes.id} title={phone_codes.name}
                                                                        key={phone_codes.name}
                                                                        id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <PatternFormat format="(###) ### ## ##" name="seller_phone" required className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setSellerPhone(e.target.value)} />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    onChange={e => setSellerEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Uyruk</label>
                                                <Select className="form-control" defaultValue={seller_nationality_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setSellerNationalityId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {nationalities?.map((nationalities: Country) => {
                                                        return (
                                                            <Option value={nationalities.id} title={nationalities.name}
                                                                key={nationalities.name}
                                                                id={nationalities.name}>{nationalities.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Doğum Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen doğum tarihi seçiniz."
                                                    className="form-control"
                                                    onChange={e => setSellerBirthday(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    name="seller_address"
                                                    required
                                                    onChange={e => setSellerAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { seller_note_see ? setSellerNoteSee(false) : setSellerNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={seller_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    onChange={e => setSellerNote(e.target.value)}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Alıcı / Kiracı Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Alıcı Bilgileri</label>
                                                <div>
                                                    <Radio.Group defaultValue={buyer_customer_type_id} onChange={e => setBuyerCustomerTypeId(e.target.value)}>
                                                        {customer_types.map((customer_types: CustomerType) => {
                                                            return (
                                                                <Radio value={customer_types.id} >{customer_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Firma Adı</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir firma adı giriniz."
                                                    className="form-control"
                                                    onChange={e => setBuyerCompanyName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Dairesi</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi dairesi giriniz."
                                                    className="form-control"
                                                    onChange={e => setBuyerTaxAdmin(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi numarası giriniz."
                                                    className="form-control"
                                                    onChange={e => setBuyerTaxNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                                    className="form-control"
                                                    name="buyer_first_name"
                                                    required
                                                    onChange={e => setBuyerFirstName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                    name="buyer_last_name"
                                                    required
                                                    onChange={e => setBuyerLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kimlik Tipi</label>
                                                <Select className="form-control" defaultValue={buyer_id_type_id} showSearch
                                                    onChange={e => setBuyerIdTypeId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {id_types.map((id_types: IdType) => {
                                                        return (
                                                            <Option value={id_types.id} title={id_types.name}
                                                                key={id_types.name}
                                                                id={id_types.name}>{id_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Kimlik Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir kimlik numarası giriniz."
                                                    className="form-control"
                                                    name="buyer_id_no"
                                                    required
                                                    onChange={e => setBuyerIdNo(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select defaultValue={buyer_phone_code_id} style={{ width: 100 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setBuyerPhoneCodeId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >

                                                            {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                return (
                                                                    <Option value={phone_codes.id} title={phone_codes.name}
                                                                        key={phone_codes.name}
                                                                        id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <PatternFormat format="(###) ### ## ##" name="buyer_phone" required onChange={e => setBuyerPhone(e.target.value)} className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    onChange={e => setBuyerEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Uyruk</label>
                                                <Select className="form-control" defaultValue={buyer_nationality_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setBuyerNationalityId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {nationalities?.map((nationalities: Country) => {
                                                        return (
                                                            <Option value={nationalities.id} title={nationalities.name}
                                                                key={nationalities.name}
                                                                id={nationalities.name}>{nationalities.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>


                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Doğum Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen doğum tarihi seçiniz."
                                                    className="form-control"
                                                    onChange={e => setBuyerBirthday(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    name="buyer_address"
                                                    required
                                                    onChange={e => setBuyerAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { buyer_note_see ? setBuyerNoteSee(false) : setBuyerNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={buyer_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    onChange={e => setBuyerNote(e.target.value)}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Mülk Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <Property
                                    initialData={''}
                                    pageInfo={{
                                        title: 'Mülk Bilgileri'
                                    }}
                                    exportData={(propertyData: any) => {
                                        setPropertyData(propertyData);
                                    }}
                                />
                                <div>
                                </div>
                            </div>
                        </div>
                        {/* Sözleşme Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Sözleşme Bilgileri</label>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Satış Fiyatı</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select defaultValue={sale_currency_id} style={{ width: 70 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setSaleCurrencyId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >
                                                            {currencies?.map((currencies: Attribute) => {
                                                                return (
                                                                    <Option value={currencies.id} title={currencies.name}
                                                                        key={currencies.name}
                                                                        id={currencies.id}>{currencies.name}</Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <NumericFormat
                                                        className="form-control"
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        onChange={e => { setSalePrice(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Kaparo Tutarı</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select defaultValue={deposit_currency_id} style={{ width: 70 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setDepositCurrencyId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >
                                                            {currencies?.map((currencies: Attribute) => {
                                                                return (
                                                                    <Option value={currencies.id} title={currencies.name}
                                                                        key={currencies.name}
                                                                        id={currencies.id}>{currencies.name}</Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <NumericFormat
                                                        className="form-control"
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        onChange={e => { setDepositPrice(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Cezai Şart Bedeli</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select defaultValue={penalty_clause_amount_currency_id} style={{ width: 70 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setPenaltyClauseAmountCurrencyId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >
                                                            {currencies?.map((currencies: Attribute) => {
                                                                return (
                                                                    <Option value={currencies.id} title={currencies.name}
                                                                        key={currencies.name}
                                                                        id={currencies.id}>{currencies.name}</Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <NumericFormat
                                                        className="form-control"
                                                        decimalSeparator=","
                                                        thousandSeparator="."
                                                        onChange={e => { setPenaltyClauseAmount(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>En Geç Tapu Devir Tarihi</label>
                                                <div>
                                                    <DatePicker
                                                        style={{ width: '100%' }}
                                                        showTime
                                                        format={'DD/MM/YYYY HH:mm'}
                                                        locale={locale}
                                                        onChange={(value, dateString) => {
                                                            setLastDateTime(moment(dateString, 'DD/MM/YYYY HH:mm').format("YYYY-MM-DD HH:mm"));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Taşınmaz Ticari Yetki Belge No</label>
                                                <input type="text"
                                                    className="form-control"
                                                    name="commercial_auth_number"
                                                    required
                                                    onChange={e => setCommercialAuthNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Sözleşme Tarihi</label>
                                                <input type="date"
                                                    className="form-control"
                                                    value={contract_date}
                                                    onChange={e => setContractDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Ödeme Türü</label>
                                                <TreeSelect
                                                    className="form-control"
                                                    showSearch
                                                    style={{ width: '100%' }}
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    placeholder="İşlem Türü Seçiniz"
                                                    allowClear
                                                    multiple
                                                    treeDefaultExpandAll
                                                    treeData={payment_types}
                                                    //onChange={(e,l,ex) =>{setSelectedPaymentTypes(e); console.log(ex);} }
                                                    onChange={(value: string[], label: React.ReactNode[]) => {
                                                        const newSelectedPaymentTypes:any = value.map(val => ({ value: val, label: label[value.indexOf(val)], currency_id: 259 }));
                                                        setSelectedPaymentTypes(newSelectedPaymentTypes);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Durum</label>
                                                <Select className="form-control" defaultValue={status_id} showSearch
                                                    value={status_id}
                                                    placeholder="Sözleşme Durumunu Seçiniz"
                                                    onChange={e => setStatusId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {statuses?.map((statuses: Attribute) => {
                                                        return (
                                                            <Option value={statuses.id} title={statuses.name}
                                                                key={statuses.name}
                                                                id={statuses.name}>{statuses.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        {
                                            selected_payment_types
                                                ? selected_payment_types.length > 0 ? selected_payment_types.map((payment_types: any, i) => {
                                                    return (
                                                        <>
                                                            <div className="col-xl-2 col-md-2">
                                                                <div className="form-group mb-3">
                                                                    <label>{payment_types.label}</label>
                                                                    <div className="input-group">
                                                                        <div className="input-group-text p-0">
                                                                            <Select style={{ width: 70 }} defaultValue={payment_types.currency_id} showSearch
                                                                                optionFilterProp="children"
                                                                                onChange={e => { 
                                                                                    const updatedSelectedPaymentTypes = [...selected_payment_types];
                                                                                    updatedSelectedPaymentTypes[i].currency_id = e;
                                                                                    setSelectedPaymentTypes(updatedSelectedPaymentTypes);                                            
                                                                                }}
                                                                                filterOption={(input, option) => {
                                                                                    return (
                                                                                        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                                        option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                    );

                                                                                }}
                                                                            >
                                                                                {currencies?.map((currencies: Attribute) => {
                                                                                    return (
                                                                                        <Option value={currencies.id} title={currencies.name}
                                                                                            key={currencies.name}
                                                                                            id={currencies.id}>{currencies.name}</Option>
                                                                                    )
                                                                                })}
                                                                            </Select>
                                                                        </div>
                                                                        <NumericFormat
                                                                            className="form-control"
                                                                            decimalSeparator=","
                                                                            thousandSeparator="."
                                                                            onChange={e => { 
                                                                                const updatedSelectedPaymentTypes = [...selected_payment_types];
                                                                                updatedSelectedPaymentTypes[i].price = parseFloat(e.target.value.replace(/[.]/g, '').replace(',', '.'));
                                                                                setSelectedPaymentTypes(updatedSelectedPaymentTypes);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }) : null : null}

                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Sözleşme Maddeleri</label>
                                                <CustomSunEditor
                                                    height={'400px'}
                                                    defaultValue={content}
                                                    value={content}
                                                    onChange={(e: any) => setContent(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-md-12">
                                        <div className="form-group mb-3">
                                            <Checkbox defaultChecked={download_word} onChange={e => setDownloadWord(e.target.checked)}>Kaydettikten sonra indir</Checkbox>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={disable_button}>{save_button_text}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div >
        </Wrapper >
    )
}

export default Create;