import { AttributeMeta } from "./attributeMeta";
import { Builder } from "../constant/builder/builder";

export class AttributeNew {
    constructor(
        public id: number = 0,
        public name: string = '',
        public type: number = 0,          
        public ordernum: number = 0,      
        public color: string = '',
        public category_id?: number,      
        public created_at?: string | null,
        public updated_at?: string | null,
        public category: any[] = [],
        public attributeMeta: AttributeMeta = new AttributeMeta()
    ) {}
}

export class AttributeNewData {
    constructor(
        public data: { [key: string]: AttributeNew[] } = {},
        public report: { total: number } = { total: 0 }
    ) {}
}

export class AttributeNewResponse {
    constructor(
        public builder: Builder = new Builder('attributeNew'),
        public attribute: AttributeNewData = new AttributeNewData()
    ) {}
}