import React, { SyntheticEvent, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import RealtorInput from '../../form/inputs/realtor';

interface RealtorModalProps {
    show: boolean;
    onClose: () => void;
    onSubmit: (e: SyntheticEvent, newRealtorData: any) => Promise<void>;
    phone_codes: any[];
}

const RealtorModal: React.FC<RealtorModalProps> = ({ show, onClose, onSubmit, phone_codes }) => {
    const [realtorData, setRealtorData] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        email: '',
        phoneCodeId: undefined,
        phone: '',
        note: ''
    });

    const handleChange = (field: string, value: string | number | undefined) => {
        setRealtorData(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    return (
        <Modal show={show} onHide={onClose} animation={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Emlakçı Ekleme Formu</Modal.Title>
            </Modal.Header>
            <form onSubmit={(e) => onSubmit(e, realtorData)}>
                <Modal.Body>
                    <RealtorInput realtorData={realtorData} onChange={handleChange} phone_codes={phone_codes} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        İptal
                    </Button>
                    <button type="submit" className="btn btn-primary">Kaydet</button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default RealtorModal;