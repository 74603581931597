import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { User } from '../../models/user';
import { UserContext } from '../../contexts/user/userContext';

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    let isMounted = true;

    async function getUsers() {
      try {
        const { data } = await axios.get('users-filter?filter_status_id=255&limit=1000&sort[by]=first_name&sort[order]=asc');
        if (isMounted) {
          setUsers(data.users.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    const timer = setTimeout(() => {
      getUsers();
    }, 5000);

    return () => {
      isMounted = false;
      clearTimeout(timer);
    };
  }, []);

  return (
    <UserContext.Provider value={[users, setUsers]}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
