import axios from "axios";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link, Navigate, NavLink } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import { Checkbox, Radio, Select, Switch } from "antd";
import { useUserContext } from "../../../providers/user/userProvider";
import { User } from "../../../models/user";
import { NumericFormat } from "react-number-format";
import CustomInput from "../../../components/form/customInput";
import { useFrequencyContext } from "../../../providers/attribute/frequency/frequencyProvider";
import { Attribute } from "../../../models/attribute/attribute";
import { Category } from "../../../models/user/category";
import Breadcrumb from "../../../components/constant/breadcrumb";
import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";

const { Option } = Select;

const Create = () => {

    // General
    const [disable_button, setDisableButton] = useState(false);
    const [save_button_text, setSaveButtonText] = useState('Kaydet');

    const [selected_categories, setSelectedCategories] = useState([]);
    const [user_categories, setUserCategories] = useState([]);
    const [price, setPrice] = useState<any | null>(null);
    const [frequency_id, setFrequencyId] = useState<number | null>(null);
    const [status_id, setStatusId] = useState<number | null>(151);
    const [user_category_id, setUserCategoryId] = useState<number>(0);
    const [due_day, setDueDay] = useState<number | null>(null);
    const [payment_date, setPaymentDate] = useState<any | null>(null);
    const [directive, setDirective] = useState<boolean>(false);
    const [definition, setDefinition] = useState<any | null>('');
    const [type, setType] = useState<number>(149);
    const [directive_type, setDirectiveType] = useState<number>(149);
    const [table_name, setTableName] = useState<string>('cashbooks');
    const [user_status, setUserStatus] = useState<boolean>(false);

    const [redirect, setRedirect] = useState(false);

    const [users, setUsers] = useUserContext();
    useEffect(() => {
        (
            async () => {
                if (user_status) {
                    if (user_category_id > 0) {
                        const { data } = await axios.get(`users/by-category/${user_category_id}`);
                        setUsers(data);
                    } else {
                        const { data } = await axios.get(`users`);
                        setUsers(data.data);
                    }
                    setUserStatus(false);
                }
            }
        )()
    }, [user_status]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('user/speacial-user-categories/1');
                setUserCategories(data);
            }
        )()
    }, []);

    const [frequencies, setFrequencies] = useFrequencyContext();

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        setDisableButton(true);
        setSaveButtonText('Bekleyiniz...');

        await axios.post('cashbooks', {
            selected_categories,
            price,
            frequency_id,
            payment_date,
            due_day,
            definition,
            status_id,
            type,
            directive_type,
            table_name,
            directive
        });
        setRedirect(true);
    }

    if (redirect) {
        return <Navigate to={'/accounting/table-revenues'} />;
    }
    const items: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Masa Gelirleri', path: '/accounting/table-revenues' },
        { label: 'Oluştur', active: true },
      ];
    
    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Masa Geliri Ekle</h4>
                        <div className="page-title-right">
                            <Breadcrumb items={items} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-4">
                            <div>
                                <form onSubmit={submit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group mb-3">
                                                <div>
                                                    <label>Danışman Türü : </label>
                                                    <Radio.Group defaultValue={user_category_id} onChange={e => { setUserCategoryId(e.target.value); setUserStatus(true); }}>
                                                        <Radio value={0} checked>Tümü</Radio>
                                                        {user_categories?.map((user_categories: Category) => {
                                                            return (
                                                                <Radio value={user_categories.id} >{user_categories.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3 select-style">
                                                <label>Danışman (lar)</label>
                                                <Select className="form-control" showSearch
                                                    allowClear={true}
                                                    mode="multiple"
                                                    placeholder="Danışman Seçiniz"
                                                    onChange={e => { setSelectedCategories(e); if (e.length === 0) { selected_categories.splice(0); setSelectedCategories([]); } }}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {users.map((users: User) => {
                                                        return (
                                                            <Option value={users.id} title={users.first_name + ' ' + users.last_name}
                                                                key={users.first_name + ' ' + users.last_name}
                                                                id={users.first_name + ' ' + users.last_name}>{users.first_name + ' ' + users.last_name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Tutar</label>
                                                <NumericFormat
                                                    value={price?.toString().replace(/[.]/g, '').replace(',', '.')}
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    onChange={e => { setPrice(e.target.value); }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Tarih</label>
                                                <CustomInput
                                                    type={'date'}
                                                    required={true}
                                                    onChange={(e: any) => setPaymentDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 mt-4 pt-2">
                                                <Checkbox defaultChecked={directive} onChange={e => setDirective(e.target.checked)}>İşlem emri ekle</Checkbox>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={directive ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3 select-style">
                                                <label>İşlem Sıklığı</label>
                                                <Select className="form-control" showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setFrequencyId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {frequencies.map((frequencies: Attribute) => {
                                                        return (
                                                            <Option value={frequencies.id} title={frequencies.name}
                                                                key={frequencies.name}
                                                                id={frequencies.name}>{frequencies.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={directive ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>İşlem Günü</label>
                                                <CustomInput
                                                    type={'number'}
                                                    onChange={(e: any) => setDueDay(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Açıklama</label>
                                                <CustomInput
                                                    type={'textarea'}
                                                    onChange={(e: any) => setDefinition(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Ödeme Durumu</label>
                                                <Switch defaultChecked checkedChildren="Ödendi" className="ms-2" unCheckedChildren="Ödenmedi" onChange={(e: any) => e ? setStatusId(151) : setStatusId(152)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={disable_button}>{save_button_text}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Create;