import React from 'react';
import { ConfigProvider, DatePicker } from 'antd';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/tr';
import trLocale from 'antd/lib/locale/tr_TR';
import { tr } from 'date-fns/locale/tr';

dayjs.extend(localeData);
dayjs.extend(updateLocale);

dayjs.updateLocale('tr', {
  week: {
    dow: 1, 
  },
});

const { RangePicker } = DatePicker;

const rangePresets: any = {
  'Son 7 Gün': [dayjs().subtract(7, 'days'), dayjs()],
  'Bu Ay': [dayjs().startOf('month'), dayjs().endOf('month')],
  'Geçen Ay': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
  'Bu Yıl': [dayjs().startOf('year'), dayjs()],
  'Geçen Yıl': [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
  '1. Çeyrek': [dayjs().startOf('year').add(0, 'month').startOf('month'), dayjs().startOf('year').add(4, 'month').endOf('month')],
  '2. Çeyrek': [dayjs().startOf('year').add(3, 'month').startOf('month'), dayjs().startOf('year').add(5, 'month').endOf('month')],
  '3. Çeyrek': [dayjs().startOf('year').add(6, 'month').startOf('month'), dayjs().startOf('year').add(8, 'month').endOf('month')],
  '4. Çeyrek': [dayjs().startOf('year').add(9, 'month').startOf('month'), dayjs().startOf('year').add(11, 'month').endOf('month')],
};

const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
for (let i = 0; i < 12; i++) {
    rangePresets[monthNames[i]] = [dayjs().month(i).startOf('month'), dayjs().month(i).endOf('month')];
}

interface AntDesignRangePickerProps {
  dateRange: any;
  setDateRange: (value: any) => void;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  sendFilter?: boolean;
  setSendFilter: (value: boolean | ((prev: boolean) => boolean)) => void;
  setSelectedDateName: (name: string) => void;
}

const AntDesignRangePicker: React.FC<AntDesignRangePickerProps> = ({
  dateRange,
  setDateRange,
  setStartDate,
  setEndDate,
  sendFilter,
  setSendFilter,
  setSelectedDateName,
}) => {
  return (
    <ConfigProvider
      locale={{
        ...trLocale,
        Calendar: {
          ...(trLocale.Calendar || {}),
          lang: {
            ...((trLocale.Calendar && trLocale.Calendar.lang) || {}),
            shortWeekDays: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
          },
        },
      }}
    >
      <RangePicker
        format={'DD/MM/YYYY'}
        ranges={rangePresets}
        value={dateRange}
        style={{ width: '100%' }}
        onChange={(value: any, dateString) => {
          setDateRange(value);
          setStartDate(dayjs(dateString.toString().split(',')[0], 'DD/MM/YYYY').format('YYYY-MM-DD'));
          setEndDate(dayjs(dateString.toString().split(',')[1], 'DD/MM/YYYY').format('YYYY-MM-DD'));
          setSendFilter(prev => !prev);
          const selectedRangeKey = Object.keys(rangePresets).find(key => {
            const range = rangePresets[key];
            return dayjs(value[0]).isSame(range[0]) && dayjs(value[1]).isSame(range[1]);
          });

          if (selectedRangeKey) {
            setSelectedDateName(selectedRangeKey);
          } else {
            setSelectedDateName(dateString.toString().split(',')[0] + '-' + dateString.toString().split(',')[1]);
          }
        }}
      />
    </ConfigProvider>
  );
};

export default AntDesignRangePicker;
