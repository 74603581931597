import React from 'react';
import { Form, FormInstance, Skeleton } from 'antd';

interface AntDesignFormProps {
  form?: FormInstance<any>;  
  isLoading: boolean;
  onSubmit: (values: any) => void;
  initialValues?: any;
  children: React.ReactNode;
}

const AntDesignForm: React.FC<AntDesignFormProps> = ({ form, isLoading, onSubmit, initialValues, children, ...props }) => {
  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      initialValues={initialValues}
      form={form}
      {...props}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {children}
        </>
      )}
    </Form>
  );
};

export default AntDesignForm;
