import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink, Navigate } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import Paginator from "../../../components/paginator";
import Mailto from "../../../components/link/mailto";
import Tel from "../../../components/link/tel";
import { Customer } from "../../../models/accounting/customer/customer";
import { Modal, ModalBody, ModalFooter, ModalTitle, Button } from "react-bootstrap";
import { NumericFormat } from 'react-number-format';
import { FiArrowRight } from "react-icons/fi";

import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

import { Transaction } from "../../../models/accounting/transaction/transaction";
import Swal from 'sweetalert2';
import { Radio } from 'antd';
import "react-datepicker/dist/react-datepicker.css";
import { PaymentMethod } from "../../../models/attribute/paymentMethod/paymentMethod";
import TransactionDetail from "../../../components/transaction/detail";
import ProfilePhoto from "../../../components/user/profilePhoto";
import { useGiroTypeContext } from "../../../providers/attribute/giroType/giroTypeProvider";
import { GiroType } from "../../../models/attribute/giroType/giroType";

const Transactions = () => {

    const [transactions, setTransactions] = useState<any | null>([]);
    const [giro_types, setGiroTypes] = useGiroTypeContext();
    const [giro_type_id, setGiroTypeId] = useState<any | null>(0);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [show_id, setShowID] = useState<number>(0);
    const [show_full_name, setShowFullName] = useState<any | null>('');
    const [redirect_id, setRedirectId] = useState(0);

    //Download 
    const [download_doc_id, setDownloadDocId] = useState<any | null>(0);

    const [loading, setLoading] = useState(true);

    const [metas, setCustomerCount] = useState([0]);
    const handleRemoveItem = (item: any) => {
        setCustomerCount(metas.filter(item => item !== item));
    };

    //Modal    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const modalLoaded = () => {
    };

    useEffect(() => {
        (
            async () => {
                if (giro_type_id > 0) {
                    const { data } = await axios.get(`transactions/giro-type/${giro_type_id}?page=${page}`);
                    setTransactions(data.data);
                    setLastPage(data?.meta?.last_page);
                } else {
                    const { data } = await axios.get(`transactions?page=${page}`);
                    setTransactions(data.data);
                    setLastPage(data?.meta?.last_page);
                }
            }
        )()
    }, [giro_type_id,page]);
    

    useEffect(() => {
        (
            async () => {
                if (download_doc_id > 0) {
                    await axios.get(`transactions/${download_doc_id}/download`)
                        .then((res: any) => {

                            if (res.data > 0) {
                                window.location.href = `https://lluparapp.remaxaqua.com/transactions/documents/${res.data}.docx`;
                            } else {
                                Swal.fire({
                                    position: 'center',
                                    icon: 'error',
                                    title: 'Hata1',
                                    text: 'İşlem sırasında bir hata gerçekleşti.',
                                    showConfirmButton: false,
                                    timer: 2500
                                })
                            }
                        })
                        .catch(error => {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: 'Hata2',
                                text: 'İşlem sırasında bir hata gerçekleşti.',
                                showConfirmButton: false,
                                timer: 2500
                            })
                        });

                }
            }
        )()
    }, [download_doc_id]);



    const del = async (id: number) => {

        Swal.fire({
            title: 'Emin misiniz?',
            text: "Kalıcı olarak silinecektir",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c84ee',
            cancelButtonColor: '#fd625e',
            confirmButtonText: 'Evet, sil!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.delete(`/transactions/${id}`);

                setTransactions(transactions.filter((u: Customer) => u.id !== id));
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Veri silindi',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }

    const edit = (id: number) => {
        console.log('213');
        return <Navigate to={`/accounting/transactions/${id}/edit`} />;
    }


    const handleRowDoubleClick = (id: number) => {
        setRedirectId(id);
    }


    if (redirect_id > 0) {
        return <Navigate to={`/accounting/transactions/${redirect_id}/edit`} />;
    }

    return (
        <Wrapper>
            <Modal show={show} onHide={handleClose} onEntered={modalLoaded} size="xl" animation={false}>
                {show_id && show_id !== null && show_id !== undefined &&
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title><ProfilePhoto id={show_id} imgHeight={35} /> <span className="ms-2">{show_full_name}</span> - <span className="text-danger">{show_id}</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <TransactionDetail id={show_id} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Kapat
                            </Button>
                            <Link className="btn btn-primary" to={`/accounting/transactions/${show_id}/edit`}>
                                Güncelle
                            </Link>
                        </Modal.Footer>
                    </>
                }
            </Modal>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">İşlemler</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><NavLink to={'/'}>Gösterge Paneli</NavLink></li>
                                <li className="breadcrumb-item active">İşlemler</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                        <div>
                                            <label>İşlem Türü : </label>
                                            <Radio.Group defaultValue={giro_type_id} onChange={e => setGiroTypeId(e.target.value)} className="ms-2">
                                                <Radio value={0} checked>Tümü</Radio>
                                                {giro_types?.map((giro_types: GiroType) => {
                                                    return (
                                                        <Radio value={giro_types.id} >{giro_types.name}</Radio>
                                                    )
                                                })}
                                            </Radio.Group>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                            <Link to="/accounting/transactions/create" className="btn btn-light"><i className="bx bx-plus me-1"></i> Yeni Ekle</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover table-striped mb-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Portföy No</th>
                                            <th>Danışman</th>
                                            <th>Satıcı / Kiralayan Müşteri</th>
                                            <th>Alıcı / Kiracı Müşteri</th>
                                            <th>Adres</th>
                                            <th>İşlem Türü</th>
                                            <th>Gönderim Tarihi</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactions.map((transactions: Transaction) => {
                                            let pReference: any = '';
                                            pReference = transactions.reference_no.toString();
                                            if (parseInt(transactions.partner_count) > 1) {
                                                pReference = <strong>{transactions.reference_no}</strong>;
                                            }
                                            return (
                                                <tr key={transactions.id} onDoubleClick={() => handleRowDoubleClick(transactions.id)}>
                                                    <td>{transactions.id}</td>
                                                    <td>{pReference}</td>
                                                    <td onClick={e => { setShow(true); setShowID(transactions.id); setShowFullName(`${transactions.agent.first_name} ${transactions.agent.last_name}`); }}>{`${transactions.agent.first_name} ${transactions.agent.last_name}`}</td>
                                                    <td>{`${transactions.seller.first_name} ${transactions.seller.last_name}`} {(transactions.seller && transactions.seller.company_name != '0') ? ' / ' + transactions.seller.company_name : null}</td>
                                                    <td>{`${transactions.buyer.first_name} ${transactions.buyer.last_name}`} {(transactions.buyer && transactions.buyer.company_name != '0') ? ' / ' + transactions.buyer.company_name : null}</td>
                                                    <td>{transactions?.property?.address}</td>
                                                    <td>{transactions.giro_type.name}</td>
                                                    <td>{transactions.created_at}</td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="bx bx-dots-horizontal-rounded"></i>
                                                            </button>

                                                            <ul className="dropdown-menu dropdown-menu-end">
                                                                <li>
                                                                    <Link to={`/accounting/transactions/${transactions.id}/edit`}
                                                                        className="dropdown-item">Güncelle</Link>
                                                                </li>
                                                                <li>
                                                                    <button className="dropdown-item" onClick={() => setDownloadDocId(transactions.id)}>İndir</button>
                                                                </li>
                                                                <li><a className="dropdown-item" href="#" onClick={() => del(transactions.id)}>Sil</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <Paginator page={page} lastPage={lastPage} pageChanged={setPage} />
                        </div>
                    </div>
                </div>
            </div>

        </Wrapper>
    )
}

export default Transactions;