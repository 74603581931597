
export class PropertyGenus {
    constructor(
        public id = 0,
        public name = '',
        public type = '',
        public color = '',
        public ordernum = '',
    ) {
    }
}