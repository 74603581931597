import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CustomCard from '../boostrap/card';
import Breadcrumb from '../breadcrumb';
import { BreadcrumbItem } from '../../../models/constant/breadcrumb/breadcrumb';

interface PageLayoutProps {
  title: string;
  breadcrumbItems: BreadcrumbItem[];
  children?: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ title, breadcrumbItems, children }) => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">{title}</h4>
            <div className="page-title-right">
              <Breadcrumb items={breadcrumbItems} />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <CustomCard>
            {children}
          </CustomCard>
        </Col>
      </Row>
    </>
  );
};

export default PageLayout;
