import { Link } from "react-router-dom";
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { AiOutlineUserSwitch } from 'react-icons/ai';
import { BiMapPin } from 'react-icons/bi';

//Table
import type { ColumnsType } from 'antd/es/table';

import { Table, Tag } from "antd";
import { Modal, Button } from "react-bootstrap";
import ProfilePhoto from "../../user/profilePhoto";
import { NumericFormat } from "react-number-format";
import { useEffect, useState } from "react";
import { GiroReport } from "../../../models/report/giro/giroReport";
import { RankingAgent } from "../../../models/report/giro/rankingAgent";
import axios from "axios";

import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { Property } from "../../../models/general/property/property";
import { PropertyType } from "../../../models/attribute/propertyType/propertyType";
import { PropertyGenus } from "../../../models/attribute/propertyGenus/propertyGenus";
import { GiroType } from "../../../models/attribute/giroType/giroType";
import { Customer } from "../../../models/accounting/customer/customer";
import TransactionDetail from "../../transaction/detail";
import { WatchScheduleActions } from '../../../models/asistant/watchSchedule/watchScheduleAction';
import Tel from "../../link/tel";
import Mailto from "../../link/mailto";
import WatchScheduleDetail from "../../watch-schedule/detail";

dayjs.locale("tr");

//Table
interface DataType {
  id(id: any): unknown;
  sale_price: any;
  created_at: any;
  property: Property;
  propertyType: PropertyType;
  propertyGenus: PropertyGenus;
  reference_no: string;
  key: React.Key;
  giro_type: GiroType;
  seller: Customer;
  buyer: Customer;
  total_service_fee: string;
  partner_count: any;
}

const WatchScheduleList = ({ user_id, meet_date }: any) => {
  const rowClassName = () => 'no-bottom-border'; // Özel sınıf adı
  const [show_id, setShowID] = useState<any | null>(0);
  const [show, setShow] = useState(false);
  const [show_full_name, setShowFullName] = useState<any | null>('');
  const handleClose = () => setShow(false);

  const [sort, setSort] = useState(false);
  const [sort_url, setSortUrl] = useState<any | null>('&sort[order]=desc');
  const [sort_req, setSortReq] = useState<any | null>([]);


  const [reports, setReports] = useState<GiroReport | any | null>(null);
  const [giro_type_data, setGiroTypeData] = useState<RankingAgent | any | null>();

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<any | null>({
    current: 1,
    pageSize: 20,
    currentPageSize: 100,
    total: reports?.total,
    pageSizeOptions: [10, 20, 50, 100, 500, 100],
    showQuickJumper: true,
    showSizeChanger: true,
    showLessItems: true,
    responsive: true,
  });

  const [data, setData] = useState<WatchScheduleActions[]>([]);

  const [redirect_id, setRedirectId] = useState(0);
  const handleRowDoubleClick = (id: any) => {
    setRedirectId(id);
  }
  useEffect(() => {
    (
      async () => {
        setLoading(false);
        axios.get(`asistant/watch-schedule-action/${user_id}/${meet_date}`)
          .then(({ data }) => {
            setData(data);
            setReports(data?.transactions?.report);
            setLoading(false);
            setPagination({
              current: pagination.current,
              pageSize: pagination.pageSize,
              currentPageSize: pagination.currentPageSize,
              total: data.transactions?.meta?.total
            });
          });
      }
    )();
  }, []);

  //Table
  const columns: ColumnsType<WatchScheduleActions> = [
    {
      title: 'Nöbet Müşterileri',
      key: 'customer',
      dataIndex: 'customer',
      render: (customer, record) => {
        let propertyGen = '';
        if (record.propertyGenus) {
          propertyGen = record.propertyGenus.name;
          if (record.propertyType) {
            propertyGen = `${propertyGen} ${record.propertyType.name}`;
          }
        }
        return (
          <>
            <a onClick={() => { setShow(true); setShowID(record.id); setShowFullName(`${customer.first_name} ${customer.last_name}`); }}>
              {`${customer.first_name} ${customer.last_name}`}
            </a>
            <br />
            {propertyGen}
          </>
        );
      },
    },
    {
      title: '',
      dataIndex: 'contactType',
      render: (contactType,record) => {

        return (
          <>
            {record?.customerApplicationType?.name}
            <br />
            <strong>İletişim:</strong> {contactType?.name}
          </>
        );
      },
    },
    Table.EXPAND_COLUMN
  ];


  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl" animation={false}>
        {show_id && show_id !== null && show_id !== undefined &&
          <>
            <Modal.Header closeButton>
              <Modal.Title><ProfilePhoto id={show_id} imgHeight={35} /> <span className="ms-2">{show_full_name}</span> - <span className="text-danger">{show_id}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <WatchScheduleDetail id={show_id} />
            </Modal.Body><Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Kapat
              </Button>
              <Link className="btn btn-primary" to={`/accounting/transactions/${show_id}/edit`}>
                Güncelle
              </Link>
            </Modal.Footer>
          </>
        }
      </Modal>
      <Table
        className="table table-striped"
        size="small"
        columns={columns}
        dataSource={data}
        loading={loading}
        scroll={{ y: 210, x: true }}
        rowKey={(record) => `${record.id}`}
        onRow={(record) => {
          return {
            onDoubleClick: (event) => handleRowDoubleClick(record.id)
          };
        }}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <>
                <div className="table-reponsive">
                  <table className={`table`}>
                    <tbody>
                      <tr>
                        <th>Telefon</th>
                        <td><Tel phone={`+${record.customer.phoneCode.phone_code} ${record.customer.phone}`}>{`+${record.customer.phoneCode.phone_code} ${record.customer.phone}`}</Tel></td>
                      </tr>
                      <tr>
                        <th>E-mail</th>
                        <td><Mailto email={record.customer?.email} body="Merhaba">{record.customer?.email}</Mailto></td>
                      </tr>
                      <tr>
                        <th>İletişim Kaynağı</th>
                        <td>{`${record.contact?.name}`}</td>
                      </tr>
                      <tr>
                        <th>Durum</th>
                        <td>
                          <Tag color={record.status?.color}>{record.status?.name}</Tag>
                        </td>
                      </tr>
                      <tr>
                        <th colSpan={2}>Açıklama</th>
                      </tr>
                      <tr>
                      <td colSpan={2}>
                          {record.definition}
                        </td>
                      </tr>
                      <tr>
                        <th>Tarih / Saat</th>
                        <td>
                          {record.meet_date} {record.meet_time}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )
          },
          rowExpandable: (record) => record.customer.first_name !== 'Not Expandable',
        }}
        pagination={false}
        onChange={(val: any, filter: any, sorter: any, extra: any) => {
          setPagination({
            current: val.current,
            pageSize: val.pageSize,
            currentPageSize: val.currentPageSize
          });
          setSortReq(sorter);
          setSort(true);
        }}
      />
    </>
  );
}

export default WatchScheduleList;

