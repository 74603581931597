import { User } from "../../user";
import { CustomerType } from "../../attribute/customerType/customerType";
import { IdType } from "../../attribute/idType/idType";
import { PhoneCode } from "../../general/country/phoneCode";
import { Citizenship } from "../../general/country/citizenship";

export class Customer {
    constructor(
        public id = 0,
        public first_name = '',
        public last_name = '',
        public company_name = '',
        public id_no = '',
        public phone_code_id = '',
        public birthday = '',
        public phone = '',
        public email = '',
        public address = '',
        public tax_number = '',
        public tax_admin = '',
        public definition = '',
        public citizenship_id = '',
        public id_type_id = '',
        public ip = '',
        public phoneCode = new PhoneCode(),
        public citizenship = new Citizenship(),
        public idType = new IdType(),
        public customerType = new CustomerType(),
        public user = new User()
    ) {
    }

    get name() {
        return this.first_name + ' ' + this.last_name;
    }
}
