
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { Attribute } from '../../../models/attribute/attribute';
import { ExpenseCategoryContext } from '../../../contexts/attribute/expenseCategory/expenseCategoryContext';

export const ExpenseCategoryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [expense_categories, setExpenseCategories] = useState<Attribute[]>([]);

  useEffect(() => {
    let isMounted = true;

    async function getExpenseCategory() {
      try {
        const { data } = await axios.get('attributes/32/null/category');
        if (isMounted) {
          setExpenseCategories(data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    getExpenseCategory();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <ExpenseCategoryContext.Provider value={[expense_categories, setExpenseCategories]}>
      {children}
    </ExpenseCategoryContext.Provider>
  );
}

export const useExpenseCategoryContext = () => useContext(ExpenseCategoryContext);
