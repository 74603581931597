import { useEffect, useState } from 'react';
import axios from 'axios';
import { Carousel, Tag } from 'antd';
import ProfilePhoto from '../../user/profilePhoto';
import moment from 'moment';
import { WorkSchedules } from '../../../models/employee/workSchedule/workSchedule';

const WorkSchedulesCarousel = () => {
    const contentStyle: React.CSSProperties = {
        minHeight: '180px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };
    const [workSchedules, setWorkSchedules] = useState([]);
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

    useEffect(() => {
        (
            async () => {
                axios.get(`employee/work-schedules?limit=500&sort[by]=created_at&sort[order]=desc&page=1&filter_between[]=${startOfMonth}&filter_between[]=${endOfMonth}`)
                    .then(({ data }) => {
                        setWorkSchedules(data.work_schedule.data.data);

                    });
            }
        )()
    }, []);
    return (
        <Carousel autoplay autoplaySpeed={8000} dots={false}>
                                        {workSchedules.map((workSchedules: WorkSchedules) => {
                                            return (
                                                <div style={contentStyle}>
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <ProfilePhoto id={workSchedules.user?.id} imgClass="avatar-lg rounded-circle img-thumbnail" />
                                                        </div>
                                                        <div className="flex-1 ms-3">
                                                            <h5 className="font-size-15 mb-1">
                                                                {workSchedules.user.first_name} {workSchedules.user.last_name}
                                                            </h5>
                                                            <div className="text-muted mb-0">
                                                                {moment(workSchedules.working_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card bg-light mt-4">
                                                        <div className="card-body pb-0">
                                                            <div style={{ height: '190px', minHeight: '190px', overflowY: 'auto', whiteSpace: 'pre-line' }}>
                                                                <p className="text-muted mt-2">
                                                                    <div dangerouslySetInnerHTML={{ __html: workSchedules.definition }} />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Carousel>
    );
};

export default WorkSchedulesCarousel;
