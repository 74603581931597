import { useEffect, useState } from 'react';
import axios from 'axios';
import DoughnutChartWithCard from '../chart/doughnutChartWithCard';
import ApexDoughnutChartWithCard from '../chart/ApexDoughnutChartWithCard';

interface CustomerApplicationType {
    name: string;
    total: number;
}


const WatchScheduleActionCountApexDonutChart = ({ start_date, end_date, dateTypeName, sendFilter }: any) => {

    const [watch_schedule_action_report, setWatchScheduleActionReport] = useState<any | null>([]);


    const [watchScheduleActionChartData, setWatchScheduleActionChartData] = useState<any>({
        series: [''],
        options: {
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '10px', // Yazı tipi boyutunu buradan ayarlayabilirsiniz
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '50%'
                    }
                }
            },
            legend: {
                show: false
            },
            colors: ["#ffcc5a", "#f04d80", "#6951ce", "#16daf1"],
            chart: {
                type: 'donut',
            },
            labels: [0],
        }
    });

    useEffect(() => {
        (
            async () => {
                axios.get(`dashboard/watch-schedule-action-report?filter_between[]=${start_date}&filter_between[]=${end_date}`)
                    .then(({ data }) => {
                        setWatchScheduleActionReport(data?.watch_schedule_action?.report);
                        const customerApplicationTotals: any = data?.watch_schedule_action?.report?.customer_application_type?.total?.map((item: CustomerApplicationType) => item.total);
                        const customerApplicationLabels: string[] = data?.watch_schedule_action?.report?.customer_application_type?.total?.map((item: CustomerApplicationType) => item.name.toString());
                        setWatchScheduleActionChartData(() => ({
                            series: customerApplicationTotals,
                            options: {
                                labels: customerApplicationLabels,
                            }
                        }));
                    });
            }
        )()
    }, [sendFilter]);


    return (
        <ApexDoughnutChartWithCard data={watchScheduleActionChartData} count={watch_schedule_action_report?.total} dateTypeName={dateTypeName} text={'Nöbet Müşterileri'} url={'/asistant/watch-schedule'} />
    );
};

export default WatchScheduleActionCountApexDonutChart;
