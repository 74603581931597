import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, OverlayView, Polygon } from '@react-google-maps/api';

import { Transaction } from '../../../models/accounting/transaction/transaction';
import { Popover, Tag } from 'antd';
import { NumericFormat } from 'react-number-format';
import ProfilePhoto from '../../user/profilePhoto';
import { AiFillCaretDown, AiFillShop } from 'react-icons/ai';
import { BsHouse, BsTextarea } from 'react-icons/bs';
import { RiLayoutMasonryLine } from 'react-icons/ri';

const TransactionMap = ({ start_date, end_date }: any) => {

    const [coordinates, setCoordinates] = useState({ lat: 36.9374435, lng: 30.6757203, weight: Math.floor(Math.random() * Math.floor(5)) });
    const containerStyle = {
        width: '100%',
        height: '400px'
    };


    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`giros?limit=10000&page=1&filter_between[]=${start_date}&filter_between[]=${end_date}`);

                setTransactions(data.transactions.data);
            }
        )()
    }, []);


    const mapData = transactions.filter((transaction: Transaction) => transaction.property?.coordinate).map((transaction: Transaction) => {
        const splitCoord = transaction.property?.coordinate.split(",");
        let markerType = 'blue';
        let color = 'geekblue';
        if (transaction.giro_type.id === 116) {
            markerType = 'red';
            color = 'red';
        }
        let markerIcon = 'house';

        switch (transaction.property.propertyGenus.id) {
            case 8:
                markerIcon = 'plot';
                break;
            case 9:
                markerIcon = 'commercial';
                break;
            case 10:
                markerIcon = 'field';
                break;
        }
        if (splitCoord && splitCoord.length > 1) {
            return {
                lat: parseFloat(splitCoord[0]),
                lng: parseFloat(splitCoord[1]),
                marker: markerType + "-" + markerIcon,
                id: transaction.id,
                markerIcon: markerIcon,
                color: color,
                fullName: transaction?.agent?.first_name + ' ' + transaction?.agent?.last_name,
                content: transaction?.agent?.first_name.substring(0, 1) + transaction?.agent?.last_name.substring(0, 1),
                giro_type_id: transaction.giro_type.id,
                seller_service_fee: transaction.seller_service_fee,
                buyer_service_fee: transaction.buyer_service_fee,
                office_referral_fee: transaction.office_referral_fee,
                other_realtor_id: transaction.realtor?.id,
                realtor_full_name: transaction.realtor?.first_name + ' ' + transaction.realtor?.last_name,
                out_office_referral_fee: transaction.out_office_referral_fee,
                total_service_fee: transaction.total_service_fee,
                agent_percent: transaction.agent_percent,
                agent_service_fee: transaction.agent_service_fee,
                agent_team_leader_id: transaction?.agent?.team_leader_id,
                agent_team_leader_percent: transaction.agent?.team_leader_percent,
                team_leader_service_fee: transaction.agent?.team_leader_price,
                office_service_fee: transaction.office_service_fee,

            };
        }
    });


    const polygonKN = [
        { lat: 36.897909, lng: 30.665544 },
        { lat: 36.885188, lng: 30.66303 },
        { lat: 36.880669, lng: 30.653727 },
        { lat: 36.873609, lng: 30.658311 },
        { lat: 36.858834, lng: 30.634194 },
        { lat: 36.84916, lng: 30.621884 },
        { lat: 36.841426, lng: 30.614061 },
        { lat: 36.836686, lng: 30.61427 },
        { lat: 36.834526, lng: 30.612737 },
        { lat: 36.838158, lng: 30.613011 },
        { lat: 36.836112, lng: 30.610788 },
        { lat: 36.837947, lng: 30.610209 },
        { lat: 36.839351, lng: 30.605711 },
        { lat: 36.837569, lng: 30.604339 },
        { lat: 36.834583, lng: 30.60847 },
        { lat: 36.832562, lng: 30.607722 },
        { lat: 36.834213, lng: 30.606083 },
        { lat: 36.832594, lng: 30.606163 },
        { lat: 36.83307, lng: 30.605187 },
        { lat: 36.832543, lng: 30.604772 },
        { lat: 36.832607, lng: 30.606981 },
        { lat: 36.830135, lng: 30.605113 },
        { lat: 36.829217, lng: 30.60792 },
        { lat: 36.830467, lng: 30.608553 },
        { lat: 36.828662, lng: 30.608758 },
        { lat: 36.828867, lng: 30.61019 },
        { lat: 36.833863, lng: 30.61689 },
        { lat: 36.828514, lng: 30.610684 },
        { lat: 36.828199, lng: 30.608786 },
        { lat: 36.829434, lng: 30.604204 },
        { lat: 36.823849, lng: 30.597704 },
        { lat: 36.798492, lng: 30.576397 },
        { lat: 36.785268, lng: 30.572741 },
        { lat: 36.783219, lng: 30.575326 },
        { lat: 36.766539, lng: 30.572902 },
        { lat: 36.765396, lng: 30.544685 },
        { lat: 36.75577, lng: 30.529755 },
        { lat: 36.733137, lng: 30.518396 },
        { lat: 36.701659, lng: 30.508334 },
        { lat: 36.693315, lng: 30.500601 },
        { lat: 36.693976, lng: 30.451826 },
        { lat: 36.67374, lng: 30.437078 },
        { lat: 36.671505, lng: 30.43385 },
        { lat: 36.66858, lng: 30.41569 },
        { lat: 36.702505, lng: 30.409157 },
        { lat: 36.731921, lng: 30.396108 },
        { lat: 36.753035, lng: 30.391951 },
        { lat: 36.75778, lng: 30.389875 },
        { lat: 36.77557, lng: 30.373868 },
        { lat: 36.778416, lng: 30.372978 },
        { lat: 36.815851, lng: 30.371705 },
        { lat: 36.846557, lng: 30.363071 },
        { lat: 36.856317, lng: 30.363958 },
        { lat: 36.869887, lng: 30.368709 },
        { lat: 36.875126, lng: 30.376436 },
        { lat: 36.893218, lng: 30.377915 },
        { lat: 36.896551, lng: 30.3794 },
        { lat: 36.925313, lng: 30.404002 },
        { lat: 36.970165, lng: 30.48213 },
        { lat: 36.967775, lng: 30.486 },
        { lat: 36.967024, lng: 30.498425 },
        { lat: 36.960622, lng: 30.5044 },
        { lat: 36.957922, lng: 30.511674 },
        { lat: 36.959098, lng: 30.516374 },
        { lat: 36.950006, lng: 30.518012 },
        { lat: 36.946276, lng: 30.524529 },
        { lat: 36.94456, lng: 30.533428 },
        { lat: 36.945457, lng: 30.543032 },
        { lat: 36.942175, lng: 30.552928 },
        { lat: 36.946778, lng: 30.564073 },
        { lat: 36.945702, lng: 30.570345 },
        { lat: 36.936939, lng: 30.572597 },
        { lat: 36.935964, lng: 30.574523 },
        { lat: 36.931782, lng: 30.574576 },
        { lat: 36.930113, lng: 30.570445 },
        { lat: 36.925371, lng: 30.567912 },
        { lat: 36.918288, lng: 30.576313 },
        { lat: 36.898549, lng: 30.578517 },
        { lat: 36.896568, lng: 30.579399 },
        { lat: 36.893825, lng: 30.585037 },
        { lat: 36.894962, lng: 30.597224 },
        { lat: 36.892928, lng: 30.60275 },
        { lat: 36.897035, lng: 30.609059 },
        { lat: 36.90136, lng: 30.644849 },
        { lat: 36.901304, lng: 30.665327 },
        { lat: 36.897909, lng: 30.665544 }
    ];

    const polygonM = [
        { lat: 36.849429, lng: 30.836913 },
        { lat: 36.846331, lng: 30.805505 },
        { lat: 36.847618, lng: 30.801505 },
        { lat: 36.84539, lng: 30.799208 },
        { lat: 36.8475, lng: 30.799101 },
        { lat: 36.851073, lng: 30.795123 },
        { lat: 36.852817, lng: 30.789102 },
        { lat: 36.849653, lng: 30.782013 },
        { lat: 36.850295, lng: 30.779017 },
        { lat: 36.848535, lng: 30.776206 },
        { lat: 36.847031, lng: 30.761056 },
        { lat: 36.845395, lng: 30.757777 },
        { lat: 36.850959, lng: 30.746763 },
        { lat: 36.854847, lng: 30.744551 },
        { lat: 36.853516, lng: 30.74066 },
        { lat: 36.857802, lng: 30.730432 },
        { lat: 36.866219, lng: 30.723858 },
        { lat: 36.868099, lng: 30.718244 },
        { lat: 36.872996, lng: 30.715469 },
        { lat: 36.874751, lng: 30.707634 },
        { lat: 36.883709, lng: 30.701331 },
        { lat: 36.883995, lng: 30.703308 },
        { lat: 36.885146, lng: 30.702545 },
        { lat: 36.883554, lng: 30.692308 },
        { lat: 36.883983, lng: 30.679261 },
        { lat: 36.873609, lng: 30.658311 },
        { lat: 36.880669, lng: 30.653727 },
        { lat: 36.885188, lng: 30.66303 },
        { lat: 36.901304, lng: 30.665327 },
        { lat: 36.911934, lng: 30.701824 },
        { lat: 36.913519, lng: 30.721317 },
        { lat: 36.91123, lng: 30.737083 },
        { lat: 36.900112, lng: 30.763137 },
        { lat: 36.911915, lng: 30.773214 },
        { lat: 36.930861, lng: 30.806113 },
        { lat: 36.927964, lng: 30.808678 },
        { lat: 36.922627, lng: 30.808632 },
        { lat: 36.921549, lng: 30.815285 },
        { lat: 36.918785, lng: 30.817104 },
        { lat: 36.874991, lng: 30.811549 },
        { lat: 36.873005, lng: 30.81649 },
        { lat: 36.873745, lng: 30.818362 },
        { lat: 36.871052, lng: 30.819653 },
        { lat: 36.874205, lng: 30.822717 },
        { lat: 36.877028, lng: 30.831019 },
        { lat: 36.877576, lng: 30.835287 },
        { lat: 36.874976, lng: 30.835846 },
        { lat: 36.876435, lng: 30.839274 },
        { lat: 36.874099, lng: 30.836263 },
        { lat: 36.868609, lng: 30.833716 },
        { lat: 36.86498, lng: 30.835195 },
        { lat: 36.86606, lng: 30.840331 },
        { lat: 36.861679, lng: 30.84269 },
        { lat: 36.861545, lng: 30.848374 },
        { lat: 36.850543, lng: 30.8497 },
        { lat: 36.849429, lng: 30.836913 }
    ];

    const polygonK = [
        { lat: 37.154183, lng: 30.735808 },
        { lat: 37.119487, lng: 30.748729 },
        { lat: 37.110753, lng: 30.760439 },
        { lat: 37.033993, lng: 30.777317 },
        { lat: 36.995049, lng: 30.793589 },
        { lat: 36.989726, lng: 30.793589 },
        { lat: 36.958608, lng: 30.76647 },
        { lat: 36.95242, lng: 30.784337 },
        { lat: 36.93808, lng: 30.796696 },
        { lat: 36.935891, lng: 30.79177 },
        { lat: 36.930861, lng: 30.806113 },
        { lat: 36.911915, lng: 30.773214 },
        { lat: 36.900112, lng: 30.763137 },
        { lat: 36.91123, lng: 30.737083 },
        { lat: 36.913517, lng: 30.7208 },
        { lat: 36.911934, lng: 30.701824 },
        { lat: 36.901111, lng: 30.664604 },
        { lat: 36.90136, lng: 30.644849 },
        { lat: 36.897035, lng: 30.609059 },
        { lat: 36.892928, lng: 30.60275 },
        { lat: 36.894962, lng: 30.597224 },
        { lat: 36.893825, lng: 30.585037 },
        { lat: 36.896568, lng: 30.579399 },
        { lat: 36.919806, lng: 30.575446 },
        { lat: 36.924847, lng: 30.604942 },
        { lat: 36.941124, lng: 30.621929 },
        { lat: 36.938838, lng: 30.625983 },
        { lat: 36.939371, lng: 30.631445 },
        { lat: 36.944371, lng: 30.632287 },
        { lat: 36.945745, lng: 30.641768 },
        { lat: 36.951198, lng: 30.65098 },
        { lat: 36.96731, lng: 30.647326 },
        { lat: 37.049001, lng: 30.639269 },
        { lat: 37.116753, lng: 30.675795 },
        { lat: 37.125831, lng: 30.690168 },
        { lat: 37.133082, lng: 30.686259 },
        { lat: 37.141335, lng: 30.686067 },
        { lat: 37.153454, lng: 30.678489 },
        { lat: 37.157896, lng: 30.677582 },
        { lat: 37.161276, lng: 30.678966 },
        { lat: 37.160987, lng: 30.681265 },
        { lat: 37.162659, lng: 30.681282 },
        { lat: 37.165652, lng: 30.686359 },
        { lat: 37.169391, lng: 30.699895 },
        { lat: 37.163331, lng: 30.73 },
        { lat: 37.154183, lng: 30.735808 }
    ];

    const polygonA = [
        { lat: 36.943251, lng: 30.896767 },
        { lat: 36.911839, lng: 30.924042 },
        { lat: 36.883834, lng: 30.975303 },
        { lat: 36.879333, lng: 30.978679 },
        { lat: 36.875707, lng: 30.977428 },
        { lat: 36.870331, lng: 30.966301 },
        { lat: 36.856792, lng: 30.958711 },
        { lat: 36.853429, lng: 30.920586 },
        { lat: 36.852994, lng: 30.876977 },
        { lat: 36.850543, lng: 30.8497 },
        { lat: 36.861545, lng: 30.848374 },
        { lat: 36.861679, lng: 30.84269 },
        { lat: 36.86606, lng: 30.840331 },
        { lat: 36.86498, lng: 30.835195 },
        { lat: 36.868609, lng: 30.833716 },
        { lat: 36.874099, lng: 30.836263 },
        { lat: 36.876435, lng: 30.839274 },
        { lat: 36.874976, lng: 30.835846 },
        { lat: 36.877576, lng: 30.835287 },
        { lat: 36.877028, lng: 30.831019 },
        { lat: 36.874205, lng: 30.822717 },
        { lat: 36.871052, lng: 30.819653 },
        { lat: 36.873745, lng: 30.818362 },
        { lat: 36.873005, lng: 30.81649 },
        { lat: 36.874991, lng: 30.811549 },
        { lat: 36.919704, lng: 30.816986 },
        { lat: 36.921839, lng: 30.814461 },
        { lat: 36.922627, lng: 30.808632 },
        { lat: 36.927964, lng: 30.808678 },
        { lat: 36.930861, lng: 30.806113 },
        { lat: 36.935891, lng: 30.79177 },
        { lat: 36.93808, lng: 30.796696 },
        { lat: 36.95242, lng: 30.784337 },
        { lat: 36.958608, lng: 30.76647 },
        { lat: 36.989726, lng: 30.793589 },
        { lat: 36.995049, lng: 30.793589 },
        { lat: 37.033993, lng: 30.777317 },
        { lat: 37.110753, lng: 30.760439 },
        { lat: 37.119487, lng: 30.748729 },
        { lat: 37.154183, lng: 30.735808 },
        { lat: 37.163331, lng: 30.73 },
        { lat: 37.171805, lng: 30.727865 },
        { lat: 37.217309, lng: 30.723031 },
        { lat: 37.224702, lng: 30.729626 },
        { lat: 37.220224, lng: 30.754492 },
        { lat: 37.222507, lng: 30.765928 },
        { lat: 37.227453, lng: 30.776353 },
        { lat: 37.241873, lng: 30.79793 },
        { lat: 37.234864, lng: 30.833883 },
        { lat: 37.203218, lng: 30.825207 },
        { lat: 37.194872, lng: 30.81408 },
        { lat: 37.186344, lng: 30.81167 },
        { lat: 37.185349, lng: 30.809998 },
        { lat: 37.181079, lng: 30.816498 },
        { lat: 37.178121, lng: 30.814813 },
        { lat: 37.16822, lng: 30.81957 },
        { lat: 37.165984, lng: 30.817856 },
        { lat: 37.161273, lng: 30.819591 },
        { lat: 37.159137, lng: 30.818691 },
        { lat: 37.158024, lng: 30.822092 },
        { lat: 37.155516, lng: 30.820921 },
        { lat: 37.151741, lng: 30.821884 },
        { lat: 37.150109, lng: 30.824417 },
        { lat: 37.148219, lng: 30.823073 },
        { lat: 37.142829, lng: 30.827298 },
        { lat: 37.134721, lng: 30.839193 },
        { lat: 37.113662, lng: 30.854179 },
        { lat: 37.115727, lng: 30.856923 },
        { lat: 37.113976, lng: 30.865742 },
        { lat: 37.109577, lng: 30.867789 },
        { lat: 37.105714, lng: 30.872205 },
        { lat: 37.109479, lng: 30.876023 },
        { lat: 37.107117, lng: 30.881781 },
        { lat: 37.100116, lng: 30.883053 },
        { lat: 37.09702, lng: 30.88214 },
        { lat: 37.093781, lng: 30.883614 },
        { lat: 37.092264, lng: 30.888425 },
        { lat: 37.09257, lng: 30.895184 },
        { lat: 37.083368, lng: 30.900899 },
        { lat: 37.077894, lng: 30.897974 },
        { lat: 37.075157, lng: 30.893051 },
        { lat: 37.072815, lng: 30.892962 },
        { lat: 37.070965, lng: 30.899857 },
        { lat: 37.069326, lng: 30.901209 },
        { lat: 37.0628, lng: 30.901466 },
        { lat: 37.054007, lng: 30.912931 },
        { lat: 37.050264, lng: 30.913802 },
        { lat: 37.048278, lng: 30.920006 },
        { lat: 37.044809, lng: 30.918948 },
        { lat: 37.041132, lng: 30.913053 },
        { lat: 37.038828, lng: 30.911656 },
        { lat: 37.035758, lng: 30.912278 },
        { lat: 37.034788, lng: 30.909692 },
        { lat: 37.031621, lng: 30.908242 },
        { lat: 37.023334, lng: 30.912668 },
        { lat: 37.004767, lng: 30.917135 },
        { lat: 36.998822, lng: 30.916882 },
        { lat: 36.996652, lng: 30.913271 },
        { lat: 36.994865, lng: 30.913243 },
        { lat: 36.989973, lng: 30.915374 },
        { lat: 36.989558, lng: 30.919763 },
        { lat: 36.989046, lng: 30.918702 },
        { lat: 36.983946, lng: 30.919455 },
        { lat: 36.985204, lng: 30.928023 },
        { lat: 36.982593, lng: 30.930167 },
        { lat: 36.975806, lng: 30.919063 },
        { lat: 36.972005, lng: 30.920164 },
        { lat: 36.976161, lng: 30.914753 },
        { lat: 36.97501, lng: 30.912327 },
        { lat: 36.971902, lng: 30.914254 },
        { lat: 36.970722, lng: 30.913749 },
        { lat: 36.970912, lng: 30.910519 },
        { lat: 36.968087, lng: 30.911057 },
        { lat: 36.966073, lng: 30.908655 },
        { lat: 36.967587, lng: 30.907155 },
        { lat: 36.966747, lng: 30.904951 },
        { lat: 36.963511, lng: 30.90312 },
        { lat: 36.957808, lng: 30.904659 },
        { lat: 36.95137, lng: 30.902916 },
        { lat: 36.946962, lng: 30.901041 },
        { lat: 36.947118, lng: 30.899425 },
        { lat: 36.943251, lng: 30.896767 } // Adding the first point again to close the polygon
    ];
    
    const polygonD = [
        { lat: 37.163331, lng: 30.73 },
        { lat: 37.169391, lng: 30.699895 },
        { lat: 37.165652, lng: 30.686359 },
        { lat: 37.162659, lng: 30.681282 },
        { lat: 37.160987, lng: 30.681265 },
        { lat: 37.161276, lng: 30.678966 },
        { lat: 37.157896, lng: 30.677582 },
        { lat: 37.149185, lng: 30.680102 },
        { lat: 37.141335, lng: 30.686067 },
        { lat: 37.133082, lng: 30.686259 },
        { lat: 37.125831, lng: 30.690168 },
        { lat: 37.116753, lng: 30.675795 },
        { lat: 37.049001, lng: 30.639269 },
        { lat: 36.96731, lng: 30.647326 },
        { lat: 36.951198, lng: 30.65098 },
        { lat: 36.945745, lng: 30.641768 },
        { lat: 36.944371, lng: 30.632287 },
        { lat: 36.939371, lng: 30.631445 },
        { lat: 36.938838, lng: 30.625983 },
        { lat: 36.941124, lng: 30.621929 },
        { lat: 36.924847, lng: 30.604942 },
        { lat: 36.919806, lng: 30.575446 },
        { lat: 36.925371, lng: 30.567912 },
        { lat: 36.930113, lng: 30.570445 },
        { lat: 36.931782, lng: 30.574576 },
        { lat: 36.935964, lng: 30.574523 },
        { lat: 36.936939, lng: 30.572597 },
        { lat: 36.945702, lng: 30.570345 },
        { lat: 36.946778, lng: 30.564073 },
        { lat: 36.942175, lng: 30.552928 },
        { lat: 36.945457, lng: 30.543032 },
        { lat: 36.94456, lng: 30.533428 },
        { lat: 36.946276, lng: 30.524529 },
        { lat: 36.950006, lng: 30.518012 },
        { lat: 36.959098, lng: 30.516374 },
        { lat: 36.957922, lng: 30.511674 },
        { lat: 36.960622, lng: 30.5044 },
        { lat: 36.967024, lng: 30.498425 },
        { lat: 36.967775, lng: 30.486 },
        { lat: 36.970253, lng: 30.480482 },
        { lat: 36.985376, lng: 30.503923 },
        { lat: 36.990376, lng: 30.507489 },
        { lat: 37.004661, lng: 30.514023 },
        { lat: 37.020134, lng: 30.515503 },
        { lat: 37.03537, lng: 30.519064 },
        { lat: 37.05703, lng: 30.511622 },
        { lat: 37.064885, lng: 30.506863 },
        { lat: 37.073214, lng: 30.49913 },
        { lat: 37.087254, lng: 30.479801 },
        { lat: 37.097723, lng: 30.459581 },
        { lat: 37.118666, lng: 30.440249 },
        { lat: 37.123426, lng: 30.438761 },
        { lat: 37.136043, lng: 30.440539 },
        { lat: 37.147472, lng: 30.447669 },
        { lat: 37.186037, lng: 30.453598 },
        { lat: 37.216985, lng: 30.461612 },
        { lat: 37.241743, lng: 30.464277 },
        { lat: 37.257456, lng: 30.473189 },
        { lat: 37.249598, lng: 30.495028 },
        { lat: 37.241372, lng: 30.554774 },
        { lat: 37.233657, lng: 30.682299 },
        { lat: 37.224702, lng: 30.729626 },
        { lat: 37.217309, lng: 30.723031 },
        { lat: 37.171805, lng: 30.727865 },
        { lat: 37.163331, lng: 30.73 } // Adding the first point again to close the polygon
    ];
    

    const findCentroid = (polygon:any) => {
        let centroidLat = 0, centroidLng = 0;
        const n = polygon.length;
    
        for (let i = 0; i < n; i++) {
            const vertex1 = polygon[i];
            const vertex2 = polygon[(i + 1) % n]; // Sonraki köşe (döngüyü kapat)
    
            const crossProduct = (vertex1.lat * vertex2.lng - vertex2.lat * vertex1.lng);
            centroidLat += (vertex1.lat + vertex2.lat) * crossProduct;
            centroidLng += (vertex1.lng + vertex2.lng) * crossProduct;
        }
    
        const area = calculatePolygonArea(polygon); // Alan hesaplaması yapılıyor.
        centroidLat /= (6 * area);
        centroidLng /= (6 * area);
    
        return { lat: centroidLat, lng: centroidLng };
    };
    
    const calculatePolygonArea = (polygon:any) => {
        let area = 0;
        const n = polygon.length;
    
        for (let i = 0; i < n; i++) {
            const vertex1 = polygon[i];
            const vertex2 = polygon[(i + 1) % n]; // Sonraki köşe (döngüyü kapat)
    
            area += (vertex1.lat * vertex2.lng - vertex2.lat * vertex1.lng);
        }
    
        area = Math.abs(area) / 2;
        return area;
    };
    
    const findCentroid1 = (polygon:any) => {
        let centroidLat = 0, centroidLng = 0;
        for (let i = 0; i < polygon.length; i++) {
            centroidLat += polygon[i].lat;
            centroidLng += polygon[i].lng;
        }
        centroidLat /= polygon.length;
        centroidLng /= polygon.length;
        return { lat: centroidLat, lng: centroidLng };
    };

    const [show, setShow] = useState(false);
    const [showID, setShowID] = useState<any>(null);
    const [showFullName, setShowFullName] = useState('');

    return (
        <LoadScript
            googleMapsApiKey="AIzaSyA4ZrsurXwR2QD4Xx5-CpHPD4N3qvurBMo">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={coordinates}
                zoom={11}
            >
                {mapData.map(dt => {
                    if (dt) {
                        return (
                            <OverlayView
                                position={{ lat: dt.lat, lng: dt.lng }}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            >
                                <div title={dt?.fullName} onClick={e => { setShow(true); setShowID(dt?.id); setShowFullName(`${dt?.fullName}`); }}>
                                    <Popover content={
                                        (
                                            <div>
                                                <table className="table table-hover table-striped">
                                                    <thead>
                                                        <tr className="table-secondary">
                                                            <th colSpan={2}>İŞLEM BİLGİLERİ</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr>
                                                            <th>{dt?.giro_type_id === 116 ? 'SATICI HİZMET BEDELİ' : 'KİRALAMA HİZMET BEDELİ'}</th>
                                                            <td>
                                                                <strong>
                                                                    <NumericFormat
                                                                        value={dt?.seller_service_fee.toString().replace('.', ',')}
                                                                        thousandSeparator="."
                                                                        decimalSeparator=","
                                                                        displayType="text"
                                                                    /> TL
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                        {dt?.giro_type_id === 116 ?
                                                            <tr>
                                                                <th>ALICI HİZMET BEDELİ</th>
                                                                <td>
                                                                    <strong>
                                                                        <NumericFormat
                                                                            value={dt?.buyer_service_fee.toString().replace('.', ',')}
                                                                            thousandSeparator="."
                                                                            decimalSeparator=","
                                                                            displayType="text"
                                                                        /> TL
                                                                    </strong>
                                                                </td>
                                                            </tr> : null}
                                                        {parseFloat(dt?.office_referral_fee) > 0 ?
                                                            <tr>
                                                                <th>OFİS İLE PAYLAŞIM BEDELİ</th>
                                                                <td>
                                                                    <strong>
                                                                        <NumericFormat
                                                                            value={dt?.office_referral_fee.toString().replace('.', ',')}
                                                                            thousandSeparator="."
                                                                            decimalSeparator=","
                                                                            displayType="text"
                                                                        /> TL
                                                                    </strong>
                                                                </td>
                                                            </tr> : null}
                                                        {dt?.other_realtor_id > 0 ?
                                                            <>
                                                                <tr>
                                                                    <th>DİĞER EMLAKÇI</th>
                                                                    <td>
                                                                        {dt?.realtor_full_name}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>OFİS DIŞI PAYLAŞIM BEDELİ</th>
                                                                    <td>
                                                                        <strong>
                                                                            <NumericFormat
                                                                                value={dt?.out_office_referral_fee.toString().replace('.', ',')}
                                                                                thousandSeparator="."
                                                                                decimalSeparator=","
                                                                                displayType="text"
                                                                            /> TL
                                                                        </strong>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            : null}
                                                        <tr>
                                                            <th>TOPLAM HİZMET BEDELİ</th>
                                                            <td>
                                                                <strong>
                                                                    <NumericFormat
                                                                        value={dt?.total_service_fee.toString().replace('.', ',')}
                                                                        thousandSeparator="."
                                                                        decimalSeparator=","
                                                                        displayType="text"
                                                                    /> TL
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>G.D. HİZMET BEDELİ (%{dt?.agent_percent})</th>
                                                            <td>
                                                                <strong>
                                                                    <NumericFormat
                                                                        value={dt?.agent_service_fee.toString().replace('.', ',')}
                                                                        thousandSeparator="."
                                                                        decimalSeparator=","
                                                                        displayType="text"
                                                                    /> TL
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                        {dt?.agent_team_leader_id > 0 ?
                                                            <tr>
                                                                <th>T. LİDERİ HİZMET BEDELİ (%{dt?.agent_team_leader_percent})</th>
                                                                <td>
                                                                    <strong>
                                                                        <NumericFormat
                                                                            value={dt?.team_leader_service_fee?.toString().replace('.', ',')}
                                                                            thousandSeparator="."
                                                                            decimalSeparator=","
                                                                            displayType="text"
                                                                        /> TL
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                            : null}
                                                        <tr>
                                                            <th>OFİS HİZMET BEDELİ</th>
                                                            <td>
                                                                <strong>
                                                                    <NumericFormat
                                                                        value={dt?.office_service_fee.toString().replace('.', ',')}
                                                                        thousandSeparator="."
                                                                        decimalSeparator=","
                                                                        displayType="text"
                                                                    /> TL
                                                                </strong>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    } title={dt?.fullName} >
                                        <div style={{ position: 'absolute', marginLeft: "10px", marginTop: "-30px" }}>
                                            <ProfilePhoto id={dt?.id} imgHeight={30} />
                                        </div>
                                        <Tag title={dt?.fullName} color={dt?.color}> {dt?.markerIcon == 'house' ? <BsHouse /> : null}{dt?.markerIcon == 'commercial' ? <AiFillShop /> : null} {dt?.markerIcon == 'field' ? <BsTextarea /> : null} {dt?.markerIcon == 'plot' ? <RiLayoutMasonryLine /> : null}   {dt?.content}</Tag>
                                        <AiFillCaretDown style={{ position: 'absolute', marginLeft: "-35px", marginTop: "18px" }} />
                                    </Popover>
                                </div>
                            </OverlayView>

                        );
                    } else {
                        return null;
                    }
                })}
                <Polygon paths={polygonKN} options={{ fillColor: "blue", fillOpacity: 0.1, strokeColor: "blue", strokeOpacity: 0.8, strokeWeight: 2 }} />
                <Polygon paths={polygonM} options={{ fillColor: "red", fillOpacity: 0.1, strokeColor: "red", strokeOpacity: 0.8, strokeWeight: 2 }} />
                <Polygon paths={polygonK} options={{ fillColor: "green", fillOpacity: 0.1, strokeColor: "green", strokeOpacity: 0.8, strokeWeight: 2 }} />
                <Polygon paths={polygonA} options={{ fillColor: "brown", fillOpacity: 0.1, strokeColor: "brown", strokeOpacity: 0.8, strokeWeight: 2 }} />
                <Polygon paths={polygonD} options={{ fillColor: "orange", fillOpacity: 0.2, strokeColor: "orange", strokeOpacity: 0.8, strokeWeight: 2 }} />


            </GoogleMap>
        </LoadScript>

    );
};

export default TransactionMap;
