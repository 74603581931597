import { Link } from "react-router-dom";
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { AiOutlineUserSwitch } from 'react-icons/ai';
import { BiMapPin } from 'react-icons/bi';

const GiroMenu = ({ type }: any) => {
  return (
    <div className="btn-group " role="group" aria-label="Giro Menu Group">
      <Link to="/report/giros" className={type==='giros'?'btn btn-primary waves-effect waves-light':'btn btn-soft-primary waves-effect waves-light'}> <CgArrowsExchangeAlt size={'1.6em'}/> <span className="d-none d-lg-inline">Genel Ciro</span></Link>
      <Link to="/report/ranking-agents" className={type==='ranking-agents'?'btn btn-primary waves-effect waves-light':'btn btn-soft-primary waves-effect waves-light'}> <AiOutlineUserSwitch size={'1.6em'}/> <span className="d-none d-lg-inline">Danışman Bazlı Ciro</span> </Link>
      <Link to="/report/ranking-locations" className={type==='ranking-locations'?'btn btn-primary waves-effect waves-light':'btn btn-soft-primary waves-effect waves-light'}> <BiMapPin size={'1.6em'}/> <span className="d-none d-lg-inline">Bölge Bazlı Ciro</span> </Link>
      <button type="button" className="btn btn-soft-primary waves-effect waves-light d-none d-lg-inline">Tapu Dairesi Bazlı Ciro</button>
      <button type="button" className="btn btn-soft-primary waves-effect waves-light d-none d-lg-inline">Site Bazlı Ciro</button>
      <button type="button" className="btn btn-soft-primary waves-effect waves-light d-none d-lg-inline">Diğer Emlakçı Bazlı Ciro</button>
    </div>
  );
}

export default GiroMenu;

