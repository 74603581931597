
const Tel = ({ phone, ...props }: any) => {
  return (
    <a href={`tel:${phone}`}>
      {props.children}
    </a>
  );
}

export default Tel;

