import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink, Navigate } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import Swal from 'sweetalert2';
import { DatePicker, Select, Table, Tag } from 'antd';

import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import { useUserContext } from "../../../providers/user/userProvider";
import { Category } from "../../../models/user/category";
import { User } from "../../../models/user";
import { useStatusContext } from "../../../providers/attribute/status/statusProvider";
import { Attribute } from "../../../models/attribute/attribute";

//Table
import type { ColumnsType, TableProps } from 'antd/es/table';
import { NumericFormat } from "react-number-format";
import { ThumbsDown } from "react-feather";
import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import Breadcrumb from "../../../components/constant/breadcrumb";
import { useExpenseCategoryContext } from "../../../providers/attribute/expenseCategory/expenseCategoryProvider";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { PropertyGenus } from "../../../models/attribute/propertyGenus/propertyGenus";
import AntDesignRangePicker from "../../../components/constant/antDesign/rangePicker";


const { Option, OptGroup } = Select;
const { Column } = Table;

dayjs.locale("tr");
const { RangePicker } = DatePicker;
const rangePresets: any = {
    'Son 7 Gün': [dayjs().subtract(7, 'days'), dayjs()],
    'Bu Ay': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Geçen Ay': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Bu Yıl': [dayjs().startOf('year'), dayjs()],
    'Geçen Yıl': [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
    '1. Çeyrek': [dayjs().startOf('year').add(0, 'month').startOf('month'), dayjs().startOf('year').add(4, 'month').endOf('month')],
    '2. Çeyrek': [dayjs().startOf('year').add(3, 'month').startOf('month'), dayjs().startOf('year').add(5, 'month').endOf('month')],
    '3. Çeyrek': [dayjs().startOf('year').add(6, 'month').startOf('month'), dayjs().startOf('year').add(8, 'month').endOf('month')],
    '4. Çeyrek': [dayjs().startOf('year').add(9, 'month').startOf('month'), dayjs().startOf('year').add(11, 'month').endOf('month')]
};

const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
for (let i = 0; i < 12; i++) {
    rangePresets[monthNames[i]] = [dayjs().month(i).startOf('month'), dayjs().month(i).endOf('month')];
}


const expandedRowData = [
    { key: 1, name: 'Ofis Payı Geliri', value: 10, percentage: '16%' },
    { key: 2, name: 'Ref. Paylaşım Geliri', value: 10, percentage: '16%' },
    { key: 3, name: 'G.D. Masa Geliri', value: 10, percentage: '16%' },
];


const expandedRowRender = (record: any) => {
    const expandedColumns = [
        { title: 'Başlık', dataIndex: 'name', key: 'name' },
        { title: 'Fiyat', dataIndex: 'value', key: 'value' },
        { title: 'Oran', dataIndex: 'percentage', key: 'percentage' },
    ];

    return (
        <Table
            dataSource={expandedRowData}
            columns={expandedColumns}
            pagination={false}

        />
    );
};

const RevenueExpense = () => {

    //Table 
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState<any | null>(null);
    const [pagination, setPagination] = useState<any | null>({
        current: 1,
        pageSize: 100,
        currentPageSize: 100,
        total: reports?.total,
        pageSizeOptions: [10, 20, 50, 100, 500, 100],
        showQuickJumper: true,
        showSizeChanger: true,
        showLessItems: true,
        responsive: true
    });
    const [filter_url, setFilterUrl] = useState<any | null>('');
    const [sort, setSort] = useState(false);
    const [sort_url, setSortUrl] = useState<any | null>('&sort[order]=desc');
    const [sort_req, setSortReq] = useState<any | null>([]);

    //For filter
    const [selectedDateName, setSelectedDateName] = useState<string>('Bu Ay');
    const [date_range, setDateRange] = useState<any | null>([]);
    const [start_date, setStartDate] = useState<any | null>(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [end_date, setEndDate] = useState<any | null>(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [send_filter, setSendFilter] = useState(false);
    const [category_id, setCategoryId] = useState<any | null>(null);
    const [status_id, setStatusId] = useState<any | null>(null);
    const [filter, setFilter] = useState(false);
    const [clear_filter_inputs, setClearFilterInputs] = useState(false);

    const [users, setUsers] = useUserContext();
    const [expense_categories, setExpenseCategories] = useExpenseCategoryContext();
    const [statuses, setStatuses] = useStatusContext();
    const [selected_categories, setSelectedCategories] = useState([]);
    const [redirect_id, setRedirectId] = useState(0);
    const [office_service_detail_see, setOfficeServiceDetailSee] = useState(false);
    const [office_ref_detail_see, setOfficeRefDetailSee] = useState(false);
    //const [expense_detail_see, setExpenseDetailSee] = useState(false);



    useEffect(() => {
        let fUrl = '';
        if (parseInt(status_id) > 0) {
            fUrl = `&filter_status_id=${status_id}`;
        }

        if (selected_categories?.length > 0) {
            selected_categories.forEach((category_id: number) => {
                fUrl = `${fUrl}&filter_category_id[]=${category_id}`;
            });
        }

        if (category_id > 0) {
            fUrl = `${fUrl}&filter_user_category=${category_id}`;
        }

        setFilterUrl(fUrl);
        setFilter(false);
        setSendFilter(true);
    }, [filter]);

    useEffect(() => {
        (
            async () => {
                if (send_filter) {
                    setLoading(true);
                    axios.get(`revenue-expense?filter_between[]=${start_date}&filter_between[]=${end_date}&filter_type=149`)
                        .then(({ data }) => {
                            setData(data.revenue_expense.report.data);
                            setLoading(false);
                        });
                    setSendFilter(false);
                }
            }
        )()
    }, [send_filter]);
    const [expenseDetailSee, setExpenseDetailSee] = useState<boolean[]>(Array(data?.[0]?.expenses?.types?.length ?? 0).fill(false));

    const toggleExpenseDetailSee = (index: number) => {
        const updatedExpenseDetailSee = [...expenseDetailSee];
        updatedExpenseDetailSee[index] = !updatedExpenseDetailSee[index];
        setExpenseDetailSee(updatedExpenseDetailSee);
    };

    useEffect(() => {
        if (clear_filter_inputs) {
            setSelectedCategories([]);
            setDateRange([]);
            setStatusId(null);
            setClearFilterInputs(false);
            setFilter(true);
        }
    }, [clear_filter_inputs]);

    const handleRowDoubleClick = (id: any) => {
        setRedirectId(id);
    }

    const del = async (id: number) => {

        Swal.fire({
            title: 'Emin misiniz?',
            text: "Kalıcı olarak silinecektir",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c84ee',
            cancelButtonColor: '#fd625e',
            confirmButtonText: 'Evet, sil!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.delete(`cashbooks/${id}`);
                setData(data.filter((u: any) => u.id !== id));
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Veri silindi',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }
 //Table
 interface DataType {
    key: number;
    name: string;
    value: string;
    percent: string;
  }
//Table
const columns: ColumnsType<DataType> = [
    {
        title: '',
        dataIndex: 'name'
    },
    {
        title: '',
        dataIndex: 'value',
        className: 'text-end',
        sorter: true,
        render: (value) => {
            return (
                <>
                    <strong style={{ whiteSpace: "nowrap"}} >
                        <NumericFormat
                            value={value?.toString().replace('.', ',')}
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                        /> ₺
                    </strong>
                </>
            );
        },
    },
    {
        title: '',
        dataIndex: 'percent'
    },
];


    const datas = [
        { key: 1, name: 'Gelirler Toplamı', value: data?.revenues?.total_revenues, percent: `%${data?.revenues?.total_revenues_percent > 0 ? data?.revenues?.total_revenues_percent : 0}` },
        { key: 2, name: 'Gider Toplamı', value: data?.expenses?.total_expenses, percent: `%${data?.expenses?.total_expenses_percent > 0 ? data?.expenses?.total_expenses_percent : 0}` },
        { key: 3, name: 'Gelir-Gider Farkı', value: data?.profit?.total_profit, percent: '' },
        { key: 4, name: 'Kurumlar Vergisi', value: data?.profit?.corporation_tax?.price, percent: data?.profit?.corporation_tax?.percent },
        { key: 5, name: 'Net Kazanç', value: data?.profit?.corporation_tax?.net_income, percent: '' },
    ];


    if (redirect_id > 0) {
        return <Navigate to={`/accounting/RevenueExpense/${redirect_id}/edit`} />;
    }

    const items: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Gelir-Gider Raporları', active: true },
    ];

    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Gelir-Gider Raporları</h4>
                        <div className="page-title-right">
                            <Breadcrumb items={items} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-2 col-sm-6 pt-2">
                                    <div className="form-group select-style">
                                    <label className="d-block">Tarih Aralığı  ( {selectedDateName} )</label>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 mb-2">
                                    <div className="form-group select-style">
                                        {/* <RangePicker
                                            format={'DD/MM/YYYY'}
                                            locale={locale}
                                            ranges={rangePresets}
                                            value={date_range}
                                            style={{ width: '100%' }}
                                            onChange={(value, dateString) => {
                                                setDateRange(value);
                                                setStartDate(dayjs(dateString.toString().split(',')[0], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setEndDate(dayjs(dateString.toString().split(',')[1], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setSendFilter(true);
                                            }
                                            }
                                        /> */}
                                            <AntDesignRangePicker
                                        dateRange={date_range}
                                        setDateRange={setDateRange}
                                        setStartDate={setStartDate}
                                        setEndDate={setEndDate}
                                        setSendFilter={setSendFilter}
                                        setSelectedDateName={setSelectedDateName}
                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <Table
                                dataSource={datas}
                                pagination={false}
                                columns={columns}
                                expandable={{
                                    expandedRowRender: (record:any) => {
                                        if (record.key == 1) {
                                            return (
                                                <>
                                                    <div className="table-reponsive" style={{paddingLeft:"55px"}}>
                                                        <table className={`table table-striped table-hover`}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div className="float-start" style={{ marginTop: "-2px", marginLeft: "-35px", cursor: "pointer" }} onClick={e => { office_service_detail_see ? setOfficeServiceDetailSee(false) : setOfficeServiceDetailSee(true); }}>
                                                                            <DownOutlined style={office_service_detail_see ? { display: 'none' } : {}} />
                                                                            <UpOutlined style={office_service_detail_see ? {} : { display: 'none' }} />
                                                                        </div> Ofis Payı Geliri</td>
                                                                    <td>
                                                                        <div  style={{ whiteSpace: "nowrap"}}>
                                                                        <NumericFormat
                                                                            value={data?.revenues?.total_office_giro.toString().replace('.', ',')}
                                                                            thousandSeparator="."
                                                                            decimalSeparator=","
                                                                            displayType="text"
                                                                        /> ₺
                                                                        </div>

                                                                    </td>
                                                                    <td>%{data?.revenues?.total_office_giro_percent}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={4} style={office_service_detail_see ? {} : { display: 'none' }}>
                                                                        <div className="card">
                                                                            <div className="card-body">

                                                                                <table className="table mb-0 table-hover">
                                                                                    <tbody>
                                                                                        {
                                                                                        data?.revenues?.office_service_fee?.types?.map((type:any, index:any) => (
                                                                                            type?.property_genuses?.map((property_genus: any) => {
                                                                                                return (
                                                                                                    <tr>
                                                                                                        <th scope="row">{property_genus?.name}  {type?.name}</th>
                                                                                                        <td>
                                                                                                        <div  style={{ whiteSpace: "nowrap"}}>
                                                                                                            <NumericFormat
                                                                                                                value={property_genus?.price.toString().replace('.', ',')}
                                                                                                                thousandSeparator="."
                                                                                                                decimalSeparator=","
                                                                                                                displayType="text"
                                                                                                            /> ₺
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>%{property_genus?.percent}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                                
                                                                                        }) )) }
                                                                                    </tbody>
                                                                                </table>

                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className="float-start" style={{ marginTop: "-2px", marginLeft: "-35px", cursor: "pointer" }} onClick={e => { office_ref_detail_see ? setOfficeRefDetailSee(false) : setOfficeRefDetailSee(true); }}>
                                                                            <DownOutlined style={office_ref_detail_see ? { display: 'none' } : {}} />
                                                                            <UpOutlined style={office_ref_detail_see ? {} : { display: 'none' }} />
                                                                        </div>
                                                                        Ref. Paylaşım Geliri</td>
                                                                    <td>
                                                                    <div  style={{ whiteSpace: "nowrap"}}>
                                                                        <NumericFormat
                                                                            value={data?.revenues?.total_office_referral_fee.toString().replace('.', ',')}
                                                                            thousandSeparator="."
                                                                            decimalSeparator=","
                                                                            displayType="text"
                                                                        /> ₺
                                                                        </div>
                                                                    </td>
                                                                    <td>%{data?.revenues?.total_office_referral_fee_percent}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={4} style={office_ref_detail_see ? {} : { display: 'none' }}>
                                                                        <div className="card">
                                                                            <div className="card-body">

                                                                                <table className="table mb-0 table-hover">
                                                                                    <tbody>
                                                                                        {
                                                                                        data?.revenues?.office_ref_fee?.types?.map((type:any, index:any) => (
                                                                                            type?.property_genuses_for_ref?.map((property_genus: any) => {
                                                                                                return (
                                                                                                    <tr>
                                                                                                        <th scope="row">{property_genus?.name}  {type?.name}</th>
                                                                                                        <td>
                                                                                                        <div  style={{ whiteSpace: "nowrap"}}>
                                                                                                            <NumericFormat
                                                                                                                value={property_genus?.price.toString().replace('.', ',')}
                                                                                                                thousandSeparator="."
                                                                                                                decimalSeparator=","
                                                                                                                displayType="text"
                                                                                                            /> ₺
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>%{property_genus?.percent}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                                
                                                                                        }) )) }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>G.D. Masa Geliri</td>
                                                                    <td>
                                                                    <div  style={{ whiteSpace: "nowrap"}}>
                                                                        <NumericFormat
                                                                            value={data?.revenues?.total_table_revenues.toString().replace('.', ',')}
                                                                            thousandSeparator="."
                                                                            decimalSeparator=","
                                                                            displayType="text"
                                                                        /> ₺
                                                                        </div>
                                                                    </td>
                                                                    <td>%{data?.revenues?.total_table_revenues_percent}</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                            )
                                        }
                                        if (record.key == 2) {
                                            return (
                                                <>
                                                    <div className="table-reponsive table-striped table-hover" style={{paddingLeft:"55px"}}>
                                                        <table className={`table`}>
                                                            <tbody>
                                                            {data?.expenses?.types?.map((type: any, index: number) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <th scope="row">
                                                                                    <div
                                                                                        className="float-start"
                                                                                        style={{ marginTop: '-2px', marginLeft: '-35px', cursor: 'pointer' }}
                                                                                        onClick={() => toggleExpenseDetailSee(index)}
                                                                                    >
                                                                                        {expenseDetailSee[index] ? (
                                                                                            <UpOutlined />
                                                                                        ) : (
                                                                                            <DownOutlined />
                                                                                        )}
                                                                                    </div>
                                                                                    {type?.name}
                                                                                </th>
                                                                                <td>
                                                                                <div  style={{ whiteSpace: "nowrap"}}>
                                                                                    <NumericFormat
                                                                                        value={type?.price.toString().replace('.', ',')}
                                                                                        thousandSeparator="."
                                                                                        decimalSeparator=","
                                                                                        displayType="text"
                                                                                    /> ₺
                                                                                    </div>
                                                                                </td>
                                                                                <td>%{type?.percent}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={4} style={expenseDetailSee[index] ? {} : { display: 'none' }}>
                                                                                    <div className="card">
                                                                                        <div className="card-body">

                                                                                            <table className="table mb-0 table-hover">
                                                                                                <tbody>
                                                                                                {type?.sub_categories?.map((sub_category: any) => {
                                                                                                        return (
                                                                                                            <tr>
                                                                                                                <th scope="row">{sub_category?.name}</th>
                                                                                                                <td>
                                                                                                                <div  style={{ whiteSpace: "nowrap"}}>
                                                                                                                    <NumericFormat
                                                                                                                        value={sub_category?.price.toString().replace('.', ',')}
                                                                                                                        thousandSeparator="."
                                                                                                                        decimalSeparator=","
                                                                                                                        displayType="text"
                                                                                                                    /> ₺
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td>%{sub_category?.percent}</td>
                                                                                                            </tr>
                                                                                                        )
                                                                                                    })}
                                                                                                </tbody>
                                                                                            </table>

                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                            )
                                        }
                                    },
                                    rowExpandable: (record:any) => record?.key <3,
                                }}
                                showHeader={false}
                            >
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default RevenueExpense;