import React, { useEffect, useState } from "react";
import { requestNotificationPermission, onMessageListener } from "./firebase";

const FirebaseNotificationComponent: React.FC = () => {
  const [notificationSendStatus, setNotificationSendStatus] = useState(false);
  const [notification, setNotification] = useState<{ title: string; body: string } | null>(null);

  // Kullanıcıdan bildirim izni iste ve FCM token'ı al
  useEffect(() => {
    requestNotificationPermission(notificationSendStatus, setNotificationSendStatus);
  }, [notificationSendStatus]);

  // Uygulama açıkken gelen mesajları dinle
  useEffect(() => {
    onMessageListener().then((payload: any) => {
      setNotification({
        title: payload.notification?.title || "",
        body: payload.notification?.body || "",
      });
    }).catch((err) => console.log("Mesaj alınamadı:", err));
  }, []);

  return (
    <div>
      {notification && (
        <div>
          <h4>{notification.title}</h4>
          <p>{notification.body}</p>
        </div>
      )}
    </div>
  );
};

export default FirebaseNotificationComponent;
