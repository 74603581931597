import React, { useEffect, useState } from 'react';
import RecruitmentMeetCountApexDonutChart from '../recruitment/recruitmentMeetCountApexDonutChart';
import WatchScheduleActionCountApexDonutChart from '../asistant/watchScheduleActionCountApexDonutChart';
import TransactionTypesCountApexDonutChart from '../transaction/transactionTypesCountApexDonutChart';
import CallAreaChart from '../call/callAreaChart';
import SatisfactionSurveyGaugeChart from '../customer/satisfactionSurveyGaugeChart';
import GiroList from '../giro/list';
import PurchaseSaleAgreementList from '../contract/purchase-sale-agreement/list';
import LeaseAgreementList from '../contract/lease-agreement/list';
import LazyLoad from 'react-lazyload';
import WatchSchedulesCarousel from '../asistant/watchSchedulesCarousel';
import SatisfactionSurveyCarousel from '../customer/satisfactionSurveyCarousel';
import WorkSchedulesCarousel from '../employee/workSchedulesCarousel';
import AgentGiroList from '../agent/list';
import TransactionMap from '../map/transactionMap';
import TransactionApexLineChart from '../transaction/transactionApexLineChart';
import { Modal, Button } from "react-bootstrap";
import ProfilePhoto from '../../user/profilePhoto';
import { Link } from "react-router-dom";
import TransactionDetail from '../../transaction/detail';
import moment from 'moment';
import AgentApexChart from '../agent/agentApexChart';
import AgentPerformanceCarouselCard from '../agent/agentPerformanceCard';

const AdminDashboard = ({ selectedDateName, start_date,end_date,send_filter }: any) => {

  const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

  const startOfWeek = moment().startOf('isoWeek').format('YYYY-MM-DD');
  const endOfWeek = moment().endOf('isoWeek').format('YYYY-MM-DD');

  const startOfYear = moment().startOf('year').format('YYYY-MM-DD');
  const endOfYear = moment().endOf('year').format('YYYY-MM-DD');

  const [show_id, setShowID] = useState<number>(0);
  const [show_full_name, setShowFullName] = useState<any | null>('');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const modalLoaded = () => {
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <RecruitmentMeetCountApexDonutChart start_date={start_date} end_date={end_date} dateTypeName={selectedDateName} sendFilter={send_filter} />
        </div>
        <div className="col-xl-3 col-md-6">
          <WatchScheduleActionCountApexDonutChart start_date={start_date} end_date={end_date} dateTypeName={selectedDateName} sendFilter={send_filter} />
        </div>
        <TransactionTypesCountApexDonutChart start_date={start_date} end_date={end_date} dateTypeName={selectedDateName} sendFilter={send_filter} />

      </div>
      <div className="row">
        {/* <div className="col-xl-9">
          <div className="card pb-0">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Anlık Arama Raporları</h4>
            </div>
            <div className="card-body px-3">
              <CallAreaChart />
            </div>
          </div>
        </div> */}
        <div className="col-xl-9">
            <div className="card">
              <AgentPerformanceCarouselCard start_date={start_date} end_date={end_date} dateTypeName={selectedDateName} sendFilter={send_filter} />
            </div>
          </div>
        <div className="col-xl-3">
          <SatisfactionSurveyGaugeChart start_date={start_date} end_date={end_date} sendFilter={send_filter} dateTypeName={selectedDateName} />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-4">
          <div className="card  pb-0">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Son İşlemler</h4>
            </div>

            <div className="card-body px-3">
              <GiroList start_date={startOfMonth} end_date={endOfMonth} />
            </div>
          </div>
        </div>

        <div className="col-xl-4">
          <div className="card  pb-0">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Alım Satım Protokolleri</h4>
            </div>

            <div className="card-body px-3">
              <PurchaseSaleAgreementList start_date={start_date} end_date={end_date} />
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="card  pb-0">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Kira Kontratları</h4>
            </div>

            <div className="card-body px-3">
              <LeaseAgreementList start_date={start_date} end_date={end_date} />
            </div>
          </div>
        </div>
      </div>
      <LazyLoad height={200} offset={100}>
        <div className="row">
          <div className="col-xl-4">
            <div className="card">
              <WatchSchedulesCarousel />
            </div>
          </div>
          <div className="col-xl-4">
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Müşteri Yorumları</h4>
              </div>
              <div className="card-body">
                <SatisfactionSurveyCarousel />
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Personel Aktiviteleri</h4>
              </div>
              <div className="card-body">
                <WorkSchedulesCarousel />
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} offset={100}>
        <div className="row">
          <div className="col-xs-12">
            <div className="card">
              <div className="card-body px-3 pb-0">
                <AgentApexChart start_date={startOfMonth} end_date={endOfMonth} />
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} offset={100}>
        <div className="row">
          <div className="col-xl-4">
            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Haftanın Danışmanları</h4>
              </div>

              <div className="card-body px-3 pb-0">
                <AgentGiroList start_date={startOfWeek} end_date={endOfWeek} />
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="card  pb-0">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Ayın Danışmanları</h4>
              </div>

              <div className="card-body px-3 pb-0">
                <AgentGiroList start_date={startOfMonth} end_date={endOfMonth} />
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="card  pb-0">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Yılın Danışmanları</h4>
              </div>

              <div className="card-body px-3 pb-0">
                <AgentGiroList start_date={startOfYear} end_date={endOfYear} />
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} offset={100}>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex flex-wrap align-items-center mb-2">
                  <h5 className="card-title me-2">Anlık İşlem Haritası</h5>
                  <div className="ms-auto">
                    <div>
                    </div>
                  </div>
                </div>

                <div className="row align-items-center">
                  <div className="col-xl-12">
                    <TransactionMap start_date={startOfMonth} end_date={endOfMonth} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
      <LazyLoad height={200} offset={100}>
        <div className="row">
          <div className="col-xl-12">
            <TransactionApexLineChart />
          </div>
        </div>
      </LazyLoad>

      <Modal show={show} onHide={handleClose} onEntered={modalLoaded} size="xl" animation={false}>
        {show_id && show_id !== null && show_id !== undefined &&
          <>
            <Modal.Header closeButton>
              <Modal.Title><ProfilePhoto id={show_id} imgHeight={35} /> <span className="ms-2">{show_full_name}</span> - <span className="text-danger">{show_id}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TransactionDetail id={show_id} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Kapat
              </Button>
              <Link className="btn btn-primary" to={`/accounting/transactions/${show_id}/edit`}>
                Güncelle
              </Link>
            </Modal.Footer>
          </>
        }
      </Modal>
    </>
  );
};

export default AdminDashboard;
