import axios from 'axios';
import React, { useEffect, useState } from 'react';
import GaugeChart from 'react-gauge-chart'
import { Attribute } from '../../../models/attribute/attribute';
import { Rate } from 'antd';
import { NumericFormat } from 'react-number-format';
import CountUp from 'react-countup';


const SatisfactionSurveyGaugeChart = ({ start_date, end_date, sendFilter, dateTypeName }: any) => {
    const chartStyle = {
        height: 130,
    }
    const [report, setReport] = useState<any | null>([]);
    const [score_names, setScoreNames] = useState<any>();

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`attributes/46`);
                const updatedDesc = data?.data?.map((item: Attribute) => item.name);
                setScoreNames(updatedDesc);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                axios.get(`dashboard/satisfaction-survey-report?filter_between[]=${start_date}&filter_between[]=${end_date}`)
                    .then(({ data }) => {
                        setReport(data?.satisfaction_survey?.report);

                    });
            }
        )()
    }, [start_date, end_date, sendFilter]);

    return (
        <>
            <div className="card pb-0">
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Müşteri Memnuniyet Oranı</h4>
                </div>
                <div className="card-body px-3">
                    <GaugeChart
                        style={chartStyle}
                        nrOfLevels={20}
                        percent={report.avg_rate?parseFloat(report?.avg_rate) / 5:0}
                        textColor="#495057"
                    />
                </div>
            </div>
            <div className="card pb-0">
                <div className="card-body px-3">
                    {report?.round_avg_rate && score_names ? (
                        <>
                            <Rate allowHalf disabled tooltips={score_names} value={report?.round_avg_rate} />
                            <span className="ant-rate-text ms-2">
                                {score_names[report?.round_avg_rate - 1]}
                            </span>
                        </>
                    ) : null}

                    <strong className="m-3">
                        <NumericFormat
                            value={report?.avg_rate?.toString().replace('.', ',')}
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                        />

                    </strong>
                    <hr />
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="flex-grow-1 text-start">
                            Toplam Yorum
                        </div>
                        <div className="flex-grow-1 text-center">
                            <strong className="ms-2">
                                <CountUp end={report?.total} />
                            </strong>
                        </div>
                        <div className="flex-grow-1 text-end">
                            {dateTypeName}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SatisfactionSurveyGaugeChart;
