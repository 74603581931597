export class Category {
    Category: any;
    constructor(
        public id = 0,
        public name = '',
        public percent = '',
        public color = '',
        public icon = '',
        public speacial = 0
    ) {        
    }
}