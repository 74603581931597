import React from "react";

const CustomInput = ({ type = "text", placeholder = null, value = null, defaultValue =null, e, autoComplete = 'off', inputClass = 'form-control', required = false, rows = null, onChange = () => { }, onBlur = () => { },
}: any) => {
    if (type === 'textarea') {
        return (
            <textarea
                autoComplete={autoComplete}
                rows={rows}
                required={required}
                className={inputClass}
                onChange={onChange} 
                placeholder={placeholder}
                value={value} >
                {value}
            </textarea>
        )
    }
    else {
        return (
            <>
                <input
                    autoComplete={autoComplete}
                    type={type}
                    className={inputClass}
                    placeholder={placeholder}
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    onBlur={onBlur}
                    required={required}
                />
            </>
        )

    }
}
export default CustomInput;
