import { apiClient } from '../config';
import { handleApiError } from '../utils/errorHandler';
import { WATCH_SCHEDULE_ENDPOINT } from '../endpoints/watchScheduleEndpoints';
import { WatchSchedules } from '../../models/asistant/watchSchedule/watchSchedule';
import { WatchScheduleResponse } from '../../models/asistant/watchSchedule/WatchScheduleResponse';

export const fetchWatchSchedules = async (params?: Record<string, any>): Promise<WatchScheduleResponse> => {
  try {
    const { data } = await apiClient.get(WATCH_SCHEDULE_ENDPOINT, { params });
    return data;
  } catch (error) {
    handleApiError(error);
    return new WatchScheduleResponse();
  }
};

export const createWatchSchedule = async (watchScheduleData: WatchSchedules) => {
  try {
    return await apiClient.post(WATCH_SCHEDULE_ENDPOINT, watchScheduleData);
  } catch (error) {
    handleApiError(error);
  }
};

export const updateWatchSchedule = async (id: number, watchScheduleData: WatchSchedules) => {
  try {
    return await apiClient.put(`${WATCH_SCHEDULE_ENDPOINT}/${id}`, watchScheduleData);
  } catch (error) {
    handleApiError(error);
  }
};

export const deleteWatchSchedule = async (id: number) => {
  try {
    return await apiClient.delete(`${WATCH_SCHEDULE_ENDPOINT}/${id}`);
  } catch (error) {
    handleApiError(error);
  }
};
