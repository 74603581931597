import { BuildTypeProvider } from "../attribute/buildType/buildTypeProvider";
import { CommentStatusProvider } from "../attribute/commentStatus/commentStatusProvider";
import { CustomerTypeProvider } from "../attribute/customerType/customerTypeProvider";
import { GiroTypeProvider } from "../attribute/giroType/giroTypeProvider";
import { IdTypeProvider } from "../attribute/idType/idTypeProvider";
import { PaymentMethodProvider } from "../attribute/paymentMethod/paymentMethodProvider";
import { PropertyGenusProvider } from "../attribute/propertyGenus/propertyGenusProvider";
import { ResourcesOfFindProvider } from "../attribute/sourceOfFind/sourceOfFindProvider";
import { ResourceOfSellProvider } from "../attribute/sourceOfSell/sourceOfSellProvider";
import { CountryProvider } from "../general/location/country/countryProvider";
import { LandOfficeProvider } from "../general/location/landOffice/landOfficeProvider";
import { ProvinceProvider } from "../general/location/province/provinceProvider";
import { UserProvider } from "../user/userProvider";

const TransactionCombineProvider = (props: { children: any; }) => {
  return (
    <UserProvider>
      <GiroTypeProvider>
        <IdTypeProvider>
          <CustomerTypeProvider>
            <CountryProvider>
              <LandOfficeProvider>
                <PaymentMethodProvider>
                  <ResourcesOfFindProvider>
                    <ResourceOfSellProvider>
                      <CommentStatusProvider>
                        <ProvinceProvider>
                          <BuildTypeProvider>
                            <PropertyGenusProvider>
                              {props.children}
                            </PropertyGenusProvider>
                          </BuildTypeProvider>
                        </ProvinceProvider>
                      </CommentStatusProvider>
                    </ResourceOfSellProvider>
                  </ResourcesOfFindProvider>
                </PaymentMethodProvider>
              </LandOfficeProvider>
            </CountryProvider>
          </CustomerTypeProvider>
        </IdTypeProvider>
      </GiroTypeProvider>
    </UserProvider>
  );
};

export default TransactionCombineProvider;
