
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { Attribute } from '../../../models/attribute/attribute';
import { FrequencyContext } from '../../../contexts/attribute/frequency/frequencyContext';

export const FrequencyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [frequencies, setFrequencies] = useState<Attribute[]>([]);

  useEffect(() => {
    let isMounted = true;

    async function getFrequency() {
      try {
        const { data } = await axios.get('attributes/19');
        if (isMounted) {
          setFrequencies(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    getFrequency();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <FrequencyContext.Provider value={[frequencies, setFrequencies]}>
      {children}
    </FrequencyContext.Provider>
  );
}

export const useFrequencyContext = () => useContext(FrequencyContext);
