import React, { useEffect, useMemo } from 'react';
import { Skeleton } from 'antd';
import useUsers from '../../../../api/hooks/useUsers';
import AntDesignSelect from '../../../constant/antDesign/select';

interface AntDesignUserSelectProps {
  placeholder?: string;
  loadingSelect?: boolean;
  autoFocus?: boolean;
  isMulti?: boolean;
  loadingStatus?: (loading: boolean) => void;
  params?: { [key: string]: any };
  [key: string]: any;
}

const AntDesignUserSelect: React.FC<AntDesignUserSelectProps> = ({
  placeholder,
  isMulti,
  params,
  loadingSelect,
  loadingStatus = () => {}, 
  showSearch,
  ...props
}) => {
  const queryParams = useMemo(() => ({
    ...params,
  }), [params]);

  const { users, loading, error } = useUsers(queryParams);


  useEffect(() => {
    if (users) {
      loadingStatus(loading);
    }
  }, [users]);

 useEffect(() => {
      loadingStatus(loading);
  }, [loading]);


  const options = useMemo(() => {
    return users.map(item => ({
      label: `${item.first_name} ${item.last_name}`,
      value: item.id,
    }));
  }, [users]);

  if (loading) {
    return <Skeleton active paragraph={{ rows: 1 }} />;
  }

  if (error) {
    return <div>İşlem sırasında bir hata gerçekleşti: {error}</div>;
  }

  return (
    <AntDesignSelect
      options={options}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default AntDesignUserSelect;
