import React, { useState, useMemo } from 'react';
import AntDesignCalendar from '../../constant/antDesign/calendar';
import dayjs, { Dayjs } from 'dayjs';
import { User } from '../../../models/user';
import { connect } from 'react-redux';
import AntDesignModal from '../../constant/antDesign/modal';
import { Button, Grid, Tag } from 'antd';
import CustomSunEditor from '../../form/sun-editor/customSunEditor';
import { BsCalendar2X, BsCheckLg } from 'react-icons/bs';
import useWorkSchedules from '../../../api/hooks/workSchedules/useWorkSchedules';
import { WorkSchedules } from '../../../models/employee/workSchedule/workSchedule';
import { Attribute } from '../../../models/attribute/attribute';

const { useBreakpoint } = Grid;

const WorkScheduleIndex = (props: { user: User }) => {
  const [selectedValue, setSelectedValue] = useState<Dayjs>(dayjs());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDefinition, setSelectedDefinition] = useState<string | null>(null);
  const [currentScheduleId, setCurrentScheduleId] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const screens = useBreakpoint();

  const startOfMonth = selectedValue.startOf('month').format('YYYY-MM-DD');
  const endOfMonth = selectedValue.endOf('month').format('YYYY-MM-DD');

  const params = useMemo(() => ({
    filter_user_id: props.user.id,
    filter_between: [startOfMonth, endOfMonth],
    limit: 31,
  }), [props.user.id, startOfMonth, endOfMonth]);

  const { workSchedules, addWorkSchedule, editWorkSchedule, loading } = useWorkSchedules(params);

  const renderDateCell = (date: Dayjs) => {
    const scheduleForDate = workSchedules.find(schedule =>
      dayjs(schedule.working_date).isSame(date, 'day')
    );

    const buttonText = scheduleForDate?.status?.name || "Eklendi";
    const buttonColor = scheduleForDate?.status?.color || "geekblue";
    const isOnLeave = scheduleForDate?.status?.id === 328;

    return (
      <div
        style={{ width: '100%', height: '100%', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        onClick={() => handleDateClick(date, scheduleForDate?.definition || null, scheduleForDate?.id || null)}
      >
        {scheduleForDate ? (
          <Tag color={buttonColor} style={{ width: '100%', textAlign: 'center' }}>
            {isOnLeave ? (
              <BsCalendar2X />
            ) : (
              <BsCheckLg />
            )}
            {!screens.xs && (isOnLeave ? " İzinli" : ` ${buttonText}`)}
          </Tag>
        ) : null}
      </div>
    );
  };

  const handleDateClick = (date: Dayjs, definition: string | null, scheduleId: number | null) => {
    setSelectedValue(date);
    setSelectedDefinition(definition);
    setCurrentScheduleId(scheduleId);
    setIsEditing(!!scheduleId);
    setIsModalVisible(true);
  };

  const handleSave = async () => {
    const workScheduleData: WorkSchedules = {
      id: currentScheduleId || 0,
      working_date: selectedValue?.format('YYYY-MM-DD') || '',
      definition: selectedDefinition || '',
      status_id: 327,
      user_id: props.user.id.toString(),
      ip: '',
      user: props.user,
      status: new Attribute(),
    };

    if (currentScheduleId) {
      await editWorkSchedule(currentScheduleId, workScheduleData);
    } else {
      await addWorkSchedule(workScheduleData);
    }

    setIsModalVisible(false);
  };

  const handleSetLeave = async () => {
    const workScheduleData: WorkSchedules = {
      id: currentScheduleId || 0,
      working_date: selectedValue?.format('YYYY-MM-DD') || '',
      definition: selectedDefinition || '',
      status_id: 328,
      user_id: props.user.id.toString(),
      ip: '',
      user: props.user,
      status: new Attribute(),
    };

    if (currentScheduleId) {
      await editWorkSchedule(currentScheduleId, workScheduleData);
    } else {
      await addWorkSchedule(workScheduleData);
    }

    setIsModalVisible(false);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedDefinition(null);
    setCurrentScheduleId(null);
    setIsEditing(false);
  };

  return (
    <>
      <AntDesignCalendar 
        dateCellRender={renderDateCell} 
        onPanelChange={(newDate) => setSelectedValue(newDate)}
      />
      <AntDesignModal
        title={`${selectedValue ? selectedValue.format('DD/MM/YYYY') : ''} ${selectedValue ? selectedValue.format('dddd') : ''}`}
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width={screens.xs ? '100vw' : '60vw'}
        bodyStyle={{
          height: screens.xs ? '100vh' : 'auto',
          maxHeight: '100vh',
          overflow: 'hidden',
          padding: screens.xs ? '10px' : '24px',
        }}
      >
        <div style={{ height: screens.xs ? '65vh' : '50vh', overflow: 'auto' }}>
          <CustomSunEditor
            placeholder="Liste halinde yazınız..."
            height="30vh"
            value={selectedDefinition || ''}
            onChange={(content: string) => setSelectedDefinition(content)}
          />
        </div>
        <div style={{ display: 'flex', gap: '5px', justifyContent: 'flex-end' }}>
          <Button type="primary" onClick={handleSave}>
            {isEditing ? "Güncelle" : "Kaydet"}
          </Button>
          <Button type="primary" danger onClick={handleSetLeave}>
            {isEditing ? "İzinli Olarak Güncelle" : "Bu Tarihte İzinliyim"}
          </Button>
          <Button onClick={handleModalClose}>İptal</Button>
        </div>
      </AntDesignModal>
    </>
  );
};

export default connect(
  (state: { user: User }) => ({
    user: state.user,
  })
)(WorkScheduleIndex);
