import { Link } from "react-router-dom";
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { AiOutlineUserSwitch } from 'react-icons/ai';
import { BiMapPin } from 'react-icons/bi';

//Table
import type { ColumnsType } from 'antd/es/table';

import { Table, Tag, Tooltip } from "antd";
import { Modal, Button } from "react-bootstrap";
import ProfilePhoto from "../../user/profilePhoto";
import { NumericFormat } from "react-number-format";
import { useEffect, useState } from "react";
import { GiroReport } from "../../../models/report/giro/giroReport";
import { RankingAgent } from "../../../models/report/giro/rankingAgent";
import axios from "axios";

import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { Property } from "../../../models/general/property/property";
import { PropertyType } from "../../../models/attribute/propertyType/propertyType";
import { PropertyGenus } from "../../../models/attribute/propertyGenus/propertyGenus";
import { GiroType } from "../../../models/attribute/giroType/giroType";
import { Customer } from "../../../models/accounting/customer/customer";
import TransactionDetail from "../../transaction/detail";
dayjs.locale("tr");

//Table
interface DataType {
  id(id: any): unknown;
  sale_price: any;
  created_at: any;
  property: Property;
  propertyType: PropertyType;
  propertyGenus: PropertyGenus;
  reference_no: string;
  key: React.Key;
  giro_type: GiroType;
  seller: Customer;
  buyer: Customer;
  total_service_fee: string;
  out_office_service_fee: string;
  office_referral_fee: string;
  out_office_referral_fee: string;
  office_service_fee: string;
  agent_service_fee: string;
  partner_count: any;
}

const GiroList = ({ start_date, end_date }: any) => {
  const rowClassName = () => 'no-bottom-border'; // Özel sınıf adı

  const [show_id, setShowID] = useState<any | null>(0);
  const [show, setShow] = useState(false);
  const [show_full_name, setShowFullName] = useState<any | null>('');
  const handleClose = () => setShow(false);

  const [sort, setSort] = useState(false);
  const [sort_url, setSortUrl] = useState<any | null>('&sort[order]=desc');
  const [sort_req, setSortReq] = useState<any | null>([]);


  const [reports, setReports] = useState<GiroReport | any | null>(null);
  const [giro_type_data, setGiroTypeData] = useState<RankingAgent | any | null>();

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<any | null>({
    current: 1,
    pageSize: 20,
    currentPageSize: 100,
    total: reports?.total,
    pageSizeOptions: [10, 20, 50, 100, 500, 100],
    showQuickJumper: true,
    showSizeChanger: true,
    showLessItems: true,
    responsive: true,
  });

  const [data, setData] = useState<DataType[]>([]);

  const [redirect_id, setRedirectId] = useState(0);
  const handleRowDoubleClick = (id: any) => {
    setRedirectId(id);
  }
  useEffect(() => {
    (
      async () => {
        setLoading(true);
        axios.get(`giros?limit=${pagination.pageSize}&page=${pagination.current}${sort_url}&filter_between[]=${start_date}&filter_between[]=${end_date}`)
          .then(({ data }) => {
            setData(data.transactions.data);
            setReports(data.transactions?.report);
            setPagination({
              current: pagination.current,
              pageSize: pagination.pageSize,
              currentPageSize: pagination.currentPageSize,
              total: data.transactions?.meta?.total
            });
            setLoading(false);
          });
      }
    )();
  }, []);

  //Table
  const columns: ColumnsType<DataType> = [
    {
      title: 'G. Tipi',
      key: 'property',
      dataIndex: 'property',
      render: (property, record:any) => {
        let giro_type = record.giro_type;
        let propertyGen = '';
        if (property?.propertyGenus) {
          propertyGen = property.propertyGenus.name;
          if (property.propertyType) {
            propertyGen = `${propertyGen} ${property.propertyType.name}`;
          }
        }
        let color = '';
        if (giro_type) {
          if (giro_type?.id == 116) { color = 'red'; } else { color = 'geekblue'; }
        }
        return (
          <>
            <a onClick={() => { setShow(true); setShowID(record.id); setShowFullName(`${record.agent.first_name} ${record.agent.last_name}`); }}>
              {`${record?.agent.first_name} ${record.agent.last_name}`}
            </a>
            <br />
          <div className="d-flex justify-content-start">
            <Tag color={color}>{propertyGen}</Tag>
            
            </div>
          </>
        );
      },
    },
    {
      title: 'Ciro',
      dataIndex: 'total_service_fee',
      className: 'text-end',
      sorter: true,
      render: (total_service_fee, record) => {
        return (
          <>
            <span>{record.created_at}</span>
            <br />
            <strong>
              <NumericFormat
                value={total_service_fee.toString().replace('.', ',')}
                thousandSeparator="."
                decimalSeparator=","
                displayType="text"
              /> ₺ </strong>
          </>
        );
      },
    },
    Table.EXPAND_COLUMN
  ];


  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl" animation={false}>
        {show_id && show_id !== null && show_id !== undefined &&
          <>
            <Modal.Header closeButton>
              <Modal.Title><ProfilePhoto id={show_id} imgHeight={35} /> <span className="ms-2">{show_full_name}</span> - <span className="text-danger">{show_id}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TransactionDetail id={show_id} />
            </Modal.Body><Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Kapat
              </Button>
              <Link className="btn btn-primary" to={`/accounting/transactions/${show_id}/edit`}>
                Güncelle
              </Link>
            </Modal.Footer>
          </>
        }
      </Modal>
      <Table
        className="table table-striped"
        size="small"
        columns={columns}
        dataSource={data}
        showHeader={false}
        loading={loading}
        scroll={{ y: 300, x: true }}
        rowKey={(record) => `${record.id}`}
        onRow={(record) => {
          return {
            onDoubleClick: (event) => handleRowDoubleClick(record.id)
          };
        }}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <>
                <div className="table-reponsive">
                  <table className={`table`}>
                    <tbody>
                      <tr>
                        <th>{record.giro_type.id == 116 ? 'Satıcı' : 'Kiraya Veren'}</th>
                        <td>{`${record?.seller.first_name} ${record?.seller.last_name}`}</td>
                      </tr>
                      <tr>
                        <th>{record.giro_type.id == 116 ? 'Alıcı' : 'Kiracı'}</th>
                        <td>{`${record?.buyer.first_name} ${record?.buyer.last_name}`}</td>
                      </tr>
                      <tr>
                        <th>Portföy Adresi</th>
                        <td>{`${record.property?.address}`}</td>
                      </tr>
                      <tr>
                        <th>Ofis Dışı Pay.</th>
                        <td>
                        <NumericFormat
                            value={record.out_office_referral_fee.toString().replace('.', ',')}
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                        /> ₺
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Ofis İle Pay.
                        </th>
                        <td>
                        <NumericFormat
                            value={record.office_referral_fee.toString().replace('.', ',')}
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                        /> ₺
                        </td>
                      </tr>
                      <tr>
                        <th>Ofis Hizmet Bedeli</th>
                        <td>
                        <strong>
                            <NumericFormat
                                value={record.office_service_fee.toString().replace('.', ',')}
                                thousandSeparator="."
                                decimalSeparator=","
                                displayType="text"
                            /> ₺ </strong>
                        </td>
                      </tr>
                      <tr>
                        <th>G.D. Bedeli</th>
                        <td>
                        <NumericFormat
                            value={record.agent_service_fee.toString().replace('.', ',')}
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                        /> ₺
                        </td>
                      </tr>
                      <tr>
                        <th>m<sup>2</sup> Fiyatı</th>
                        <td>
                          <strong>
                            <NumericFormat
                              value={(parseFloat(record.sale_price) / parseFloat(record.property?.gross)).toFixed(2)}
                              thousandSeparator="."
                              decimalSeparator=","
                              displayType="text"
                            /> ₺
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}><a href={`https://www.google.com/maps/dir//${record.property?.coordinate}`} target={'_blank'} rel="nofollow">Harita görünümü</a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )
          },
          rowExpandable: (record) => record?.seller?.first_name !== 'Not Expandable',
        }}
        pagination={pagination}
        onChange={(val: any, filter: any, sorter: any, extra: any) => {
          setPagination({
            current: val.current,
            pageSize: val.pageSize,
            currentPageSize: val.currentPageSize
          });
          setSortReq(sorter);
          setSort(true);
        }}
      />
    </>
  );
}

export default GiroList;

