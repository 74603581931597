import React from "react";
import Wrapper from "../components/layout/wrapper";
import { Row, Col, Card } from 'react-bootstrap';
import { connect } from "react-redux";
import { User } from "../models/user";
import { Link } from "react-router-dom";
import { GetProps, Input } from "antd";
import { FaSearch, FaMicrophone } from 'react-icons/fa';
import { FcStatistics, FcComboChart, FcMoneyTransfer, FcCustomerSupport, FcOrganization, FcAssistant, FcAdvertising, FcBusinessContact, FcConferenceCall } from 'react-icons/fc'; // react-icons/fc'dan uygun ikonlar
import { AiOutlineAudio } from "react-icons/ai";

interface LaunchpadItem {
    id: number;
    title: string;
    icon: React.ReactNode; // İkon tipi
    link: string;
}

const items: LaunchpadItem[] = [
    {
        id: 1,
        title: "Gösterge Paneli",
        icon: <FcStatistics className="icon" size={200} />, // Ana sayfa ikonu
        link: "/",
    },
    {
        id: 2,
        title: "Raporlar",
        icon: <FcComboChart className="icon" size={200} />, // Raporlar ikonu
        link: "/report/giros",
    },
    {
        id: 3,
        title: "Muhasebe",
        icon: <FcMoneyTransfer className="icon" size={200} />, // Muhasebe ikonu
        link: "/contract/purchase-sale-agreements",
    },
    {
        id: 4,
        title: "Müşteri",
        icon: <FcConferenceCall className="icon" size={200} />, // Müşteri ikonu
        link: "/",
    },
    {
        id: 5,
        title: "Gayrimenkul",
        icon: <FcOrganization className="icon" size={200} />, // Gayrimenkul ikonu
        link: "/",
    },
    {
        id: 6,
        title: "Asistan",
        icon: <FcAssistant className="icon" size={200} />, // Asistan ikonu
        link: "/asistant/calls",
    },
    {
        id: 7,
        title: "Recruiter",
        icon: <FcAdvertising className="icon" size={200} />, // Reklam ikonu (veya başka bir ikon)
        link: "/recruitment/meets",
    },
    {
        id: 8,
        title: "Personel",
        icon: <FcBusinessContact className="icon" size={200} />, // Yönetici ikonu
        link: "/employee/work-schedules",
    },
    {
        id: 9,
        title: "Kullanıcılar",
        icon: <FcConferenceCall className="icon" size={200} />, // Kullanıcılar ikonu
        link: "/users",
    },
];
type SearchProps = GetProps<typeof Input.Search>;


const { Search } = Input;

const suffix = (
    <AiOutlineAudio
        style={{
            fontSize: 16,
            color: '#1677ff',
        }}
    />
);

const onSearch: SearchProps['onSearch'] = (value: any, _e: any, info: any) => console.log(info?.source, value);
const Launch = (props: { user: User }) => {
    return (
        <Wrapper fullLayout={false}>
            <div className="launch-bg"></div>
            <div className="launch-content d-flex flex-column align-items-center">
                <Row className="g-4" style={{ maxWidth: "1200px", padding: "20px" }}>
                    <Col xs={12} className="text-center mb-1">
                        <Search
                            placeholder="Ara ya da konuş..."
                            className="mb-5 launch-search-input"
                            suffix={suffix}
                            onSearch={onSearch}
                        />
                    </Col>
                    <Col xs={12} className="text-center">
                        <Link to={items[0].link} style={{ textDecoration: "none" }}>
                            <Card className="text-center bg-transparent border-0">
                                <div className="icon-container"> {/* İkonların etrafına beyaz arka plan eklemek için div */}
                                    {items[0].icon}
                                </div>
                                <Card.Body>
                                    <Card.Text className="text-light">{items[0].title}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>

                    {items.slice(1).map((item) => (
                        <Col key={item.id} xs={4} sm={4} md={3} className="d-flex justify-content-center">
                            <Link to={item.link} style={{ textDecoration: "none" }}>
                                <Card className="text-center bg-transparent border-0 d-flex flex-column align-items-center">
                                    <div className="icon-container"> {/* İkonların etrafına beyaz arka plan eklemek için div */}
                                        {item.icon}
                                    </div>
                                    <Card.Body className="d-flex flex-column align-items-center">
                                        <Card.Text className="launch-text">{item.title}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            </div>
        </Wrapper>
    );
}

export default connect(
    (state: { user: User }) => ({
        user: state.user
    })
)(Launch);
