import axios from "axios";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link, Navigate, NavLink, useParams, } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import { MAX_ORDERNUM } from '../../../components/constant/ordernum';
import  isSelectOption  from '../../../components/helper/selectSetting';
import Select from 'react-select';


const Edit = (props: any) => {
    const [name, setName] = useState('');
    const [ordernum, setOrdernum] = useState('');
    const [type, setType] = useState(1);
    const [redirect, setRedirect] = useState(false);
    const { id } = useParams();


    useEffect(() => {
        (
            async () => {

                const { data } = await axios.get(`attribute/${id}`);
                setName(data.name);
                setOrdernum(data.ordernum);
                
            }
        )()
    }, []);

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        await axios.put(`attribute/${id}`, {
            name,
            type,
            ordernum
        });

        setRedirect(true);
    }

   

    var orOpt=[] as any;
    var arrOr = [];

    for (var _i = 1; _i <= MAX_ORDERNUM; _i++) {
        orOpt['value']=_i;
        orOpt['label']=_i;
        arrOr.push({...orOpt});

    }

  
    if (redirect) {
        return <Navigate to={'/attribute/communications'} />;
    }

    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">İletişim Kaynağı Güncelle</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><NavLink to={'/'}>Gösterge Paneli</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to={'/attribute/communications'}>İletişim Kaynakları</NavLink></li>
                                <li className="breadcrumb-item active">Güncelle</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-4">
                            <div>
                                <form onSubmit={submit}>
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Başlık</label>
                                                <input type="text" required data-pristine-required-message="Lütfen bir baslik giriniz."
                                                    className="form-control"
                                                    onChange={e => setName(e.target.value)}
                                                    defaultValue={name}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Öncelik</label>
                                                <Select
                                                    value={arrOr[parseInt(ordernum??'')-1]}
                                                    onChange={(v) => {
                                                        if(isSelectOption(v)) {
                                                          setOrdernum(v.value)
                                                        }
                                                      }}
                                                    options={arrOr}
                                                    placeholder={'Seçiniz...'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary">Güncelle</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Edit;