import axios from "axios";
import { useEffect, useState } from "react";
import { Rate, Spin, Tag } from 'antd';
import Tel from "../../link/tel";
import Mailto from "../../link/mailto";
import { TransactionType } from "../../../models/customer/satisfactionSurvey/transactionType";
import { Attribute } from "../../../models/attribute/attribute";
import { Question } from "../../../models/customer/satisfactionSurvey/question";
import { NumericFormat } from "react-number-format";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const SatisfactionSurveyDetail = ({ id }: any) => {
  const [satisfaction_survey, setSatisfactionSurvey] = useState<any | null>(null);
  const [transaction_types, setTransactionTypes] = useState([]);
  const [score_names, setScoreNames] = useState<any>();

  useEffect(() => {
    (
      async () => {
        if (id > 0) {
          const { data } = await axios.get(`customer/satisfaction-survey/${id}`);
          setSatisfactionSurvey(data);
        }
      }
    )()
  }, [id]);

  useEffect(() => {
    (
      async () => {
        const { data } = await axios.get('attributes/45');
        setTransactionTypes(data.data);
      }
    )()
  }, []);


  useEffect(() => {
    (
      async () => {
        const { data } = await axios.get(`attributes/46`);
        const updatedDesc = data?.data?.map((item: Attribute) => item.name);
        setScoreNames(updatedDesc);
      }
    )()
  }, []);

  if (!satisfaction_survey) {
    return (
      <>
        <div className="d-flex flex-column justify-content-center align-items-center mb-3 mt-5 mb-5">
          <div className="d-flex justify-content-between bd-highlight mb-3">
            <Spin></Spin>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="row">

      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>  Müşteri Bilgileri</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>AD SOYAD</th>
              <td>{satisfaction_survey?.satisfaction_survey.data?.customer?.first_name} {satisfaction_survey?.satisfaction_survey.data?.customer?.last_name}</td>
            </tr>
            {satisfaction_survey?.satisfaction_survey.data?.customer.id_no != '0' ?
              <tr>
                <th>{satisfaction_survey?.satisfaction_survey.data?.customer?.idType?.name}</th>
                <td>{satisfaction_survey?.satisfaction_survey.data?.customer?.id_no}</td>
              </tr>
              : null}
            <tr>
              <th scope="row">TELEFON</th>
              <td><Tel phone={`${satisfaction_survey?.satisfaction_survey.data?.customer?.phoneCode.phone_code} ${satisfaction_survey?.satisfaction_survey.data?.customer?.phone}`}>+{satisfaction_survey?.satisfaction_survey.data?.customer.phoneCode?.phone_code} {satisfaction_survey?.satisfaction_survey.data?.customer.phone}</Tel></td>
            </tr>
            {satisfaction_survey?.satisfaction_survey.data?.customer.email != '0' ?
              <tr>
                <th scope="row">EMAIL</th>
                <td><Mailto email={satisfaction_survey?.satisfaction_survey.data?.customer.email} body="Merhaba">{satisfaction_survey?.satisfaction_survey.data?.customer.email}</Mailto></td>
              </tr>
              : null}
          </tbody>
        </table>
      </div>

      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>Diğer Bilgiler</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>İŞLEM TÜRÜ </th>
              <td>
                {satisfaction_survey?.satisfaction_survey.data?.transactionType.map((transaction_type: TransactionType) => {
                  return (
                    <Tag color={'volcano'}>{transaction_type?.transaction_type?.name}</Tag>
                  )
                })}
              </td>
            </tr>
            <tr>
              <th style={{ width: 170 }}>YORUM TARİHİ </th>
              <td>{`${satisfaction_survey?.satisfaction_survey.data?.created_at}`}</td>
            </tr>
            <tr>
              <th style={{ width: 170 }}>ORTALAMA PUAN </th>
              <td>
                {satisfaction_survey.satisfaction_survey.report.avg && score_names ? (
                  <>
                    <Rate allowHalf disabled tooltips={score_names} value={satisfaction_survey.satisfaction_survey.report.round_avg} />
                    <span className="ant-rate-text">
                      {score_names[satisfaction_survey.satisfaction_survey.report.round_avg - 1]}
                    </span>
                  </>
                ) : (
                  ''
                )}
                <strong className="p-4">
                  <NumericFormat
                    value={satisfaction_survey.satisfaction_survey.report.avg.toString().replace('.', ',')}
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                  />
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="col-12 col-md-12">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>Yorum Bilgileri</th>
            </tr>
          </thead>
          <tbody>
            {transaction_types.map((transaction_type: Attribute) => {
              return (
                <tr>
                  <th style={{ width: '40%' }}>{transaction_type?.name} </th>
                  <td><Rate disabled defaultValue={
                    satisfaction_survey?.satisfaction_survey.data?.question.find((question: Question) => question.question_id === transaction_type.id)
                      ?.rate ?? 0
                  } />
                    <span className="ant-rate-text">
                      {score_names ? score_names[satisfaction_survey?.satisfaction_survey.data?.question.find((question: Question) => question.question_id === transaction_type.id)
                      ?.rate - 1 ?? 0] : ''}
                    </span>

                  </td>

                </tr>
              )
            })}
            <tr className="table-secondary">
              <th colSpan={2}>Mesaj</th>
            </tr>
            <tr>
              <td colSpan={2}>{satisfaction_survey?.satisfaction_survey.data?.comment}</td>
            </tr>
            <tr className="table-secondary">
              <th colSpan={2}>İzinler</th>
            </tr>
            <tr>
              <td>
               {satisfaction_survey?.satisfaction_survey.data?.notification_permission==1?<CheckOutlined/>:<CloseOutlined/>} Sms & Bildirim izni
                </td>

                <td>
                 {satisfaction_survey?.satisfaction_survey.data?.kvkk_permission==1?<CheckOutlined/>:<CloseOutlined/>} KVKK izni
                </td>
            </tr>
            <tr className="table-secondary">
              <td><strong>IP Adresi</strong></td>
              <td>{satisfaction_survey?.satisfaction_survey.data?.ip}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SatisfactionSurveyDetail;

