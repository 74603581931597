import { ExpenseCategoryProvider } from "../attribute/expenseCategory/expenseCategoryProvider";
import { FrequencyProvider } from "../attribute/frequency/frequencyProvider";
import { StatusProvider } from "../attribute/status/statusProvider";
import { UserProvider } from "../user/userProvider";

const CashbookCombineProvider = (props: { children: any; }) => {
  return (
    <UserProvider>
      <FrequencyProvider>
        <StatusProvider>
          <ExpenseCategoryProvider>
            {props.children}
          </ExpenseCategoryProvider>
        </StatusProvider>
      </FrequencyProvider>
    </UserProvider>
  );
};

export default CashbookCombineProvider;
