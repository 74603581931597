
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Country } from '../../../../models/general/country/country';
import { CountryContext } from '../../../../contexts/general/location/country/countryContext';


export const CountryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
      let isMounted = true;

      async function getCountries() {
        try {
          const { data } = await axios.get('general/countries');
          if (isMounted) {
            setCountries(data);
          }
        } catch (error) {
          console.log(error);
        }
      }
  
      const timer = setTimeout(() => {
        getCountries();
      }, 5000);
  
      return () => {
        isMounted = false;
        clearTimeout(timer);
      };
  }, []);


  return (
      <CountryContext.Provider value={[countries, setCountries]}>
          {children}
      </CountryContext.Provider>
  );
}

export const useCountryContext = () => useContext(CountryContext);
