import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink, Navigate } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import Paginator from "../../../components/paginator";
import Mailto from "../../../components/link/mailto";
import Tel from "../../../components/link/tel";
import { Customer } from "../../../models/accounting/customer/customer";
import { Modal, ModalBody, ModalFooter, ModalTitle, Button } from "react-bootstrap";
import { NumericFormat, PatternFormat } from 'react-number-format';

import { CloseOutlined, CheckOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { Checkbox, DatePicker, Select, Table, Tag } from 'antd';

//Table
import type { ColumnsType, TableProps } from 'antd/es/table';

import { Transaction } from "../../../models/accounting/transaction/transaction";
import Swal from 'sweetalert2';

import { PaymentMethod } from "../../../models/attribute/paymentMethod/paymentMethod";
import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';


import { PropertyGenus } from "../../../models/attribute/propertyGenus/propertyGenus";
import { PropertyType } from "../../../models/attribute/propertyType/propertyType";
import { GiroType } from "../../../models/attribute/giroType/giroType";
import 'moment/locale/tr';
import { Realtor } from "../../../models/general/realtor/realtor";
import locale from 'antd/es/date-picker/locale/tr_TR';
import { SourceOfSell } from "../../../models/attribute/sourceOfSell/sourceOfSell";
import { SourceOfFind } from "../../../models/attribute/sourceOfFind/sourceOfFind";
import { LandOffice } from "../../../models/general/location/landOffice/landOffice";
import { Neighbourhood } from "../../../models/general/location/neighbourhood/neighbourhood";
import { District } from "../../../models/general/location/district/district";
import { Province } from "../../../models/general/location/province/province";
import { Site } from "../../../models/general/site/site";
import { User } from "../../../models/user";
import { Category } from "../../../models/user/category";
import { isArray } from "react-select/dist/declarations/src/utils";
import { Property } from "../../../models/general/property/property";
import { PhoneCode } from "../../../models/general/country/phoneCode";
import { GiroReport } from "../../../models/report/giro/giroReport";
import { useUserContext } from "../../../providers/user/userProvider";
import { useGiroTypeContext } from "../../../providers/attribute/giroType/giroTypeProvider";
import { useCountryContext } from "../../../providers/general/location/country/countryProvider";
import { useResourceOfSellContext } from "../../../providers/attribute/sourceOfSell/sourceOfSellProvider";
import { useResourcesOfFindContext } from "../../../providers/attribute/sourceOfFind/sourceOfFindProvider";
import { useLandOfficeContext } from "../../../providers/general/location/landOffice/landOfficeProvider";
import { useProvinceContext } from "../../../providers/general/location/province/provinceProvider";
import { usePropertyGenusContext } from "../../../providers/attribute/propertyGenus/propertyGenusProvider";
import GiroMenu from "../../../components/report/giro/giroMenu";
import TransactionDetail from "../../../components/transaction/detail";
import ProfilePhoto from "../../../components/user/profilePhoto";
import CustomInput from "../../../components/form/customInput";
import { report } from "process";
import GiroLossFormat from "../../../components/report/giro/giroLossFormat";
import AntDesignRangePicker from "../../../components/constant/antDesign/rangePicker";
const { Option } = Select;


dayjs.locale("tr");
const { RangePicker } = DatePicker;
const currentYear = moment().year();
const rangePresets: any = {
    'Son 7 Gün': [dayjs().subtract(7, 'days'), dayjs()],
    'Bu Ay': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Geçen Ay': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Bu Yıl': [dayjs().startOf('year'), dayjs()],
    'Geçen Yıl': [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
    '1. Çeyrek': [dayjs().startOf('year').add(0, 'month').startOf('month'), dayjs().startOf('year').add(4, 'month').endOf('month')],
    '2. Çeyrek': [dayjs().startOf('year').add(3, 'month').startOf('month'), dayjs().startOf('year').add(5, 'month').endOf('month')],
    '3. Çeyrek': [dayjs().startOf('year').add(6, 'month').startOf('month'), dayjs().startOf('year').add(8, 'month').endOf('month')],
    '4. Çeyrek': [dayjs().startOf('year').add(9, 'month').startOf('month'), dayjs().startOf('year').add(11, 'month').endOf('month')]
};

const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
for (let i = 0; i < 12; i++) {
    rangePresets[monthNames[i]] = [dayjs().month(i).startOf('month'), dayjs().month(i).endOf('month')];
}

//Table
interface DataType {
    id(id: any): unknown;
    sale_price: any;
    created_at: any;
    property: Property;
    propertyType: PropertyType;
    propertyGenus: PropertyGenus;
    reference_no: string;
    key: React.Key;
    giro_type: GiroType;
    seller: Customer;
    buyer: Customer;
    total_service_fee: string;
    partner_count: any;
}

const Giros = () => {
    const [selectedDateName, setSelectedDateName] = useState<string>('Bu Ay');

    const [giros, setGiros] = useState([]);
    const [reports, setReports] = useState<GiroReport | any | null>(null);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [show_id, setShowID] = useState<any | null>(0);
    const [show_full_name, setShowFullName] = useState<any | null>('');

    const [redirect_id, setRedirectId] = useState(0);

    //For Filter
    const [property_genus_ids, setPropertyGenusIds] = useState<any | null>();
    const [property_type_ids, setPropertyTypeIds] = useState<any | null>([]);
    const [giro_type_id, setGiroTypeId] = useState<any | null>(null);
    const [category_id, setCategoryId] = useState<any | null>(null);
    const [extra_filter_see, setExtraFilterSee] = useState(false);
    const [extra_report_see, setExtraReportSee] = useState(false);
    const [realtor_id, setRealtorId] = useState<any | null>(null);
    const [realtor_search, setRealtorSearch] = useState('');
    const [site_id, setSiteId] = useState<any | null>(null);
    const [site_search, setSiteSearch] = useState('');
    const [source_of_sell_id, setSourceOfSellId] = useState<any | null>(null);
    const [source_of_find_id, setSourceOfFindId] = useState<any | null>(null);
    const [province_id, setProvinceId] = useState<any | null>(null);
    const [province_name, setProvinceName] = useState('');
    const [district_id, setDistrictId] = useState<any | null>(null);
    const [district_name, setDistrictName] = useState('');
    const [neighbourhood_id, setNeighbourhoodId] = useState<any | null>(null);
    const [neighbourhood_name, setNeighbourhoodName] = useState('');
    const [land_office_id, setLandOfficeId] = useState<any | null>(null);
    const [date_range, setDateRange] = useState<any | null>([]);
    const [start_date, setStartDate] = useState<any | null>(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [end_date, setEndDate] = useState<any | null>(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [out_office_see, setOutOfficeSee] = useState(false);
    const [reference_no, setReferenceNo] = useState<any | null>(null);
    const [reference_no_val, setReferenceNoVal] = useState<any | null>(null);
    const [customer_name, setCustomerName] = useState<any | null>(null);
    const [customer_name_val, setCustomerNameVal] = useState<any | null>(null);
    const [customer_id_no, setCustomerIdNo] = useState<any | null>(null);
    const [customer_id_no_val, setCustomerIdNoVal] = useState<any | null>(null);
    const [bob, setBob] = useState<any | null>(null);
    const [parcel, setParcel] = useState<any | null>(null);
    const [filter, setFilter] = useState(false);
    const [send_filter, setSendFilter] = useState(false);
    const [filter_url, setFilterUrl] = useState<any | null>('');
    const [sort, setSort] = useState(false);
    const [sort_url, setSortUrl] = useState<any | null>('&sort[order]=desc');
    const [sort_req, setSortReq] = useState<any | null>([]);

    //const [phone_codes, setPhoneCodes] = useState([]);
    const [customer_phone_code_id, setCustomerPhoneCodeId] = useState<any | null>(34);
    const [customer_phone, setCustomerPhone] = useState<any | null>(null);


    //const [property_genuses, setPropertyGenuses] = useState([]);
    const [property_types, setPropertyTypes] = useState([]);
    //const [giro_types, setGiroTypes] = useState([]);
    const [selectedGiroTypes, setSelectedGiroTypes] = useState([]);

    //const [users, setUsers] = useState([]);
    const [user_categories, setUserCategories] = useState([]);
    const [realtors, setRealtors] = useState([]);
    const [sites, setSites] = useState([]);
    //const [resources_of_sell, setResourcesOfSell] = useState([]);
    //const [resources_of_find, setResourcesOfFind] = useState([]);
    //const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [neighbourhoods, setNeighbourhoods] = useState([]);
    //const [land_offices, setLandOffices] = useState([]);
    const [selected_agents, setSelectedAgents] = useState([]);
    const [clear_filter_inputs, setClearFilterInputs] = useState(false);

    //For Report Table
    const [tax_status, setTaxStatus] = useState(true);
    const [tax_rate, setTaxRate] = useState<number>(20);

    const [metas, setCustomerCount] = useState([0]);

    //Modal    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    };

    const [data, setData] = useState<DataType[]>([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<any | null>({
        current: 1,
        pageSize: 100,
        currentPageSize: 100,
        total: reports?.total,
        pageSizeOptions: [10, 20, 50, 100, 500, 100],
        showQuickJumper: true,
        showSizeChanger: true,
        showLessItems: true,
        responsive: true
    });

    //Table
    const columns: ColumnsType<DataType> = [
        {
            title: 'No',
            dataIndex: 'reference_no',
            sorter: true,
            responsive: ["md", "lg", "xl", "xxl"],
            render: (reference_no, record) => {
                if (record.partner_count > 1) {
                    return (
                        <>
                            <strong>{reference_no}</strong>
                        </>
                    );
                }
                return (
                    <>
                        {reference_no}
                    </>
                );
            },
        },
        {
            title: 'Ay',
            key: 'created_at',
            dataIndex: 'created_at',
            responsive: ["md", "lg", "xl", "xxl"],
            render: (created_at) => (
                <>
                    <Tag className={`label label-${moment(created_at, "DD/MM/YYYY").format('MM')}`}>{moment(created_at, "DD/MM/YYYY").format('MMMM')}</Tag>
                </>
            ),

        },
        {
            title: 'Tarih',
            dataIndex: 'created_at',
            sorter: true
        },
        {
            title: 'G. Tipi',
            key: 'property',
            dataIndex: 'property',
            responsive: ["md", "lg", "xl", "xxl"],
            render: (property, record) => {
                let giro_type = record.giro_type;
                let propertyGen = '';
                if (property?.propertyGenus) {
                    propertyGen = property.propertyGenus.name;
                    if (property.propertyType) {
                        propertyGen = `${propertyGen} ${property.propertyType.name}`;
                    }
                }
                let color = '';
                if (giro_type) {
                    if (giro_type?.id == 116) { color = 'red'; } else { color = 'geekblue'; }
                }
                return (
                    <>
                        <Tag color={color}>{propertyGen}</Tag>
                    </>
                );
            },
        },
        {
            title: 'Ciro',
            dataIndex: 'total_service_fee',
            responsive: ["md", "lg", "xl", "xxl"],
            className: 'text-end',
            sorter: true,
            render: (total_service_fee) => {
                return (
                    <>
                        <strong>
                            <NumericFormat
                                value={Number(total_service_fee).toString().replace('.', ',')}
                                thousandSeparator="."
                                decimalSeparator=","
                                displayType="text"
                            /> ₺ </strong>
                    </>
                );
            },
        },
        {
            title: 'Ofis Dışı Pay.',
            responsive: ["md", "lg", "xl", "xxl"],
            dataIndex: 'out_office_referral_fee',
            className: 'text-end',
            sorter: true,
            render: (out_office_referral_fee) => {
                return (
                    <>
                        <NumericFormat
                            value={Number(out_office_referral_fee).toString().replace('.', ',')}
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                        /> ₺
                    </>
                );
            },
        },
        {
            title: 'Ofis Ref.',
            responsive: ["md", "lg", "xl", "xxl"],
            className: 'text-end',
            dataIndex: 'office_referral_fee',
            sorter: true,
            render: (office_referral_fee) => {
                return (
                    <>
                        <NumericFormat
                            value={Number(office_referral_fee).toString().replace('.', ',')}
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                        /> ₺
                    </>
                );
            },
        },
        {
            title: 'Ofis Payı',
            responsive: ["md", "lg", "xl", "xxl"],
            className: 'text-end',
            dataIndex: 'office_service_fee',
            sorter: true,
            render: (office_service_fee) => {
                return (
                    <>
                        <strong>
                            <NumericFormat
                                value={Number(office_service_fee).toString().replace('.', ',')}
                                thousandSeparator="."
                                decimalSeparator=","
                                displayType="text"
                            /> ₺ </strong>
                    </>
                );
            },
        },
        {
            title: 'G.D. Payı',
            responsive: ["md", "lg", "xl", "xxl"],
            dataIndex: 'agent_service_fee',
            className: 'text-end',
            sorter: true,
            render: (agent_service_fee) => {
                return (
                    <>
                        <NumericFormat
                            value={Number(agent_service_fee).toString().replace('.', ',')}
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                        /> ₺
                    </>
                );
            },
        },
        {
            title: 'G.D.',
            dataIndex: 'agent',
            sorter: false,
            render: (agent, record) => {

                return (
                    <>
                        <a onClick={() => { setShow(true); setShowID(record.id); setShowFullName(`${agent.first_name} ${agent.last_name}`); }}>
                            {`${agent.first_name} ${agent.last_name}`}
                        </a>
                    </>
                );
            },
        },
        {
            title: 'İşlem Fiyatı',
            responsive: ["md", "lg", "xl", "xxl"],
            className: 'text-end',
            dataIndex: 'sale_price',
            sorter: true,
            render: (sale_price) => {
                return (
                    <>
                        <NumericFormat
                            value={Number(sale_price).toString().replace('.', ',')}
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                        /> ₺
                    </>
                );
            },
        },
        {
            title: 'İşlem Günü',
            responsive: ["md", "lg", "xl", "xxl"],
            dataIndex: 'contract_date',
            sorter: true,
            render: (contract_date, record) => {
                const startDate = moment(contract_date, "DD/MM/YYYY");
                const endDate = moment(record.created_at, "DD/MM/YYYYD");
                const duration = moment.duration(endDate.diff(startDate));
                const differenceInDays = duration.asDays();
                return (
                    <>
                        <strong> {differenceInDays}
                        </strong>
                    </>
                );
            },
        },
    ];

    const [users, setUsers] = useUserContext();
    const [giro_types, setGiroTypes] = useGiroTypeContext();
    const [phone_codes, setPhoneCodes] = useCountryContext();
    const [resources_of_sell, setResourcesOfSell] = useResourceOfSellContext();
    const [resources_of_find, setResourcesOfFind] = useResourcesOfFindContext();
    const [land_offices, setLandOffices] = useLandOfficeContext();
    const [provinces, setProvinces] = useProvinceContext();
    const [property_genuses, setPropertyGenuses] = usePropertyGenusContext();

    //Filter
    useEffect(() => {

        let fUrl = '';
        if (giro_type_id > 0) {
            fUrl = `&filter_giro_type_id=${giro_type_id}`;
        }

        if (parseInt(reference_no) > 0) {
            fUrl = `${fUrl}&filter_reference_no=${reference_no}`;
        }

        if (property_genus_ids?.length > 0) {
            property_genus_ids.forEach((property_genus_id: number) => {
                fUrl = `${fUrl}&filter_property_genus_id[]=${property_genus_id}`;
            });
        }

        if (property_type_ids?.length > 0) {
            property_type_ids.forEach((property_type_id: number) => {
                fUrl = `${fUrl}&filter_property_type_id[]=${property_type_id}`;
            });
        }

        if (parseInt(category_id) > 0) {
            fUrl = `${fUrl}&filter_category_id=${category_id}`;
        }

        if (selected_agents?.length > 0) {
            selected_agents.forEach((agent_id: number) => {
                fUrl = `${fUrl}&filter_agent_id[]=${agent_id}`;
            });
        }

        if (customer_name !== '' && customer_name !== ' ' && customer_name !== null && customer_name !== undefined) {
            fUrl = `${fUrl}&filter_customer_name=${customer_name}`;
        }

        if (customer_id_no !== '' && customer_id_no !== ' ' && customer_id_no !== null && customer_id_no !== undefined) {
            fUrl = `${fUrl}&filter_customer_id_no=${customer_id_no}`;
        }

        if (customer_phone !== '' && customer_phone !== ' ' && customer_phone !== null && customer_phone !== undefined) {
            fUrl = `${fUrl}&filter_customer_phone_code_id=${customer_phone_code_id}&filter_customer_phone=${customer_phone}`;
        }

        if (parseInt(realtor_id) > 0) {
            fUrl = `${fUrl}&filter_other_realtor_id=${realtor_id}`;
        }

        if (parseInt(source_of_find_id) > 0) {
            fUrl = `${fUrl}&filter_source_of_find_id=${source_of_find_id}`;
        }

        if (parseInt(source_of_sell_id) > 0) {
            fUrl = `${fUrl}&filter_source_of_sell_id=${source_of_sell_id}`;
        }

        if (parseInt(province_id) > 0) {
            fUrl = `${fUrl}&filter_province_id=${province_id}`;
        }

        if (parseInt(district_id) > 0) {
            fUrl = `${fUrl}&filter_district_id=${district_id}`;
        }

        if (parseInt(neighbourhood_id) > 0) {
            fUrl = `${fUrl}&filter_neighbourhood_id=${neighbourhood_id}`;
        }

        if (parseInt(bob) > 0) {
            fUrl = `${fUrl}&filter_bob=${bob}`;
        }


        if (parseInt(parcel) > 0) {
            fUrl = `${fUrl}&filter_parcel=${parcel}`;
        }

        if (parseInt(site_id) > 0) {
            fUrl = `${fUrl}&filter_site_id=${site_id}`;
        }

        if (parseInt(land_office_id) > 0) {
            fUrl = `${fUrl}&filter_land_office_id=${land_office_id}`;
        }

        setFilterUrl(fUrl);
        setFilter(false);
        setSendFilter(true);
    }, [filter]);

    useEffect(() => {
        setTimeout(() => {
            let sUrl = '';
            let sType = '';
            if (sort_req.order === undefined) {
                sUrl = '&sort[order]=desc';
            } else {
                (sort_req.order == 'ascend') ? sType = '&sort[order]=asc' : sType = '&sort[order]=desc';
                sUrl = `&sort[by]=${sort_req.field}`;
            }
            setSortUrl(sUrl + sType);
            setSort(false);
            setSendFilter(true);
        }, 500);
    }, [sort]);


    useEffect(() => {
        (
            async () => {
                if (send_filter) {
                    setLoading(true);

                    axios.get(`giros?limit=${pagination.pageSize}&page=${pagination.current}${sort_url}&filter_tax_status=${tax_status}&filter_tax_rate=${tax_rate}&filter_between[]=${start_date}&filter_between[]=${end_date}${filter_url}`)
                        .then(({ data }) => {
                            setData(data.transactions.data);
                            setReports(data.transactions.report);
                            setPagination({
                                current: pagination.current,
                                pageSize: pagination.pageSize,
                                currentPageSize: pagination.currentPageSize,
                                total: data.transactions?.meta?.total
                            });
                            setLoading(false);
                            setCalcStatus(true);
                        });
                    setSendFilter(false);
                }
            }
        )();
    }, [send_filter]);


    useEffect(() => {
        (
            async () => {
                if (property_genus_ids?.length > 0) {
                    const { data } = await axios.get('attributes/8/' + property_genus_ids + '/cats');
                    setPropertyTypes(data.data);
                }
            }
        )()
    }, [property_genus_ids]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('user/user-categories');
                setUserCategories(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('realtors');
                if (realtor_search.length > 0) {
                    const { data } = await axios.get('realtors/search/' + realtor_search);
                }
                setRealtors(data);
            }
        )()
    }, [realtor_search]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('sites');
                if (site_search.length > 0) {
                    const { data } = await axios.get('sites/search/' + site_search);
                }
                setSites(data.data);
            }
        )()
    }, [site_search]);

    useEffect(() => {
        (
            async () => {
                if (province_id > 0) {
                    const { data } = await axios.get('location/district/' + province_id);
                    setDistricts(data);
                }
            }
        )()
    }, [province_id]);

    useEffect(() => {

        (
            async () => {
                if (typeof province_name === 'string' && province_name.trim().length > 0) {
                    const { data } = await axios.get('location/province/name-like/' + province_name);
                    if (data.id > 0) { setDistrictId(null); setNeighbourhoodId(null); }
                    setProvinceId(data.id);
                }
            }
        )()

    }, [province_name])


    useEffect(() => {
        (
            async () => {
                if (district_id > 0) {
                    const { data } = await axios.get('location/neighbourhood/' + district_id);
                    setNeighbourhoods(data);
                }
            }
        )()
    }, [district_id]);

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0 && province_id > 0) {

                        const { data } = await axios.get(`location/district/name-like/${district_name}/${province_id}`);
                        setDistrictId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [district_name, province_id])

    useEffect(() => {
        setTimeout(() => {
            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0 && district_id > 0) {
                        const { data } = await axios.get(`location/neighbourhood/name-like/${neighbourhood_name}/${district_id}`);
                        setNeighbourhoodId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [neighbourhood_name, district_id])

    useEffect(() => {
        if (clear_filter_inputs) {
            setPropertyGenusIds([]);
            setPropertyTypeIds([]);
            setGiroTypeId(null);
            setCategoryId(null);
            setRealtorId(null);
            setRealtorSearch('');
            setSiteId(null);
            setSiteSearch('');
            setSourceOfSellId(null);
            setSourceOfFindId(null);
            setProvinceId(null);
            setProvinceName('');
            setDistrictId(null);
            setDistrictName('');
            setNeighbourhoodId(null);
            setNeighbourhoodName('');
            setLandOfficeId(null);
            setSelectedAgents([]);
            setDateRange([]);
            setReferenceNo('');
            setReferenceNoVal('');
            setCustomerName('');
            setCustomerNameVal('');
            setCustomerIdNo('');
            setCustomerIdNoVal('');
            setCustomerPhone('');
            setCustomerPhoneCodeId(34);
            setBob('');
            setParcel('');
            setClearFilterInputs(false);
            setFilter(true);
        }
    }, [clear_filter_inputs]);

   //Calculate
   const [calc_status, setCalcStatus] = useState(false);

   const [calcData, setCalcData] = useState({
    giro_loss_rate: 0,
    giro_loss_price: 0,
    agent_loss_price: 0,
    office_loss_price: 0
  });

    const edit = (id: number) => {
        return <Navigate to={`/accounting/transactions/${id}/edit`} />;
    }

    const handleRowDoubleClick = (id: any) => {
        setRedirectId(id);
    }

    if (redirect_id > 0) {
        return <Navigate to={`/accounting/transactions/${redirect_id}/edit`} />;
    }

    return (
        <Wrapper>
            <Modal show={show} onHide={handleClose} size="xl" animation={false}>
                {show_id && show_id !== null && show_id !== undefined &&
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title><ProfilePhoto id={show_id} imgHeight={35} /> <span className="ms-2">{show_full_name}</span> - <span className="text-danger">{show_id}</span></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <TransactionDetail id={show_id} />
                        </Modal.Body><Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Kapat
                            </Button>
                            <Link className="btn btn-primary" to={`/accounting/transactions/${show_id}/edit`}>
                                Güncelle
                            </Link>
                        </Modal.Footer>
                    </>
                }
            </Modal>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <div className="d-flex flex-wrap gap-2">
                            <GiroMenu type={'giros'} />
                        </div>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><NavLink to={'/'}>Gösterge Paneli</NavLink></li>
                                <li className="breadcrumb-item active">Ciro</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-sm-6 mb-3">
                                    <div className="form-group select-style">
                                        <label className="d-block">Tarih Aralığı ( {selectedDateName} )</label>
                                        {/* <RangePicker
                                            format={'DD/MM/YYYY'}
                                            locale={locale}
                                            ranges={rangePresets}
                                            value={date_range}
                                            style={{ width: '100%' }}
                                            onChange={(value, dateString) => {
                                                setDateRange(value);
                                                setStartDate(dayjs(dateString.toString().split(',')[0], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setEndDate(dayjs(dateString.toString().split(',')[1], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setSendFilter(true);
                                            }
                                            }
                                        /> */}
                                           <AntDesignRangePicker
                                        dateRange={date_range}
                                        setDateRange={setDateRange}
                                        setStartDate={setStartDate}
                                        setEndDate={setEndDate}
                                        setSendFilter={setSendFilter}
                                        setSelectedDateName={setSelectedDateName}
                                    />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="form-group select-style">
                                        <label>Nitelik</label>
                                        <Select className="form-control" defaultValue={giro_type_id} showSearch
                                            value={giro_type_id}
                                            placeholder="Nitelik Seçiniz"
                                            allowClear={true}
                                            onChange={e => { setGiroTypeId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >

                                            {giro_types.map((giro_types: GiroType) => {
                                                return (
                                                    <Option value={giro_types.id} title={giro_types.name}
                                                        key={giro_types.name}
                                                        id={giro_types.name}>{giro_types.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="form-group select-style">
                                        <label>Gayrimenkul Cinsi</label>
                                        <Select className="form-control" defaultValue={property_genus_ids} value={property_genus_ids} showSearch
                                            optionFilterProp="children"
                                            mode="multiple"
                                            allowClear={true}
                                            placeholder="Gayrimenkul Cinsi Seçiniz"
                                            onChange={e => { setPropertyGenusIds(e); setPropertyTypeIds([]); setFilter(true); }}
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {property_genuses.map((property_genuses: PropertyGenus) => {
                                                return (
                                                    <Option value={property_genuses.id} title={property_genuses.name}
                                                        key={property_genuses.name}
                                                        id={property_genuses.name}>{property_genuses.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6" style={property_genus_ids == 10 ? { display: 'none' } : {}} >
                                    <div className="form-group select-style">
                                        <label>Gayrimenkul Türü</label>
                                        <Select className="form-control" defaultValue={property_type_ids} showSearch
                                            value={property_type_ids}
                                            mode="multiple"
                                            allowClear={true}
                                            placeholder="Gayrimenkul Türü Seçiniz"
                                            onChange={e => { setPropertyTypeIds(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >

                                            {property_types.map((property_types: PropertyType) => {
                                                return (
                                                    <Option value={property_types.id} title={property_types.name}
                                                        key={property_types.name}
                                                        id={property_types.name}>{property_types.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-3">
                                    <div className="form-group mb-3">
                                        <label>Referans No</label>
                                        <input type="number" data-pristine-required-message="Lütfen referans numarasını giriniz."
                                            className="form-control"
                                            defaultValue={reference_no_val}
                                            value={reference_no_val}
                                            onChange={e => { setReferenceNoVal(e.target.value); setTimeout(() => { setReferenceNo(e.target.value); setFilter(true); }, 500); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-3">
                                    <div className="form-group mb-3 select-style">
                                        <label>Paylaşım Tipi</label>
                                        <Select className="form-control" defaultValue={category_id} showSearch
                                            value={category_id}
                                            allowClear={true}
                                            placeholder="Paylaşım Tipi Seçiniz"
                                            onChange={e => { setCategoryId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );
                                            }}
                                        >
                                            {user_categories.map((user_categories: Category) => {
                                                return (
                                                    <Option value={user_categories.id} title={user_categories.name}
                                                        key={user_categories.name}
                                                        id={user_categories.name}>{user_categories.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="form-group mb-3 select-style">
                                        <label>Danışman (lar)</label>
                                        <Select className="form-control" showSearch
                                            allowClear={true}
                                            onChange={e => { setSelectedAgents(e); setFilter(true); }}
                                            value={selected_agents}
                                            defaultValue={selected_agents}
                                            mode="multiple"
                                            placeholder="Danışman Seçiniz"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {users.map((users: User) => {
                                                return (
                                                    <Option value={users.id} title={users.first_name + ' ' + users.last_name}
                                                        key={users.first_name + ' ' + users.last_name+ ' ' + users.id}
                                                        id={users.first_name + ' ' + users.last_name}>{users.first_name + ' ' + users.last_name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-md-3" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3">
                                        <label>Müşteri / Firma Adı</label>
                                        <input type="text"
                                            className="form-control"
                                            defaultValue={customer_name_val}
                                            value={customer_name_val}
                                            onChange={e => { setCustomerNameVal(e.target.value); setTimeout(() => { setCustomerName(e.target.value); setFilter(true); }, 500); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-md-3" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3">
                                        <label>Kimlik / Pasaport Numarası</label>
                                        <input type="text"
                                            className="form-control"
                                            defaultValue={customer_id_no_val}
                                            value={customer_id_no_val}
                                            onChange={e => { setCustomerIdNoVal(e.target.value); setTimeout(() => { setCustomerIdNo(e.target.value); setFilter(true); }, 500); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-2 col-md-3" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3">
                                        <label>Telefon</label>
                                        <div className="input-group">
                                            <div className="input-group-text p-0">
                                                <Select value={customer_phone_code_id} style={{ width: 100 }} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => { setCustomerPhoneCodeId(e); setFilter(true); }}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {phone_codes.map((phone_codes: PhoneCode) => {
                                                        let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                        return (
                                                            <Option value={phone_codes.id} title={phone_codes.name}
                                                                key={phone_codes.name}
                                                                id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <PatternFormat format="(###) ### ## ##"
                                                onChange={e => { setTimeout(() => { setCustomerPhone(e.target.value); setFilter(true); }, 500); }}
                                                value={customer_phone} className="form-control" allowEmptyFormatting mask=" " />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-6" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group select-style">
                                        <label>Emlakçı</label>
                                        <Select className="form-control" defaultValue={realtor_id} showSearch
                                            value={realtor_id}
                                            allowClear={true}
                                            placeholder="Emlakçı Seçiniz"
                                            onChange={e => { setRealtorId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            onSearch={e => setRealtorSearch(e)}
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {realtors.map((realtors: Realtor) => {
                                                return (
                                                    <Option value={realtors.id} title={realtors.first_name + ' ' + realtors.last_name + ' ' + realtors.company_name}
                                                        key={realtors.first_name + ' ' + realtors.last_name + ' ' + realtors.company_name}
                                                        id={realtors.first_name + ' ' + realtors.last_name + ' ' + realtors.company_name}>{realtors.first_name + ' ' + realtors.last_name + ' ' + realtors.company_name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-6" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3 select-style">
                                        <label>Portföy Bulma Kaynağı</label>
                                        <Select className="form-control" defaultValue={source_of_find_id} showSearch
                                            value={source_of_find_id}
                                            allowClear={true}
                                            placeholder="Portföy Bulma Kaynağını Seçiniz"
                                            onChange={e => { setSourceOfFindId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {resources_of_find.map((resources_of_find: SourceOfFind) => {
                                                return (
                                                    <Option value={resources_of_find.id} title={resources_of_find.name}
                                                        key={resources_of_find.name}
                                                        id={resources_of_find.name}>{resources_of_find.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-6" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3 select-style">
                                        <label>Portföy İşlem Kaynağı</label>
                                        <Select className="form-control" defaultValue={source_of_sell_id} showSearch
                                            value={source_of_sell_id}
                                            allowClear={true}
                                            placeholder="Portföy Satma Kaynağını Seçiniz"
                                            onChange={e => { setSourceOfSellId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {resources_of_sell.map((resources_of_sell: SourceOfSell) => {
                                                return (
                                                    <Option value={resources_of_sell.id} title={resources_of_sell.name}
                                                        key={resources_of_sell.name}
                                                        id={resources_of_sell.name}>{resources_of_sell.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-6" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3 select-style">
                                        <label>İl</label>
                                        <Select className="form-control" defaultValue={province_id} value={province_id} showSearch
                                            optionFilterProp="children"
                                            allowClear={true}
                                            placeholder="İl Seçiniz"
                                            onChange={e => { setProvinceId(e); setDistrictId(null); setNeighbourhoodId(null); setFilter(true); }}
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {provinces.map((provinces: Province) => {
                                                return (
                                                    <Option value={provinces.id} title={provinces.name}
                                                        key={provinces.name}
                                                        id={provinces.name}>{provinces.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-6" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3 select-style">
                                        <label>İlçe</label>
                                        <Select className="form-control" defaultValue={district_id} showSearch
                                            optionFilterProp="children"
                                            allowClear={true}
                                            onChange={e => { setDistrictId(e); setNeighbourhoodId(null); setFilter(true); }}
                                            value={district_id}
                                            placeholder="İlçe Seçiniz"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {districts.map((districts: District) => {
                                                return (
                                                    <Option value={districts.id} title={districts.name}
                                                        key={districts.name}
                                                        id={districts.name}>{districts.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-6" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3 select-style">
                                        <label>Mahalle</label>
                                        <Select className="form-control" defaultValue={neighbourhood_id} showSearch
                                            value={neighbourhood_id}
                                            allowClear={true}
                                            onChange={e => { setNeighbourhoodId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            placeholder="Mahalle Seçiniz"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {neighbourhoods.map((neighbourhoods: Neighbourhood) => {
                                                return (
                                                    <Option value={neighbourhoods.id} title={neighbourhoods.name}
                                                        key={neighbourhoods.name}
                                                        id={neighbourhoods.name}>{neighbourhoods.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-xl-1 col-md-1" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3">
                                        <label>Ada</label>
                                        <input type="text"
                                            className="form-control"
                                            value={bob}
                                            onChange={e => { setBob(e.target.value); setFilter(true); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-xl-1 col-md-1" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3">
                                        <label>Parsel</label>
                                        <input type="text"
                                            className="form-control"
                                            value={parcel}
                                            onChange={e => { setParcel(e.target.value); setFilter(true); }}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-6" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group select-style">
                                        <label>Site</label>
                                        <Select className="form-control" defaultValue={site_id} showSearch
                                            value={site_id}
                                            allowClear={true}
                                            placeholder="Site Seçiniz"
                                            onChange={e => { setSiteId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            onSearch={e => setSiteSearch(e)}
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {sites.map((sites: Site) => {
                                                return (
                                                    <Option value={sites.id} title={sites.name}
                                                        key={sites.name}
                                                        id={sites.name}>{sites.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-6" style={extra_filter_see ? {} : { display: 'none' }}>
                                    <div className="form-group mb-3 select-style">
                                        <label>Tapu Dairesi</label>
                                        <Select className="form-control" defaultValue={land_office_id} value={land_office_id} showSearch
                                            optionFilterProp="children"
                                            allowClear={true}
                                            placeholder="Tapu Dairesi Seçiniz"
                                            onChange={e => { setLandOfficeId(e); setFilter(true); }}
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {land_offices.map((land_offices: LandOffice) => {
                                                return (
                                                    <Option value={land_offices.id} title={land_offices.name}
                                                        key={land_offices.name}
                                                        id={land_offices.name}>{land_offices.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="float-start">
                                    <button type="button" onClick={e => setClearFilterInputs(true)} className="btn btn-soft-light btn-sm" >Temizle</button>
                                </div>
                                <div className="float-end">
                                    <button type="button" onClick={e => { extra_filter_see ? setExtraFilterSee(false) : setExtraFilterSee(true); }} className="btn btn-soft-secondary btn-sm" >{extra_filter_see ? 'Daha az seçenek' : 'Daha fazla seçenek'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table mb-0 table-bordered table-hover">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Toplam</th>
                                            {giro_types.map((giro_types: GiroType) => {
                                                return (
                                                    <th>{giro_types.name}</th>
                                                )
                                            })}
                                            <th>Ciro</th>
                                            <th>Ofis Dışı Pay.</th>
                                            <th>Ref Pay.</th>
                                            <th>Ofis Payı <strong className="text-danger d-inline-flex">(<ArrowDownOutlined className="pt-1" /> %7,53)</strong></th>
                                            <th>Ofis Oranı</th>
                                            <th>G. D. Payı</th>
                                            <th>G. D. Oranı</th>
                                            <th>Satış Fiyatı</th>
                                            {giro_type_id === 116 && <th>H.B. / S.Fiyatı</th>}
                                            <th>Ort. İşlem Günü</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th><strong>{reports?.total}</strong></th>
                                            {
                                                Object.keys(reports || {})
                                                    .filter(key => key.startsWith('total_giro_type_'))
                                                    .map(key => (
                                                        <th key={key}>
                                                            <strong>{reports[key]}</strong>
                                                        </th>
                                                    ))
                                            }
                                            <td scope="row">
                                                <NumericFormat
                                                    value={reports?.total_service_fee.toFixed(2).toString().replace('.', ',')}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                />
                                            </td>
                                            <td>
                                                <NumericFormat
                                                    value={reports?.out_office_referral_fee.toFixed(2).toString().replace('.', ',')}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                />
                                            </td>
                                            <td>
                                                <NumericFormat
                                                    value={reports?.office_referral_fee.toFixed(2).toString().replace('.', ',')}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                />
                                            </td>
                                            <td>
                                                <NumericFormat
                                                    value={reports?.office_service_fee.toFixed(2).toString().replace('.', ',')}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                />
                                            </td>
                                            <td>
                                                %
                                                <NumericFormat
                                                    value={reports?.office_percent.toFixed(2).toString().replace('.', ',')}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                />
                                            </td>
                                            <td>
                                                <NumericFormat
                                                    value={reports?.agent_service_fee.toFixed(2).toString().replace('.', ',')}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                />
                                            </td>
                                            <td>
                                                %
                                                <NumericFormat
                                                    value={reports?.agent_percent.toFixed(2).toString().replace('.', ',')}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                />
                                            </td>
                                            <td>
                                                <NumericFormat
                                                    value={reports?.sale_price.toFixed(2).toString().replace('.', ',')}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                />
                                            </td>
                                            {giro_type_id === 116 && <td>
                                                %
                                                <NumericFormat
                                                    value={reports?.rat_sale_to_service_fee.toFixed(2).toString().replace('.', ',')}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                />
                                            </td>}
                                            
                                            <td>
                                                <NumericFormat
                                                    value={reports?.avg_day.toFixed(2).toString().replace('.', ',')}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="mt-3">
                                <div className="float-end">
                                    <button type="button" onClick={e => { extra_report_see ? setExtraReportSee(false) : setExtraReportSee(true); }} className="btn btn-soft-secondary btn-sm" >{extra_report_see ? 'Daha az' : 'Daha fazla'}</button>
                                </div>
                            </div>
                            <div style={extra_report_see ? {} : { display: 'none' }}>
                                <div className="d-inline-block table-responsive mt-3">
                                    <table className="table mb-0 table-bordered table-hover">
                                        <thead className="table-light">
                                            <tr>
                                                <th>KDV Durumu</th>
                                                <th style={{width:'120px'}}>KDV Oranı</th>
                                                <th>Ciro Kayıp Oranı</th>
                                                <th>Ciro Kaybı</th>
                                                <th>G. D. Payı Kaybı</th>
                                                <th>Ofis Payı Kaybı</th>
                                                <th>Durum</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="align-middle">
                                                <th><Checkbox checked={tax_status} 
                                                onClick={() => {
                                                    setTaxStatus((prevTaxStatus) => !prevTaxStatus);
                                                    setSendFilter(true);
                                                  }}
                                                >KDV Dahil</Checkbox>
                                                </th>
                                                <th>
                                                    <div className="input-group">
                                                        <div className="input-group-text p-2">
                                                            <strong>%</strong>
                                                        </div>
                                                        <CustomInput type={'number'} inputClass={'form-control'} value={tax_rate} onChange={(e:any) => {setTaxRate(e.target.value); setSendFilter(true);}}/>
                                                    </div>
                                                </th>
                                                <th>
                                                    <strong>%</strong>
                                                <NumericFormat
                                                        value={reports?.giro_loss_rate.toFixed(2).toString().replace('.', ',')}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        displayType="text"
                                                    />
                                                    </th>
                                                <td scope="row">
                                                      <GiroLossFormat value={reports?.giro_loss_price} />
                                                </td>
                                                <td>
                                                      <GiroLossFormat value={reports?.agent_loss_price} />
                                                </td>
                                                <td>
                                                      <GiroLossFormat value={reports?.office_loss_price} />
                                                </td>
                                                <td scope="row">
                                                    {reports?.giro_loss_rate <= 0 ? (
                                                        <strong className="text-success">Başarılı</strong>
                                                    ) : (
                                                        <strong className="text-danger">Başarısız</strong>
                                                    )}
                                                    </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <Table
                                className="table table-striped"
                                size="small"
                                columns={columns}
                                dataSource={data}
                                loading={loading}
                                scroll={{ y: '100%', x: true }}
                                rowKey={(record) => `${record.id}`}
                                onRow={(record) => {
                                    return {
                                        onDoubleClick: (event) => handleRowDoubleClick(record.id)
                                    };
                                }}
                                expandable={{
                                    expandedRowRender: (record) => {
                                        return (
                                            <>
                                                <div className="table-reponsive">
                                                    <table className={`table`}>
                                                        <thead>
                                                            <tr>
                                                                <th>{record.giro_type.id == 116 ? 'Satıcı' : 'Kiraya Veren'}</th>
                                                                <th>{record.giro_type.id == 116 ? 'Alıcı' : 'Kiracı'}</th>
                                                                <th>Portföy Adresi</th>
                                                                <th>m<sup>2</sup> Fiyatı</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{`${record.seller.first_name} ${record.seller.last_name}`}</td>
                                                                <td>{`${record.buyer.first_name} ${record.buyer.last_name}`}</td>
                                                                <td>{`${record.property?.address}`}</td>
                                                                <td>
                                                                    <strong>
                                                                        <NumericFormat
                                                                            value={(parseFloat(record.sale_price) / parseFloat(record.property?.gross)).toFixed(2)}
                                                                            thousandSeparator="."
                                                                            decimalSeparator=","
                                                                            displayType="text"
                                                                        /> ₺
                                                                    </strong>
                                                                </td>
                                                                <td><a href={`https://www.google.com/maps/dir//${record.property?.coordinate}`} target={'_blank'} rel="nofollow">Harita görünümü</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        )
                                    },
                                    rowExpandable: (record) => record.seller.first_name !== 'Not Expandable',
                                }}
                                pagination={pagination}
                                onChange={(val: any, filter: any, sorter: any, extra: any) => {
                                    setPagination({
                                        current: val.current,
                                        pageSize: val.pageSize,
                                        currentPageSize: val.currentPageSize
                                    });
                                    setSortReq(sorter);
                                    setSort(true);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Giros;