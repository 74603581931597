import { useState, useEffect } from 'react';
import { createWatchSchedule, deleteWatchSchedule, fetchWatchSchedules, updateWatchSchedule } from '../services/watchScheduleService';
import { WatchSchedules } from '../../models/asistant/watchSchedule/watchSchedule';
import { WatchScheduleResponse } from '../../models/asistant/watchSchedule/WatchScheduleResponse';

const useWatchSchedules = (params?: Record<string, any>, onSuccess?: () => void) => {
  const [watchSchedules, setWatchSchedules] = useState<WatchSchedules[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [builder, setBuilder] = useState<any>({ sort_by: 'schedule_date', sort_order: null, limit: null });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const loadWatchSchedules = async () => {
    setLoading(true);
    try {
      const data: WatchScheduleResponse | any = await fetchWatchSchedules(params);
      setWatchSchedules(data.watch_schedule.data.data);
      setCurrentPage(data.watch_schedule.data.current_page);
      setBuilder(data.builder);
    } catch (err) {
      setError('Failed to fetch watch schedules');
    } finally {
      setLoading(false);
    }
  };
  
  const reloadWatchSchedules = () => {
    loadWatchSchedules();
  };

  useEffect(() => {
    loadWatchSchedules();
  }, [params]);

  const addWatchSchedule = async (watchScheduleData: WatchSchedules) => {
    try {
      await createWatchSchedule(watchScheduleData);
      if (onSuccess) onSuccess();
      reloadWatchSchedules();
    } catch (err) {
      setError('Failed to add watch schedule');
    }
  };

  const editWatchSchedule = async (id: number, watchScheduleData: WatchSchedules) => {
    try {
      await updateWatchSchedule(id, watchScheduleData);
      if (onSuccess) onSuccess();
      reloadWatchSchedules();
    } catch (err) {
      setError('Failed to update watch schedule');
    }
  };

  const removeWatchSchedule = async (id: number) => {
    try {
      await deleteWatchSchedule(id);
      if (onSuccess) onSuccess();
      reloadWatchSchedules();
    } catch (err) {
      setError('Failed to delete watch schedule');
    }
  };

  return { watchSchedules, currentPage, builder, loading, error, addWatchSchedule, editWatchSchedule, removeWatchSchedule, reloadWatchSchedules };
};

export default useWatchSchedules;
