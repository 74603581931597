import { useState, useEffect } from 'react';
import { WorkSchedules } from '../../../models/employee/workSchedule/workSchedule';
import { WorkScheduleResponse } from '../../../models/employee/workSchedule/WorkScheduleResponse';
import { createWorkSchedule, deleteWorkSchedule, fetchWorkSchedules, updateWorkSchedule } from '../../services/workSchedule/workScheduleActionService';

const useWorkSchedules = (params?: Record<string, any>, onSuccess?: () => void) => {
  const [workSchedules, setWorkSchedules] = useState<WorkSchedules[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [builder, setBuilder] = useState<any>({ sort_by: 'working_date', sort_order: null, limit: null });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const loadWorkSchedules = async () => {
    setLoading(true);
    try {
      const data: WorkScheduleResponse | any = await fetchWorkSchedules(params);
      setWorkSchedules(data.work_schedule.data.data);
      setCurrentPage(data.work_schedule.data.current_page);
      setBuilder(data.builder);
    } catch (err) {
      setError('Failed to fetch work schedules');
    } finally {
      setLoading(false);
    }
  };
  
  const reloadWorkSchedules = () => {
    loadWorkSchedules();
  };

  useEffect(() => {
    loadWorkSchedules();
  }, [params]);

  const addWorkSchedule = async (workScheduleData: WorkSchedules) => {
    try {
      await createWorkSchedule(workScheduleData);
      if (onSuccess) onSuccess();
      reloadWorkSchedules();
    } catch (err) {
      setError('Failed to add work schedule');
    }
  };

  const editWorkSchedule = async (id: number, workScheduleData: WorkSchedules) => {
    try {
      await updateWorkSchedule(id, workScheduleData);
      if (onSuccess) onSuccess();
      reloadWorkSchedules();
    } catch (err) {
      setError('Failed to update work schedule');
    }
  };

  const removeWorkSchedule = async (id: number) => {
    try {
      await deleteWorkSchedule(id);
      if (onSuccess) onSuccess();
      reloadWorkSchedules();
    } catch (err) {
      setError('Failed to delete work schedule');
    }
  };

  return { workSchedules, currentPage, builder, loading, error, addWorkSchedule, editWorkSchedule, removeWorkSchedule, reloadWorkSchedules };
};

export default useWorkSchedules;
