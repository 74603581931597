import axios from "axios";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link, Navigate, NavLink, useParams, } from "react-router-dom";
import Wrapper from "../../components/layout/wrapper";
import { Role } from "../../models/role";
import { TeamLeader } from "../../models/user/teamLeader";
import { Select, Switch } from "antd";
import { NumericFormat } from "react-number-format";
import { User } from "../../models/user";
import { Category } from "../../models/user/category";

const { Option } = Select;

const Edit = (props: any) => {
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirm, setPasswordConfirm] = useState('');
    const [birthday, setBirthday] = useState('');
    const [employment_start_date, setEmploymentStartDate] = useState('');
    const [role_id, setRoleId] = useState(0);
    const [team_leader_id, setTeamLeaderId] = useState<any | null>(null);
    const [team_leader_percent, setTeamLeaderPercent] = useState<any | null>(0);
    const [roles, setRoles] = useState([]);
    const [whatsapp, setWhatsapp] = useState('');
    const [user_search, setUserSearch] = useState('');
    const [category_id, setCategoryId] = useState<any | null>(null);
    const [current_id, setCurrentId] = useState<any | null>(null);
    const [status_id, setStatusID] = useState(255);
    const [checkedStatus, setCheckedStatus] = useState(true);

    const [selected_users, setSelectedUsers] = useState([]);
    const [user_name, setUserName] = useState<any | null>('');

    const [team_leaders, setTeamLeaders] = useState([]);
    const [user_categories, setUserCategories] = useState([]);
    const [active_users, setActiveUsers] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        (
            async () => {
                const response = await axios.get('roles');

                setRoles(response.data);
                const {data} = await axios.get(`users/${id}`);
                setFirstName(data.first_name);
                setLastName(data.last_name);
                setEmail(data.email);
                setRoleId(data.role.id);
                setPhone(data.phone);
                setBirthday(data.birthday);
                setEmploymentStartDate(data.employment_start_date);
                setRoleId(data.role_id);
                setWhatsapp(data.whatsapp);
                setCategoryId(data.category_id);
                setCurrentId(data.current_id);
                setStatusID(data.status_id);
                setCheckedStatus(data.status_id==255?true:false);
                setTeamLeaderId(data.team_leader_id);
                setTeamLeaderPercent(data.team_leader_percent);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('roles');

                setRoles(data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('user/user-categories');

                setUserCategories(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('users');
                if (user_search.length > 0) {
                    const { data } = await axios.get('users/search/' + user_search);
                }

                setTeamLeaders(data.data);
            }
        )()
    }, [user_search]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`users-filter?filter_status_id=255&limit=10000`);
                setActiveUsers(data.users.data.data);
            }
        )()
    }, []);

    const onChange = (checked: boolean) => {
        setCheckedStatus(checkedStatus?false:true);   
        checked?setStatusID(255):setStatusID(256);
      };



    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        await axios.put(`users/${id}`, {
            first_name,
            last_name,
            email,
            role_id,
            category_id,
            current_id,
            team_leader_id,
            team_leader_percent,
            whatsapp,
            phone,
            employment_start_date,
            status_id,
            birthday
        });

        setRedirect(true);
    }

    if (redirect) {
        return <Navigate to={'/users'}/>;
     }

    return(
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Kullanıcı Ekle</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><NavLink to={'/'}>Gösterge Paneli</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to={'/users'}>Kullanıcılar</NavLink></li>
                                <li className="breadcrumb-item active">Güncelle</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>        
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-4">
                            <div>
                                <form onSubmit={submit}>
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" required data-pristine-required-message="Lütfen bir isim giriniz."
                                                    className="form-control"
                                                defaultValue={first_name}

                                                    onChange={e => setFirstName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" required data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                defaultValue={last_name}

                                                    onChange={e => setLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" required data-pristine-required-message="Lütfen bir eposta adresi giriniz."
                                                    className="form-control"
                                                defaultValue={email}

                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <input type="text" required data-pristine-required-message="Lütfen bir telefon numarası giriniz."
                                                    className="form-control"
                                                defaultValue={phone}

                                                    onChange={e => setPhone(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Whatsapp</label>
                                                <input type="text" required data-pristine-required-message="Lütfen bir telefon numarası giriniz."
                                                    className="form-control"
                                                defaultValue={whatsapp}

                                                    onChange={e => setWhatsapp(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Cari ID</label>
                                                <input type="text" required data-pristine-required-message="Lütfen bir telefon numarası giriniz."
                                                    className="form-control"
                                                defaultValue={current_id}

                                                    onChange={e => setCurrentId(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kategori</label>
                                                <Select className="form-control" defaultValue={category_id} showSearch
                                                    value={category_id}
                                                    allowClear={true}
                                                    placeholder="Kategori Seçiniz"
                                                    onChange={e => setCategoryId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );
                                                    }}
                                                >
                                                    {user_categories.map((user_categories: Category) => {
                                                        return (
                                                            <Option value={user_categories.id} title={user_categories.name}
                                                                key={user_categories.name}
                                                                id={user_categories.name}>{user_categories.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className={team_leader_id > 0 ? 'col-xl-3 col-md-3' : 'col-xl-6 col-md-6'}>
                                            <div className="form-group mb-3 select-style">
                                                <label>Takım Lideri</label>
                                                <Select className="form-control" defaultValue={team_leader_id} showSearch
                                                    value={team_leader_id}
                                                    allowClear={true}
                                                    placeholder="Takım Lideri Seçiniz"
                                                    onChange={e => setTeamLeaderId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        setUserSearch(input);
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                    onClear={() => {setTeamLeaderId(0); setTeamLeaderPercent(0);}}
                                                >

                                                    {team_leaders.map((team_leaders: TeamLeader) => {
                                                        return (
                                                            <Option value={team_leaders.id} title={team_leaders.first_name + ' ' + team_leaders.last_name}
                                                                key={team_leaders.first_name + ' ' + team_leaders.last_name}
                                                                id={team_leaders.first_name + ' ' + team_leaders.last_name}>{team_leaders.first_name + ' ' + team_leaders.last_name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={team_leader_id > 0 ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Takım Lideri Paylaşım Yüzdesi  </label>
                                                <div className="input-group">
                                                    <div className="input-group-text"><strong>%</strong></div>
                                                    <NumericFormat
                                                        data-pristine-required-message="Toplam hizmet bedelini giriniz."
                                                        className="form-control"
                                                        decimalSeparator=","
                                                        value={team_leader_percent}
                                                        onChange={e => { setTeamLeaderPercent(e.target.value.toString().replace(',', '.')); }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Doğum Tarihi</label>
                                                <input type="date" required data-pristine-required-message="Lütfen bir doğum tarihini seçiniz."
                                                    className="form-control"
                                                defaultValue={birthday}

                                                    onChange={e => setBirthday(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>İşe Başlama Tarihi</label>
                                                <input type="date" required
                                                    className="form-control"
                                                defaultValue={employment_start_date}

                                                    onChange={e => setEmploymentStartDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Rol</label>
                                                <select className="form-control" 
                                                defaultValue={role_id}
                                                value={role_id}
                                                onChange={e => setRoleId(Number(e.target.value))}>
                                                    {roles.map((r: Role) => {
                                                        return (
                                                            <option key={r.id} value={r.id}>{r.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3 select-style">
                                                <label>Sorumlusu Olduğu Danışmanlar</label>
                                                <Select className="form-control" showSearch
                                                    placeholder="Danışman Seçiniz"
                                                    mode="tags"
                                                    allowClear={true}
                                                    onChange={e => setSelectedUsers(e)}
                                                    onSearch={e => setUserName(e)}
                                                    optionFilterProp="children"
                                                >
                                                    {active_users?.map((users: User) => {
                                                        return (
                                                            <Option value={users.id} title={users.name}
                                                                key={users.name}
                                                                id={users.name}>{`${users.first_name} ${users.last_name}`}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-group mb-3">
                                                <label>Durum</label>
                                                <Switch className="ms-2"
                                                    checked={checkedStatus}
                                                    onChange={onChange}
                                                    checkedChildren="Aktif" unCheckedChildren="Pasif"
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary">Güncelle</button>
                                    </div>
                                </form>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Edit;