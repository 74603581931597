
import { Customer } from "../../accounting/customer/customer";
import { Attribute } from "../../attribute/attribute";
import { Contact } from "../../attribute/contact/contact";
import { CustomerType } from "../../attribute/customerType/customerType";
import { PropertyGenus } from "../../attribute/propertyGenus/propertyGenus";
import { PropertyType } from "../../attribute/propertyType/propertyType";
import { Status } from "../../attribute/status/status";
import { User } from "../../user";

export class WatchScheduleActions {
    constructor(
        public id = 0,
        public first_name = '',
        public last_name = '',
        public email = '',
        public phone = '',
        public definition = '',
        public customer = new Customer(),
        public customerType = new CustomerType(),
        public propertyType = new PropertyType(),
        public propertyGenus = new PropertyGenus(),
        public contact = new Contact(),
        public customerApplicationType = new Attribute(),
        public status = new Status(),
        public meet_date='',
        public meet_time='',
        public ip = '',
        public user = new User(),
        public agent = new User(),
        public created_at=''

    ) {
    }
}