import { User } from "../../models/user";

interface UserState {
  user: User;
}

const initialState: UserState = {
  user: new User()
};

export const setUserReducer = (state = initialState, action: { type: string, user: User }) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user
      }
    default:
      return state;
  }
}
