import React from 'react';
import { Table, TableProps } from 'antd';
import { ColumnType } from 'antd/lib/table';

interface AntDesignTableProps<T> extends TableProps<T> {
  columns: ColumnType<T>[];
  data: T[];
  isLoading: boolean;
  rowKey?: keyof T;
}

const AntDesignTable = <T extends object>({
  columns,
  data,
  isLoading,
  rowKey,
  ...props
}: AntDesignTableProps<T>) => {
  return (
    <Table<T>
      columns={columns}
      dataSource={data}
      loading={isLoading}
      rowKey={rowKey ? (record) => record[rowKey as keyof T] as string : undefined}
      pagination={{ pageSize: 10 }}
      {...props}
    />
  );
};

export default AntDesignTable;
