type SelectOption = {
    label: string
    value: string
}

const isSelectOption = (v: any): v is SelectOption => {
    if ((v as SelectOption).value !== undefined) return v.value
    return false
}

export default isSelectOption;