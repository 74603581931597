import React from 'react';
import { Modal, ModalProps } from 'antd';

interface AntDesignModalProps extends ModalProps {
  onOk?: () => void;
  onCancel?: () => void;
  children: React.ReactNode;
}

const AntDesignModal: React.FC<AntDesignModalProps> = ({
  onOk,
  onCancel,
  children,
  ...props
}) => {
  return (
    <Modal
      {...props}
      onOk={onOk}
      onCancel={onCancel}
    >
      {children}
    </Modal>
  );
};

export default AntDesignModal;
