import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink, Navigate } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import Swal from 'sweetalert2';
import { DatePicker, Select, Table, Tag } from 'antd';

import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import { useUserContext } from "../../../providers/user/userProvider";
import { Category } from "../../../models/user/category";
import { User } from "../../../models/user";
import { useStatusContext } from "../../../providers/attribute/status/statusProvider";
import { Attribute } from "../../../models/attribute/attribute";

//Table
import type { ColumnsType, TableProps } from 'antd/es/table';
import { NumericFormat } from "react-number-format";
import { ThumbsDown } from "react-feather";
import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import Breadcrumb from "../../../components/constant/breadcrumb";
import { useExpenseCategoryContext } from "../../../providers/attribute/expenseCategory/expenseCategoryProvider";
import AntDesignRangePicker from "../../../components/constant/antDesign/rangePicker";

const { Option, OptGroup } = Select;

dayjs.locale("tr");
const { RangePicker } = DatePicker;
const rangePresets: any = {
    'Son 7 Gün': [dayjs().subtract(7, 'days'), dayjs()],
    'Bu Ay': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Geçen Ay': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Bu Yıl': [dayjs().startOf('year'), dayjs()],
    'Geçen Yıl': [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
    '1. Çeyrek': [dayjs().startOf('year').add(0, 'month').startOf('month'), dayjs().startOf('year').add(4, 'month').endOf('month')],
    '2. Çeyrek': [dayjs().startOf('year').add(3, 'month').startOf('month'), dayjs().startOf('year').add(5, 'month').endOf('month')],
    '3. Çeyrek': [dayjs().startOf('year').add(6, 'month').startOf('month'), dayjs().startOf('year').add(8, 'month').endOf('month')],
    '4. Çeyrek': [dayjs().startOf('year').add(9, 'month').startOf('month'), dayjs().startOf('year').add(11, 'month').endOf('month')]
};

const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
for (let i = 0; i < 12; i++) {
    rangePresets[monthNames[i]] = [dayjs().month(i).startOf('month'), dayjs().month(i).endOf('month')];
}


//Table
interface DataType {
    key: React.Key;
    id(id: any): unknown;
    type: any;
    month: any;
    category: string;
    price: string;
    status: Attribute;
    agent: User;
}

const Expenses = () => {

    //Table 
    const [data, setData] = useState<DataType[]>([]);
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState<any | null>(null);
    const [pagination, setPagination] = useState<any | null>({
        current: 1,
        pageSize: 100,
        currentPageSize: 100,
        total: reports?.total,
        pageSizeOptions: [10, 20, 50, 100, 500, 100],
        showQuickJumper: true,
        showSizeChanger: true,
        showLessItems: true,
        responsive: true
    });
    const [filter_url, setFilterUrl] = useState<any | null>('');
    const [sort, setSort] = useState(false);
    const [sort_url, setSortUrl] = useState<any | null>('&sort[order]=desc');
    const [sort_req, setSortReq] = useState<any | null>([]);

    //For filter
    const [selectedDateName, setSelectedDateName] = useState<string>('Bu Ay');
    const [date_range, setDateRange] = useState<any | null>([]);
    const [start_date, setStartDate] = useState<any | null>(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [end_date, setEndDate] = useState<any | null>(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [send_filter, setSendFilter] = useState(false);
    const [category_id, setCategoryId] = useState<any | null>(null);
    const [status_id, setStatusId] = useState<any | null>(null);
    const [filter, setFilter] = useState(false);
    const [clear_filter_inputs, setClearFilterInputs] = useState(false);

    const [users, setUsers] = useUserContext();
    const [expense_categories, setExpenseCategories] = useExpenseCategoryContext();
    const [statuses, setStatuses] = useStatusContext();
    const [selected_categories, setSelectedCategories] = useState([]);
    const [redirect_id, setRedirectId] = useState(0);

    const columns: ColumnsType<DataType> = [

        {
            title: 'Ay',
            key: 'payment_date',
            dataIndex: 'payment_date',
            responsive: ["md", "lg", "xl", "xxl"],
            render: (created_at) => (
                <>
                    <Tag className={`label label-${moment(created_at, "DD/MM/YYYY").format('MM')}`}>{moment(created_at, "DD/MM/YYYY").format('MMMM')}</Tag>
                </>
            ),

        },
        {
            title: 'Ödeme Tarihi',
            dataIndex: 'payment_date',
            sorter: true
        },
        {
            title: 'Kategori',
            dataIndex: 'category',
            sorter: false,
            render: (category) => {
                return (
                    <>
                        {category?.name}
                    </>
                );
            },
        },
        {
            title: 'Durum',
            dataIndex: 'status',
            sorter: false,
            render: (status) => {
                let color = '';
                if (status) {
                    if (status?.id == 151) { color = 'green'; } else { color = 'red'; }
                }
                return (
                    <>
                        <Tag color={color}>{status?.name}</Tag>
                    </>
                );
            },
        },
        {
            title: 'Tutar',
            responsive: ["md", "lg", "xl", "xxl"],
            dataIndex: 'price',
            className: 'text-end',
            sorter: true,
            render: (price) => {
                return (
                    <>
                        <strong><NumericFormat
                            value={Number(price).toFixed(2).toString().replace('.', ',')}
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                        /> ₺
                        </strong>
                    </>
                );
            },
        },
        {
            render: (record) => {
                return (
                    <>
                        <div className="dropdown">
                            <button className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="bx bx-dots-horizontal-rounded"></i>
                            </button>

                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <Link to={`/accounting/expenses/${record.id}/edit`}
                                        className="dropdown-item">Güncelle</Link>
                                </li>
                                <li><a className="dropdown-item" href="#" onClick={() => del(record.id)}>Sil</a></li>
                            </ul>
                        </div>
                    </>
                );
            },
        }
    ];

    useEffect(() => {
        let fUrl = '';
        if (parseInt(status_id) > 0) {
            fUrl = `&filter_status_id=${status_id}`;
        }

        if (selected_categories?.length > 0) {
            selected_categories.forEach((category_id: number) => {
                fUrl = `${fUrl}&filter_category_id[]=${category_id}`;
            });
        }

        if (category_id > 0) {
            fUrl = `${fUrl}&filter_user_category=${category_id}`;
        }

        setFilterUrl(fUrl);
        setFilter(false);
        setSendFilter(true);
    }, [filter]);

    useEffect(() => {
        setTimeout(() => {
            let sUrl = '';
            let sType = '';
            if (sort_req.order === undefined) {
                sUrl = '`&sort[by]=payment_date&sort[order]=desc';
            } else {
                (sort_req.order == 'ascend') ? sType = '&sort[order]=asc' : sType = '&sort[order]=desc';
                sUrl = `&sort[by]=${sort_req.field}`;
            }
            setSortUrl(sUrl + sType);
            setSort(false);
            setSendFilter(true);
        }, 500);
    }, [sort]);
    

    useEffect(() => {
        (
            async () => {
                if (send_filter) {
                    setLoading(true);
                    axios.get(`cashbooks?limit=${pagination.pageSize}&page=${pagination.current}${sort_url}&filter_between[]=${start_date}&filter_between[]=${end_date}${filter_url}&filter_type=148`)
                        .then(({ data }) => {
                            setData(data.cashbooks.data);
                            setReports(data.cashbooks.report);
                            setPagination({
                                current: pagination.current,
                                pageSize: pagination.pageSize,
                                currentPageSize: pagination.currentPageSize,
                                total: data.cashbooks.report?.total
                            });
                            setLoading(false);
                        });
                    setSendFilter(false);
                }
            }
        )()
    }, [send_filter]);

    useEffect(() => {
        if (clear_filter_inputs) {
            setSelectedCategories([]);
            setDateRange([]);
            setStatusId(null);
            setClearFilterInputs(false);
            setFilter(true);
        }
    }, [clear_filter_inputs]);

    const handleRowDoubleClick = (id: any) => {
        setRedirectId(id);
    }

    const del = async (id: number) => {

        Swal.fire({
            title: 'Emin misiniz?',
            text: "Kalıcı olarak silinecektir",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c84ee',
            cancelButtonColor: '#fd625e',
            confirmButtonText: 'Evet, sil!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.delete(`cashbooks/${id}`);
                setData(data.filter((u: any) => u.id !== id));
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Veri silindi',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }

    if (redirect_id > 0) {
        return <Navigate to={`/accounting/expenses/${redirect_id}/edit`} />;
    }

    const items: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Giderler', active: true },
    ];

    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Giderler</h4>
                        <div className="page-title-right">
                            <Breadcrumb items={items} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-sm-6 mb-3">
                                    <div className="form-group select-style">
                                        <label className="d-block">Tarih Aralığı ( {selectedDateName} )</label>
                                        {/* <RangePicker
                                            format={'DD/MM/YYYY'}
                                            locale={locale}
                                            ranges={rangePresets}
                                            value={date_range}
                                            style={{ width: '100%' }}
                                            onChange={(value, dateString) => {
                                                setDateRange(value);
                                                setStartDate(dayjs(dateString.toString().split(',')[0], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setEndDate(dayjs(dateString.toString().split(',')[1], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setSendFilter(true);
                                            }
                                            }
                                        /> */}
                                        <AntDesignRangePicker
                                        dateRange={date_range}
                                        setDateRange={setDateRange}
                                        setStartDate={setStartDate}
                                        setEndDate={setEndDate}
                                        setSendFilter={setSendFilter}
                                        setSelectedDateName={setSelectedDateName}
                                    />
                                    </div>
                                </div>
                                <div className="col-lg-2 col-sm-6">
                                    <div className="form-group select-style">
                                        <label>Ödeme Durumu</label>
                                        <Select className="form-control" defaultValue={status_id} showSearch
                                            value={status_id}
                                            allowClear={true}
                                            placeholder="Ödeme Durumu Seçiniz"
                                            onChange={e => { setStatusId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );
                                            }}
                                        >
                                            {statuses.map((statuses: Attribute) => {
                                                return (
                                                    <Option value={statuses.id} title={statuses.name}
                                                        key={statuses.name}
                                                        id={statuses.name}>{statuses.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-sm-10">
                                    <div className="form-group select-style">
                                        <label>Kategori (ler)</label>
                                        <Select className="form-control" showSearch
                                            placeholder="Kategori Seçiniz"
                                            onChange={e => { setSelectedCategories(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            mode="multiple"
                                            allowClear={true}
                                            value={selected_categories}
                                            defaultValue={selected_categories}
                                            onSearch={value => {
                                                const lowercaseValue = value.toLowerCase();
                                                const filteredOptions = expense_categories.map((parent_category: any) => {
                                                    const filteredCategory = parent_category.category.filter((child_category: any) => {
                                                        return child_category.name.toLowerCase().includes(lowercaseValue);
                                                    });
                                                    return { ...parent_category, category: filteredCategory };
                                                });
                                                return filteredOptions;
                                            }}
                                        >
                                            {expense_categories.map((parent_category: any) => (
                                                <OptGroup label={parent_category.name} key={parent_category.id}>
                                                    {parent_category.category.map((child_category: any) => (
                                                        <Option value={child_category.id} title={child_category.name} key={child_category.id}>
                                                            {child_category.name}
                                                        </Option>
                                                    ))}
                                                </OptGroup>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="float-start">
                                    <button type="button" onClick={e => setClearFilterInputs(true)} className="btn btn-soft-light btn-sm" >Temizle</button>
                                </div>
                                <div className="float-end">
                                    <Link to="/accounting/expenses/create" className="btn btn-light btn-sm"><i className="bx bx-plus me-1"></i> Yeni Ekle</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table mb-0 table-bordered table-hover">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Toplam Tutar</th>
                                            <th>Toplam İşlem</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td scope="row">
                                                <strong>
                                                    <NumericFormat
                                                        value={reports?.price.toString().replace('.', ',')}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        displayType="text"
                                                    /> ₺
                                                </strong>
                                            </td>
                                            <td>{reports?.total}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <Table
                                className="table table-striped"
                                size="small"
                                columns={columns}
                                dataSource={data}
                                loading={loading}
                                scroll={{ y: '100%', x: true }}
                                rowKey={(record) => `${record.id}`}
                                onRow={(record) => {
                                    return {
                                        onDoubleClick: (event) => handleRowDoubleClick(record.id)
                                    };
                                }}
                                pagination={pagination}
                                onChange={(val: any, filter: any, sorter: any, extra: any) => {
                                    setPagination({
                                        current: val.current,
                                        pageSize: val.pageSize,
                                        currentPageSize: val.currentPageSize
                                    });
                                    setSortReq(sorter);
                                    setSort(true);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Expenses;