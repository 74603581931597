import { useState, useEffect } from 'react';
import { WatchScheduleActions } from '../../models/asistant/watchSchedule/watchScheduleAction';
import { createWatchScheduleAction, deleteWatchScheduleAction, fetchWatchScheduleActions, updateWatchScheduleAction } from '../services/watchScheduleActionService';
import { WatchScheduleActionResponse } from '../../models/asistant/watchSchedule/WatchScheduleActionResponse';

const useWatchScheduleActions = (params?: Record<string, any>, onSuccess?: () => void) => {
  const [watchScheduleActions, setWatchScheduleActions] = useState<WatchScheduleActions[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [builder, setBuilder] = useState<any>({ sort_by: 'schedule_date', sort_order: null, limit: null });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const loadWatchScheduleActions = async () => {
    setLoading(true);
    try {
      const data: WatchScheduleActionResponse | any = await fetchWatchScheduleActions(params);
      setWatchScheduleActions(data.watch_schedule.data.data);
      setCurrentPage(data.watch_schedule.data.current_page);
      setBuilder(data.builder);
    } catch (err) {
      setError('Failed to fetch watch schedules');
    } finally {
      setLoading(false);
    }
  };

  const reloadWatchScheduleActions = () => {
    loadWatchScheduleActions();
  };

  useEffect(() => {
    loadWatchScheduleActions();
  }, [params]);

  const addWatchScheduleAction = async (data: WatchScheduleActions) => {
    try {
      await createWatchScheduleAction(data);
      if (onSuccess) onSuccess();
      reloadWatchScheduleActions();
    } catch (err) {
      setError('Failed to add watch schedule');
    }
  };

  const editWatchScheduleAction = async (id: number, data: WatchScheduleActions) => {
    try {
      await updateWatchScheduleAction(id, data);
      if (onSuccess) onSuccess();
      reloadWatchScheduleActions();
    } catch (err) {
      setError('Failed to update watch schedule');
    }
  };

  const removeWatchScheduleAction = async (id: number) => {
    try {
      await deleteWatchScheduleAction(id);
      if (onSuccess) onSuccess();
      reloadWatchScheduleActions();
    } catch (err) {
      setError('Failed to delete watch schedule');
    }
  };

  return { watchScheduleActions, currentPage, builder, loading, error, addWatchScheduleAction, editWatchScheduleAction, removeWatchScheduleAction, reloadWatchScheduleActions };
};

export default useWatchScheduleActions;
