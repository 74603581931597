import axios from "axios";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Spin, Tag } from 'antd';

import Mailto from "../../link/mailto";
import Tel from "../../link/tel";

const AuthorizationAgreementDetail = ({ id }: any) => {
  const [authorization_agreement, setAuthorizationAgreement] = useState<any | null>(null);

  useEffect(() => {
    (
      async () => {
        if (id > 0) {
          const { data } = await axios.get(`contract/authorization-agreements/${id}`);
          setAuthorizationAgreement(data);
        }
      }
    )()
  }, [id]);

  if (!authorization_agreement) {
    return (
      <>
        <div className="d-flex flex-column justify-content-center align-items-center mb-3 mt-5 mb-5">
          <div className="d-flex justify-content-between bd-highlight mb-3">
            <Spin></Spin>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="row">

      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>  {authorization_agreement.type?.id === 116 ? 'SATICI BİLGİLERİ' : 'KİRALAYAN BİLGİLERİ'}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>MÜŞTERİ TÜRÜ</th>
              <th>{authorization_agreement?.customer?.customerType?.name}</th>
            </tr>
            {authorization_agreement?.customer?.customer_type_id === 2 ?
              <>
                <tr>
                  <th>FİRMA ADI</th>
                  <td>{authorization_agreement?.customer?.company_name}</td>
                </tr>
                <tr>
                  <th>VERGİ DAİRESİ / VERGİ NUMARASI</th>
                  <td>{authorization_agreement?.customer?.tax_admin} / {authorization_agreement?.customer?.tax_number}</td>
                </tr>
              </>
              : null}
            <tr>
              <th>AD SOYAD</th>
              <td>{authorization_agreement?.customer?.first_name} {authorization_agreement?.customer?.last_name}</td>
            </tr>
            <tr>
              <th>{authorization_agreement?.customer?.idType?.name}</th>
              <td>{authorization_agreement?.customer?.id_no}</td>
            </tr>
            <tr>
              <th scope="row">TELEFON</th>
              <td><Tel phone={`${authorization_agreement?.customer?.phoneCode?.phone_code} ${authorization_agreement?.customer?.phone}`}>+{authorization_agreement?.customer?.phoneCode?.phone_code} {authorization_agreement?.customer?.phone}</Tel></td>
            </tr>
            {authorization_agreement?.customer?.email != '0' ?
              <tr>
                <th scope="row">EMAİL</th>
                <td><Mailto email={authorization_agreement?.customer?.email} body="Merhaba">{authorization_agreement?.customer?.email}</Mailto></td>
              </tr>
              : null}
            <tr>
              <th>DOĞUM TARİHİ</th>
              <td>{authorization_agreement?.customer?.birthday}</td>
            </tr>
            <tr>
              <th scope="row">ADRES</th>
              <td>{authorization_agreement?.customer?.address}</td>
            </tr>
            {authorization_agreement.customer?.definition != '0' ?

              <tr>
                <th scope="row">NOT</th>
                <td>{authorization_agreement?.customer?.definition}</td>
              </tr>
              : null}

          </tbody>
        </table>
      </div>

      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>SÖZLEŞME BİLGİLERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>BAŞLANGIÇ TARİHİ</th>
              <th>{authorization_agreement.start_date}</th>
            </tr>
            <tr>
              <th>BİTİŞ TARİHİ</th>
              <td>{authorization_agreement.end_date}</td>
            </tr>
            <tr>
              <th scope="row">YETKİ BELGE NO</th>
              <td><strong className="text-danger">{authorization_agreement.document_no}</strong></td>
            </tr>
            <tr>
              <th>{authorization_agreement.type?.id === 116 ? 'SATIŞ BEDELİ' : 'KİRALAMA BEDELİ'}</th>
              <td>  <strong>
                    <NumericFormat
                      value={authorization_agreement.price.toString().replace('.', ',')}
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                    /> TL
                  </strong></td>
            </tr>
            <tr>
              <th scope="row">PORTFÖY BULMA KAYNAĞI</th>
              <td>{authorization_agreement.source_of_find?.name}</td>
            </tr>
              <tr>
                <th scope="row">DURUM</th>
                <td><Tag color={authorization_agreement.status?.color}>{authorization_agreement.status?.name}</Tag></td>
              </tr>

          </tbody>
        </table>
      </div>
      <div className="clearfix border-bottom border-light mb-3"></div>
      <div className="col-12 col-md-6">
        <table className="table table-hover table-striped">
          <thead>
            <tr className="table-secondary">
              <th colSpan={2}>MÜLK BİLGİLERİ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{ width: 170 }}>GAYRİMENKUL CİNSİ</th>
              <th>{authorization_agreement.property?.propertyGenus?.name}</th>
            </tr>
            {authorization_agreement.property?.propertyGenus?.id !== 10 ?
              <>
                <tr>
                  <th>GAYRİMENKUL TÜRÜ</th>
                  <td>{authorization_agreement.property?.propertyType && authorization_agreement.property?.propertyType.name}</td>
                </tr>
              </>
              : null}
            <tr>
              <th>ADA</th>
              <td>{authorization_agreement.property?.bob}</td>
            </tr>
            <tr>
              <th>PARSEL</th>
              <td>{authorization_agreement.property?.parcel}</td>
            </tr>
            <tr>
              <th>İL</th>
              <td>{authorization_agreement.property?.province.name}</td>
            </tr>
            <tr>
              <th>İLÇE</th>
              <td>{authorization_agreement.property?.district?.name}</td>
            </tr>
            <tr>
              <th>MAHALLE</th>
              <td>{authorization_agreement.property?.neighbourhood?.name}</td>
            </tr>
            <tr>
              <th>TAM ADRES</th>
              <td>{authorization_agreement.property?.address}</td>
            </tr>
            {authorization_agreement.property_genus_id === 8 || authorization_agreement.property_genus_id === 10 ? null :
              <>
                <tr>
                  <th>BULUNDUĞU KAT</th>
                  <td>{authorization_agreement.property?.floorLocation?.name}</td>
                </tr>
              </>
            }
            <tr>
              <th>BİNA YAŞI</th>
              <td>{authorization_agreement.property?.build_date}</td>
            </tr>
            <tr>
              <th>BRÜT m<sup>2</sup></th>
              <td>{authorization_agreement.property?.brut}</td>
            </tr>
            {authorization_agreement.property_genus_id === 8 || authorization_agreement.property_genus_id === 10 ? null :
              <>
                <tr>
                  <th>NET m<sup>2</sup></th>
                  <td>{authorization_agreement.property?.net}</td>
                </tr>
                <tr>
                  <th>YAPI TÜRÜ</th>
                  <td>{authorization_agreement.property?.buildType?.name}</td>
                </tr>
                {authorization_agreement.property?.buildType.id === 115 ?
                  <>
                    <tr>
                      <th>SİTE</th>
                      <td>{authorization_agreement.property?.site?.name}</td>
                    </tr>
                  </>
                  : null}
              </>
            }
            {authorization_agreement.property?.definition != '0' && authorization_agreement.property?.definition != null ?
              <tr>
                <th scope="row">NOT</th>
                <td>{authorization_agreement.property?.definition}</td>
              </tr>
              : null}

          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AuthorizationAgreementDetail;

