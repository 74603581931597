
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { GiroTypeContext } from '../../../contexts/attribute/giroType/giroTypeContext';
import { Attribute } from '../../../models/attribute/attribute';

export const GiroTypeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [giro_types, setGiroTypes] = useState<Attribute[]>([]);

  useEffect(() => {
      let isMounted = true;

      async function getGiroTypes() {
        try {
          const { data } = await axios.get('attributes/11');
          if (isMounted) {
            setGiroTypes(data.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
  
      const timer = setTimeout(() => {
        getGiroTypes();
      }, 5000);
  
      return () => {
        isMounted = false;
        clearTimeout(timer);
      };
  }, []);


  return (
      <GiroTypeContext.Provider value={[giro_types, setGiroTypes]}>
          {children}
      </GiroTypeContext.Provider>
  );
}

export const useGiroTypeContext = () => useContext(GiroTypeContext);
