import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "axios";

// Firebase konfigürasyonu
const firebaseConfig = {
  apiKey: "AIzaSyA4ZrsurXwR2QD4Xx5-CpHPD4N3qvurBMo",
  authDomain: "remax-aqua-llupar.firebaseapp.com",
  projectId: "remax-aqua-llupar",
  storageBucket: "remax-aqua-llupar.appspot.com",
  messagingSenderId: "1077321259797",
  appId: "1:1077321259797:web:606130c8fb00a06694271c",
  measurementId: "G-YRTJFV8Y3P"
};

// Firebase uygulamasını başlat
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Kullanıcıdan bildirim izni iste ve FCM token al
export const requestNotificationPermission = async (notificationSendStatus: boolean, setNotificationSendStatus: (status: boolean) => void) => {
  // Bildirim izni durumu kontrol et
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    try {
      const currentToken = await getToken(messaging, { vapidKey: "BGNY6Ozx-Y9MKvP4HpEPm8tXw4s6UBGiWAKUoBliRvQQObsQ6iL6RvyWVevL9yHMzAMTxtMVi_r0VZSdX7Fuetc" });
console.log(messaging);

      if (currentToken) {
        console.log("FCM Token:", currentToken);

        if (!notificationSendStatus) {
          // FCM token'ı sunucuya gönder
          axios.post('notifications-subscriptions', {
            notification_id: currentToken
          }).then(() => {
            setNotificationSendStatus(true);
          });
        }
      } else {
        console.log("FCM token alınamadı. Bildirim izni gerekli.");
      }
    } catch (error) {
      console.error("FCM token alınırken hata oluştu:", error);
    }
  } else {
    console.log("Bildirim izni verilmedi.");
  }
};

// Foreground (Uygulama açıkken) bildirim dinleyici
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Mesaj alındı:", payload);
      resolve(payload);
    });
  });
