import React, { useState } from 'react';
import SatisfactionSurveyCarousel from '../customer/satisfactionSurveyCarousel';
import LeaseAgreementList from '../contract/lease-agreement/list';
import moment from 'moment';
import SatisfactionSurveyGaugeChart from '../customer/satisfactionSurveyGaugeChart';
import LeaseAgreementUpcominDeadlineList from '../contract/lease-agreement/upcomingDeadline';

const AgentDashboard = ({ props, selectedDateName, start_date, end_date, send_filter }: any) => {

  const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

  const startOfWeek = moment().startOf('isoWeek').format('YYYY-MM-DD');
  const endOfWeek = moment().endOf('isoWeek').format('YYYY-MM-DD');

  const startOfYear = moment().startOf('year').format('YYYY-MM-DD');
  const endOfYear = moment().endOf('year').format('YYYY-MM-DD');

  const [show_id, setShowID] = useState<number>(0);
  const [show_full_name, setShowFullName] = useState<any | null>('');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className="row">
        <div className="col-xl-8 col-lg-8">
          <div className="profile-content">
            <div className="row align-items-end">
              <div className="col-sm">
                <div className="d-flex align-items-end mt-5 mt-sm-5 pt-3">
                  <div className="flex-shrink-0">
                    <div className="avatar-xxl me-3">
                      <img src="/assets/images/users/avatar-1.jpg" alt="" className="img-fluid rounded-circle d-block img-thumbnail" />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <div>
                      <h5 className="font-size-16 mb-1">{props.user.name}</h5>
                      <p className="text-muted font-size-13 mb-2 pb-2">{props.user?.role?.name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4">
          <h2>23</h2> Gündür işlem yapmıyorsun. Artık harekete geçmenin zamanı gelmedi mi?
          <p>Son İşlem Tarihi: 12/08/2024</p>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-8 col-lg-8">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Merhaba, {props.user.first_name}</h5>
            </div>
            <div className="card-body">
              <div>
                <div className="pb-3">
                  <p>
                    Bugün harika işler başarmaya hazır mısın? Her adımda seni desteklemek için buradayız! Hadi mülk durumlarını kontrol edelim ve her şeyi mükemmel bir şekilde yönetelim. Senin uzmanlığınla işler her zaman yolunda! 💼✨
                  </p>
                  <h3>
                    <strong className='text-danger'>130</strong> gündür işlem yapmıyorsun.
                  </h3>
                  {/* <br/>
                  <h4><strong className='text-danger'>15</strong> gündür satış ya da kiralama işlemi yapmadın, ama bu sadece kısa bir ara! 💪 Hadi birlikte yeniden hızlanalım ve sıradaki büyük anlaşmanı kap! Şans seninle!</h4>
                 <h4><strong className='text-danger'>Bir ay</strong> oldu! 🌟 Ama büyük başarılar her an gelebilir. Hadi bu ayı yeni bir satış ya da kiralama işlemiyle taçlandıralım. Sen bunu başarabilirsin!</h4>
                 <h4> <strong className='text-danger'>45</strong> gündür bir işlem yapmadın, ama büyük fırsatlar her zaman kapıda! 🚪 Hadi, yeni bir mülkü listeleyelim ya da alıcılarla buluşturalım. Başarı çok yakın!</h4>
                 <h4>Tam  <strong className='text-danger'>60</strong> gün oldu! 🕒 Belki bir süre ara verdin, ama şimdi geri dönüş zamanı. Daha güçlü, daha odaklı! Hedefin sadece bir adım ötede. Yeni bir başarı için hazırsın!</h4>
                 <h4> <strong className='text-danger'>90</strong> gün uzun bir zaman olabilir, ama bu, geri dönüp işleri toparlamak için harika bir fırsat! 💼 Hadi yeniden sahaya çık, seni bekleyen fırsatlar var!</h4>
                <h4> <strong className='text-danger'>100</strong> günü geçti, ama bu sadece yeni bir başlangıcın işareti olabilir! 🌟 Şimdi tam zamanı: harekete geç, daha büyük başarılar ve kazançlar seni bekliyor. Başarı seninle!</h4> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Departman</h5>
            </div>
            <div className="card-body">
              <div className="d-flex flex-wrap gap-2 font-size-16">
                <a href="#" className="badge badge-soft-primary">{props.user.role.name}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className="col-xl-4">
          <div className="card">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Müşteri Yorumları</h4>
            </div>
            <div className="card-body">
              <SatisfactionSurveyCarousel />
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="card pb-0">
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Yenilemesi Yaklaşan Kira Kontratları</h4>
            </div>
            <div className="card-body px-3">
            <LeaseAgreementUpcominDeadlineList/>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <SatisfactionSurveyGaugeChart start_date={start_date} end_date={end_date} sendFilter={send_filter} dateTypeName={selectedDateName} />
        </div>
      </div>
    </>
  );
};

export default AgentDashboard;
