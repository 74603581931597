import { Link } from "react-router-dom";
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { AiOutlineUserSwitch } from 'react-icons/ai';
import { BiMapPin } from 'react-icons/bi';

//Table
import type { ColumnsType } from 'antd/es/table';

import { Table, Tag, Statistic } from "antd";
import { Modal, Button } from "react-bootstrap";
import ProfilePhoto from "../../../user/profilePhoto";
import { NumericFormat } from "react-number-format";
import { useEffect, useState } from "react";
import { GiroReport } from "../../../../models/report/giro/giroReport";
import { RankingAgent } from "../../../../models/report/giro/rankingAgent";
import axios from "axios";

import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { Property } from "../../../../models/general/property/property";
import { PropertyType } from "../../../../models/attribute/propertyType/propertyType";
import { PropertyGenus } from "../../../../models/attribute/propertyGenus/propertyGenus";
import { GiroType } from "../../../../models/attribute/giroType/giroType";
import { Customer } from "../../../../models/accounting/customer/customer";
import TransactionDetail from "../../../transaction/detail";
import { Status } from "../../../../models/attribute/status/status";
import { Attribute } from "../../../../models/attribute/attribute";
import { User } from "../../../../models/user";
import LeaseAgreementDetail from "../../../contract/lease-agreement/detail";
dayjs.locale("tr");
const today = dayjs().format('YYYY-MM-DD');
const fiveYearsLater = dayjs().add(5, 'year').format('YYYY-MM-DD');
const { Countdown } = Statistic;
//Table
interface DataType {
  id(id: any): unknown;
  per_month_price: any;
  created_at: any;
  start_date: any;
  end_date: any;
  status: Status;
  property: Property;
  propertyType: PropertyType;
  propertyGenus: PropertyGenus;
  document_no: string;
  key: React.Key;
  giro_type: GiroType;
  customer: Customer;
  partner_count: any;
}

const LeaseAgreementUpcominDeadlineList = ({ start_date, end_date }: any) => {
  const rowClassName = () => 'no-bottom-border'; // Özel sınıf adı

  const [show_id, setShowID] = useState<any | null>(0);
  const [show, setShow] = useState(false);
  const [show_full_name, setShowFullName] = useState<any | null>('');
  const handleClose = () => setShow(false);

  const [sort, setSort] = useState(false);
  const [sort_url, setSortUrl] = useState<any | null>('&sort[order]=asc');
  const [sort_req, setSortReq] = useState<any | null>([]);


  const [reports, setReports] = useState<GiroReport | any | null>(null);
  const [giro_type_data, setGiroTypeData] = useState<RankingAgent | any | null>();

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<any | null>({
    current: 1,
    pageSize: 20,
    currentPageSize: 100,
    total: reports?.total,
    pageSizeOptions: [10, 20, 50, 100, 500, 100],
    showQuickJumper: true,
    showSizeChanger: true,
    showLessItems: true,
    responsive: true,
  });

  const [data, setData] = useState<DataType[]>([]);

  const [redirect_id, setRedirectId] = useState(0);
  const handleRowDoubleClick = (id: any) => {
    setRedirectId(id);
  }
  useEffect(() => {
    (
      async () => {
        setLoading(true);
        axios.get(`contract/lease-agreements?limit=${pagination.pageSize}&page=${pagination.current}${sort_url}&sort[by]=end_date&filter_between_end_date[]=${today}&filter_between_end_date[]=${fiveYearsLater}`)
          .then(({ data }) => {
            setData(data.lease_agreement.data);
            setReports(data.lease_agreement?.report);
            setPagination({
              current: pagination.current,
              pageSize: pagination.pageSize,
              currentPageSize: pagination.currentPageSize,
              total: data.lease_agreement?.meta?.total
            });
            setLoading(false);
          });
      }
    )();
  }, []);

  //Table
  const columns: ColumnsType<DataType> = [
    {
      title: 'G. Tipi',
      key: 'property',
      dataIndex: 'property',
      render: (property, record: any) => {
        let giro_type = record?.type;
        let propertyGen = '';
        if (property?.propertyGenus) {
          propertyGen = property.propertyGenus.name;
          if (property.propertyType) {
            propertyGen = `${propertyGen} ${property.propertyType.name}`;
          }
        }
        let color = 'geekblue';
        return (
          <>
            <a onClick={() => { setShow(true); setShowID(record.id); setShowFullName(`${record.agent.first_name} ${record.agent.last_name}`); }}>
            {`${record?.seller.first_name} ${record?.seller.last_name}`}
            </a>
            <br />
            <div className="d-flex justify-content-start">
              <Tag color={color}>{propertyGen}</Tag>
            </div>
          </>
        );
      },
    },
    {
      title: 'Ciro',
      dataIndex: 'per_month_price',
      className: 'text-end',
      sorter: true,
      render: (per_month_price, record: any) => {
        return (
          <>
            <span>{record.end_date}</span>
            <br />
            <Tag color="red">
              <Countdown className="custom-antd-countdown"
                value={moment(record.end_date, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm')}
                format="D Gün HH:mm:ss"
                onFinish={() => {
                  return;
                }}

              />
            </Tag>
          </>
        );
      },
    },
    Table.EXPAND_COLUMN
  ];


  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl" animation={false}>
        {show_id && show_id !== null && show_id !== undefined &&
          <>
            <Modal.Header closeButton>
              <Modal.Title><ProfilePhoto id={show_id} imgHeight={35} /> <span className="ms-2">{show_full_name}</span> - <span className="text-danger">{show_id}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <LeaseAgreementDetail id={show_id} />
            </Modal.Body><Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Kapat
              </Button>
              <Link className="btn btn-primary" to={`/contract/lease-agreements/${show_id}/edit`}>
                Güncelle
              </Link>
            </Modal.Footer>
          </>
        }
      </Modal>
      <Table
        className="table table-striped"
        size="small"
        columns={columns}
        dataSource={data}
        showHeader={false}
        loading={loading}
        scroll={{ y: 300, x: true }}
        rowKey={(record) => `${record.id}`}
        onRow={(record) => {
          return {
            onDoubleClick: (event) => handleRowDoubleClick(record.id)
          };
        }}
        expandable={{
          expandedRowRender: (record: any) => {
            return (
              <>

                <div className="table-reponsive">
                  <table className={`table`}>
                    <tbody>
                      <tr>
                        <th>Kiraya Veren</th>
                        <td>{`${record?.seller.first_name} ${record?.seller.last_name}`}</td>
                      </tr>
                      <tr>
                        <th>Kiracı</th>
                        <td>{`${record?.buyer.first_name} ${record?.buyer.last_name}`}</td>
                      </tr>
                      <tr>
                        <th>Portföy Adresi</th>
                        <td>{`${record.property?.address}`}</td>
                      </tr>
                      <tr>
                        <th>Mülk Adresi</th>
                        <td>
                          {`${record.property?.address}`}
                        </td>
                      </tr>
                      <tr>
                        <th>Aylık Kira Bedeli</th>
                        <td>
                          <strong>
                            <NumericFormat
                              value={(parseFloat(record?.per_month_price)).toFixed(2)}
                              thousandSeparator="."
                              decimalSeparator=","
                              displayType="text"
                            /> ₺
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <th>Yıllık Kira Bedeli</th>
                        <td>
                          <strong>
                            <NumericFormat
                              value={(parseFloat(record?.per_year_price)).toFixed(2)}
                              thousandSeparator="."
                              decimalSeparator=","
                              displayType="text"
                            /> ₺
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}><a href={`https://www.google.com/maps/dir//${record.property?.coordinate}`} target={'_blank'} rel="nofollow">Harita görünümü</a></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </>
            )
          },
          rowExpandable: (record) => record.customer?.first_name !== 'Not Expandable',
        }}
        pagination={pagination}
        onChange={(val: any, filter: any, sorter: any, extra: any) => {
          setPagination({
            current: val.current,
            pageSize: val.pageSize,
            currentPageSize: val.currentPageSize
          });
          setSortReq(sorter);
          setSort(true);
        }}
      />
    </>
  );
}

export default LeaseAgreementUpcominDeadlineList;

