import { useState, useEffect, useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { fetchAttributes, createAttribute, updateAttribute, deleteAttribute } from '../../services/attributes/attributeService';
import { AttributeNew, AttributeNewResponse } from '../../../models/attribute/attributeNewData';

const useAttributes = (params?: Record<string, any>, onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  const { data, isLoading, error, refetch } = useQuery<AttributeNewResponse>(
    ['attributes', params],
    () => fetchAttributes(params),
    {
      enabled: Boolean(params),
      keepPreviousData: true,
      staleTime: 60000,
    }
  );

  const [attributes, setAttributes] = useState<AttributeNew[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [builder, setBuilder] = useState<any>({ sort_by: 'ordernum', sort_order: null, limit: null });

  useEffect(() => {
    if (data) {
      const transformedData = Object.values(data.attribute.data).flat() as AttributeNew[];
      setAttributes(transformedData);
      setCurrentPage(data.attribute.report?.total || 1);
      setBuilder(data.builder);
    }
  }, [data]);

  const mutationCreate = useMutation(
    (newAttribute: AttributeNew) => createAttribute(newAttribute),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('attributes');
        if (onSuccess) onSuccess();
      },
    }
  );

  const mutationUpdate = useMutation(
    ({ id, updatedAttribute }: { id: number; updatedAttribute: AttributeNew }) => updateAttribute(id, updatedAttribute),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('attributes');
        if (onSuccess) onSuccess();
      },
    }
  );

  const mutationDelete = useMutation(
    (id: number) => deleteAttribute(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('attributes');
        if (onSuccess) onSuccess();
      },
    }
  );

  const reloadAttributes = useCallback(() => {
    refetch();
  }, [refetch]);

  return {
    attributes,
    currentPage,
    builder,
    isLoading,
    error,
    addAttribute: mutationCreate.mutate,
    editAttribute: mutationUpdate.mutate,
    removeAttribute: mutationDelete.mutate,
    reloadAttributes,
  };
};

export default useAttributes;