import { AttributeNewResponse } from "../../../models/attribute/attributeNewData";
import { apiClient } from "../../config";
import { handleApiError } from "../../utils/errorHandler";

export const fetchAttributes = async (params?: Record<string, any>): Promise<AttributeNewResponse> => {
  try {
    const { data } = await apiClient.get('attributes-filter', { params });
    return data;
  } catch (error) {
    handleApiError(error);
    return new AttributeNewResponse(); 
  }
};

export const createAttribute = async (attributeData: Record<string, any>) => {
  try {
    return await apiClient.post('attributes', attributeData);
  } catch (error) {
    handleApiError(error);
  }
};

export const updateAttribute = async (id: number, attributeData: Record<string, any>) => {
  try {
    return await apiClient.put(`attributes/${id}`, attributeData);
  } catch (error) {
    handleApiError(error);
  }
};

export const deleteAttribute = async (id: number) => {
  try {
    return await apiClient.delete(`attributes/${id}`);
  } catch (error) {
    handleApiError(error);
  }
};