import { useEffect, useState } from 'react';
import axios from 'axios';
import DoughnutChartWithCard from '../chart/doughnutChartWithCard';
import ApexDoughnutChartWithCard from '../chart/ApexDoughnutChartWithCard';

const RecruitmentMeetCountApexDonutChart = ({ start_date, end_date, dateTypeName, sendFilter }: any) => {

    const [meet_report, setMeetReport] = useState<any | null>([]);
    const [meet_results, setMeetResults] = useState([]);

    const [meetsChartData, setMeetsChartData] = useState<any>({
        series: [''],
        options: {
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '10px', // Yazı tipi boyutunu buradan ayarlayabilirsiniz
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '50'
                    }
                }
            },
            legend: {
                show: false
            },
            colors: ["#ffcc5a", "#f04d80", "#6951ce", "#16daf1"],
            chart: {
                type: 'donut',
            },
            labels: [0],
        }
    });

    // useEffect(() => {
    //     const meetReportKeys = Object.keys(meet_report);
    //     const wantedProperties = meetReportKeys.filter(property => property.startsWith('total_result_'));
    //     const meetReportValues = wantedProperties.map(property => meet_report[property]);
    //     setMeetsChartData((prevState: any) => ({
    //         ...prevState,
    //             series:meetReportValues,
    //     }));
    // }, [meet_report]);

    useEffect(() => {
        if (meet_report) {
            const meetReportValues = Object.entries(meet_report).reduce((acc: number[], [key, value]: [string, any]) => {
                if (key !== 'total') {
                    acc.push(value.value);
                }
                return acc;
            }, []);
    
            setMeetsChartData((prevState: any) => ({
                ...prevState,
                series: meetReportValues,
            }));
        }
    }, [meet_report]);


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/52');
                setMeetsChartData((prevState: any) => ({
                    ...prevState,
                    options:{
                        labels:data.data.map((result: any) => result.name.toString()),
                    }
                }));
                
                const labels:any = data.data.map((result: any) => result.name.toString());
                setMeetsChartData((prevState: any) => ({
                    ...prevState,
                    options:{
                        label:labels
                    },
                }));
            }
        )()
    }, []);
    useEffect(() => {
        (
            async () => {
                const generateChartData = (propertyGenus: any) => {
                    const labels: any = [];
                    const data: any = [];
                    return {
                        series: data,
                        options: {
                          chart: {
                            type: 'donut',
                          },
                          labels: labels,
                        },

                    };
                };
                axios.get(`dashboard/meet-report?filter_between[]=${start_date}&filter_between[]=${end_date}`)
                    .then(({ data }) => {
                        setMeetReport(data?.meet?.report);
                    });
            }
        )()
    }, [sendFilter]);

    meetsChartData.options.labels = meet_results.map((result: any) => result.name.toString());

    return (
        <ApexDoughnutChartWithCard data={meetsChartData} count={meet_report?.total} dateTypeName={dateTypeName} text={'Recruiter Görüşmeleri'} url={'/recruitment/meets'} />
    );
};

export default RecruitmentMeetCountApexDonutChart;
