import { useEffect, useState } from 'react';
import axios from 'axios';
import { Carousel, Rate, Tag } from 'antd';
import { WatchSchedules } from '../../../models/asistant/watchSchedule/watchSchedule';
import ProfilePhoto from '../../user/profilePhoto';
import WatchScheduleList from '../watchSchedule/list';
import moment from 'moment';
import TransactionTypesCountApexDonutChart from '../transaction/transactionTypesCountApexDonutChart';
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';

const customIcons: Record<number, React.ReactNode> = {
    1: <FrownOutlined />,
    2: <FrownOutlined />,
    3: <MehOutlined />,
    4: <SmileOutlined />,
    5: <SmileOutlined />,
  };

const AgentPerformanceCarouselCard = ({ start_date, end_date, dateTypeName, send_filter }: any) => {

    const [watchSchedules, setWatchSchedules] = useState([]);
    const startOfWeek = moment().startOf('isoWeek').format('YYYY-MM-DD');

    useEffect(() => {
        (
            async () => {
                const todayDate = moment().format('YYYY-MM-DD');
                const { data } = await axios.get(`asistant/watch-schedules?limit=50&page=1&filter_between[]=${startOfWeek}&filter_between[]=${todayDate}&&sort[by]=schedule_date&sort[order]=desc`);
                setWatchSchedules(data.watch_schedule.data.data);
                const todaysPerson: any = watchSchedules.find((schedule: WatchSchedules) => schedule.schedule_date === todayDate);

                if (todaysPerson) {
                    localStorage.setItem('onDutyPerson', `${todaysPerson?.user?.first_name} ${todaysPerson?.user?.last_name}`);

                }
            }
        )()
    }, []);

    return (
        <Carousel autoplay autoplaySpeed={15000} dots={false}>
            {watchSchedules.map((watchSchedules: WatchSchedules) => {
                let scheduleDate = moment(watchSchedules.schedule_date);
                let displayDate;

                if (scheduleDate.isSame(moment(), 'day')) {
                    displayDate = "Bugün";
                } else if (scheduleDate.isSame(moment().subtract(1, 'day'), 'day')) {
                    displayDate = "Dün";
                } else {
                    displayDate = scheduleDate.format('dddd');
                }

                return (
                    <>
                        <div className="card-header align-items-center d-flex">
                            <div className="card-title mb-0 flex-grow-1">
                                <div className="d-flex justify-content-between align-items-center">

                                    <div className="d-flex align-items-center">
                                        <div>
                                            <ProfilePhoto id={watchSchedules.user?.id} imgClass="avatar-lg rounded-circle img-thumbnail" />
                                        </div>
                                        <div className="flex-1 ms-3">
                                            <h5 className="font-size-15 mb-1"><a href="#" className="text-dark">{watchSchedules.user?.first_name} {watchSchedules.user?.last_name}</a></h5>
                                            <p className="text-muted mb-0">{watchSchedules.user?.category?.name} ( Yapım aşamasında.  )</p>
                                        </div>
                                    </div>
                                    <div className="float-end d-none d-lg-inline">
                                        <p className="text-muted mb-0 text-end">
                                            <div>Başarı Puanı: <strong>93,54</strong> </div>  <Rate defaultValue={5} character={({ index = 0 }) => customIcons[index + 1]} />
                                            <br />{dateTypeName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="mt-3 pt-1 pb-3">
                                <div className='row'>
                                    <div className='col-12 col-lg-6'>
                                        <div className="table-responsive">
                                            <table className="table mb-0 table-hover table-striped">
                                                <tbody>
                                                    <tr>
                                                        <td>Yetki Sözleşmesi </td>
                                                        <td>120</td>
                                                    </tr>
                                                    <tr>
                                                        <td>İşleme Döndürme Oranı </td>
                                                        <td>%23,53</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Kıdem Yılı </td>
                                                        <td>5 Yıl 3 Ay</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Müşteri Memniyet Oranı  </td>
                                                        <td>4,96</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Toplantı / Etkinlik Katılım Oranı </td>
                                                        <td>%97</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Ortalama Ciro Oranı </th>
                                                        <th>25.750 TL</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Cayma Akçesi İşleme Dönüştürme Oranı </th>
                                                        <th>%98,96</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-6'>
                                        <div className="table-responsive">
                                            <table className="table mb-0 table-hover table-striped">
                                                <tbody>
                                                    <tr>
                                                        <td>Toplam Ciro </td>
                                                        <td><strong>1.000.000 TL</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Kiralama Ciro </td>
                                                        <td>475.000 TL</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Satış Ciro </td>
                                                        <td>525.000</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Son İşlem Tarihi  </td>
                                                        <td>24/10/2024</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Geçen Gün </th>
                                                        <th>5</th>
                                                    </tr>
                                                    <tr>
                                                        <th>GD Ciro Sıralaması </th>
                                                        <th>34</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Tekrar İşlem Yapma Oranı </th>
                                                        <th>%11,36</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            })}
        </Carousel>
    );
};

export default AgentPerformanceCarouselCard;
