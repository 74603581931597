const Mailto = ({ email, subject, body, ...props }: any) => {
  return (
    <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
      {props.children}
    </a>
  );
}

export default Mailto;

