import React from 'react';

const Footer = () => {

    const verDateValue = localStorage.getItem('verDate');
    let daysDifference = 0;

    if (verDateValue) {
        const verDateParts = verDateValue.split('.');
        const day = parseInt(verDateParts[0]);
        const month = parseInt(verDateParts[1]) - 1;
        const year = parseInt(verDateParts[2]);

        const today = new Date();
        const storedDate = new Date(year, month, day);
        const timeDifference = Math.abs(today.getTime() - storedDate.getTime());
        daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    }


    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        <script>document.write(new Date().getFullYear())</script> © LLUPAR | All rights reserved. {localStorage.getItem('ver')} {daysDifference < 3 ? <span className="badge bg-warning text-dark">Yeni sürüm</span> : ''}

                    </div>
                    <div className="col-sm-6">
                        <div className="text-sm-end d-none d-sm-block">
                            Design & Develop by <a href="https://rapull.com" target="_blank" className="text-decoration-underline"> <img src="/assets/images/rapull.svg" height={20} /></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;