import React from 'react';
import { Select } from 'antd';
import { PatternFormat } from 'react-number-format';

const { Option } = Select;

interface RealtorInputProps {
    realtorData: {
        firstName: string;
        lastName: string;
        companyName: string;
        email: string;
        phoneCodeId: number | undefined;
        phone: string;
        note: string;
    };
    onChange: (field: string, value: string | number | undefined) => void;
    phone_codes: any[];
}

const RealtorInput: React.FC<RealtorInputProps> = ({ realtorData, onChange, phone_codes }) => {
    return (
        <div className="row">
            <div className="col-xl-6 col-md-6">
                <div className="form-group mb-3">
                    <label>Ad</label>
                    <input
                        type="text"
                        className="form-control"
                        onChange={e => onChange('firstName', e.target.value)}
                    />
                </div>
            </div>
            <div className="col-xl-6 col-md-6">
                <div className="form-group mb-3">
                    <label>Soyad</label>
                    <input
                        type="text"
                        className="form-control"
                        onChange={e => onChange('lastName', e.target.value)}
                    />
                </div>
            </div>
            <div className="col-xl-12 col-md-12">
                <div className="form-group mb-3">
                    <label>Firma Adı</label>
                    <input
                        type="text"
                        className="form-control"
                        onChange={e => onChange('companyName', e.target.value)}
                    />
                </div>
            </div>
            <div className="col-xl-12 col-md-12">
                <div className="form-group mb-3">
                    <label>Email</label>
                    <input
                        type="email"
                        className="form-control"
                        onChange={e => onChange('email', e.target.value)}
                    />
                </div>
            </div>
            <div className="col-xl-12 col-md-12">
                <div className="form-group mb-3">
                    <label>Telefon</label>
                    <div className="input-group">
                        <div className="input-group-text p-0">
                            <Select
                                defaultValue={realtorData.phoneCodeId}
                                style={{ width: 100 }}
                                showSearch
                                onChange={value => onChange('phoneCodeId', value)}
                            >
                                {phone_codes.map(phoneCode => {
                                    const img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phoneCode.code}.svg`;
                                    return (
                                        <Option key={phoneCode.name} value={phoneCode.id}>
                                            <img src={img_url} height={16} alt={phoneCode.name} /> +{phoneCode.phone_code}
                                            <br />
                                            <small className="text-black-50">{phoneCode.name}</small>
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <PatternFormat
                            format="(###) ### ## ##"
                            className="form-control"
                            allowEmptyFormatting
                            mask=" "
                            onChange={e => onChange('phone', e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="col-xl-12 col-md-12">
                <div className="form-group mb-3">
                    <label>Not</label>
                    <textarea
                        className="form-control"
                        onChange={e => onChange('note', e.target.value)}
                    />
                </div>
            </div>
        </div>
    );
};

export default RealtorInput;