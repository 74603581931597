import React, { useState, SyntheticEvent, useCallback, useMemo, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { Select, ConfigProvider, Divider, Tag, Checkbox } from 'antd';
import { Button, Modal } from 'react-bootstrap';
import RealtorModal from '../modals/realtors/addRealtor';
import { User } from '../../models/user';
import ProfilePhoto from '../user/profilePhoto';
import useAttributes from '../../api/hooks/attributes/useAttributes';
import { AttributeFilter, Attribute } from '../../models/attribute/attributeFilter';
import { PhoneCode } from '../../models/general/country/phoneCode';
import { Agent } from '../../models/transaction/agent';
import { Realtor } from '../../models/general/realtor/realtor';
import { TransactionInfo } from '../../models/transaction/transactionInfo';

const { Option } = Select;

interface TransactionInfoCardProps {
    agents: User[] | Agent[];
    realtors: Realtor[];
    phone_codes: PhoneCode[];
    giro_type_id: number;
    attributes: Attribute[];
    onDataChange?: (data: TransactionInfo) => void; // Yeni tip tanımı
}

const TransactionInfoCard: React.FC<TransactionInfoCardProps> = ({ agents, realtors, phone_codes, giro_type_id, attributes,onDataChange }) => {

    const [filteredAttributes, setFilteredAttributes] = useState<{
        payment_methods: Attribute[];
        comment_statuses: Attribute[];
        resources_of_find: Attribute[];
        resources_of_sell: Attribute[];
    }>({
        payment_methods: [],
        comment_statuses: [],
        resources_of_find: [],
        resources_of_sell: [],
    });
    useEffect(() => {
        const transformedAttributes: AttributeFilter = {
            data: {
                '13': attributes.filter((attr) => attr.type === 13),
                '14': attributes.filter((attr) => attr.type === 14),
                '15': attributes.filter((attr) => attr.type === 15),
                '16': attributes.filter((attr) => attr.type === 16),
            },
            report: {
                total: attributes.length,
            },
        };

        setFilteredAttributes({
            payment_methods: transformedAttributes.data['13'] || [],
            comment_statuses: transformedAttributes.data['14'] || [],
            resources_of_find: transformedAttributes.data['15'] || [],
            resources_of_sell: transformedAttributes.data['16'] || [],
        });
    }, [attributes]);

    const [transaction_data, setTransactionData] = useState<TransactionInfo>({
        sale_price: 0,
        fair_price: 0,
        title_deed_cost: 0,
        buyer_service_fee: 0,
        seller_service_fee: 0,
        office_referral_fee: 0,
        out_office_referral_fee: 0,
        contract_date: '',
        source_of_find_id: undefined as number | undefined,
        source_of_sell_id: undefined as number | undefined,
        comment_status: [] as number[],
        payment_method: [139] as number[],
        selected_agents: [] as Agent[],
        realtor_id: undefined as number | undefined,
        total_service_fee:0
    });

    useEffect(() => {
        onDataChange?.(transaction_data);
    }, [transaction_data, onDataChange]);

    const [realtorId, setRealtorId] = useState<number | undefined>(undefined);
    const [outOfficeSee, setOutOfficeSee] = useState<boolean>(false);
    const [showRealtor, setShowRealtor] = useState<boolean>(false);

    const handleFieldChange = useCallback((field: string, value: any) => {
        setTransactionData((prev) => {
            const updatedData = { ...prev, [field]: value };
            const totalServiceFee = (updatedData.buyer_service_fee + updatedData.seller_service_fee)
                - (updatedData.office_referral_fee + updatedData.out_office_referral_fee);
    
            return {
                ...updatedData,
                total_service_fee: totalServiceFee,
            };
        });
    }, []);

    const handleSalePriceChange = useCallback(
        (value: number | undefined) => {
            setTransactionData((prev) => {
                const salePrice = value ?? 0;
                const calculatedFee = salePrice * 0.02 * 1.2;
                return {
                    ...prev,
                    sale_price: salePrice,
                    buyer_service_fee: giro_type_id === 117 ? 0 : calculatedFee,
                    seller_service_fee: giro_type_id === 117 ? salePrice * 1.2 : calculatedFee,
                };
            });
        },
        [giro_type_id]
    );

    const handleFairPriceChange = useCallback((value: number) => {
        const title_deed_cost = value * 0.04 + 2466;
        setTransactionData(prev => ({
            ...prev,
            fair_price: value,
            title_deed_cost,
        }));
    }, []);

    const totalServiceFee = useMemo(() => {
        const { buyer_service_fee, seller_service_fee, office_referral_fee, out_office_referral_fee } = transaction_data;
        return (buyer_service_fee + seller_service_fee) - (office_referral_fee + out_office_referral_fee);
    }, [transaction_data]);
    const handleAgentFieldChange = (agentId: number, field: string, value: number) => {
        setTransactionData(prev => {
            const agentShare = totalServiceFee / prev.selected_agents.length;
    
            const updatedAgents = prev.selected_agents.map(agent => {
                if (agent.id === agentId) {
                    const updatedAgent = {
                        ...agent,
                        [field]: value,
                        category: field === 'percent' ? { ...agent.category, percent: value } : agent.category,
                    };
    
                    if (field === 'percent' || field === 'team_leader_percent') {
                        const price = agentShare * (updatedAgent.category.percent / 100);
                        const teamLeaderFee = updatedAgent.team_leader?.id
                            ? agentShare * ((updatedAgent.team_leader_percent || 0) / 100)
                            : 0;
                        const officeServiceFee = agentShare - price - teamLeaderFee;
    
                        return {
                            ...updatedAgent,
                            price,
                            team_leader_price: teamLeaderFee,
                            office_service_fee: officeServiceFee,
                            total_service_fee: agentShare,
                        };
                    }
    
                    return updatedAgent;
                }
                return agent;
            });
    
            return {
                ...prev,
                selected_agents: updatedAgents,
            };
        });
    };
    const agentFees = useMemo(() => {
        const { selected_agents } = transaction_data;
        const agentCount = selected_agents.length;
        if (agentCount === 0) return [];

        const agentShare = totalServiceFee / agentCount;

        return selected_agents.map(agent => {
            const price = (agentShare * (agent.category.percent / 100)).toFixed(2);
            const team_leader_price = agent.team_leader?.id
                ? (agentShare * (agent.team_leader_percent || 0) / 100).toFixed(2)
                : '0.00';
            const office_service_fee = (agentShare - parseFloat(price) - parseFloat(team_leader_price)).toFixed(2);

            return {
                ...agent,
                total_service_fee: agentShare.toFixed(2),
                price: parseFloat(price),
                team_leader_price: parseFloat(team_leader_price),
                office_service_fee: parseFloat(office_service_fee),
            };
        });
    }, [transaction_data, totalServiceFee]);

    const handleAgentSelect = useCallback((selectedAgentIds: number[]) => {
        if (Array.isArray(agents) && (agents as Agent[])[0]?.category) {
            const selected = (agents as Agent[]).filter(agent =>
                selectedAgentIds.includes(agent.id)
            ).map(agent => {
                const agentShare = totalServiceFee / selectedAgentIds.length;
                const price = agentShare * (agent.category.percent / 100);
                const teamLeaderFee = agent.team_leader?.id
                    ? agentShare * ((agent.team_leader_percent || 0) / 100)
                    : 0;
                const officeServiceFee = agentShare - price - teamLeaderFee;
    
                return {
                    ...agent,
                    total_service_fee: agentShare,
                    price,
                    team_leader_price: teamLeaderFee,
                    office_service_fee: officeServiceFee,
                };
            });
    
            handleFieldChange('selected_agents', selected);
        }
    }, [agents, handleFieldChange, totalServiceFee]);

    const handleShowRealtorModal = () => setShowRealtor(true);
    const handleCloseRealtorModal = () => setShowRealtor(false);

    const realtorSubmit = async (e: SyntheticEvent, newRealtorData: any) => {
        e.preventDefault();
        setShowRealtor(false);
    };

    return (
        <>
            <div className="row mb-3">
                <div className="col-xl-12 col-md-12">
                    <div className="form-group mb-3">
                        <label>İşlem Bilgileri</label>
                    </div>
                </div>
                <div className="col-xl-2 col-md-2">
                    <div className="form-group mb-3">
                        <label>Sözleşme Tarihi</label>
                        <input
                            type="date"
                            name="contract_date"
                            required
                            data-pristine-required-message="Lütfen sözleşme tarihini giriniz."
                            className="form-control"
                            value={transaction_data.contract_date}
                            onChange={e => handleFieldChange('contract_date', e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-xl-3 col-md-3">
                    <div className="form-group mb-3 select-style">
                        <label>Portföy Bulma Kaynağı</label>
                        <Select
                            className='form-control'
                            placeholder="Portföy Bulma Kaynağını Seçiniz"
                            value={transaction_data.source_of_find_id}
                            onChange={(value) => handleFieldChange('source_of_find_id', value)}
                        >
                            {filteredAttributes.resources_of_find.map((resource) => (
                                <Option key={resource.id} value={resource.id}>
                                    {resource.name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="col-xl-3 col-md-3">
                    <div className="form-group mb-3 select-style">
                        <label>Portföy Satma Kaynağı</label>
                        <Select
                            className='form-control'
                            placeholder="Portföy Satma Kaynağını Seçiniz"
                            value={transaction_data.source_of_sell_id}
                            onChange={(value) => handleFieldChange('source_of_sell_id', value)}
                        >
                            {filteredAttributes.resources_of_sell.map((resource) => (
                                <Option key={resource.id} value={resource.id}>
                                    {resource.name}
                                </Option>
                            ))}
                        </Select>

                    </div>
                </div>
                <div className="col-xl-4 col-md-4">
                    <div className="form-group mb-3">
                        <label>Yorum Durumu</label>
                        <Checkbox.Group
                            value={transaction_data.comment_status}
                            onChange={(values) => handleFieldChange('comment_status', values)}
                        >
                            {filteredAttributes.comment_statuses.map((status) => (
                                <Checkbox key={status.id} value={status.id}>
                                    {status.name}
                                </Checkbox>
                            ))}
                        </Checkbox.Group>

                    </div>
                </div>
                <div className="col-xl-2 col-md-2">
                    <label>{giro_type_id === 116 ? 'Satış Bedeli' : 'Kiralama Bedeli'}</label>
                    <NumericFormat
                        className="form-control"
                        decimalSeparator=","
                        thousandSeparator="."
                        decimalScale={2}
                        value={transaction_data.sale_price || ''}
                        onValueChange={(values) => {
                            const parsedValue = parseFloat(values.value);
                            handleSalePriceChange(isNaN(parsedValue) ? undefined : parsedValue);
                        }}
                    />
                </div>
                {giro_type_id === 116 && (
                    <>
                        <div className="col-xl-2 col-md-2">
                            <label>Rayiç Bedeli</label>
                            <NumericFormat
                                className="form-control"
                                decimalSeparator=","
                                thousandSeparator="."
                                decimalScale={2}
                                value={transaction_data.fair_price || ''}
                                onValueChange={(values) => {
                                    const parsedValue = parseFloat(values.value);
                                    handleFairPriceChange(isNaN(parsedValue) ? 0 : parsedValue);
                                }}
                            />
                        </div>
                        <div className="col-xl-2 col-md-2">
                            <label>Tapu Masrafı</label>
                            <NumericFormat
                                className="form-control"
                                decimalSeparator=","
                                thousandSeparator="."
                                decimalScale={2}
                                value={transaction_data.title_deed_cost || ''}
                                onValueChange={(values) => {
                                    const parsedValue = parseFloat(values.value);
                                    handleFieldChange('title_deed_cost', isNaN(parsedValue) ? 0 : parsedValue);
                                }}
                            />
                        </div>
                        <div className="col-xl-2 col-md-2">
                            <label>Alıcı Hizmet Bedeli</label>
                            <NumericFormat
                                className="form-control"
                                decimalSeparator=","
                                thousandSeparator="."
                                decimalScale={2}
                                value={transaction_data.buyer_service_fee || ''}
                                onValueChange={(values) => {
                                    const parsedValue = parseFloat(values.value);
                                    handleFieldChange('buyer_service_fee', isNaN(parsedValue) ? 0 : parsedValue);
                                }}
                            />
                        </div>
                    </>
                )}
                <div className="col-xl-2 col-md-2">
                    <label>{giro_type_id === 116 ? 'Satıcı Hizmet Bedeli' : 'Kiralama Hizmet Bedeli'}</label>
                    <NumericFormat
                        className="form-control"
                        decimalSeparator=","
                        thousandSeparator="."
                        decimalScale={2}
                        value={transaction_data.seller_service_fee || ''}
                        onValueChange={(values) => {
                            const parsedValue = parseFloat(values.value);
                            handleFieldChange('seller_service_fee', isNaN(parsedValue) ? 0 : parsedValue);
                        }}
                    />
                </div>
                <div className="col-xl-2 col-md-2">
                    <label>Ofis ile Paylaşım Bedeli</label>
                    <NumericFormat
                        className="form-control"
                        decimalSeparator=","
                        thousandSeparator="."
                        decimalScale={2}
                        value={transaction_data.office_referral_fee || ''}
                        onValueChange={(values) => {
                            const parsedValue = parseFloat(values.value);
                            handleFieldChange('office_referral_fee', isNaN(parsedValue) ? 0 : parsedValue);
                        }}
                    />
                </div>
            </div>
            {/* Other Realtor Selector */}
            <div className="mb-2 row">
                <div className={outOfficeSee ? 'col-xl-10 col-md-10' : 'col-xl-12 col-md-12'}>
                    <div className="form-group mb-1 select-style">
                        <label>Diğer Emlakçı</label>
                        <ConfigProvider renderEmpty={() => (
                            <div style={{ textAlign: 'center' }}>
                                <p>Emlakçı Bulunamadı!</p>
                                <Button variant="primary" onClick={handleShowRealtorModal}>Yeni Ekle</Button>
                            </div>
                        )}>
                            <Select
                                className="form-control"
                                value={transaction_data.realtor_id}
                                showSearch
                                allowClear={true}
                                placeholder="Diğer Emlakçı Seçiniz"
                                onChange={(value) => {
                                    setRealtorId(value);
                                    setOutOfficeSee(value !== undefined);
                                    setTransactionData((prev) => ({
                                        ...prev,
                                        realtor_id: value,
                                    }));
                                }}
                                optionFilterProp="children"
                            >
                                {realtors.map(realtor => (
                                    <Option key={realtor.id} value={realtor.id}>
                                        {`${realtor.first_name} ${realtor.last_name} ${realtor.company_name || ''}`}
                                    </Option>
                                ))}
                            </Select>
                        </ConfigProvider>
                    </div>
                </div>
                {outOfficeSee && (
                    <div className="col-xl-2 col-md-2">
                        <div className="form-group mb-3">
                            <label>Ofis Dışı Paylaşım Bedeli</label>
                            <NumericFormat
                                className="form-control"
                                decimalSeparator=","
                                thousandSeparator="."
                                decimalScale={2}
                                value={transaction_data.out_office_referral_fee || ''}
                                onValueChange={(values) => {
                                    const parsedValue = parseFloat(values.value);
                                    handleFieldChange('out_office_referral_fee', isNaN(parsedValue) ? 0 : parsedValue);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="col-xl-12 col-md-12">
                <div className="form-group mb-3 d-lg-flex align-items-center">
                    <label className="mb-0 me-2 align-self-center">{giro_type_id === 116 ? 'Satış Bedeli Ödeme Şekli' : 'Kiralama Bedeli Ödeme Şekli'}: </label>
                    <Checkbox.Group defaultValue={transaction_data.payment_method} className="ms-lg-2 d-block d-lg-inline-flex" onChange={(value) => handleFieldChange('payment_method', value)}>
                        {filteredAttributes.payment_methods?.map((payment_methods: Attribute) => {
                            return (
                                <Checkbox value={payment_methods.id}>{payment_methods.name}</Checkbox>
                            )
                        })}
                    </Checkbox.Group>
                </div>
            </div>

            {/* Total Service Fee */}
            <div className="col-xl-12 col-md-12">
                <div className="form-group mb-3">
                    <label>Toplam Hizmet Bedeli :</label>
                    <strong className='ms-2'>
                        <Tag color="green">
                            <NumericFormat
                                value={totalServiceFee.toFixed(2)}
                                thousandSeparator="."
                                decimalSeparator=","
                                displayType="text"
                            /> TL
                        </Tag>
                    </strong>
                </div>
            </div>

            {/* Selected Agents */}
            <div className="form-group mb-3 select-style">
                <label>Danışman (lar)</label>
                <Select
                    mode="multiple"
                    placeholder="Danışman Seçiniz"
                    optionFilterProp="children"
                    className="form-control"
                    onChange={handleAgentSelect}
                >
                    {agents.map(agent => (
                        <Option key={agent.id} value={agent.id}>
                            {`${agent.first_name} ${agent.last_name}`}
                        </Option>
                    ))}
                </Select>
            </div>

            {/* Agent Details */}
            {agentFees.map((agent) => (
                <div key={agent.id} className="mb-1 row">
                    <Divider orientation="left">
                        <div className='d-flex justify-content-center align-middle'>
                            <ProfilePhoto id={agent.id} name={`${agent.first_name.charAt(0)}${agent.last_name.charAt(0)}`} imgClass="avatar-md rounded-circle img-thumbnail" />
                            <div className='ms-2 pt-1'>{agent.first_name} {agent.last_name}</div>
                        </div>
                    </Divider>
                    <div className="col-xl-2 col-md-2">
                        <div className="form-group mb-3">
                            <label>Toplam Hizmet Bedeli</label>
                            <NumericFormat
                                className="form-control"
                                value={agent.total_service_fee}
                                decimalSeparator=","
                                thousandSeparator="."
                                displayType="input"
                                onValueChange={(values) => {
                                    const parsedValue = parseFloat(values.value);
                                    handleAgentFieldChange(agent.id, 'total_service_fee', isNaN(parsedValue) ? 0 : parsedValue);
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-2">
                        <div className="form-group mb-3">
                            <label>Paylaşım Yüzdesi</label>
                            <div className="input-group">
                                <div className="input-group-text"><strong>%</strong></div>
                                <NumericFormat
                                    className="form-control"
                                    value={agent.category.percent}
                                    decimalSeparator=","
                                    displayType="input"
                                    onValueChange={(values) => {
                                        const parsedValue = parseFloat(values.value);
                                        handleAgentFieldChange(agent.id, 'percent', isNaN(parsedValue) ? 0 : parsedValue);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-2">
                        <div className="form-group mb-3">
                            <label>G.D. Hizmet Bedeli</label>
                            <NumericFormat
                                className="form-control"
                                value={agent.price}
                                decimalSeparator=","
                                thousandSeparator="."
                                displayType="input"
                                onValueChange={(values) => {
                                    const parsedValue = parseFloat(values.value);
                                    handleAgentFieldChange(agent.id, 'price', isNaN(parsedValue) ? 0 : parsedValue);
                                }}
                            />
                        </div>
                    </div>
                    {agent.team_leader && agent.team_leader.id > 0 && (
                        <>
                            <div className="col-xl-2 col-md-2">
                                <div className="form-group mb-3">
                                    <label>T. Lideri Paylaşım Yüzdesi</label>
                                    <div className="input-group">
                                        <div className="input-group-text"><strong>%</strong></div>
                                        <NumericFormat
                                            className="form-control"
                                            value={agent.team_leader_percent}
                                            decimalSeparator=","
                                            displayType="input"
                                            onValueChange={(values) => {
                                                const parsedValue = parseFloat(values.value);
                                                handleAgentFieldChange(agent.id, 'team_leader_percent', isNaN(parsedValue) ? 0 : parsedValue);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-2">
                                <div className="form-group mb-3">
                                    <label>T. Lideri H. Bedeli</label>
                                    <NumericFormat
                                        className="form-control"
                                        value={agent.team_leader_price}
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        displayType="input"
                                        onValueChange={(values) => {
                                            const parsedValue = parseFloat(values.value);
                                            handleAgentFieldChange(agent.id, 'team_leader_price', isNaN(parsedValue) ? 0 : parsedValue);
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <div className="col-xl-2 col-md-2">
                        <div className="form-group mb-3">
                            <label>Ofis Hizmet Bedeli</label>
                            <NumericFormat
                                className="form-control"
                                value={agent.office_service_fee}
                                decimalSeparator=","
                                thousandSeparator="."
                                displayType="input"
                                onValueChange={(values) => {
                                    const parsedValue = parseFloat(values.value);
                                    handleAgentFieldChange(agent.id, 'office_service_fee', isNaN(parsedValue) ? 0 : parsedValue);
                                }}
                            />
                        </div>
                    </div>
                </div>
            ))}

            {/* Realtor Modal */}
            <RealtorModal
                show={showRealtor}
                onClose={handleCloseRealtorModal}
                onSubmit={realtorSubmit}
                phone_codes={phone_codes}
            />
        </>
    );
};

export default TransactionInfoCard;