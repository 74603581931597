import { useState, useEffect, useCallback, useMemo } from 'react';
import { User } from '../../models/user';
import { createUser, deleteUser, fetchUsers, updateUser } from '../services/userService';
import { UserResponse } from '../../models/user/userResponse';

const useUsers = (initialParams?: Record<string, any>) => {
  const [users, setUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [builder, setBuilder] = useState<any>({ sort_by: 'created_at', sort_order: null, limit: null });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const params = useMemo(() => initialParams, [JSON.stringify(initialParams)]);

  const loadUsers = useCallback(async () => {
    setLoading(true);
    try {
      const data: UserResponse | any = await fetchUsers(params);
      setUsers(data.users.data.data);
      setCurrentPage(data.users.data.current_page);
      setBuilder(data.builder);
      setError(null);
    } catch (err) {
      setError('Failed to fetch');
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const addUser = async (userData: User) => {
    try {
      await createUser(userData);
      loadUsers();
    } catch (err) {
      setError('Failed to add');
    }
  };

  const editUser = async (id: number, userData: User) => {
    try {
      await updateUser(id, userData);
      loadUsers();
    } catch (err) {
      setError('Failed to update');
    }
  };

  const removeUser = async (id: number) => {
    try {
      await deleteUser(id);
      loadUsers(); 
    } catch (err) {
      setError('Failed to delete');
    }
  };

  return { users, currentPage, builder, loading, error, addUser, editUser, removeUser };
};

export default useUsers;
