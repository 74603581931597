
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ProvinceContext } from '../../../../contexts/general/location/provider/provinceContext';
import { Province } from '../../../../models/general/location/province/province';


export const ProvinceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [provinces, setProvinces] = useState<Province[]>([]);

  useEffect(() => {
      let isMounted = true;

      async function getProvinces() {
        try {
          const { data } = await axios.get('location/provinces');
          if (isMounted) {
            setProvinces(data);
          }
        } catch (error) {
          console.log(error);
        }
      }
  
      const timer = setTimeout(() => {
        getProvinces();
      }, 5000);

      return () => {
        isMounted = false;
        clearTimeout(timer);
      };
  }, []);


  return (
      <ProvinceContext.Provider value={[provinces, setProvinces]}>
          {children}
      </ProvinceContext.Provider>
  );
}

export const useProvinceContext = () => useContext(ProvinceContext);
