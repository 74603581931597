import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Wrapper from "../../components/layout/wrapper";
import Paginator from "../../components/paginator";
import { User } from "../../models/user";
import { Switch, message } from "antd";

const Users = () => {

    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);

    const [update, setUpdate] = useState(true);
    const [loading, setLoading] = useState(false);
    const [updateID, setUpdateID] = useState(0);


    useEffect(() => {
        (
            async () => {
                if(update){
                const { data } = await axios.get(`users?page=${page}`);

                setUsers(data.data);
                setLastPage(data.meta.last_page);
                setUpdate(false);
            }
            }
        )()
    }, [page,update]);

    const del = async (id: number) => {
        if (window.confirm('Emin misiniz? Kalıcı olarak silinecektir!')) {
            await axios.delete(`users/${id}`);

            setUsers(users.filter((u: User) => u.id !== id));
        }
    }

    const [messageApi, contextHolder] = message.useMessage();

      const onChange = async (checked: boolean, userId: number) => {
        setLoading(true);
        const status_id = checked ? 255 : 256;
    
        try {
            const updatedUsers:any = users.map((user:User) =>
            user.id == userId ? { ...user, status_id: status_id } : user
          );
    
          setUsers(updatedUsers);

          await axios.put(`users/status/${userId}`, {
            status_id,
          });
        //   setUpdate(true);
          messageApi.success('Başarıyla güncellendi');
        } catch (error) {
          messageApi.error('İşlem sırasında bir hata gerçekleşti! Hata mesajı: ' + error);
        }
    
        setLoading(false);
      };


    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Kullanıcılar</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><NavLink to={'/'}>Gösterge Paneli</NavLink></li>
                                <li className="breadcrumb-item active">Kullanıcılar</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <h5 className="card-title">Kullanıcı Listesi</h5>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                        <div>
                                            <Link to="/users/create" className="btn btn-light"><i className="bx bx-plus me-1"></i> Yeni Ekle</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Ad Soyad</th>
                                            <th>Email</th>
                                            <th>Kategori</th>
                                            <th>Rol</th>
                                            <th>Durum</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user: User) => {
                                            return (
                                                <tr key={user.id}>
                                                    <td>{user.id}</td>
                                                    <td>{user.first_name} {user.last_name}</td>
                                                    <td>{user.email}</td>
                                                    <td>{(user?.category) ? user?.category?.name : null}</td>
                                                    <td>{user.role?.name}</td>
                                                    <td>
                                                    <Switch
                                                        loading={loading}
                                                        checked={user.status_id == 255}
                                                        onChange={(checked) => onChange(checked, user.id)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="bx bx-dots-horizontal-rounded"></i>
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-end">
                                                                <li>
                                                                    <Link to={`/users/${user.id}/edit`}
                                                                        className="dropdown-item">Güncelle</Link>
                                                                </li>
                                                                {/* <li><a className="dropdown-item" href="#" onClick={() => del(user.id)}>Sil</a></li> */}
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {contextHolder}

                            <Paginator page={page} lastPage={lastPage} pageChanged={setPage} />

                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Users;