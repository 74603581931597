import { AttributeMeta } from "./attributeMeta";

export class Attribute {
    public id: number;
    public name: string;
    public type: string;
    public ordernum: string;
    public color: string;
    private _attributeMeta: AttributeMeta | null = null; // Başlangıçta null

    constructor(id = 0, name = '', type = '', ordernum = '', color = '') {
        this.id = id;
        this.name = name;
        this.type = type;
        this.ordernum = ordernum;
        this.color = color;
    }

    // Gerektiğinde attributeMeta'yı başlatan bir getter
    get attributeMeta(): AttributeMeta {
        if (this._attributeMeta === null) {
            this._attributeMeta = new AttributeMeta();
        }
        return this._attributeMeta;
    }
}
