import { useEffect, useState } from 'react';
import axios from 'axios';
import DoughnutChartWithCard from '../chart/doughnutChartWithCard';
import ApexDoughnutChartWithCard from '../chart/ApexDoughnutChartWithCard';

const TransactionTypesCountApexDonutChart = ({ start_date, end_date, dateTypeName, sendFilter }: any) => {

    const [transactions_sum_count_report, setTransactionsSumCountReport] = useState<any | null>([]);

    const [watchScheduleActionChartData, setWatchScheduleActionChartData] = useState<any>({
        series: [''],
        options: {
            legend: {
                show: false
            },
          colors: ["#6951ce", "#f04d80","#ffcc5a","#16daf1"],
          chart: {
            type: 'donut',
          },
          labels: [0],
        }
    });


    const [chartsData, setChartsData] = useState({
        chartSales: {
            series: [''],
            options: {
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '10px'
                    }
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '50'
                        },
                    },
                },
                legend: {
                    show: false,
                },
                colors: ["#6951ce", "#f04d80", "#ffcc5a", "#16daf1"],
                chart: {
                    type: 'donut',
                },
                labels: [0],
            }
        },
        chartRent: {
            series: [''],
            options: {
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '10px'
                    }
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '50'
                        },
                    },
                },
                legend: {
                    show: false,
                },
                colors: ["#6951ce", "#f04d80", "#ffcc5a", "#16daf1"],
                chart: {
                    type: 'donut',
                },
                labels: [0],
            }
        },
    });

    useEffect(() => {
        (
            async () => {
                const generateChartData:any = (propertyGenus: any) => {
                    const labels: any = [];
                    const data: any = [];

                    Object.values(propertyGenus).forEach((item: any) => {
                        labels.push(item.name);
                        data.push(item.value);
                    });

                    return {
                        series: data,
                        options: {
                            legend: {
                                show: false
                            },
                          colors: ["#6951ce", "#f04d80","#ffcc5a","#16daf1"],
                          chart: {
                            type: 'donut',
                          },
                          labels: labels,
                        }
                    };
                };

                    axios.get(`dashboard?filter_between[]=${start_date}&filter_between[]=${end_date}`)
                        .then(({ data }) => {
                            setTransactionsSumCountReport(data?.transactions?.report);

                            // Döngüyle chartSales ve chartRent verilerini oluştur
                            const chartSalesData = generateChartData(data?.transactions?.report?.giro_type?.total['116'].property_genus);
                            const chartRentData = generateChartData(data?.transactions?.report?.giro_type?.total['117'].property_genus);

                            setChartsData({
                                chartSales: chartSalesData,
                                chartRent: chartRentData
                            });
                        });
            }
        )()
    }, [sendFilter]);

    return (
        <>
            <div className="col-xl-3 col-md-6">
                <ApexDoughnutChartWithCard data={chartsData.chartSales} count={transactions_sum_count_report?.total_giro_type_116} dateTypeName={dateTypeName} text={'Toplam Satış'} url={'/report/giros'} price={transactions_sum_count_report?.giro_type?.sum?.total_service_fee?.[116]} />
            </div>
            <div className="col-xl-3 col-md-6">
                <ApexDoughnutChartWithCard data={chartsData.chartRent} count={transactions_sum_count_report?.total_giro_type_117} dateTypeName={dateTypeName} text={'Toplam Kiralama'} url={'/report/giros'} price={transactions_sum_count_report?.giro_type?.sum?.total_service_fee?.["117"]} />
            </div>
        </>

    );
};

export default TransactionTypesCountApexDonutChart;
