import React, { useEffect,useState } from "react";
import { Select } from "antd";
import axios from "axios";

const { Option, OptGroup } = Select;

const CustomInputForContract = ({ inputType = "input", type = "text", placeholder = null, value = null, autoComplete = 'off', inputClass = 'form-control', required = false, data = null, onChange = () => { },
}: any) => {

    const [datas, setDatas] = useState<any>(null);

    useEffect(() => {
        if(data){
            (
                async () => {
                    const { getData }:any = await axios.get(`${data}`);
                    setDatas(getData.data);
                }
            )()
        }
    }, [data]);


    if (inputType === 'antDesignSelect') {
        return (
            <Select className={inputClass} showSearch
                placeholder={placeholder}
                onChange={onChange}
                optionFilterProp="children"
                value={value}
                defaultValue={value}
                onSearch={value => {
                    const lowercaseValue = value.toLowerCase();
                    const filteredOptions = data.map((data: any) => {
                        const filteredCategory = data.category.filter((child_category: any) => {
                            return child_category.name.toLowerCase().includes(lowercaseValue);
                        });
                        return { ...data, category: filteredCategory };
                    });
                    return filteredOptions;
                }}
            >
                {/* {data.category.map((child_category: any) => (
                    <Option value={child_category.id} title={child_category.name} key={child_category.id}>
                        {child_category.name}
                    </Option>
                ))} */}
            </Select>
        )
    } else if (inputType === 'textarea') {
        return (
            <textarea
                autoComplete={autoComplete}
                required={required}
                className={inputClass}
                onChange={onChange}
                value={value} >
                {value}
            </textarea>
        )
    }
    else {
        return (
            <>
                <input
                    autoComplete={autoComplete}
                    type={type}
                    className={inputClass}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    required={required}
                />
            </>
        )

    }
}
export default CustomInputForContract;
