import React, { useMemo, useState } from "react";
import { Table, Tag } from "antd";
import { ColumnsType, ColumnProps } from "antd/es/table";
import { BsCheckLg } from "react-icons/bs";
import { FiTrash } from "react-icons/fi";
import Tel from "../../link/tel";
import Mailto from "../../link/mailto";
import AntDesignTable from "../../constant/antDesign/table";
import useWatchScheduleActions from "../../../api/hooks/useWatchScheduleActions";


interface WatchScheduleAction {
    id: number;
    name: string;
    phone: string;
    email: string;
    customer_type: string;
    property_type: string;
    property_genus: string;
    contact_id: string;
    status_id: string;
    status_color: string;
    meet_time: string;
}

interface Props {
    agent_id: number;
    meet_date: string;
}

const WatchScheduleActionList: React.FC<Props> = ({ agent_id, meet_date }) => {
    const [limit, setLimit] = useState<number>(10000);

    const params = useMemo(() => ({
        'filter_agent_id': agent_id,
        'filter_meet_date': meet_date,
        limit,
      }), [agent_id, meet_date, limit]);
    
      const { watchScheduleActions, loading, reloadWatchScheduleActions } = useWatchScheduleActions(params);
console.log('sda');
    const columns: ColumnsType<WatchScheduleAction> = [
        {
            title: "ID",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "İsim",
            dataIndex: "name",
            key: "name",
            render: (name: string, record: WatchScheduleAction) => (
                <a
                    onClick={() => {
                        // setCustomerDetailShow(true);
                        // setShowCustomerDetailID(record.id);
                    }}
                >
                    {name}
                </a>
            ),
        },
        {
            title: "Telefon",
            dataIndex: "phone",
            render: (phone: string) => (
                <Tel phone={phone}>{phone}</Tel>
            ),
        },
        {
            title: "E-Posta",
            dataIndex: "email",
            render: (email: string) => (
                <Mailto email={email} body="Merhaba">
                    {email}
                </Mailto>
            ),
        },
        {
            title: "Müşteri Türü",
            dataIndex: "customer_type",
            key: "customer_type",
            sorter: (a, b) => a.customer_type.localeCompare(b.customer_type),
        },
        {
            title: "İlan Türü",
            dataIndex: "property_type",
            sorter: (a, b) => a.property_type.localeCompare(b.property_type),
        },
        {
            title: "İlan Cinsi",
            dataIndex: "property_genus",
            sorter: (a, b) => a.property_genus.localeCompare(b.property_genus),
        },
        {
            title: "İletişim Kaynağı",
            dataIndex: "contact_id",
            sorter: (a, b) => a.contact_id.localeCompare(b.contact_id),
        },
        {
            title: "Durum",
            dataIndex: "status_id",
            render: (status_id: string, record: WatchScheduleAction) => (
                <Tag color={record.status_color}>{status_id}</Tag>
            ),
            sorter: (a, b) => a.status_id.localeCompare(b.status_id),
        },
        {
            title: "Görüşme Saati",
            dataIndex: "meet_time",
            sorter: (a, b) => a.meet_time.localeCompare(b.meet_time),
        },
        {
            title: "İşlemler",
            dataIndex: "operation",
            render: (_, record: WatchScheduleAction) => (
                <div>
                    {/* <a onClick={() => save(record.id)}>
                        <BsCheckLg />
                    </a>
                    <a onClick={() => delAction(record.id)}>
                        <FiTrash />
                    </a> */}
                </div>
            ),
        },
    ];

    return (
        <AntDesignTable columns={columns} data={[]} isLoading={false}/>
    );
};

export default WatchScheduleActionList;
