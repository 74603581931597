import axios from "axios";
import React, { SyntheticEvent, useEffect, useState, useRef } from "react";
import { Link, Navigate, NavLink } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";

import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import Breadcrumb from "../../../components/constant/breadcrumb";
import CustomInput from "../../../components/form/customInput";
import CustomSunEditor from "../../../components/form/sun-editor/customSunEditor";
import { useUserContext } from "../../../providers/user/userProvider";
import { Attribute } from "../../../models/attribute/attribute";
import { User } from "../../../models/user";
import { CustomerType } from "../../../models/attribute/customerType/customerType";
import { useCustomerTypeContext } from "../../../providers/attribute/customerType/customerTypeProvider";
import { IdType } from "../../../models/attribute/idType/idType";
import { useIdTypeContext } from "../../../providers/attribute/idType/idTypeProvider";
import { useCountryContext } from "../../../providers/general/location/country/countryProvider";
import { PhoneCode } from "../../../models/general/country/phoneCode";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Country } from "../../../models/general/country/country";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { Province } from "../../../models/general/location/province/province";
import { District } from "../../../models/general/location/district/district";
import { Neighbourhood } from "../../../models/general/location/neighbourhood/neighbourhood";
import { PropertyType } from "../../../models/attribute/propertyType/propertyType";
import { FloorLocation } from "../../../models/attribute/floorLocation/floorLocation";
import { BuildType } from "../../../models/attribute/buildType/buildType";

import { Radio, Select, DatePicker, Checkbox, ConfigProvider, message } from 'antd';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { setKey, fromAddress,fromLatLng } from "react-geocode";
import { useProvinceContext } from "../../../providers/general/location/province/provinceProvider";
import { useBuildTypeContext } from "../../../providers/attribute/buildType/buildTypeProvider";
import { usePropertyGenusContext } from "../../../providers/attribute/propertyGenus/propertyGenusProvider";
import moment from 'moment';

//Boostrap
import { Modal, Button } from "react-bootstrap";
import { Asset } from "../../../models/contract/asset/asset";
import { Item } from "../../../models/contract/item/item";
import { Site } from "../../../models/general/site/site";

const { Option } = Select;

const Edit = () => {
    //General
    const [name, setName] = useState('');
    const [content, setContent] = useState('');
    const [redirect, setRedirect] = useState(false);

    const [users, setUsers] = useUserContext();
    const [customer_types, setCustomerTypes] = useCustomerTypeContext();
    const [id_types, setIdTypes] = useIdTypeContext();
    const [phone_codes, setPhoneCodes] = useCountryContext();
    const [nationalities, setNationalities] = useCountryContext();

    const [selected_agent, setSelectedAgent] = useState<any | null>(null);
    const [partner_count, setPartnerCount] = useState<any | null>(0);
    const [selected_agents, setSelectedAgents] = useState([]);
    const [contract_types, setContractTypes] = useState([]);

    const [assets, setAssets] = useState([]);
    const [selected_assets, setSelectedAssets] = useState([]);
    const [asset_name, setAssetName] = useState<any | null>('');

    const [items, setItems] = useState([]);
    const [selected_items, setSelectedItems] = useState([]);
    const [item_name, setItemName] = useState<any | null>('');

    //Document
    const [status_id, setStatusId] = useState<any | null>(184);
    const [purpose_of_use_id, setPurposeOfUseId] = useState<any | null>(187);
    const [statuses, setStatuses] = useState([]);
    const [purpose_of_use, setPurposeOfUse] = useState([]);
    const [property_status, setPropertyStatus] = useState<any | null>('');

    // Seller
    const [seller_customer_type_id, setSellerCustomerTypeId] = useState(1);
    const [seller_company_name, setSellerCompanyName] = useState('');
    const [seller_tax_admin, setSellerTaxAdmin] = useState('');
    const [seller_tax_number, setSellerTaxNumber] = useState('');
    const [seller_phone_code_id, setSellerPhoneCodeId] = useState(34);
    const [seller_first_name, setSellerFirstName] = useState<any | null>('');
    const [seller_last_name, setSellerLastName] = useState<any | null>('');
    const [seller_id_type_id, setSellerIdTypeId] = useState(3);
    const [seller_nationality_id, setSellerNationalityId] = useState(34);
    const [seller_id_no, setSellerIdNo] = useState('');
    const [seller_birthday, setSellerBirthday] = useState('');
    const [seller_email, setSellerEmail] = useState('');
    const [seller_address, setSellerAddress] = useState('');
    const [seller_phone, setSellerPhone] = useState('');
    const [seller_note, setSellerNote] = useState('');

    // Buyer
    const [buyer_customer_type_id, setBuyerCustomerTypeId] = useState(1);
    const [buyer_company_name, setBuyerCompanyName] = useState('');
    const [buyer_tax_admin, setBuyerTaxAdmin] = useState('');
    const [buyer_tax_number, setBuyerTaxNumber] = useState('');
    const [buyer_phone_code_id, setBuyerPhoneCodeId] = useState(34);
    const [buyer_first_name, setBuyerFirstName] = useState('');
    const [buyer_last_name, setBuyerLastName] = useState('');
    const [buyer_nationality_id, setBuyerNationalityId] = useState(34);
    const [buyer_id_type_id, setBuyerIdTypeId] = useState(3);
    const [buyer_id_no, setBuyerIdNo] = useState('');
    const [buyer_birthday, setBuyerBirthday] = useState('');
    const [buyer_email, setBuyerEmail] = useState('');
    const [buyer_address, setBuyerAddress] = useState('');
    const [buyer_phone, setBuyerPhone] = useState('');
    const [buyer_note, setBuyerNote] = useState('');

    //Property
    const [province_id, setProvinceId] = useState<any | null>(7);
    const [province_name, setProvinceName] = useState<any | null>('');
    const [district_id, setDistrictId] = useState<any | null>(2038);
    const [district_name, setDistrictName] = useState<any | null>('');
    const [neighbourhood_id, setNeighbourhoodId] = useState<any | null>(2338);
    const [neighbourhood_hsb_id, setNeighbourhoodHsbId] = useState<any | null>(null);
    const [neighbourhood_name, setNeighbourhoodName] = useState<any | null>('');
    const [csb_floor_id, setCsbFloorId] = useState<any | null>(0);
    const [csb_parcel_id, setCsbParcelId] = useState<any | null>(0);
    const [rectangle_coordinates, setRectangleCoordinates] = useState<any | null>('');

    const [payment_type, setPaymentType] = useState<any | null>(null);
    const [street, setStreet] = useState<any | null>(null);
    const [bob, setBob] = useState<any | null>(null);
    const [parcel, setParcel] = useState<any | null>(null);
    const [gross, setGross] = useState<any | null>(null);
    const [net, setNet] = useState<any | null>(null);
    const [land_office_id, setLandOfficeId] = useState<any | null>(1);
    const [deed_date, setDeedDate] = useState<any | null>('');
    const [property_address, setPropertyAddress] = useState<any | null>('');
    const [build_date, setBuildDate] = useState<any | null>(null);
    const [property_note, setPropertyNote] = useState<any | null>('');
    const [site_search, setSiteSearch] = useState<any | null>('');
    const [site_id, setSiteId] = useState<any | null>(null);
    const [property_genus_id, setPropertyGenusId] = useState<any | null>(7);
    const [property_type_id, setPropertyTypeId] = useState<any | null>(14);
    const [floor_location_id, setFloorLocationId] = useState<any | null>();
    const [build_type_id, setBuildTypeId] = useState<any | null>(114);
    const [districts, setDistricts] = useState([]);
    const [neighbourhoods, setNeighbourhoods] = useState([]);
    const [property_types, setPropertyTypes] = useState([]);
    const [floor_locations, setFloorLocations] = useState([]);
    const [sites, setSites] = useState([]);

    //Site
    const [site_name, setSiteName] = useState('');
    const [site_address, setSiteAddress] = useState('');
    const [total_house, setTotalHouse] = useState(0);
    const [manager_name, setManagerName] = useState('');
    const [mobile_phone, setMobilePhone] = useState('');
    const [office_phone, setOfficePhone] = useState('');
    const [site_note, setSiteNote] = useState('');

    //Contract
    const [contract_type_id, setContractTypeId] = useState(182);
    const [start_date, setStartDate] = useState<any | null>(null);
    const [end_date, setEndDate] = useState<any | null>(null);
    const [lease_time, setLeaseTime] = useState<any | null>(null);
    const [per_month_price, setPerMonthPrice] = useState<any | null>(null);
    const [per_year_price, setPerYearPrice] = useState<any | null>(null);
    const [deposit_price, setDepositPrice] = useState<any | null>(null);

    //Other
    const [get_coordinate, setGetCoordinate] = useState(false);
    const [coordinate_info, setCoordinateInfo] = useState([]);
    const [note_see, setCustomerNoteSee] = useState(false);
    const [seller_note_see, setSellerNoteSee] = useState(false);
    const [buyer_note_see, setBuyerNoteSee] = useState(false);
    const [property_note_see, setPropertyNoteSee] = useState(false);

    const [disable_button, setDisableButton] = useState(false);
    const [save_button_text, setSaveButtonText] = useState('Kaydet');

    const [provinces, setProvinces] = useProvinceContext();
    const [build_types, setBuildTypes] = useBuildTypeContext();
    const [property_genuses, setPropertyGenuses] = usePropertyGenusContext();


    useEffect(() => {
        (
            async () => {

                const { data } = await axios.get('location/district/' + province_id);

                setDistricts(data);
            }
        )()
    }, [province_id]);

    useEffect(() => {

        (
            async () => {
                if (typeof province_name === 'string' && province_name.trim().length > 0 && province_name != null) {
                    const { data } = await axios.get('location/province/name-like/' + province_name);
                    if (data.id > 0) { setDistrictId(null); setNeighbourhoodId(null); }
                    setProvinceId(data.id);
                }
            }
        )()

    }, [province_name])


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('location/neighbourhood/' + district_id);

                setNeighbourhoods(data);
            }
        )()
    }, [district_id]);

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof district_name === 'string' && district_name.trim().length > 0 && district_name != null) {

                        const { data } = await axios.get(`location/district/name-like/${district_name}/${province_id}`);
                        setDistrictId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [district_name, province_id])


    useEffect(() => {
        (
            async () => {

                const { data } = await axios.get('location/district/' + province_id);

                setDistricts(data);
            }
        )()
    }, [province_id]);

    useEffect(() => {

        (
            async () => {
                if (typeof province_name === 'string' && province_name.trim().length > 0 && province_name != null) {
                    const { data } = await axios.get('location/province/name-like/' + province_name);
                    if (data.id > 0) { setDistrictId(null); setNeighbourhoodId(null); }
                    setProvinceId(data.id);
                }
            }
        )()

    }, [province_name])


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('location/neighbourhood/' + district_id);

                setNeighbourhoods(data);
            }
        )()
    }, [district_id]);

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof district_name === 'string' && district_name.trim().length > 0 && district_name != null) {

                        const { data } = await axios.get(`location/district/name-like/${district_name}/${province_id}`);
                        setDistrictId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [district_name, province_id])

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0) {

                        const { data } = await axios.get(`location/neighbourhood/name-like/${neighbourhood_name}/${district_id}`);
                        setNeighbourhoodId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [neighbourhood_name, district_id])

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/8/' + property_genus_id);

                setPropertyTypes(data.data);
            }
        )()
    }, [property_genus_id]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/41');

                setStatuses(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/42');

                setPurposeOfUse(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof asset_name === 'string' && asset_name.trim().length > 0) {
                        const { data } = await axios.get(`contract/assets/name-like/${asset_name}`);
                        setAssets(data.data);
                    } else {
                        const { data } = await axios.get(`contract/assets`);
                        setAssets(data.data);
                    }
                }
            )()
        }, 100);

    }, [asset_name])

    useEffect(() => {
        setTimeout(() => {
            (
                async () => {
                    if (typeof item_name === 'string' && item_name.trim().length > 0) {
                        const { data } = await axios.get(`contract/items/name-like/${item_name}`);
                        setItems(data.data);
                    } else {
                        const { data } = await axios.get(`contract/items`);
                        setItems(data.data);
                    }
                }
            )()
        }, 100);
    }, [item_name])

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0) {

                        const { data } = await axios.get(`location/neighbourhood/name-like/${neighbourhood_name}/${district_id}`);
                        setNeighbourhoodId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [neighbourhood_name, district_id])


    //Google Maps Geo For Site
    const [site_coordinates, setSiteCoordinates] = useState('');

    useEffect(() => {


        fromAddress(site_address).then(
            (response: any) => {
                const { lat, lng } = response.results[0].geometry.location;
                setSiteCoordinates(`${lat},${lng}`)
            },
            (error: any) => {
                //console.error(error);
            }
        );
    }, [site_address]);

    // Google maps  geoFor Property
    const [coordinates, setCoordinates] = useState({ lat: 36.87524849186124, lng: 30.64364152042535 });
    const [property_coordinate, setPropertyCoordinate] = useState<any | null>('36.87524849186124,30.64364152042535');

    useEffect(() => {
        fromAddress(`${property_address}, ${district_name}, ${province_name}`)
            .then(
                (response: any) => {
                    const { lat, lng } = response.results[0].geometry.location;
                    setCoordinates({ lat: lat, lng: lng })
                    setPropertyCoordinate(`${lat},${lng}`)

                    let administrativeAreaLevel1;

                    for (const result of response.results) {
                        for (const component of result.address_components) {
                            if (component.types.includes('administrative_area_level_1')) {
                                administrativeAreaLevel1 = component.long_name;
                                break;
                            }
                        }
                    }
                    let administrativeAreaLevel2;

                    for (const result of response.results) {
                        for (const component of result.address_components) {
                            if (component.types.includes('administrative_area_level_2')) {
                                administrativeAreaLevel2 = component.long_name;
                                break;
                            }
                        }
                    }
                    let administrativeAreaLevel3;

                    for (const result of response.results) {
                        for (const component of result.address_components) {
                            if (component.types.includes('administrative_area_level_3')) {
                                administrativeAreaLevel3 = component.long_name;
                                break;
                            }
                        }
                    }

                    let administrativeAreaLevel4;
                    for (const result of response.results) {
                        for (const component of result.address_components) {
                            if (component.types.includes('administrative_area_level_4')) {
                                administrativeAreaLevel4 = component.long_name;
                                break;
                            }
                        }
                    }

                    let administrativeAreaLevelNeighbourhood;
                    for (const result of response.results) {
                        for (const component of result.address_components) {
                            if (component.types.includes('neighborhood')) {
                                administrativeAreaLevelNeighbourhood = component.long_name;
                                break;
                            }
                        }
                    }

                    setProvinceName(administrativeAreaLevel1);
                    setDistrictName(administrativeAreaLevel2);
                    if (administrativeAreaLevelNeighbourhood != null && administrativeAreaLevelNeighbourhood != '' && administrativeAreaLevelNeighbourhood.trim().length > 0) {
                        setNeighbourhoodName(administrativeAreaLevelNeighbourhood);
                    } else if (administrativeAreaLevel3 != null && administrativeAreaLevel3 != '' && administrativeAreaLevel3.trim().length > 0) {
                        setNeighbourhoodName(administrativeAreaLevel3);
                    } else if (administrativeAreaLevel4 != null && administrativeAreaLevel4 != '' && administrativeAreaLevel4.trim().length > 0) {
                        setNeighbourhoodName(administrativeAreaLevel4);
                    }

                },
                (error: any) => {
                    //console.error(error);
                }
            );
    }, [property_address]);

    useEffect(() => {
        if (coordinates.lat && coordinates.lng) {
            fromLatLng(coordinates?.lat, coordinates.lng).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    setPropertyAddress(address);
                    // Diğer işlemleri yapabilirsiniz
                },
                (error) => {
                    console.error('Adres çözümleme hatası:', error);
                }
            );
        }

    }, [coordinates]);

    //Get Coordinate
    useEffect(() => {
        if (get_coordinate) {
            if (province_id > 0 && district_id > 0 && neighbourhood_id > 0 && bob > 0 && parcel > 0) {
                (
                    async () => {
                        try {
                            const { data } = await axios.get(`properties-hsb-info/${neighbourhood_id}/${bob}/${parcel}`);
                            setCoordinates({ lat: Number(data.coordinate.latitude), lng: Number(data.coordinate.longitude) });
                            setGross(data?.m2);
                            setPropertyCoordinate(`${data.coordinate.latitude},${data.coordinate.longitude}`);
                            setCsbParcelId(data?.csb_parcel_id);
                            setCsbFloorId(data?.csb_floor_id);
                            setRectangleCoordinates(data.coordinates);
                        } catch (error) {
                            console.error('Veri çekme hatası:', error);
                        }
                    }
                )();
            }
            setGetCoordinate(false);

        }
    }, [get_coordinate]);

    useEffect(() => {
        let isMounted = true;
        const source = axios.CancelToken.source();

        const fetchSites = async () => {
            try {
                let response;

                if (site_search.length > 0) {
                    response = await axios.get('sites/search/' + site_search, { cancelToken: source.token });
                } else {
                    response = await axios.get('sites');
                }

                if (isMounted) {
                    setSites(response.data.data || []);
                }
            } catch (error) {
                console.log(error);
            }
        };

        try {
            fetchSites();
        } catch (error) {
            console.log(error);
        }

        const intervalId = setInterval(() => {
            try {
                fetchSites();
            } catch (error) {
                console.log(error);
            }
        }, 5000); // Her 5 saniyede bir sorgu yap

        return () => {
            isMounted = false;
            clearInterval(intervalId);
            source.cancel('Request canceled');
        };
    }, [site_search]);

    useEffect(() => {
        if (start_date != null && end_date != null) {
            const diff = moment(end_date).diff(start_date, 'months');
            let lTimeDiff = diff >= 12 && diff > 0
                ? `${Math.floor(diff / 12)} Yıl ${(diff % 12 == 0) ? '' : diff % 12 + ' Ay'}`
                : `${diff} Ay`;

            setLeaseTime(lTimeDiff);
        }
    }, [start_date, end_date]);

    useEffect(() => {

        setPerYearPrice(per_month_price * 12);
    }, [per_month_price]);

    const customizeRenderEmptyForSite = () => (
        <div style={{ textAlign: 'center' }}>
            <InboxOutlined style={{ fontSize: 40 }} />
            <p>Site Bulunamadı!</p>
            <Button variant="primary" onClick={handleSiteShow}>Yeni Ekle</Button>

        </div>
    );


    //Modals
    //Add Realtor Modal
    const [show_realtor, setShowRealtor] = useState(false);
    const handleClose = () => setShowRealtor(false);

    const handleShow = (e: any) => {
        (
            () => {
                setShowRealtor(true);
            }
        )()
    };

    //Add Site Modal
    const [show_site_modal, setShowSiteModal] = useState(false);
    const handleSiteClose = () => setShowSiteModal(false);

    const handleSiteShow = (e: any) => {
        (
            () => {
                setShowSiteModal(true);
            }
        )()
    };


    const containerStyle = {
        width: '100%',
        height: '400px'
    };


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/40');

                setContractTypes(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                let contrat_type = null;
                (contract_type_id === 183) ? contrat_type = 4 : contrat_type = 3;
                const { data } = await axios.get('contract/contract-templates/' + contrat_type);

                setContent(data.content);
            }
        )()
    }, [contract_type_id]);


    //Site
    const siteSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const new_site_id = await axios.post('sites', {
            name: site_name,
            address: site_address,
            manager_name: manager_name,
            office_phone: office_phone,
            mobile_phone: mobile_phone,
            coordinate: site_coordinates,
            definition: site_note,
            house_count: total_house,

        });
        setSiteSearch(`${site_name}`);
        setSiteId(new_site_id);
        setShowSiteModal(false);
    }

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        setDisableButton(true);
        setSaveButtonText('Bekleyiniz...');

        await axios.post('contract/lease-agreements', {
            contract_type_id,
            seller_customer_type_id,
            seller_company_name,
            seller_tax_admin,
            seller_tax_number,
            seller_first_name,
            seller_last_name,
            seller_email,
            seller_phone_code_id,
            seller_phone,
            seller_nationality_id,
            seller_id_type_id,
            seller_id_no,
            seller_birthday,
            seller_address,
            seller_note,

            buyer_customer_type_id,
            buyer_company_name,
            buyer_tax_admin,
            buyer_tax_number,
            buyer_first_name,
            buyer_last_name,
            buyer_email,
            buyer_phone_code_id,
            buyer_phone,
            buyer_nationality_id,
            buyer_id_type_id,
            buyer_id_no,
            buyer_birthday,
            buyer_address,
            buyer_note,

            bob,
            parcel,
            land_office_id,
            deed_date,
            street,
            property_address,
            province_id,
            district_id,
            neighbourhood_id,
            property_genus_id,
            property_type_id,
            floor_location_id,
            build_date,
            gross,
            net,
            build_type_id,
            site_id,
            property_note,
            property_coordinate,

            start_date,
            end_date,
            lease_time,
            per_month_price,
            per_year_price,
            deposit_price,
            payment_type,
            purpose_of_use_id,
            property_status,
            selected_assets,
            selected_items,
            selected_agents,
            partner_count,
            content
        });

        setRedirect(true);
    }


    if (redirect) {
        return <Navigate to={'/contract/lease-agreements'} />;
    }

    const brItems: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Kira Kontratları', path: '/contract/lease-agreements' },
        { label: 'Güncelle', active: true },
    ];
    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Kira Kontratı Oluştur</h4>
                        <div className="page-title-right">
                            <Breadcrumb items={brItems} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <form onSubmit={submit}>
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Kontrat Türü</label>
                                                <div>
                                                    <Radio.Group defaultValue={contract_type_id} onChange={e => setContractTypeId(e.target.value)}>
                                                        {contract_types?.map((contract_types: Attribute) => {
                                                            return (
                                                                <Radio value={contract_types.id} >{contract_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3 select-style">
                                                <label>Yetkili Danışman(lar)</label>
                                                <Select className="form-control" showSearch
                                                    allowClear={true}
                                                    mode="multiple"
                                                    placeholder="Danışman Seçiniz"
                                                    onChange={e => { setPartnerCount(e.length); setSelectedAgents(e); if (e.length === 0) { selected_agents.splice(0); setSelectedAgents([]); } }}

                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {users.map((users: User) => {
                                                        return (
                                                            <Option value={users.id} title={users.first_name + ' ' + users.last_name}
                                                                key={users.first_name + ' ' + users.last_name}
                                                                id={users.first_name + ' ' + users.last_name}>{users.first_name + ' ' + users.last_name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Kiralayan Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Kiralayan Bilgileri </label>
                                                <div>
                                                    <Radio.Group defaultValue={seller_customer_type_id} value={seller_customer_type_id} onChange={e => setSellerCustomerTypeId(e.target.value)}>
                                                        {customer_types.map((customer_types: CustomerType) => {
                                                            return (
                                                                <Radio value={customer_types.id} >{customer_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Firma Adı</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir firma adı giriniz."
                                                    className="form-control"
                                                    value={seller_company_name}
                                                    onChange={e => setSellerCompanyName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Dairesi</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi dairesi giriniz."
                                                    value={seller_tax_admin}
                                                    className="form-control"
                                                    onChange={e => setSellerTaxAdmin(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi numarası giriniz."
                                                    value={seller_tax_number}
                                                    className="form-control"
                                                    onChange={e => setSellerTaxNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                                    value={seller_first_name}
                                                    name="seller_first_name"
                                                    className="form-control"
                                                    required
                                                    onChange={e => setSellerFirstName(e.target.value)}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" name="seller_last_name" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    value={seller_last_name}
                                                    className="form-control"
                                                    required
                                                    onChange={e => setSellerLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kimlik Tipi</label>
                                                <Select className="form-control" defaultValue={seller_id_type_id} value={seller_id_type_id}  showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setSellerIdTypeId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {id_types.map((id_types: IdType) => {
                                                        return (
                                                            <Option value={id_types.id} title={id_types.name}
                                                                key={id_types.name}
                                                                id={id_types.name}>{id_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Numara</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir kimlik numarası giriniz."
                                                    className="form-control"
                                                    value={seller_id_no}
                                                    name="seller_id_no"
                                                    required
                                                    onChange={e => setSellerIdNo(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select defaultValue={seller_phone_code_id} style={{ width: 100 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setSellerPhoneCodeId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >

                                                            {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                return (
                                                                    <Option value={phone_codes.id} title={phone_codes.name}
                                                                        key={phone_codes.name}
                                                                        id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <PatternFormat format="(###) ### ## ##" name="seller_phone" required className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setSellerPhone(e.target.value)} />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    onChange={e => setSellerEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Uyruk</label>
                                                <Select className="form-control" defaultValue={seller_nationality_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setSellerNationalityId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {nationalities?.map((nationalities: Country) => {
                                                        return (
                                                            <Option value={nationalities.id} title={nationalities.name}
                                                                key={nationalities.name}
                                                                id={nationalities.name}>{nationalities.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Doğum Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen doğum tarihi seçiniz."
                                                    className="form-control"
                                                    onChange={e => setSellerBirthday(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    name="seller_address"
                                                    required
                                                    onChange={e => setSellerAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { seller_note_see ? setSellerNoteSee(false) : setSellerNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={seller_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    onChange={e => setSellerNote(e.target.value)}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Kiracı Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Kiracı Bilgileri</label>
                                                <div>
                                                    <Radio.Group defaultValue={buyer_customer_type_id} onChange={e => setBuyerCustomerTypeId(e.target.value)}>
                                                        {customer_types.map((customer_types: CustomerType) => {
                                                            return (
                                                                <Radio value={customer_types.id} >{customer_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Firma Adı</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir firma adı giriniz."
                                                    className="form-control"
                                                    onChange={e => setBuyerCompanyName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Dairesi</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi dairesi giriniz."
                                                    className="form-control"
                                                    onChange={e => setBuyerTaxAdmin(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi numarası giriniz."
                                                    className="form-control"
                                                    onChange={e => setBuyerTaxNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                                    className="form-control"
                                                    name="buyer_first_name"
                                                    required
                                                    onChange={e => setBuyerFirstName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                    name="buyer_last_name"
                                                    required
                                                    onChange={e => setBuyerLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kimlik Tipi</label>
                                                <Select className="form-control" defaultValue={buyer_id_type_id} showSearch
                                                    onChange={e => setBuyerIdTypeId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {id_types.map((id_types: IdType) => {
                                                        return (
                                                            <Option value={id_types.id} title={id_types.name}
                                                                key={id_types.name}
                                                                id={id_types.name}>{id_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Kimlik Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir kimlik numarası giriniz."
                                                    className="form-control"
                                                    name="buyer_id_no"
                                                    required
                                                    onChange={e => setBuyerIdNo(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select defaultValue={buyer_phone_code_id} style={{ width: 100 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setBuyerPhoneCodeId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >

                                                            {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                return (
                                                                    <Option value={phone_codes.id} title={phone_codes.name}
                                                                        key={phone_codes.name}
                                                                        id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <PatternFormat format="(###) ### ## ##" name="buyer_phone" required onChange={e => setBuyerPhone(e.target.value)} className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    onChange={e => setBuyerEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Uyruk</label>
                                                <Select className="form-control" defaultValue={buyer_nationality_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setBuyerNationalityId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {nationalities?.map((nationalities: Country) => {
                                                        return (
                                                            <Option value={nationalities.id} title={nationalities.name}
                                                                key={nationalities.name}
                                                                id={nationalities.name}>{nationalities.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>


                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Doğum Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen doğum tarihi seçiniz."
                                                    className="form-control"
                                                    onChange={e => setBuyerBirthday(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    name="buyer_address"
                                                    required
                                                    onChange={e => setBuyerAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { buyer_note_see ? setBuyerNoteSee(false) : setBuyerNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={buyer_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    onChange={e => setBuyerNote(e.target.value)}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Mülk Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Mülk Bilgileri</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>İl</label>
                                                <Select className="form-control" defaultValue={province_id} value={province_id} showSearch
                                                    optionFilterProp="children"
                                                    placeholder="İl Seçiniz"
                                                    onChange={(e, option: any) => { setProvinceId(e); setProvinceName(option.children); setDistrictId(null); setNeighbourhoodId(null); }}
                                                    onBlur={e => setGetCoordinate(true)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {provinces?.map((provinces: Province) => {
                                                        return (
                                                            <Option value={provinces.id} title={provinces.name}
                                                                key={provinces.name}
                                                                id={provinces.name}>{provinces.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>İlçe</label>
                                                <Select className="form-control" defaultValue={district_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={(e, option: any) => { setDistrictId(e); setDistrictName(option.children); setNeighbourhoodId(null); }}
                                                    value={district_id}
                                                    onBlur={e => setGetCoordinate(true)}
                                                    placeholder="İlçe Seçiniz"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {districts.map((districts: District) => {
                                                        return (
                                                            <Option value={districts.id} title={districts.name}
                                                                key={districts.name}
                                                                id={districts.name}>{districts.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>Mahalle</label>
                                                <Select className="form-control" defaultValue={neighbourhood_id} showSearch
                                                    value={neighbourhood_id}
                                                    onChange={e => setNeighbourhoodId(e)}
                                                    optionFilterProp="children"
                                                    placeholder="Mahalle Seçiniz"
                                                    onBlur={e => setGetCoordinate(true)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {neighbourhoods.map((neighbourhoods: Neighbourhood) => {
                                                        return (
                                                            <Option value={neighbourhoods.id} title={neighbourhoods.name}
                                                                key={neighbourhoods.name}
                                                                id={neighbourhoods.name}>{neighbourhoods.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Sokak</label>
                                                <input type="text"
                                                    className="form-control"
                                                    onChange={e => setStreet(e.target.value)}
                                                    onBlur={e => setGetCoordinate(true)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Ada</label>
                                                <input type="number" data-pristine-required-message="Lütfen ada giriniz."
                                                    className="form-control"
                                                    onChange={e => setBob(e.target.value)}
                                                    onBlur={e => setGetCoordinate(true)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Parsel</label>
                                                <input type="number" data-pristine-required-message="Lütfen parsel giriniz."
                                                    className="form-control"
                                                    onChange={e => setParcel(e.target.value)}
                                                    onBlur={e => setGetCoordinate(true)}

                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" name="property_address" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    defaultValue={property_address}
                                                    onBlur={e => setPropertyAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Harita Konumu </label>
                                                <LoadScript
                                                    googleMapsApiKey="AIzaSyA4ZrsurXwR2QD4Xx5-CpHPD4N3qvurBMo"
                                                >
                                                    <GoogleMap
                                                        mapContainerStyle={containerStyle}
                                                        center={coordinates}
                                                        zoom={15}
                                                    >
                                                        <Marker
                                                            position={coordinates}
                                                            draggable={true}
                                                            onDragEnd={e => {
                                                                if (e.latLng && e.latLng) {
                                                                    setCoordinates({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                                                                    setPropertyCoordinate(`${e.latLng.lat()},${e.latLng.lng()}`);
                                                                }
                                                            }}
                                                        />
                                                    </GoogleMap>
                                                </LoadScript>
                                            </div>
                                        </div>


                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>Gayrimenkul Cinsi</label>
                                                <Select className="form-control" defaultValue={property_genus_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => { setPropertyGenusId(e); setPropertyTypeId(null); }}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {property_genuses?.map((property_genuses: Attribute) => {
                                                        return (
                                                            <Option value={property_genuses.id} title={property_genuses.name}
                                                                key={property_genuses.name}
                                                                id={property_genuses.name}>{property_genuses.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 10 ? { display: 'none' } : {}} >
                                            <div className="form-group mb-3 select-style">
                                                <label>Gayrimenkul Türü</label>
                                                <Select className="form-control" defaultValue={property_type_id} showSearch
                                                    value={property_type_id}
                                                    placeholder="Gayrimenkul Türü Seçiniz"
                                                    onChange={e => setPropertyTypeId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {property_types.map((property_types: PropertyType) => {
                                                        return (
                                                            <Option value={property_types.id} title={property_types.name}
                                                                key={property_types.name}
                                                                id={property_types.name}>{property_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3 select-style">
                                                <label>Bulunduğu Kat</label>
                                                <Select className="form-control" defaultValue={floor_location_id} showSearch
                                                    value={floor_location_id}
                                                    placeholder="Bulunduğu Katı Seçiniz"
                                                    onChange={e => setFloorLocationId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {floor_locations.map((floor_locations: FloorLocation) => {
                                                        return (
                                                            <Option value={floor_locations.id} title={floor_locations.name}
                                                                key={floor_locations.name}
                                                                id={floor_locations.name}>{floor_locations.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>

                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3 date-style">
                                                <label>Bina Yaşı</label>
                                                <DatePicker className="form-control" placeholder="Bina Yaşını Seçiniz" picker="year" format={'YYYY'} onChange={(e, dateStr) => setBuildDate(dateStr)} />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Brüt m<sup>2</sup></label>
                                                <input type="number" data-pristine-required-message="Lütfen brüt alanı giriniz."
                                                    className="form-control"
                                                    value={gross}
                                                    defaultValue={gross}
                                                    onChange={e => setGross(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3">
                                                <label>Net m<sup>2</sup></label>
                                                <input type="number" data-pristine-required-message="Lütfen net alanı giriniz."
                                                    className="form-control"
                                                    onChange={e => setNet(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3">
                                                <label>Yapı Türü</label>
                                                <div>
                                                    <Radio.Group defaultValue={build_type_id} onChange={e => setBuildTypeId(e.target.value)}>
                                                        {build_types?.map((build_types: BuildType) => {
                                                            return (
                                                                <Radio value={build_types.id} >{build_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={property_genus_id == 8 || property_genus_id == 10 || build_type_id != 115 ? { display: 'none' } : {}} >
                                            <div className="form-group mb-3 select-style">
                                                <label>Site</label>
                                                <ConfigProvider renderEmpty={customizeRenderEmptyForSite}>
                                                    <Select className="form-control" defaultValue={site_id} showSearch
                                                        value={site_id}
                                                        allowClear={true}
                                                        placeholder="Site Seçiniz"
                                                        onChange={e => setSiteId(e)}
                                                        onSearch={e => setSiteSearch(e)}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => {
                                                            return (
                                                                option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            );

                                                        }}
                                                    >

                                                        {(Array.isArray(sites) && sites.length > 0) ? sites.map((sites: Site) => {
                                                            return (
                                                                <Option value={sites.id} title={sites.name} key={sites.name} id={sites.name}>
                                                                    {sites.name}
                                                                </Option>
                                                            )
                                                        }) : null}
                                                    </Select>
                                                </ConfigProvider>
                                            </div>
                                        </div>

                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { property_note_see ? setPropertyNoteSee(false) : setPropertyNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={property_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    onChange={e => setPropertyNote(e.target.value)}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Sözleşme Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Sözleşme Bilgileri</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Başlangıç Tarihi</label>
                                                <input type="date"
                                                    className="form-control"
                                                    onChange={e => setStartDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Bitiş Tarihi</label>
                                                <input type="date"
                                                    className="form-control"
                                                    onChange={e => setEndDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Sözleşme Süresi</label>
                                                <input type="text"
                                                    value={lease_time}
                                                    defaultValue={lease_time}
                                                    className="form-control"
                                                    onChange={e => setLeaseTime(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Aylık Kira Bedeli</label>
                                                <NumericFormat
                                                    className="form-control"
                                                    name="per_month_price"
                                                    defaultValue={per_month_price?.toString().replace(/[.]/g, '').replace(',', '.')}
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    onChange={e => { setPerMonthPrice(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')); }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Yıllık Kira Bedeli</label>
                                                <NumericFormat
                                                    className="form-control"
                                                    name="per_year_price"
                                                    value={per_year_price?.toString().replace(/[.]/g, '').replace('.', ',')}
                                                    defaultValue={per_year_price?.toString().replace(/[.]/g, '').replace(',', '.')}
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    onChange={e => { setPerYearPrice(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')); }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Depozito Tutarı</label>
                                                <NumericFormat
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    onChange={e => { setDepositPrice(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')); }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">

                                            <div className="form-group mb-3 select-style">
                                                <label>Durum</label>
                                                <Select className="form-control" defaultValue={status_id} showSearch
                                                    value={status_id}
                                                    placeholder="Sözleşme Durumunu Seçiniz"
                                                    onChange={e => setStatusId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {statuses?.map((statuses: Attribute) => {
                                                        return (
                                                            <Option value={statuses.id} title={statuses.name}
                                                                key={statuses.name}
                                                                id={statuses.name}>{statuses.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kullanım Amacı</label>
                                                <Select className="form-control" defaultValue={purpose_of_use_id} showSearch
                                                    value={purpose_of_use_id}
                                                    placeholder="Kullanım Amacını Seçiniz"
                                                    onChange={e => setPurposeOfUseId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {purpose_of_use?.map((purpose_of_use: Attribute) => {
                                                        return (
                                                            <Option value={purpose_of_use.id} title={purpose_of_use.name}
                                                                key={purpose_of_use.name}
                                                                id={purpose_of_use.name}>{purpose_of_use.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-8 col-md-8">
                                            <div className="form-group mb-3">
                                                <label>Kiralananın Durumu</label>
                                                <input type="text"
                                                    className="form-control"
                                                    onChange={e => setPropertyStatus(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3 select-style">
                                                <label>Demirbaş(lar)</label>
                                                <Select className="form-control" showSearch
                                                    placeholder="Demirbaş Seçiniz"
                                                    mode="tags"
                                                    allowClear={true}
                                                    onChange={e => setSelectedAssets(e)}
                                                    onSearch={e => setAssetName(e)}
                                                    optionFilterProp="children"
                                                >
                                                    {assets?.map((assets: Asset) => {
                                                        return (
                                                            <Option value={assets.id} title={assets.name}
                                                                key={assets.name}
                                                                id={assets.name}>{assets.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3 select-style">
                                                <label>Eşya(lar)</label>
                                                <Select className="form-control" showSearch
                                                    placeholder="Eşya Seçiniz"
                                                    allowClear={true}
                                                    mode="tags"
                                                    onChange={e => setSelectedItems(e)}
                                                    onSearch={e => setItemName(e)}
                                                    optionFilterProp="children"
                                                >
                                                    {items?.map((items: Item) => {
                                                        return (
                                                            <Option value={items.id} title={items.name}
                                                                key={items.name}
                                                                id={items.name}>{items.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Ödeme Şekli</label>
                                                <input type="text"
                                                    className="form-control"
                                                    onChange={e => setPaymentType(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Sözleşme Maddeleri</label>
                                                <CustomSunEditor
                                                    defaultValue={content}
                                                    value={content}
                                                    onChange={(e: any) => setContent(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={disable_button}>{save_button_text}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </Wrapper>
    )
}

export default Edit;