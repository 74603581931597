import axios from "axios";
import React, { SyntheticEvent, useEffect, useState, useRef } from "react";
import { Link, Navigate, NavLink } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";

import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import Breadcrumb from "../../../components/constant/breadcrumb";
import CustomInput from "../../../components/form/customInput";
import CustomSunEditor from "../../../components/form/sun-editor/customSunEditor";

const Create = () => {
    const [name, setName] = useState('');
    const [content, setContent] = useState('');
    const [redirect, setRedirect] = useState(false);

    const [disable_button, setDisableButton] = useState(false);
    const [save_button_text, setSaveButtonText] = useState('Kaydet');

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        
        setDisableButton(true);
        setSaveButtonText('Bekleyiniz...');
        
        await axios.post('contract/contract-templates', {
            name,
            content
        });

        setRedirect(true);
    }


    if (redirect) {
        return <Navigate to={'/contract/contract-templates'} />;
    }

    const items: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Sözleşme Şablonları', path: '/contract/contact-templates' },
        { label: 'Oluştır', active: true },
    ];
    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Sözleşme Şablonu Ekle</h4>
                        <div className="page-title-right">
                            <Breadcrumb items={items} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-4">
                            <div>
                                <form onSubmit={submit}>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Şablon Adı</label>
                                                <CustomInput
                                                    required={true}
                                                    onChange={(e: any) => setName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Sözleşme İçeriği</label>
                                                <CustomSunEditor
                                                    onChange={(e: any) => setContent(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary"  disabled={disable_button}>{save_button_text}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Create;