import axios from "axios";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link, Navigate, NavLink } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Result } from "../../../models/attribute/result/result";
import { Communication } from "../../../models/attribute/communication/communication";
import  isSelectOption  from '../../../components/helper/selectSetting';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { Select, DatePicker, Checkbox, ConfigProvider, message } from 'antd';
import tr from 'date-fns/locale/tr';

import { Attribute } from "../../../models/attribute/attribute";
import CustomInput from "../../../components/form/customInput";
import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import Breadcrumb from "../../../components/constant/breadcrumb";
import moment from "moment";

const { Option } = Select;

interface FormValues {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    address: string;
    note: string;
    advertisement_type_id: number | null;
    result_id: number | null;
    communication_id: number | null;
    meet_date_time: string;
  }

  
const Create = () => {

    function getCurrentDateTime() {
        const now = new Date();
        const offset = now.getTimezoneOffset();
        const localDateTime = new Date(now.getTime() - offset * 60 * 1000);
        return localDateTime.toISOString().slice(0, -8);
      }

    const [formValues, setFormValues] = useState<FormValues>({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        note: '',
        advertisement_type_id: 284,
        result_id: null,
        communication_id: null,
        meet_date_time: moment().format('YYYY-MM-DD HH:mm'),
      });
    

    const [isFormValid, setIsFormValid] = useState(true);

    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [note, setNote] = useState('');

    const [advertisement_type_id, setAdvertisementTypeId] = useState(284);    
    const [result_id, setResultId] = useState('');    
    const [communication_id, setCommunicationId] = useState('');
    const [meets, setMeets] = useState([]);
    const [redirect, setRedirect] = useState(false);
    
    const [results, setResults] = useState([]);
    const [communications, setCommunications] = useState([]);
    const [advertisement_types, setAdvertisementTypes] = useState([]);
    

    
      const customFilterOption = (input:any, option:any) => {
        const normalizedInput = input.toLowerCase().trim();
        const normalizedOption = option.children.toLowerCase().trim();
    
        return normalizedOption.localeCompare(normalizedInput, 'tr', { sensitivity: 'base' }) === 0;
      };

      const handleInputChange = (field:any, value:any) => {
        setFormValues({ ...formValues, [field]: value });
      };

      const getFieldDisplayName = (field: keyof FormValues): string => {
        const fieldNames: Record<keyof FormValues, string> = {
          first_name: 'Ad',
          last_name: 'Soyad',
          email: 'Email',
          phone: 'Telefon',
          address: 'Adres',
          note: 'Not',
          advertisement_type_id: 'İlan Türü',
          result_id: 'Görüşme Sonucu',
          communication_id: 'İletişim Kaynağı',
          meet_date_time: 'İşlem Tarih / Saat',
        };
        return fieldNames[field];
      };
    
      
      const handleSubmit = async (e:any) => {
        e.preventDefault();
      
        // Check if all required fields are filled
        const requiredFields: (keyof FormValues)[] = [
          'first_name',
          'last_name',
          'email',
          'phone',
          'address',
          'result_id',
          'communication_id',
          'advertisement_type_id',
          'meet_date_time',
        ];
      
        for (const field of requiredFields) {
          if (!formValues[field]) {
            setIsFormValid(false);
            message.error(`Lütfen ${getFieldDisplayName(field)} alanını doldurun.`);
            return;
          }
        }
      
        // Submit the form if all required fields are filled
        setIsFormValid(true);
        await axios.post('recruitment/meets', formValues);
        setRedirect(true);
      };
      
    const [meet_date_time, setMeetDateTime] = useState(getCurrentDateTime());

    useEffect(() => {
        (
            async () => {
                const {data} = await axios.get('attributes/52');

                setResults(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const {data} = await axios.get('attributes/53');

                setCommunications(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const {data} = await axios.get('attributes/54');

                setAdvertisementTypes(data.data);
            }
        )()
    }, []);




    function withEvent(func: Function): React.ChangeEventHandler<any> {
        return (event: React.ChangeEvent<any>) => {
          const { target } = event;
          func(target.value);
        };
      }
      
    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('meets');

                setMeets(data);
            }
        )()
    }, []);


    if (redirect) {
        return <Navigate to={'/recruitment/meets'} />;
    }

    const brItems: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Görüşmeler', path: '/recruitment/meets' },
        { label: 'Oluştur', active: true },
    ];

    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Görüşme Ekle</h4>

                        <div className="page-title-right">
                            <Breadcrumb items={brItems} />
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-4">
                            <div>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" required data-pristine-required-message="Lütfen bir isim giriniz."
                                                    className="form-control"
                                                    onChange={(e) => handleInputChange('first_name', e.target.value)}
                                                />
                                                {!formValues.first_name && !isFormValid && <p style={{ color: 'red' }}>Lütfen Ad alanını doldurun.</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" required data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                    onChange={(e) => handleInputChange('last_name', e.target.value)}
                                                />
                                                {!formValues.last_name && !isFormValid && <p style={{ color: 'red' }}>Lütfen soyad alanını doldurun.</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" required data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    onChange={(e) => handleInputChange('email', e.target.value)}
                                                />
                                                {!formValues.email && !isFormValid && <p style={{ color: 'red' }}>Lütfen email alanını doldurun.</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <input type="text" required data-pristine-required-message="Lütfen bir telefon numarası giriniz."
                                                    className="form-control"
                                                    onChange={(e) => handleInputChange('phone', e.target.value)}
                                                />
                                                {!formValues.phone && !isFormValid && <p style={{ color: 'red' }}>Lütfen bir telefon numarası giriniz.</p>}
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>İlan Türü</label>
                                                <Select className="form-control" defaultValue={formValues.advertisement_type_id} showSearch
                                                    value={formValues.advertisement_type_id}
                                                    placeholder="İlan Türünü Seçiniz"
                                                    onChange={e => handleInputChange('advertisement_type_id', e)}
                                                    optionFilterProp="children"
                                                    filterOption={customFilterOption}
                                                >
                                                    {advertisement_types?.map((advertisement_type: Attribute) => {
                                                        return (
                                                            <Option value={advertisement_type.id} title={advertisement_type.name}
                                                                key={advertisement_type.name}
                                                                id={advertisement_type.name}>{advertisement_type.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {!formValues.advertisement_type_id && !isFormValid && <p style={{ color: 'red' }}>Lütfen ilan türünü seçiniz.</p>}
                                            </div>
                                        </div>

                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Görüşme Sonucu</label>
                                                <Select className="form-control" defaultValue={formValues.result_id} showSearch
                                                    value={formValues.result_id}
                                                    placeholder="Görüşme Sonucunu Seçiniz"
                                                    onChange={e => handleInputChange('result_id', e)}
                                                    optionFilterProp="children"
                                                    filterOption={customFilterOption}
                                                >
                                                    {results?.map((result: Attribute) => {
                                                        return (
                                                            <Option value={result.id} title={result.name}
                                                                key={result.name}
                                                                id={result.name}>{result.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {!formValues.result_id && !isFormValid && <p style={{ color: 'red' }}>Lütfen görüşme sonucunu seçiniz.</p>}
                                            </div>
                                        </div>

                                        
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>İletişim Kaynağı</label>
                                                <Select className="form-control" defaultValue={formValues.communication_id} showSearch
                                                    value={formValues.communication_id}
                                                    placeholder="İletişim Kaynağını Seçiniz"
                                                    onChange={e => handleInputChange('communication_id', e)}
                                                    optionFilterProp="children"
                                                    filterOption={customFilterOption}
                                                >
                                                    {communications?.map((communication: Attribute) => {
                                                        return (
                                                            <Option value={communication.id} title={communication.name}
                                                                key={communication.name}
                                                                id={communication.name}>{communication.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {!formValues.communication_id && !isFormValid && <p style={{ color: 'red' }}>Lütfen iletişim kaynağını seçiniz.</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>İşlem Tarih / Saat</label>
                                                <CustomInput
                                                    value={formValues.meet_date_time}
                                                    type={'datetime-local'}
                                                    onChange={(e:any) => handleInputChange('meet_date_time', e.target.value)}
                                                />
                                                {!formValues.meet_date_time && !isFormValid && <p style={{ color: 'red' }}>Lütfen işlem tarih satini giriniz.</p>}
                                            </div>
                                        </div>
                                      
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" required data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    onChange={e => handleInputChange('address', e.target.value)}
                                                />
                                                {!formValues.address && !isFormValid && <p style={{ color: 'red' }}>Lütfen adres alanını doldurun.</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea  
                                                    className="form-control"
                                                    onChange={e => handleInputChange('note', e.target.value)}
                                                    >
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary">Kaydet</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Create;