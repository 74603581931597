import axios from "axios";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { connect } from "react-redux";

import CustomSunEditor from "../../../components/form/sun-editor/customSunEditor";
import { Link, NavLink } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import { User } from "../../../models/user";
import { WatchSchedules } from "../../../models/asistant/watchSchedule/watchSchedule";
import { WatchScheduleActions } from "../../../models/asistant/watchSchedule/watchScheduleAction";
import Swal from 'sweetalert2';
import { Modal, ModalBody, ModalFooter, ModalTitle, Button } from "react-bootstrap";
import Moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";


import { Input, Form, Select } from "antd";

import CustomInput from "../../../components/form/customInput";
import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import Breadcrumb from "../../../components/constant/breadcrumb";
import { WorkSchedules } from "../../../models/employee/workSchedule/workSchedule";
import isMobile from "../../../components/constant/isMobile";
import { CheckOutlined } from "@ant-design/icons";
import PageLayout from "../../../components/constant/page/layout";
import WorkScheduleIndex from "../../../components/pages/workSchedule";
const { TextArea } = Input;

interface Item {
    key: string;
    id: number;
    name: string;
    phone: string;
    email: string;
    customer_type: string;
    property_type: string;
    property_genus: string;
    contact_id: string;
    meet_time: string;
}

const WorkSchedule = (props: { user: User }) => {

    const [definition, setDefinition] = useState<any>(' ');
    const [working_date, setWorkingDate] = useState('');
    const [btn_text, setBtnText] = useState('Kaydet');
    const [get_type, setGetType] = useState(1);
    const [get_by_id, setGetById] = useState(0);
    const [update, setUpdate] = useState(false);
    const [get_data_status, setGetDataStatus] = useState(false);
    const [see_type, setSeeType] = useState(false);
    const [update_id, setUpdateId] = useState(0);
    const [handle_show, setHandleShow] = useState<any | null>(null);
    const [submit, setSubmit] = useState<any | null>(null);

    const [get_data, setGetData] = useState(true);

    const [workSchedules, setWorkSchedules] = useState([]);
    useEffect(() => {
        if (props?.user?.id > 0) {
            if (get_data) {
                (
                    async () => {
                        const { data } = await axios.get(`employee/work-schedules?filter_user_id=${props.user.id}&sort[by]=created_at&sort[order]=desc`);
                        setWorkSchedules(data.work_schedule.data.data);
                        setGetData(false);
                    }
                )();
            }
        }
    }, [props, get_data]);

    var watchData = [] as any;
    var watchArrdata = [] as any;

    {
        workSchedules.map((w: WorkSchedules) => {
            watchData['id'] = w.id;
            watchData['user_id'] = w.user.id;
            watchData['date'] = w.working_date;
            watchData['title'] = isMobile?'✔':'✔ Eklendi';
            watchArrdata.push({ ...watchData });
        })

    }
    //Modal    
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); setUpdate(false); }

    useEffect(() => {
        setUpdate(false);
        if (handle_show) {
            setShow(true);
            setDefinition('');
            if (handle_show?.event?._def?.publicId > 0) {
                (isTodayOrYesterday(handle_show?.event?._instance?.range?.start)) ? setSeeType(true) : setSeeType(false);
                setGetType(1);
                setGetById(handle_show?.event?._def?.publicId);
                setWorkingDate(Moment(handle_show?.event?._instance?.range?.start).format("YYYY-MM-DD"));
            } else {
                setWorkingDate(Moment(handle_show.dateStr).format("YYYY-MM-DD"));
                setGetType(2);
                setSeeType(true);
            }
            setGetDataStatus(true);
        }
    }, [handle_show]);

    useEffect(() => {
        if (get_data_status) {
            (
                async () => {
                    setUpdate(false);
                    setBtnText('Kaydet');
                    setDefinition('');
                    if (get_type == 1) {
                        if (get_by_id > 0) {
                            const { data } = await axios.get(`employee/work-schedules/${get_by_id}`);
                            setDefinition(data.definition);
                            setUpdateId(data.id);
                            if (data.id > 0) {
                                setUpdate(true);
                                setBtnText('Güncelle');
                            }
                        }
                    } else {
                        const { data } = await axios.get(`employee/work-schedules?filter_user_id=${props.user.id}&filter_working_date=${working_date}`);
                        (data.work_schedule?.data?.data[0]?.definition)?setDefinition(data.work_schedule?.data?.data[0]?.definition):setDefinition('');
                        setUpdateId(data.work_schedule?.data?.data[0]?.id);
                        if (data.work_schedule?.data?.data[0]?.id > 0) {
                            setUpdate(true);
                            setBtnText('Güncelle');
                        }
                    }
                }
            )();
            setGetDataStatus(false);
        }
    }, [get_data_status]);


    useEffect(() => {
        if (submit) {
            (async () => {
                try {
                    setBtnText('Bekleyiniz...');
                    const url = update ? `employee/work-schedules/${update_id}` : 'employee/work-schedules';
                    const data = update ? { working_date, definition } : { working_date, definition };

                    await axios[update ? 'put' : 'post'](url, data);

                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: update ? 'Güncellendi' : 'Kaydedildi',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setGetData(true);
                    // reset any form values as needed
                } catch (error) {
                    (update) ? setBtnText('Güncelle') : setBtnText('Kaydet');
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'İşlem sırasında bir hata gerçekleşti. Error: ' + error,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setUpdate(false);
                } finally {
                    setShow(false);
                    setSubmit(false);
                    setUpdate(false);

                }
            })();
        }
    }, [submit]);

    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState("");

    const isEditing = (record: Item) => record.key === editingKey;

    const edit = (record: Item) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey("");
    };


    const items: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Günlük İş Raporları', active: true },
    ];
    const modalLoaded = () => {
    };

    function isTodayOrYesterday(date: Date): boolean {
        const today = new Date();
        const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
        return date.toDateString() === today.toDateString() || date.toDateString() === yesterday.toDateString();
    }

    return (
        <Wrapper>

        <PageLayout title={'Günlük İş Raporu'}
            breadcrumbItems={[
                { label: 'Gösterge Paneli', path: '/' },
                { label: 'Günlük İş Raporu', active: true },
            ]}>
            <WorkScheduleIndex />
        </PageLayout>

    </Wrapper>
    )
}

export default connect(
    (state: { user: User }) => {
        return {
            user: state.user
        };
    }
)(WorkSchedule);