import axios from "axios";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link, Navigate, NavLink } from "react-router-dom";
import Wrapper from "../../components/layout/wrapper";
import { Permission } from "../../models/permission";
import { Module } from "../../models/system/module/module";
import { Role } from "../../models/role";
import * as api from "../../axios";
import { Switch } from "@mui/material";

const Create = () => {
    const [permissions, setPermissions] = useState([]);
    const [modules, setModules] = useState([]);
    const [selected, setSelected] = useState([] as number[]);
    const [name, setName] = useState('');
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        (
            async () => {
                const {data} = await axios.get('permissions');

                setPermissions(data);
            }
        )();
    }, []);

    useEffect(() => {
        (
            async () => {
                const {data} = await axios.get('modules');

                setModules(data);
            }
        )();
    }, []);

    const check = (id: number) => {
        if (selected.some(s => s === id)) {
            setSelected(selected.filter(s => s !== id));
            return;
        }

        setSelected([...selected, id]);
        console.log(selected);
    }

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        
     //   api.createRole(name,permissions);

        await axios.post('roles', {
            name,
            permissions: selected
        }); 

        setRedirect(true);
    }

    if (redirect) {
        return <Navigate to={'/roles'}/>;
     }
    return(
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Rol Ekle</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><NavLink to={'/'}>Gösterge Paneli</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to={'/roles'}>Roller</NavLink></li>
                                <li className="breadcrumb-item active">Oluştur</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>        
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-4">
                            <div>
                                <form onSubmit={submit}>
                                    <div className="row">
                                        <div className="row mb-4">
                                            <label htmlFor="horizontal-firstname-input" className="col-sm-2 col-form-label">Rol Adı</label>
                                            <div className="col-sm-9">
                                                <input type="text" className="form-control" required data-pristine-required-message="Lütfen bir rol adı giriniz." id="horizontal-firstname-input"
                                                onChange={e => setName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label className="col-sm-2 col-form-label">İzinler</label>
                                            <div className="col-sm-10">
                                                <div className="table-responsive">
                                                    <table className="table table-striped table-hover mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Modül</th>
                                                                <th>Görüntüleme</th>
                                                                <th>Güncelleme</th>
                                                                <th>Silme</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {modules.map((m: Module) => {
                                                    return (
                                                        <tr>
                                                        <th scope="row">{m.name}</th>
                                                        {m.permission.map((p: Permission) => {
                                                                return (
                                                        <td>
                                                            <Switch value={p.id}  onChange={() => check(p.id)}/> {p.name}
                                                        </td>
                                                         )
                                                        })}
                                                    </tr>
                                                    )
                                                })}
                                                        

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary">Oluştur</button>
                                    </div>
                                </form>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Create;