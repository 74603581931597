import React from 'react';

const EmployeeDashboard = ({ props }: any) => {
  return (
    <>
      <div className="row">
        <div className="profile-content">
          <div className="row align-items-end">
            <div className="col-sm">
              <div className="d-flex align-items-end mt-5 mt-sm-5 pt-3">
                <div className="flex-shrink-0">
                  <div className="avatar-xxl me-3">
                    <img src="/assets/images/users/avatar-1.jpg" alt="" className="img-fluid rounded-circle d-block img-thumbnail" />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div>
                    <h5 className="font-size-16 mb-1">{props.user.name}</h5>
                    <p className="text-muted font-size-13 mb-2 pb-2">{props.user?.role?.name}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-8 col-lg-8">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Görev Tanımı</h5>
            </div>

            <div className="card-body">
              <div>
                <div className="pb-3">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-lg-4">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title mb-0">Departman</h5>
            </div>
            <div className="card-body">
              <div className="d-flex flex-wrap gap-2 font-size-16">
                <a href="#" className="badge badge-soft-primary">{props.user.role.name}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeDashboard;
