import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link, Navigate, NavLink, useParams } from "react-router-dom";
import axios from "axios";

//Boostrap
import { Modal, Button } from "react-bootstrap";

import { Radio, Select, DatePicker, Checkbox, ConfigProvider, message } from 'antd';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { animateScroll as scroll } from 'react-scroll'

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { setKey, fromAddress,fromLatLng } from "react-geocode";

import Wrapper from "../../../components/layout/wrapper";
import { User } from "../../../models/user";

import { PlusOutlined, InboxOutlined, DownloadOutlined } from "@ant-design/icons";

import { CustomerType } from "../../../models/attribute/customerType/customerType";
import { PhoneCode } from "../../../models/general/country/phoneCode";
import { IdType } from "../../../models/attribute/idType/idType";
import { LandOffice } from "../../../models/general/location/landOffice/landOffice";
import { Province } from "../../../models/general/location/province/province";
import { District } from "../../../models/general/location/district/district";
import { Neighbourhood } from "../../../models/general/location/neighbourhood/neighbourhood";
import { PropertyGenus } from "../../../models/attribute/propertyGenus/propertyGenus";
import { PropertyType } from "../../../models/attribute/propertyType/propertyType";
import { FloorLocation } from "../../../models/attribute/floorLocation/floorLocation";
import { BuildType } from "../../../models/attribute/buildType/buildType";
import { Site } from "../../../models/general/site/site";
import { GiroType } from "../../../models/attribute/giroType/giroType";
import { Realtor } from "../../../models/general/realtor/realtor";
import { PaymentMethod } from "../../../models/attribute/paymentMethod/paymentMethod";

import { SourceOfFind } from "../../../models/attribute/sourceOfFind/sourceOfFind";
import { SourceOfSell } from "../../../models/attribute/sourceOfSell/sourceOfSell";
import moment from 'moment';
import dayjs from 'dayjs';
import { Country } from "../../../models/general/country/country";
import { useUserContext } from "../../../providers/user/userProvider";
import { useIdTypeContext } from "../../../providers/attribute/idType/idTypeProvider";
import { useCustomerTypeContext } from "../../../providers/attribute/customerType/customerTypeProvider";
import { useCountryContext } from "../../../providers/general/location/country/countryProvider";
import { usePaymentMethodContext } from "../../../providers/attribute/paymentMethod/paymentMethodProvider";
import { useCommentStatusContext } from "../../../providers/attribute/commentStatus/commentStatusProvider";
import { useResourceOfSellContext } from "../../../providers/attribute/sourceOfSell/sourceOfSellProvider";
import { useResourcesOfFindContext } from "../../../providers/attribute/sourceOfFind/sourceOfFindProvider";
import { useLandOfficeContext } from "../../../providers/general/location/landOffice/landOfficeProvider";
import { useProvinceContext } from "../../../providers/general/location/province/provinceProvider";
import { useBuildTypeContext } from "../../../providers/attribute/buildType/buildTypeProvider";
import { Attribute } from "../../../models/attribute/attribute";
import { usePropertyGenusContext } from "../../../providers/attribute/propertyGenus/propertyGenusProvider";
import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import Breadcrumb from "../../../components/constant/breadcrumb";
const { Option } = Select;


const Edit = () => {


    //Edit
    const { id } = useParams();
    const [authorization_agreement, setAuthorizationAgreement] = useState<any | null>(null);
    setKey("AIzaSyA4ZrsurXwR2QD4Xx5-CpHPD4N3qvurBMo");

    // General
    const [disable_button, setDisableButton] = useState(false);
    const [first_load, setFirstLoad] = useState(true);
    const [save_button_text, setSaveButtonText] = useState('Güncelle');
    const [ret_agents, setRetAgents] = useState<any | null>([]);

    // Customer
    const [customer_type_id, setCustomerTypeId] = useState<any | null>(1);
    const [company_name, setCompanyName] = useState('');
    const [tax_admin, setTaxAdmin] = useState('');
    const [tax_number, setTaxNumber] = useState('');
    const [phone_code_id, setPhoneCodeId] = useState<any | null>(34);
    const [first_name, setFirstName] = useState<any | null>('');
    const [last_name, setLastName] = useState<any | null>('');
    const [nationality_id, setNationalityId] = useState(34);
    const [id_type_id, setIdTypeId] = useState<any | null>(3);
    const [id_no, setIdNo] = useState('');
    const [birthday, setBirthday] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [note, setNote] = useState('');


    //Other
    const [note_see, setNoteSee] = useState(false);
    const [buyer_note_see, setBuyerNoteSee] = useState(false);
    const [property_note_see, setPropertyNoteSee] = useState(false);
    const [out_office_see, setOutOfficeSee] = useState(false);
    const [calc_status, setCalcStatus] = useState(false);
    const [ref_calc_status, setRefCalcStatus] = useState(false);
    const [calc_status_for_agent, setCalcStatusForAgent] = useState(false);
    const [selected_agent_reload, setSelectedAgentReload] = useState(false);
    const [agents_total_service_fee, setAgentsTotalServiceFee] = useState(0);
    const [get_coordinate, setGetCoordinate] = useState(false);


    const [agent_price_status, setAgentPriceStatus] = useState([]);
    const MAX_LIMIT = 100;

    const [other_realtor_show, setOtherRealtorShow] = useState(false);

    //Property
    const [province_id, setProvinceId] = useState<any | null>();
    const [province_name, setProvinceName] = useState('');
    const [district_id, setDistrictId] = useState<any | null>();
    const [district_name, setDistrictName] = useState('');
    const [neighbourhood_id, setNeighbourhoodId] = useState<any | null>();
    const [neighbourhood_hsb_id, setNeighbourhoodHsbId] = useState<any | null>(null);
    const [neighbourhood_name, setNeighbourhoodName] = useState('');
    const [csb_floor_id, setCsbFloorId] = useState<any | null>(0);
    const [csb_parcel_id, setCsbParcelId] = useState<any | null>(0);
    const [rectangle_coordinates, setRectangleCoordinates] = useState<any | null>('');
     
    const [bob, setBob] = useState<any | null>(null);
    const [parcel, setParcel] = useState<any | null>(null);
    const [gross, setGross] = useState<any | null>(null);
    const [net, setNet] = useState<any | null>(null);
    const [land_office_id, setLandOfficeId] = useState(1);
    const [deed_date, setDeedDate] = useState('');
    const [property_address, setPropertyAddress] = useState('');
    const [build_date, setBuildDate] = useState<any | null>(null);
    const [property_note, setPropertyNote] = useState('');
    const [site_search, setSiteSearch] = useState('');
    const [site_id, setSiteId] = useState<any | null>(null);
    const [property_genus_id, setPropertyGenusId] = useState<any | null>();
    const [property_type_id, setPropertyTypeId] = useState<any | null>();
    const [floor_location_id, setFloorLocationId] = useState();
    const [build_type_id, setBuildTypeId] = useState(114);

    //authorization_agreement
    const [contract_date, setContractDate] = useState('');
    const [title_deed_cost, setTitleDeedCost] = useState<any | null>(0);
    const [total_service_fee, setTotalServiceFee] = useState<any | null>(0);
    const [type_id, setTypeId] = useState(116);
    const [source_of_find_id, setSourceOfFindId] = useState<any | null>(null);
   
    //Document
    const [start_date, setStartDate] = useState<any | null>('');
    const [end_date, setEndDate] = useState<any | null>('');
    const [sale_price, setSalePrice] = useState<any | null>(null);
    const [document_no, setDocumentNo] = useState<any | null>('');
    const [status_id, setStatusId] = useState<any | null>(159);

    //Site
    const [site_name, setSiteName] = useState('');
    const [site_address, setSiteAddress] = useState('');
    const [total_house, setTotalHouse] = useState(0);
    const [manager_name, setManagerName] = useState('');
    const [mobile_phone, setMobilePhone] = useState('');
    const [office_phone, setOfficePhone] = useState('');
    const [site_note, setSiteNote] = useState('');

      //Get Coordinate
      useEffect(() => {
        if (get_coordinate) {
            if (province_id > 0 && district_id > 0 && neighbourhood_id > 0 && bob > 0 && parcel > 0) {
                (
                    async () => {
                        try {
                            const { data } = await axios.get(`properties-hsb-info/${neighbourhood_id}/${bob}/${parcel}`);
                            setCoordinates({ lat: Number(data.coordinate.latitude), lng: Number(data.coordinate.longitude) });
                            setGross(data?.m2);
                            setPropertyCoordinate(`${data.coordinate.latitude},${data.coordinate.longitude}`);
                            setCsbParcelId(data?.csb_parcel_id);
                            setCsbFloorId(data?.csb_floor_id);
                            setRectangleCoordinates(data.coordinates);
                        } catch (error) {
                            console.error('Veri çekme hatası:', error);
                        }
                    }
                )();
            }
            setGetCoordinate(false);

        }
    }, [get_coordinate]);


    const [redirect, setRedirect] = useState(false);

    const [districts, setDistricts] = useState([]);
    const [neighbourhoods, setNeighbourhoods] = useState([]);
    const [property_types, setPropertyTypes] = useState([]);
    const [floor_locations, setFloorLocations] = useState([]);
    const [sites, setSites] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [realtors, setRealtors] = useState([]);
    const [giro_types, setGiroTypes] = useState([]);

    const [users, setUsers] = useUserContext();
    const [id_types, setIdTypes] = useIdTypeContext();
    const [customer_types, setCustomerTypes] = useCustomerTypeContext();
    const [phone_codes, setPhoneCodes] = useCountryContext();
    const [nationalities, setNationalities] = useCountryContext();
    const [payment_methods, setPaymentMethods] = usePaymentMethodContext();
    const [comment_statuses, setCommentStatuses] = useCommentStatusContext();
    const [resources_of_sell, setResourcesOfSell] = useResourceOfSellContext();
    const [resources_of_find, setResourcesOfFind] = useResourcesOfFindContext();
    const [land_offices, setLandOffices] = useLandOfficeContext();
    const [provinces, setProvinces] = useProvinceContext();
    const [build_types, setBuildTypes] = useBuildTypeContext();
    const [property_genuses, setPropertyGenuses] = usePropertyGenusContext();

    const [coordinates, setCoordinates] = useState<any | null>({ lat: 36.87524849186124, lng: 30.64364152042535 });
    const [property_coordinate, setPropertyCoordinate] = useState<any | null>('36.87524849186124,30.64364152042535');

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`contract/authorization-agreements/${id}`);
                setAuthorizationAgreement(data);

                //Customer
                setCustomerTypeId(data?.customer?.customer_type_id);
                setCompanyName(data?.customer?.company_name !== '0' ? data?.customer?.company_name : '');
                setTaxAdmin(data?.customer?.tax_admin !== '0' ? data?.customer?.tax_admin : '');
                setTaxNumber(data?.customer?.tax_number !== '0' ? data?.customer?.tax_number : '');
                setFirstName(data?.customer?.first_name);
                setLastName(data?.customer?.last_name);
                setNationalityId(data?.customer?.nationality_id);
                setIdTypeId(data?.customer?.idType?.id);
                setIdNo(data?.customer?.id_no);
                setPhoneCodeId(data?.customer?.phone_code_id);
                setPhone(data?.customer?.phone);
                setEmail(data?.customer?.email !== '0' ? data?.customer?.email : '');
                setBirthday(data?.customer?.birthday);
                setAddress(data?.customer?.address);
                (data?.customer?.definition !== null && data?.customer?.definition !== undefined && data?.customer?.definition !== '0') ? setNoteSee(true) : setNoteSee(false);
                setNote(data?.customer?.definition);

                //Property
                if (data && data.property) {
                    setProvinceId(data?.property.province_id);
                    setDistrictId(data?.property.district_id);
                    setNeighbourhoodId(data?.property?.neighbourhood_id);
                    setBob(data?.property.bob);
                    setParcel(data?.property.parcel);
                    setGross(data?.property.gross);
                    setNet(data?.property.net);
                    setLandOfficeId(data?.property.land_office_id);
                    setDeedDate(data?.property.deed_date);
                    setPropertyAddress(data?.property.address);
                    setBuildDate(data?.property.build_date !== '0' ? data?.property.build_date : '0000');

                    setPropertyNote(data?.property.definition);
                    (data?.property.definition !== null && data?.property.definition !== undefined && data?.property.definition !== '0') ? setPropertyNoteSee(true) : setPropertyNoteSee(false);
                    setSiteId(data.property.site && data.property.site.id);
                    setPropertyGenusId(data?.property.propertyGenus.id);
                    setPropertyTypeId(data.property.propertyType && data.property.propertyType.id);
                    setFloorLocationId(data.property.floorLocation && data.property.floorLocation.id);
                    setBuildTypeId(data?.property.buildType.id);

                    let splitCoordinate = data.property.coordinate.split(',');
                    setCoordinates({ lat: splitCoordinate[0], lng: splitCoordinate[1] });
                    setPropertyCoordinate(data.property.coordinate);
                }

                //authorization_agreement
                setTypeId(data?.type.id);
                setContractDate(data?.contract_date);
                setSalePrice(data?.price);
                setStatusId(data?.status?.id);
                setTitleDeedCost(data?.title_deed_cost);
                setSourceOfFindId(data?.source_of_find?.id);
                setStartDate(data?.start_date);
                setEndDate(data?.end_date);
                setDocumentNo(data?.document_no);
            }
        )()
    }, [id]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('users');

                setUsers(data.data);
            }

        )()
    }, []);

    useEffect(() => {
        (
            async () => {

                const { data } = await axios.get('location/district/' + province_id);

                setDistricts(data);
            }
        )()
    }, [province_id]);

    useEffect(() => {
if(!first_load){
        (
            async () => {
                if (typeof province_name === 'string' && province_name.trim().length > 0) {
                    const { data } = await axios.get('location/province/name-like/' + province_name);
                    if (data.id > 0) { setDistrictId(null); setNeighbourhoodId(null); }
                    setProvinceId(data.id);
                }
            }
        )();
    }
    }, [province_name])


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('location/neighbourhood/' + district_id);

                setNeighbourhoods(data);
            }
        )()
    }, [district_id]);

    useEffect(() => {
if(!first_load){

        setTimeout(() => {

            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0) {

                        const { data } = await axios.get(`location/district/name-like/${district_name}/${province_id}`);
                        setDistrictId(data.id);
                    }
                }
            )()
        }, 1000);
    }
    }, [district_name, province_id])

    useEffect(() => {
if(!first_load){

        setTimeout(() => {

            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0) {

                        const { data } = await axios.get(`location/neighbourhood/name-like/${neighbourhood_name}/${district_id}`);
                        setNeighbourhoodId(data.id);
                    }
                }
            )()
        }, 1000);
    }
    }, [neighbourhood_name, district_id])


    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/7');

    //             setPropertyGenuses(data.data);
    //         }
    //     )()
    // }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/8/' + property_genus_id);

                setPropertyTypes(data.data);
            }
        )()
    }, [property_genus_id]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/9');

                setFloorLocations(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/34');

                setStatuses(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/34');

                setStatuses(data.data);
            }
        )()
    }, [neighbourhood_hsb_id, bob, parcel]);

    useEffect(() => {
        (
            async () => {
                if (site_search.length > 0) {
                    const { data } = await axios.get('sites/search/' + site_search);
                    setSites(data.data);
                } else {
                    const { data } = await axios.get('sites');
                    setSites(data.data);
                }
            }
        )()
    }, [site_search]);


    const customizeRenderEmptyForSite = () => (
        <div style={{ textAlign: 'center' }}>
            <InboxOutlined style={{ fontSize: 40 }} />
            <p>Site Bulunamadı!</p>
            <Button variant="primary" onClick={handleSiteShow}>Yeni Ekle</Button>

        </div>
    );


    //Modals
    //Add Site Modal
    const [show_site_modal, setShowSiteModal] = useState(false);
    const handleSiteClose = () => setShowSiteModal(false);

    const handleSiteShow = (e: any) => {
        (
            () => {
                setShowSiteModal(true);
            }
        )()
    };

    //Downlaod Modal
    const [show_download, setShowDownload] = useState(false);
    const handleDownloadClose = () => { setShowDownload(false); setRedirect(true); }

    const handleDownloadShow = (e: any) => {
        (
            () => {
                setShowDownload(true);
            }
        )()
    };

    //Google Maps Geo For Site
    const [site_coordinates, setSiteCoordinates] = useState('');

    useEffect(() => {


        fromAddress(site_address).then(
            (response: any) => {
                const { lat, lng } = response.results[0].geometry.location;
                setSiteCoordinates(`${lat},${lng}`)
            },
            (error: any) => {
                //console.error(error);
            }
        );
    }, [site_address]);

    // Google maps  geoFor Property


    useEffect(() => {
        fromAddress(property_address).then(
            (response: any) => {
                const { lat, lng } = response.results[0].geometry.location;
                setCoordinates({ lat: lat, lng: lng })
                setPropertyCoordinate(`${lat},${lng}`)

                let administrativeAreaLevel1;

                for (const result of response.results) {
                    for (const component of result.address_components) {
                        if (component.types.includes('administrative_area_level_1')) {
                            administrativeAreaLevel1 = component.long_name;
                            break;
                        }
                    }
                }
                let administrativeAreaLevel2;

                for (const result of response.results) {
                    for (const component of result.address_components) {
                        if (component.types.includes('administrative_area_level_2')) {
                            administrativeAreaLevel2 = component.long_name;
                            break;
                        }
                    }
                }
                let administrativeAreaLevel3;

                for (const result of response.results) {
                    for (const component of result.address_components) {
                        if (component.types.includes('administrative_area_level_3')) {
                            administrativeAreaLevel3 = component.long_name;
                            break;
                        }
                    }
                }

                let administrativeAreaLevel4;
                for (const result of response.results) {
                    for (const component of result.address_components) {
                        if (component.types.includes('administrative_area_level_4')) {
                            administrativeAreaLevel4 = component.long_name;
                            break;
                        }
                    }
                }

                let administrativeAreaLevelNeighbourhood;
                for (const result of response.results) {
                    for (const component of result.address_components) {
                        if (component.types.includes('neighborhood')) {
                            administrativeAreaLevelNeighbourhood = component.long_name;
                            break;
                        }
                    }
                }

                setProvinceName(administrativeAreaLevel1);
                setDistrictName(administrativeAreaLevel2);
                if (administrativeAreaLevelNeighbourhood != null && administrativeAreaLevelNeighbourhood != '' && administrativeAreaLevelNeighbourhood.trim().length > 0) {
                    setNeighbourhoodName(administrativeAreaLevelNeighbourhood);
                } else if (administrativeAreaLevel3 != null && administrativeAreaLevel3 != '' && administrativeAreaLevel3.trim().length > 0) {
                    setNeighbourhoodName(administrativeAreaLevel3);
                } else if (administrativeAreaLevel4 != null && administrativeAreaLevel4 != '' && administrativeAreaLevel4.trim().length > 0) {
                    setNeighbourhoodName(administrativeAreaLevel4);
                }

            },
            (error: any) => {
                //console.error(error);
            }
        );
    }, [property_address]);

    useEffect(() => {
        if (coordinates.lat && coordinates.lng) {
            fromLatLng(coordinates?.lat.toString(), coordinates.lng.toString()).then(
                (response) => {
                    const address = response.results[0].formatted_address;
                    setPropertyAddress(address);
                    // Diğer işlemleri yapabilirsiniz
                },
                (error) => {
                    console.error('Adres çözümleme hatası:', error);
                }
            );
        }

    }, [coordinates]);

    const containerStyle = {
        width: '100%',
        height: '400px'
    };
    const center = {
        lat: 36.87524849186124,
        lng: 30.64364152042535
    };
    const position = {
        lat: 36.87524849186124,
        lng: 30.64364152042535
    }




    function withEvent(func: Function): React.ChangeEventHandler<any> {
        return (event: React.ChangeEvent<any>) => {
            const { target } = event;
            func(target.value);
        };
    }



    //Save operations 
    //Site
    const siteSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const new_site_id = await axios.post('sites', {
            name: site_name,
            address: site_address,
            manager_name: manager_name,
            office_phone: office_phone,
            mobile_phone: mobile_phone,
            coordinate: site_coordinates,
            definition: site_note,
            house_count: total_house,

        });
        setSiteSearch(`${site_name}`);
        setSiteId(new_site_id);
        setShowSiteModal(false);
    }

    //General
    interface FormState {
        error: { [key: string]: any | null };
        inputRefs: { [key: string]: any | React.RefObject<HTMLInputElement> };
    }

    const [formState, setFormState] = useState<any | null>({ error: {}, inputRefs: {} });
    const [messageApi, contextHolder] = message.useMessage();

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        let errorFields: { [key: string | number]: { name: string, key: any, error: string | null, label: string } } = {};
        let hasError = false;
        const requiredInputs: any = [
            { key: 'first_name', name: 'Satıcı adı ' },
            { key: 'last_name', name: 'Satıcı soyadı' },
            { key: 'phone', name: 'Satıcı telefon numarası' },
            { key: 'id_no', name: 'Satıcı kimlik numarası' },
            { key: 'address', name: 'Satıcı adresi' },

            { key: 'buyer_first_name', name: 'Alıcı adı ' },
            { key: 'buyer_last_name', name: 'Alıcı soyadı' },
            { key: 'buyer_phone', name: 'Alıcı telefon numarası' },
            { key: 'buyer_id_no', name: 'Alıcı kimlik numarası' },
            { key: 'buyer_address', name: 'Alıcı adresi' },

            { key: 'property_address', name: 'Portföy adresi ' },
            { key: 'province_id', name: 'Portföy ili' },
            { key: 'district_id', name: 'Portföy ilçesi' },
            { key: 'neighbourhood_id', name: 'Portföy mahallesi' },

            { key: 'contract_date', name: 'Sözleşme tarihi' },
            { key: 'source_of_find_id', name: 'Portföy bulma kaynağı' },
            { key: 'source_of_sell_id', name: 'Portföy satma kaynağı' },
            { key: 'sale_price', name: 'Satış bedeli' },
            { key: 'service_fee', name: 'Satıcı hizmet bedeli' },
        ];

        requiredInputs.forEach((input: { key: any; name: any; }) => {
            const el = (e.currentTarget as HTMLFormElement).elements[input.key] as HTMLInputElement;


            if (el && !el.value) {
                errorFields[input.key] = { key: input.key, label: input.name, name: input.name, error: "Bu alan zorunlu!" };
                messageApi.open({
                    type: 'error',
                    content: input.name + ' zorunludur. Lütfen doldurunuz.',
                });
                hasError = true;
            }

        });

        if (hasError) {
            if (source_of_find_id === 0 || source_of_find_id === null) {
                errorFields['source_of_find_id'] = { key: 'source_of_find_id', name: 'source_of_find_id', label: 'Portföy bulma kaynağı zorunludur.', error: "Bu alan zorunlu!" };
                messageApi.open({
                    type: 'error',
                    content: 'Portföy bulma kaynağı zorunludur.. Lütfen doldurunuz.',
                });
            }
            if (district_id === 0 || district_id === null) {
                errorFields['district_id'] = { key: 'district_id', name: 'district_id', label: 'İlçe seçimi zorunludur.', error: "Bu alan zorunlu!" };
                messageApi.open({
                    type: 'error',
                    content: 'İlçe seçimi zorunludur. Lütfen doldurunuz.',
                });
            }

            if (neighbourhood_id === 0 || neighbourhood_id === null) {
                errorFields['neighbourhood_id'] = { key: 'neighbourhood_id', name: 'neighbourhood_id', label: 'Mahalle seçimi zorunludur.', error: "Bu alan zorunlu!" };
                messageApi.open({
                    type: 'error',
                    content: 'Mahalle seçimi zorunludur. Lütfen doldurunuz.',
                });
            }

            setFormState({ error: errorFields, inputRefs: formState.inputRefs });

            return;
        }

        if (hasError) {


            messageApi.open({
                type: 'error',
                content: 'Lütfen zorunlu alanları doldurun!',
            });

            const firstErrorInput = Object.keys(errorFields).find((key) => errorFields[key] !== null);
            if (firstErrorInput && formState.inputRefs[firstErrorInput] && formState.inputRefs[firstErrorInput].current) {
                formState.inputRefs[firstErrorInput.toString()].current.focus();
                scroll.scrollTo(formState.inputRefs[firstErrorInput].current.offsetTop, {
                    duration: 500,
                    smooth: "easeInOutQuint"
                });
            }
            console.log(firstErrorInput);

        } else {
            setFormState({ error: {}, inputRefs: formState.inputRefs });

            setDisableButton(true);
            setSaveButtonText('Bekleyiniz...');
            //  setAgentServiceFee(agent_service_fee.toString().replace('.','.'));
            const res = await axios.put(`contract/authorization-agreements/${id}`, {
                customer_type_id,
                company_name,
                tax_admin,
                tax_number,
                first_name,
                last_name,
                email,
                phone_code_id,
                phone,
                nationality_id,
                id_type_id,
                id_no,
                birthday,
                address,
                note,

                bob,
                parcel,
                land_office_id,
                deed_date,
                property_address,
                province_id,
                district_id,
                neighbourhood_id,
                property_genus_id,
                property_type_id,
                floor_location_id,
                build_date,
                gross,
                net,
                build_type_id,
                site_id,
                property_note,
                property_coordinate,

                source_of_find_id,
                sale_price,
                start_date,
                end_date,
                document_no,
                status_id,

            }).then(response => {
                setRedirect(true);
            })
                .catch(error => {
                    messageApi.open({
                        type: 'error',
                        content: 'İşlem sırasında bir hata gerçekleşti! Hata mesajı: ' + error,
                    });

                    if (source_of_find_id === 0 || source_of_find_id === null) {
                        errorFields['source_of_find_id'] = { key: 'source_of_find_id', name: 'source_of_find_id', label: 'Portföy bulma kaynağı zorunludur.', error: "Bu alan zorunlu!" };
                        messageApi.open({
                            type: 'error',
                            content: 'Portföy bulma kaynağı zorunludur.. Lütfen doldurunuz.',
                        });
                    }
                  

                    if (district_id === 0 || district_id === null) {
                        errorFields['district_id'] = { key: 'district_id', name: 'district_id', label: 'İlçe seçimi zorunludur.', error: "Bu alan zorunlu!" };
                        messageApi.open({
                            type: 'error',
                            content: 'İlçe seçimi zorunludur. Lütfen doldurunuz.',
                        });
                    }

                    if (neighbourhood_id === 0 || neighbourhood_id === null) {
                        errorFields['neighbourhood_id'] = { key: 'neighbourhood_id', name: 'neighbourhood_id', label: 'Mahalle seçimi zorunludur.', error: "Bu alan zorunlu!" };
                        messageApi.open({
                            type: 'error',
                            content: 'Mahalle seçimi zorunludur.. Lütfen doldurunuz.',
                        });
                    }

                    setFormState({ error: errorFields, inputRefs: formState.inputRefs });

                    const firstErrorInput = Object.keys(errorFields).find((key) => errorFields[key] !== null);
                    if (firstErrorInput && formState.inputRefs[firstErrorInput] && formState.inputRefs[firstErrorInput].current) {
                        formState.inputRefs[firstErrorInput.toString()].current.focus();
                        scroll.scrollTo(formState.inputRefs[firstErrorInput].current.offsetTop, {
                            duration: 500,
                            smooth: "easeInOutQuint"
                        });
                    }
                });
        }
        setSaveButtonText('Güncelle');
        setDisableButton(false);
    }

    if (redirect) {
        return <Navigate to={'/contract/authorization-agreements'} />;
    }
    const items: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Yetki Sözleşmeleri', path: '/contract/authorization-agreements' },
        { label: 'Yetki Sözleşmesi Güncelle', active: true },
    ];

    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Yetki Sözleşmesi Güncelle</h4>
                        <div className="page-title-right">
                            <Breadcrumb items={items} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <form onSubmit={submit}>
                        <div className="card">
                            <div className="card-body p-4">
                                <div className="row align-items-end">
                                    <div className="col-12 col-lg-6">
                                        <div className="d-flex align-items-end mt-0 mt-lg-0">
                                            <div className="flex-shrink-0">
                                                <div className="avatar-xl me-3">
                                                    <img src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745" alt="" style={{ width: 100 }} className="img-fluid rounded-circle d-block img-thumbnail" />
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div>
                                                    <h5 className="font-size-16 mb-1">{authorization_agreement?.agent.first_name} {authorization_agreement?.agent.last_name}</h5>
                                                    <p className="text-muted font-size-13 mb-1 pb-0"><strong>İşlem Türü : </strong> {authorization_agreement?.type.name}</p>
                                                    <p className="text-muted font-size-13 mb-1 pb-0"><strong>Ortak Sayısı : </strong> {authorization_agreement?.partner_count}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="mt-2 mt-lg-0">
                                            <p className="text-muted font-size-13 mb-1"><strong>İşlem Tarihi : </strong> {authorization_agreement?.created_at}</p>
                                            <p className="text-muted font-size-13 mb-1 pb-0"><strong>Ekleyen / Güncelleyen Personel : </strong> {authorization_agreement?.user.first_name} {authorization_agreement?.user.last_name}</p>
                                            <p className="text-muted font-size-13 mb-1 pb-0"><strong>IP : </strong> {authorization_agreement?.ip}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Satıcı/Kiralayan Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>{type_id === 116 ? 'Satıcı Bilgileri' : 'Kiralayan Bilgileri'}</label>
                                                <div>
                                                    <Radio.Group defaultValue={customer_type_id} value={customer_type_id} onChange={e => setCustomerTypeId(e.target.value)}>
                                                        {customer_types?.map((customer_types: CustomerType) => {
                                                            return (
                                                                <Radio value={customer_types.id} >{customer_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6" style={customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Firma Adı</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir firma adı giriniz."
                                                    className="form-control"
                                                    value={company_name}
                                                    onChange={e => setCompanyName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Dairesi</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi dairesi giriniz."
                                                    className="form-control"
                                                    value={tax_admin}
                                                    onChange={e => setTaxAdmin(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi numarası giriniz."
                                                    className="form-control"
                                                    value={tax_number}
                                                    onChange={e => setTaxNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                                    name="first_name"
                                                    className="form-control"
                                                    required
                                                    value={first_name}
                                                    onChange={e => setFirstName(e.target.value)}
                                                />
                                                {formState.error.first_name && <p className="text-danger">{formState.error.first_name.error}</p>}

                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" name="last_name" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                    required
                                                    value={last_name}
                                                    onChange={e => setLastName(e.target.value)}
                                                />
                                                {formState.error.last_name && <p className="text-danger">{formState.error.last_name.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kimlik Tipi</label>
                                                <Select className="form-control" defaultValue={id_type_id} value={id_type_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setIdTypeId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {id_types?.map((id_types: IdType) => {
                                                        return (
                                                            <Option value={id_types.id} title={id_types.name}
                                                                key={id_types.name}
                                                                id={id_types.name}>{id_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Kimlik Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir kimlik numarası giriniz."
                                                    className="form-control"
                                                    name="id_no"
                                                    value={id_no}
                                                    required
                                                    onChange={e => setIdNo(e.target.value)}
                                                />
                                                {formState.error.id_no && <p className="text-danger">{formState.error.id_no.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select value={phone_code_id} style={{ width: 100 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setPhoneCodeId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >

                                                            {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                return (
                                                                    <Option value={phone_codes.id} title={phone_codes.name}
                                                                        key={phone_codes.name}
                                                                        id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <PatternFormat format="(###) ### ## ##" value={phone} defaultValue={phone} name="phone" required className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setPhone(e.target.value)} />
                                                    {formState.error.phone && <p className="text-danger">{formState.error.phone?.error}</p>}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Uyruk</label>
                                                <Select className="form-control" defaultValue={nationality_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setNationalityId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {nationalities.map((nationalities: Country) => {
                                                        return (
                                                            <Option value={nationalities.id} title={nationalities.name}
                                                                key={nationalities.name}
                                                                id={nationalities.name}>{nationalities.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>                                        
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Doğum Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen doğum tarihi seçiniz."
                                                    className="form-control"
                                                    value={moment(birthday, "DD/MM/YYYY").format("YYYY-MM-DD")}
                                                    onChange={e => setBirthday(moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY"))}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    name="address"
                                                    value={address}
                                                    required
                                                    onChange={e => setAddress(e.target.value)}
                                                />
                                                {formState.error.address && <p className="text-danger">{formState.error.address.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { note_see ? setNoteSee(false) : setNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    value={note}
                                                    onChange={e => setNote(e.target.value)}>
                                                    {note}
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                          {/* Mülk Bilgileri */}
                          <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Mülk Bilgileri</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>İl</label>
                                                <Select className="form-control" defaultValue={province_id} value={province_id} showSearch
                                                    optionFilterProp="children"
                                                    placeholder="İl Seçiniz"
                                                    onChange={(e,option:any) => { setProvinceId(e); setProvinceName(option.children); setDistrictId(null); setNeighbourhoodId(null); setFirstLoad(false); }}
                                                    onBlur={e => setGetCoordinate(true)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {provinces?.map((provinces: Province) => {
                                                        return (
                                                            <Option value={provinces.id} title={provinces.name}
                                                                key={provinces.name}
                                                                id={provinces.name}>{provinces.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {formState.error.province_id && <p className="text-danger">{formState.error.province_id.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>İlçe</label>
                                                <Select className="form-control" defaultValue={district_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={(e,option:any) => { setDistrictId(e); setDistrictName(option.children);  setNeighbourhoodId(null);setFirstLoad(false); }}
                                                    value={district_id}
                                                    onBlur={e => setGetCoordinate(true)}
                                                    placeholder="İlçe Seçiniz"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {districts.map((districts: District) => {
                                                        return (
                                                            <Option value={districts.id} title={districts.name}
                                                                key={districts.name}
                                                                id={districts.name}>{districts.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {formState.error.district_id && <p className="text-danger">{formState.error.district_id.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>Mahalle</label>
                                                <Select className="form-control" defaultValue={neighbourhood_id} showSearch
                                                    value={neighbourhood_id}
                                                    onChange={e => setNeighbourhoodId(e)}
                                                    optionFilterProp="children"
                                                    placeholder="Mahalle Seçiniz"
                                                    onBlur={e => setGetCoordinate(true)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {neighbourhoods.map((neighbourhoods: Neighbourhood) => {
                                                        return (
                                                            <Option value={neighbourhoods.id} title={neighbourhoods.name}
                                                                key={neighbourhoods.name}
                                                                id={neighbourhoods.name}>{neighbourhoods.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {formState.error.neighbourhood_id && <p className="text-danger">{formState.error.neighbourhood_id.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ada</label>
                                                <input type="number" data-pristine-required-message="Lütfen ada giriniz."
                                                    className="form-control"
                                                    onChange={e => setBob(e.target.value)}
                                                    onBlur={e => setGetCoordinate(true)}
                                                    defaultValue={bob}
                                                    value={bob}

                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Parsel</label>
                                                <input type="number" data-pristine-required-message="Lütfen parsel giriniz."
                                                    className="form-control"
                                                    onChange={e => setParcel(e.target.value)}
                                                    onBlur={e => setGetCoordinate(true)}
                                                    defaultValue={parcel}
                                                    value={parcel}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" name="property_address" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    defaultValue={property_address}
                                                    onBlur={e => setPropertyAddress(e.target.value)}
                                                />
                                                {formState.error.property_address && <p className="text-danger">{formState.error.property_address.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Harita Konumu </label>
                                                <LoadScript
                                                    googleMapsApiKey="AIzaSyA4ZrsurXwR2QD4Xx5-CpHPD4N3qvurBMo"
                                                >
                                                    <GoogleMap
                                                        mapContainerStyle={containerStyle}
                                                        center={coordinates}
                                                        zoom={15}
                                                    >
                                                        <Marker
                                                            position={coordinates}
                                                            draggable={true}
                                                            onDragEnd={e => {
                                                                if (e.latLng && e.latLng) {
                                                                    setCoordinates({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                                                                    setPropertyCoordinate(`${e.latLng.lat()},${e.latLng.lng()}`);
                                                                }
                                                            }}
                                                        />
                                                    </GoogleMap>
                                                </LoadScript>
                                            </div>
                                        </div>


                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>Gayrimenkul Cinsi</label>
                                                <Select className="form-control" defaultValue={property_genus_id} showSearch
                                                    optionFilterProp="children"
                                                    value={property_genus_id}
                                                    onChange={e => { setPropertyGenusId(e); setPropertyTypeId(null); }}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {property_genuses?.map((property_genuses: Attribute) => {
                                                        return (
                                                            <Option value={property_genuses.id} title={property_genuses.name}
                                                                key={property_genuses.name}
                                                                id={property_genuses.name}>{property_genuses.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 10 ? { display: 'none' } : {}} >
                                            <div className="form-group mb-3 select-style">
                                                <label>Gayrimenkul Türü</label>
                                                <Select className="form-control" defaultValue={property_type_id} showSearch
                                                    value={property_type_id}
                                                    placeholder="Gayrimenkul Türü Seçiniz"
                                                    onChange={e => setPropertyTypeId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {property_types.map((property_types: PropertyType) => {
                                                        return (
                                                            <Option value={property_types.id} title={property_types.name}
                                                                key={property_types.name}
                                                                id={property_types.name}>{property_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3 select-style">
                                                <label>Bulunduğu Kat</label>
                                                <Select className="form-control" defaultValue={floor_location_id} showSearch
                                                    value={floor_location_id}
                                                    placeholder="Bulunduğu Katı Seçiniz"
                                                    onChange={e => setFloorLocationId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {floor_locations.map((floor_locations: FloorLocation) => {
                                                        return (
                                                            <Option value={floor_locations.id} title={floor_locations.name}
                                                                key={floor_locations.name}
                                                                id={floor_locations.name}>{floor_locations.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>

                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3 date-style">
                                                <label>Bina Yaşı</label>
                                                <DatePicker className="form-control" value={dayjs(build_date + '-01-01', 'YYYY-MM-DD')} placeholder="Bina Yaşını Seçiniz" picker="year" format={'YYYY'} onChange={(e, dateStr) => setBuildDate(dateStr)} />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Brüt m<sup>2</sup></label>
                                                <input type="number" data-pristine-required-message="Lütfen brüt alanı giriniz."
                                                    className="form-control"
                                                    value={gross}
                                                    defaultValue={gross}
                                                    onChange={e => setGross(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3">
                                                <label>Net m<sup>2</sup></label>
                                                <input type="number" data-pristine-required-message="Lütfen net alanı giriniz."
                                                    className="form-control"
                                                    onChange={e => setNet(e.target.value)}
                                                    value={net}
                                                    defaultValue={net}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3">
                                                <label>Yapı Türü</label>
                                                <div>
                                                    <Radio.Group defaultValue={build_type_id} onChange={e => setBuildTypeId(e.target.value)}>
                                                        {build_types?.map((build_types: BuildType) => {
                                                            return (
                                                                <Radio value={build_types.id} >{build_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={property_genus_id == 8 || property_genus_id == 10 || build_type_id != 115 ? { display: 'none' } : {}} >
                                            <div className="form-group mb-3 select-style">
                                                <label>Site</label>
                                                <ConfigProvider renderEmpty={customizeRenderEmptyForSite}>
                                                    <Select className="form-control" defaultValue={site_id} showSearch
                                                        value={site_id}
                                                        allowClear={true}
                                                        placeholder="Site Seçiniz"
                                                        onChange={e => setSiteId(e)}
                                                        onSearch={e => setSiteSearch(e)}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => {
                                                            return (
                                                                option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            );

                                                        }}
                                                    >

                                                        {(Array.isArray(sites) && sites.length > 0) ? sites.map((sites: Site) => {
                                                            return (
                                                                <Option value={sites.id} title={sites.name} key={sites.name} id={sites.name}>
                                                                    {sites.name}
                                                                </Option>
                                                            )
                                                        }) : null}
                                                    </Select>
                                                </ConfigProvider>
                                            </div>
                                        </div>

                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { property_note_see ? setPropertyNoteSee(false) : setPropertyNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={property_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    onChange={e => setPropertyNote(e.target.value)}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* İşlem Bedel Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Sözleşme Bilgileri</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Başlangıç Tarihi</label>
                                                <input type="date" name="start_date" data-pristine-required-message="Lütfen sözleşme tarihini giriniz."
                                                    className="form-control"
                                                    value={moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD")}
                                                    onChange={e => setStartDate(moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY"))}
                                                    defaultValue={moment(start_date, "DD/MM/YYYY").format("YYYY-MM-DD")}
                                                    
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Bitiş Tarihi</label>
                                                <input type="date" name="end_date" data-pristine-required-message="Lütfen sözleşme tarihini giriniz."
                                                    className="form-control"
                                                    value={moment(end_date, "DD/MM/YYYY").format("YYYY-MM-DD")}
                                                    onChange={e => setEndDate(moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY"))}
                                                    defaultValue={moment(end_date, "DD/MM/YYYY").format("YYYY-MM-DD")}

                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Yeki Belge No</label>
                                                <input type="text" name="document_no" required
                                                    className="form-control"
                                                    value={document_no}
                                                    onChange={e => setDocumentNo(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>Portföy Bulma Kaynağı</label>
                                                <Select className="form-control" defaultValue={source_of_find_id} showSearch
                                                    value={source_of_find_id}
                                                    placeholder="Portföy Bulma Kaynağını Seçiniz"
                                                    onChange={e => setSourceOfFindId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {resources_of_find?.map((resources_of_find: SourceOfFind) => {
                                                        return (
                                                            <Option value={resources_of_find.id} title={resources_of_find.name}
                                                                key={resources_of_find.name}
                                                                id={resources_of_find.name}>{resources_of_find.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {formState.error.source_of_find_id && <p className="text-danger">{formState.error.source_of_find_id.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>{type_id === 116 ? 'Satış Bedeli' : 'Kiralama Bedeli'}</label>
                                                <NumericFormat
                                                    className="form-control"
                                                    name="sale_price"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    value={sale_price?.toString().replace('.', ',')}
                                                    onChange={e => { setSalePrice(e.target.value.toString().replace(/[.]/g, '').replace(',', '.')); }}
                                                />
                                                {formState.error.sale_price && <p className="text-danger">{formState.error.sale_price.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">

                                            <div className="form-group mb-3 select-style">
                                                <label>Durum</label>
                                                <Select className="form-control" defaultValue={status_id} showSearch
                                                    value={status_id}
                                                    placeholder="Sözleşme Durumunu Seçiniz"
                                                    onChange={e => setStatusId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {statuses?.map((statuses: Attribute) => {
                                                        return (
                                                            <Option value={statuses.id} title={statuses.name}
                                                                key={statuses.name}
                                                                id={statuses.name}>{statuses.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {formState.error.source_of_find_id && <p className="text-danger">{formState.error.source_of_find_id.error}</p>}
                                            </div>
                                        </div>
                                    </div>

                                    {contextHolder}
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" disabled={disable_button}>{save_button_text}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {/* //Site Modal */}
            <Modal show={show_site_modal} onHide={handleSiteClose} animation={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Site Ekleme Formu</Modal.Title>
                </Modal.Header>
                <form onSubmit={siteSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Site Adı</label>
                                    <input type="text" data-pristine-required-message="Lütfen bir site adı giriniz."
                                        className="form-control"
                                        onChange={e => setSiteName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Adres</label>
                                    <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                        className="form-control"
                                        onChange={e => setSiteAddress(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Toplam Daire</label>
                                    <input type="number" data-pristine-required-message="Lütfen daire adetini giriniz."
                                        className="form-control"
                                        onChange={e => setTotalHouse(parseInt(e.target.value))}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Yönetici Adı</label>
                                    <input type="text" data-pristine-required-message="Lütfen yönetici adıı giriniz."
                                        className="form-control"
                                        onChange={e => setManagerName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Ofis Telefon</label>
                                    <PatternFormat format="0(###) ### ## ##" className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setOfficePhone(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Cep Telefon</label>
                                    <PatternFormat format="0(###) ### ## ##" className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setMobilePhone(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Not</label>
                                    <textarea
                                        className="form-control"
                                        onChange={e => setSiteNote(e.target.value)}>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleSiteClose}>
                            İptal
                        </Button>
                        <button type="submit" className="btn btn-primary">Kaydet</button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* //Download Modal */}
            <Modal show={show_download} onHide={handleDownloadClose} animation={false} centered>

                <Modal.Body>
                    <table className="table table-hover mb-0">
                        <tbody>
                            {ret_agents && Array.isArray(ret_agents) ? ret_agents.map((item: any, i: any) => {
                                let download_url = `https://lluparapp.remaxaqua.com/authorization_agreements/documents/${item.id}.docx`;
                                return (
                                    <tr>
                                        <th scope="row">{item.name}</th>
                                        <td><a href={download_url} className="btn btn-sm btn-soft-primary waves-effect waves-light"> <DownloadOutlined style={{ fontSize: '16px' }} /> </a></td>
                                    </tr>
                                )
                            }) : null}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDownloadClose}>
                        Kapat
                    </Button>
                </Modal.Footer>
            </Modal>
        </Wrapper >
    )
}

export default Edit;