import { useEffect, useState } from 'react';
import axios from 'axios';
import { Radio } from 'antd';
import CustomInput from '../../form/customInput';
import ReactApexChart from 'react-apexcharts';

const TransactionApexLineChart = () => {

    const [transactions_report, setTransactionsReport] = useState<any | null>([]);
    const [send_transaction_filter, setSendTransactionFilter] = useState(true);
    const [seeMonth, setSeeMonths] = useState<number>(24);
    const [seeTransactionType, setSeeTransactionType] = useState<number>(2);
    const [seeChartType, setSeeChartType] = useState<any>('line');
    const [transactionsChartData, setTransactionsChartData] = useState<any>({
        series: [],
        options: {
            chart: {
                type: seeChartType,
                height: 500
            },
            stroke: {
                curve: 'smooth'
              },
            xaxis: {
                categories: []
            }
        }
    });

    useEffect(() => {
        if (transactions_report?.giro_type?.total_transaction?.Satış || transactions_report?.giro_type?.total_giro?.Satış) {
            let salesData: any;
            let rentData: any;
            let totalData: any;
            if (seeTransactionType === 1) {
                salesData = transactions_report.giro_type.total_transaction.Satış;
                rentData = transactions_report.giro_type.total_transaction.Kiralama;
                totalData = transactions_report.giro_type.total_transaction.Toplam;
            } else if (seeTransactionType === 2) {
                salesData = transactions_report.giro_type.total_giro.Satış;
                rentData = transactions_report.giro_type.total_giro.Kiralama;
                totalData = transactions_report.giro_type.total_giro.Toplam;
            }

            const labels = Object.keys(salesData);
            const salesDatasets = labels.map(month => salesData[month]);
            const rentDatasets = labels.map(month => rentData[month]);
            const totalDatasets = labels.map(month => totalData[month]);

            setTransactionsChartData({
                series: [
                    {
                        name: 'Satış',
                        data: salesDatasets
                    },
                    {
                        name: 'Kiralama',
                        data: rentDatasets
                    },
                    {
                        name: 'Toplam',
                        data: totalDatasets
                    }
                ],
                options: {
                    legend: {
                        show: true
                    },
                    chart: {
                        type: seeChartType,
                        height: 350
                    },
                    xaxis: {
                        categories: labels
                    },
                    colors: ['#f04d80', '#6951ce', '#ffcc5a']
                }
            });
        }
    }, [transactions_report]);

    useEffect(() => {
        if (send_transaction_filter) {
            axios.get(`dashboard/transaction-report?filter_see_month=${seeMonth}&filter_see_transaction_type=${seeTransactionType}`)
                .then(({ data }) => {
                    setTransactionsReport(data.transactions.report);
                })
                .catch(error => {
                    console.error('Error fetching transaction report:', error);
                });
            setSendTransactionFilter(false);
        }
    }, [send_transaction_filter]);

    return (
        <div className="card">
            <div className="card-body">
                <div className="d-lg-flex d-block justify-content-between mb-4">
                    <Radio.Group
                        className="d-flex justify-content-center justify-lg-content-end d-sm-block"
                        value={seeTransactionType}
                        onChange={(e) => { setSendTransactionFilter(true); setSeeTransactionType(e.target.value); }}
                        buttonStyle="solid"
                    >
                        <Radio.Button value={2}>Ciro Bazlı Rapor</Radio.Button>
                        <Radio.Button value={1}>İşlem Bazlı Rapor</Radio.Button>
                    </Radio.Group>

                    <Radio.Group
                        className="d-flex justify-content-center justify-lg-content-end d-sm-block"
                        value={seeChartType}
                        onChange={(e) => { setSendTransactionFilter(true); setSeeChartType(e.target.value); }}
                        buttonStyle="solid"
                    >
                        <Radio.Button value={'line'}>Çizgi Grafiği</Radio.Button>
                        <Radio.Button value={'bar'}>Çubuk Grafiği</Radio.Button>
                    </Radio.Group>

                    <div className="d-flex justify-content-center justify-lg-content-end">
                        <div className="align-self-center">
                            Gösterilen Ay Sayısı
                        </div>
                        <div className="ms-2 col-3">
                            <CustomInput
                                type={'number'}
                                required={true}
                                defaultValue={seeMonth}
                                onBlur={(e: any) => { setSeeMonths(e.target.value); setSendTransactionFilter(true); }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-xl-12">
                        <ReactApexChart
                            options={transactionsChartData.options}
                            series={transactionsChartData.series}
                            type={seeChartType}
                            height={500}
                        />
                    </div>
                </div>
            </div>
        </div>
    ); };

export default TransactionApexLineChart;
