import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import Paginator from "../../../components/paginator";
import { Communication } from "../../../models/attribute/communication/communication";

import Swal from 'sweetalert2'


const Communications = () => {
    
    const [communications, setCommunication] = useState([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);

    useEffect(() => {
        (
            async () => {
                const {data} = await axios.get(`attributes/2?page=${page}`);

                setCommunication(data.data);
                setLastPage(data.meta.last_page);
            }
        )()
    }, [page]);

    const del = async (id: number) => {
     
        Swal.fire({
            title: 'Emin misiniz?',
            text: "Kalıcı olarak silinecektir",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c84ee',
            cancelButtonColor: '#fd625e',
            confirmButtonText: 'Evet, sil!'
          }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.delete(`attribute/${id}`);

                setCommunication(communications.filter((u: Communication) => u.id !== id));
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Veri silindi',
                    showConfirmButton: false,
                    timer: 1500
                  })
            }
          })
      
    }

    return(
        
        <Wrapper>
           
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">İletişim Kaynakları</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><NavLink to={'/'}>Gösterge Paneli</NavLink></li>
                                <li className="breadcrumb-item active">İletişim Kaynakları</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>        
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">                                             
                        <div className="card-body">
                        <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <h5 className="card-title">İletişim Kaynakları</h5>
                                    </div>
                                </div>
    
                                <div className="col-md-6">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">                                      
                                        <div>
                                        <Link to="/attribute/communications/create" className="btn btn-light"><i className="bx bx-plus me-1"></i> Yeni Ekle</Link>
                                        </div>
                                    </div>
    
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Başlık</th>
                                            <th>Öncelik</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {communications.map((result: Communication) => {
                                            return (
                                                <tr key={result.id}>
                                                    <td>{result.id}</td>
                                                    <td>{result.name}</td>                                                                                                        
                                                    <td>{result.ordernum}</td>                                                                                                        
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="bx bx-dots-horizontal-rounded"></i>
                                                            </button>
                                                            
                                                            <ul className="dropdown-menu dropdown-menu-end">
                                                                <li>
                                                                    <Link to={`/attribute/communications/${result.id}/edit`}
                                                                    className="dropdown-item">Güncelle</Link>
                                                                </li>
                                                                <li><a className="dropdown-item" onClick={() => del(result.id)}>Sil</a></li>
                                                            </ul>
                                                        </div>                                                       
                                                    </td>
                                                </tr>
                                            )
                                        })}                                      
                                    </tbody>
                                </table>
                            </div>
                            <Paginator page={page} lastPage={lastPage} pageChanged={setPage}/>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Communications;