import { Link } from "react-router-dom";
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { AiOutlineUserSwitch } from 'react-icons/ai';
import { BiMapPin } from 'react-icons/bi';

//Table
import type { ColumnsType } from 'antd/es/table';

import { Table, Tag } from "antd";
import ProfilePhoto from "../../user/profilePhoto";
import { NumericFormat } from "react-number-format";
import { useEffect, useState } from "react";
import { GiroReport } from "../../../models/report/giro/giroReport";
import { RankingAgent } from "../../../models/report/giro/rankingAgent";
import axios from "axios";

import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
dayjs.locale("tr");

//Table
interface DataType {
  property_genus_id: number;
  property_type_id: number;
  id: number;
  agent_id: number;
  agent: {
    first_name: string;
    last_name: string;
  };
  total_giro: any;
  sales_total_service_fee: any;
  rents_total_service_fee: any;
  total: any;
  total_sale: any;
  total_rent: any;
}

const AgentGiroList = ({ start_date, end_date }: any) => {
  const rowClassName = () => 'no-bottom-border'; // Özel sınıf adı

  const [show_id, setShowID] = useState<any | null>(0);
  const [show, setShow] = useState(false);

  const [sort, setSort] = useState(false);
  const [sort_url, setSortUrl] = useState<any | null>('&special_sort[by]=total_giro&special_sort[order]=desc');
  const [sort_req, setSortReq] = useState<any | null>([]);


  const [reports, setReports] = useState<GiroReport | any | null>(null);
  const [giro_type_data, setGiroTypeData] = useState<RankingAgent | any | null>();

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<any | null>({
    current: 1,
    pageSize: 5,
    currentPageSize: 100,
    total: reports?.total,
    pageSizeOptions: [10, 20, 50, 100, 500, 100],
    showQuickJumper: true,
    showSizeChanger: true,
    showLessItems: true,
    responsive: true,
  });

  const [data, setData] = useState<DataType[]>([]);

  
  useEffect(() => {
    (
      async () => {
        setLoading(true);
        axios.get(`giros-ranking-agents?limit=${pagination.pageSize}${sort_url}&page=${pagination.current}&filter_between[]=${start_date}&filter_between[]=${end_date}`)
          .then(({ data }) => {
            setData(data.transactions.data.data);
            setGiroTypeData(data.propertyTypes.data.data);
            setPagination({
              current: pagination.current,
              pageSize: pagination.pageSize,
              currentPageSize: pagination.currentPageSize,
              total: data.transactions.report?.total,
            });
            setLoading(false);
          });

      }
    )()
  }, []);


  //Table
  const columns: ColumnsType<DataType> = [
    {
      title: 'Gayrimenkul Danışmanı',
      dataIndex: 'agent',
      sorter: false,
      render: (agent, record) => {

        return (
          <>


              <div className="d-flex align-items-center pb-0">
                <div className="avatar-md me-4">
                  <ProfilePhoto id={agent.id} imgHeight={48} />
                </div>
                <div className="flex-grow-1">
                  <h5 className="font-size-15 mb-1">{`${agent.first_name} ${agent.last_name}`}</h5>
                  <span className="text-muted"> <span className="text-muted">
                    <NumericFormat
                      value={record?.total_giro.toString().replace('.', ',')}
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                    /> ₺ </span></span>
                </div>
              </div>
          </>
        );
      },
    },
    Table.EXPAND_COLUMN
  ];


  return (
    <>
      <Table
        className="table table-striped"
        rowClassName={rowClassName} 
        size="small"
        columns={columns}
        dataSource={data}
        loading={loading}
        showHeader={false}
        scroll={{ y: '100%', x: true }}
        rowKey={(record) => `${record.agent_id}`}
        pagination={{
          ...pagination,
          position: ['bottomCenter']
        }}
        expandable={{
          expandedRowRender: (record) => {
            let results = giro_type_data.filter((giro_type_data: { agent_id: number; }) => giro_type_data.agent_id === record.agent_id);
            return (
              <>
                <div className="table-reponsive">
                  <table className={`table`}>
                    <thead>
                      <tr>
                        <th>İşlem Türleri</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {results.map((prop_type: RankingAgent) => (
                            <Tag color={prop_type.giro_type_id === 116 ? 'red' : 'geekblue'}>{prop_type.propertyGenusName} {prop_type.propertyTypeName} <strong>({prop_type.total_giro})</strong></Tag>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )
          },
          rowExpandable: (record) => record.total !== 'Not Expandable',
        }}
        onChange={(val: any, filter: any, sorter: any, extra: any) => {
          setPagination({
            current: val.current,
            pageSize: val.pageSize,
            currentPageSize: val.currentPageSize
          });
          setSortReq(sorter);
          setSort(true);
        }}
      />
    </>
  );
}

export default AgentGiroList;

