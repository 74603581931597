import React from 'react';
import { Select, SelectProps } from 'antd';

const { Option } = Select;

interface AntDesignSelectProps extends SelectProps<any> {
  options: { label: React.ReactNode; value: string | number }[];
  isMulti?: boolean;
}

const normalizeText = (text: string) => {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

const AntDesignSelect: React.FC<AntDesignSelectProps> = ({ options, isMulti = false, ...props }) => {
  return (
    <Select
      mode={isMulti ? 'multiple' : undefined}
      showSearch
      filterOption={(input, option) => {
        const normalizedInput = normalizeText(input);
        const normalizedLabel = normalizeText(option?.children as unknown as string);
        return normalizedLabel.includes(normalizedInput);
      }}
      {...props}
    >
      {options.map(option => (
        <Option
          key={option.value}
          value={option.value}
          title={typeof option.label === 'string' ? option.label : ''}
        >
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default AntDesignSelect;
