import { apiClient } from '../config';
import { handleApiError } from '../utils/errorHandler';
import { WATCH_SCHEDULE_ACTION_ENDPOINT } from '../endpoints/watchScheduleActionEndpoints';
import { WatchScheduleActions } from '../../models/asistant/watchSchedule/watchScheduleAction';
import { WatchScheduleActionResponse } from '../../models/asistant/watchSchedule/WatchScheduleActionResponse';

export const fetchWatchScheduleActions = async (params?: Record<string, any>): Promise<WatchScheduleActionResponse> => {
  try {
    const { data } = await apiClient.get(WATCH_SCHEDULE_ACTION_ENDPOINT, { params });
    return data;
  } catch (error) {
    handleApiError(error);
    return new WatchScheduleActionResponse();
  }
};

export const createWatchScheduleAction = async (watchScheduleActionData: WatchScheduleActions) => {
  try {
    return await apiClient.post(WATCH_SCHEDULE_ACTION_ENDPOINT, watchScheduleActionData);
  } catch (error) {
    handleApiError(error);
  }
};

export const updateWatchScheduleAction = async (id: number, watchScheduleActionData: WatchScheduleActions) => {
  try {
    return await apiClient.put(`${WATCH_SCHEDULE_ACTION_ENDPOINT}/${id}`, watchScheduleActionData);
  } catch (error) {
    handleApiError(error);
  }
};

export const deleteWatchScheduleAction = async (id: number) => {
  try {
    return await apiClient.delete(`${WATCH_SCHEDULE_ACTION_ENDPOINT}/${id}`);
  } catch (error) {
    handleApiError(error);
  }
};
