import React, {Component, SyntheticEvent,useState ,useEffect} from 'react';
import Wrapper from "../components/layout/wrapper";
import { Row,Container,Col} from 'react-bootstrap';
import axios from 'axios';
import {Link, Navigate} from 'react-router-dom';

class Register extends Component {
    first_name = '';
    last_name = '';
    email = '';
    password = '';
    password_confirm = '';
    state = {
        redirect: false
    };

    submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const response=await axios.post('register', {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            password: this.password,
            password_confirm: this.password_confirm,
        });
        this.setState({
            redirect: true
        });
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={'/login'}/>;
        }

        return (
           <div className="auth-page">
           <Container fluid className="p-0">
                <Row className="g-0">
                    <Col xxl={3} lg={4} md={5}>
                        <div className="auth-full-page-content d-flex p-sm-5 p-4">
                            <div className="w-100">
                                <div className="d-flex flex-column h-100">
                                    <div className="mb-4 mb-md-5 text-center">
                                        <a href="index.html" className="d-block auth-logo">
                                            <img src="assets/images/logo-sm.svg" alt="" height="28"/> <span className="logo-txt">REMAPP</span>
                                        </a>
                                    </div>
                                    <div className="auth-content my-auto">
                                        <div className="text-center">
                                            <h5 className="mb-0">Hoş Geldiniz</h5>
                                            <p className="text-muted mt-2">Kaydol</p>
                                        </div>
                                        <div className="mt-4 pt-2">
                                            <form onSubmit={this.submit}>
                                                <div>
                                                    <div className="form-floating form-floating-custom mb-4">
                                                        <input type="text" className="form-control" name="name" id="input-name" placeholder="First Name" required
                                                        onChange={e => this.first_name = e.target.value}/>
                                                        <label htmlFor="input-name">Ad</label>
                                                        <div className="form-floating-icon">
                                                            <i data-feather="users"></i>
                                                        </div>                                                        
                                                    </div>
                                                    <div className="form-floating form-floating-custom mb-4">
                                                        <input type="text" className="form-control" name="name" id="input-name" placeholder="Last Name" required
                                                        onChange={e => this.last_name = e.target.value}/>
                                                        <label htmlFor="input-name">Soyad</label>
                                                        <div className="form-floating-icon">
                                                            <i data-feather="users"></i>
                                                        </div>                                                        
                                                    </div>

                                                    <div className="form-floating form-floating-custom mb-4">
                                                        <input type="email" name="email" className="form-control" id="input-email" placeholder="Email" required
                                                        onChange={e => this.email = e.target.value}/>
                                                        <label htmlFor="input-email">Email</label>
                                                        <div className="form-floating-icon">
                                                        <i data-feather="users"></i>
                                                        </div>                                                        
                                                    </div>

                                                    <div className="form-floating form-floating-custom mb-4 auth-pass-inputgroup">
                                                        <input type="password" name="password" className="form-control pe-5" id="password-input" placeholder="Password" required
                                                        onChange={e => this.password = e.target.value}/>                                                    
                                                        <button type="button" className="btn btn-link position-absolute h-100 end-0 top-0" id="password-addon">
                                                            <i className="mdi mdi-eye-outline font-size-18 text-muted"></i>
                                                        </button>
                                                        <label htmlFor="input-password">Şifre</label>
                                                        <div className="form-floating-icon">
                                                            <i data-feather="lock"></i>
                                                        </div>                                                        

                                                    </div>

                                                    <div className="form-floating form-floating-custom mb-4 auth-pass-inputgroup">
                                                        <input type="password" name="password_confirmation" className="form-control pe-5" id="password-input" placeholder="Password Confirm" required
                                                        onChange={e => this.password_confirm = e.target.value}
                                                        />                                                        
                                                        <button type="button" className="btn btn-link position-absolute h-100 end-0 top-0" id="password-addon">
                                                            <i className="mdi mdi-eye-outline font-size-18 text-muted"></i>
                                                        </button>
                                                        <label htmlFor="input-password">Şifre Tekrar</label>
                                                        <div className="form-floating-icon">
                                                            <i data-feather="lock"></i>
                                                        </div>                                                        
                                                    </div>

                                                    <div className="mb-3">
                                                        <button className="btn btn-primary w-100 waves-effect waves-light" type="submit">Kaydol</button>
                                                    </div>
                                                </div>
                                            </form>                                             
                                        </div>

                                        <div className="mt-4 pt-2 text-center">
                                            <div className="signin-other-title">
                                                <h5 className="font-size-14 mb-3 text-muted fw-medium">- Veya -</h5>
                                            </div>

                                            <ul className="list-inline mb-0">
                                                <li className="list-inline-item">
                                                    <a href="javascript:void()"
                                                        className="social-list-item bg-primary text-white border-primary">
                                                        <i className="mdi mdi-facebook"></i>
                                                    </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href="javascript:void()"
                                                        className="social-list-item bg-info text-white border-info">
                                                        <i className="mdi mdi-twitter"></i>
                                                    </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href="javascript:void()"
                                                        className="social-list-item bg-danger text-white border-danger">
                                                        <i className="mdi mdi-google"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="mt-5 text-center">
                                            <p className="text-muted mb-0">
                                                <Link to="/forgot-password" className="text-primary fw-semibold">Şifremi unuttum</Link>
                                                </p>
                                        </div>
                                    </div>
                                    <div className="mt-4 mt-md-5 text-center">
                                        <p className="mb-0">© <script>document.write(new Date().getFullYear())</script> LLupar   . Crafted with <i className="mdi mdi-heart text-danger"></i> by Rapull Soft</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col> 
                    <Col xxl={9} lg={8} md={7}>
                        <div className="auth-bg pt-md-5 p-4 d-flex">
                            <div className="bg-overlay"></div>
                            <ul className="bg-bubbles">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                           
                        </div>
                    </Col>               
                </Row>        
            </Container>
        </div>
        );
    }
}
export default Register;