import React from 'react';
import { Calendar, ConfigProvider } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/tr';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import trLocale from 'antd/lib/locale/tr_TR';
import { CalendarProps } from 'antd/es/calendar';

dayjs.extend(localeData);
dayjs.extend(updateLocale);

dayjs.updateLocale('tr', {
    weekStart: 1,
    weekdays: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
    weekdaysShort: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
});

interface CustomCalendarProps extends CalendarProps<Dayjs> {
    dateCellRender?: (date: Dayjs) => React.ReactNode;
    monthCellRender?: (date: Dayjs) => React.ReactNode;
}

const AntDesignCalendar: React.FC<CustomCalendarProps> = ({
    dateCellRender,
    monthCellRender,
    headerRender,
    ...rest
}) => {
    return (
        <ConfigProvider locale={{
            ...trLocale,
            Calendar: {
                ...(trLocale.Calendar || {}),
                lang: {
                    ...((trLocale.Calendar && trLocale.Calendar.lang) || {}),
                    shortWeekDays: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
                },
            },
        }}>
            <Calendar
                dateCellRender={dateCellRender}
                monthCellRender={monthCellRender}
                headerRender={headerRender}
                {...rest}
            />
        </ConfigProvider>
    );
};

export default AntDesignCalendar;
